import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Header } from '@ui';
import { ReactComponent as PrimaryLogo } from 'resources/app-logos/primary-logo.svg';

const HeaderBarWithLogo = () => {
  return (
    <Root>
      <StyledLogo />
    </Root>
  );
};

const Root = styled(Header)`
  width: 100%;
  height: auto;
  padding: 20px 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    padding: 30px;
    padding-top: 100px;
  }
`;

const StyledLogo = styled(PrimaryLogo)`
  width: 350px;
`;

export default HeaderBarWithLogo;
