import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { TransferTemplateTypes } from '../../../../../../enums/accounting/transfers';
import { INewTransferTemplateFormValues } from '../../../../../../typings/accounting/transfer';

// components
import { SectionIntro } from '@ui';
import WireTransferTemplateForm from './WireTransferTemplateForm';
import GeneralTemplateDataSection from './GeneralTemplateDataSection';
import InternalTransferTemplateForm from './InternalTransferTemplateForm';
import BlockchainTransferTemplateForm from './BlockchainTransferTemplateForm';

interface IProps {
  disabledTemplateTypeField?: boolean;
  isEditMode?: boolean;
  isViewOnly?: boolean;
}

const InnerForm = memo(
  ({ disabledTemplateTypeField, isEditMode, isViewOnly }: IProps) => {
    const { t } = useTranslation('transfers');
    const form = useFormikContext<INewTransferTemplateFormValues>();

    const renderTemplateName = (templateType: TransferTemplateTypes | null) => {
      if (!templateType) {
        return null;
      }

      return (
        <SectionIntro
          title={t(
            `transfer_templates.transfer_template_types.${TransferTemplateTypes[templateType]}`,
          )}
        />
      );
    };

    const renderTransferForm = (templateType: TransferTemplateTypes) => {
      switch (templateType) {
        case TransferTemplateTypes.Internal:
          return (
            <InternalTransferTemplateForm
              isViewOnly={isViewOnly}
              isEditMode={isEditMode}
            />
          );

        case TransferTemplateTypes.Wire:
          return (
            <WireTransferTemplateForm
              isViewOnly={isViewOnly}
              isEditMode={isEditMode}
            />
          );

        case TransferTemplateTypes.Blockchain:
          return <BlockchainTransferTemplateForm isViewOnly={isViewOnly} />;

        default:
          return null;
      }
    };

    return (
      <>
        <GeneralTemplateDataSection
          disabledTemplateTypeField={disabledTemplateTypeField}
          isEditMode={isEditMode}
          isViewOnly={isViewOnly}
        />
        {renderTemplateName(form.values.templateType as TransferTemplateTypes)}
        {renderTransferForm(form.values.templateType as TransferTemplateTypes)}
      </>
    );
  },
);

export default InnerForm;
