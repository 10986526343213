import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { securityAPI } from 'api/profile/securityAPI';
import { FormikHelpers } from 'formik';
import { Enable2FAuthenticatorValidationSchema } from 'validations/profile/security';

// components
import { Form, ModalDialog, RequiredPropsForModalDialogModel } from '@ui';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import VerificationCode from './VerificationCode';
import TFAVerificationForm, {
  FormValuesModel,
} from '../../../../Forms/TemplateForms/UserProfile/TFAVerificationForm';

type IProps = RequiredPropsForModalDialogModel;

const GetVerificationCodeModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation(['profile', 'server_errors']);
  const [otpCode, setOtpCode] = useState<string | undefined>();
  const isAuthenticatorEnabled = useSelector<StateModel, boolean>(
    (state) => !!state.auth.profileData?.isTwoFactorAuthenticationEnabled,
  );

  useEffect(() => {
    if (!isVisible && otpCode) {
      setOtpCode(undefined);
    }
  }, [isVisible]);

  const canGetAuthenticationCode = useMemo(() => {
    return isVisible && !isAuthenticatorEnabled;
  }, [isVisible, isAuthenticatorEnabled]);

  const { response, loading } = useFetch(() => {
    return canGetAuthenticationCode
      ? securityAPI.fetchIdentityVerificationCode(otpCode)
      : null;
  }, [canGetAuthenticationCode]);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      code: '',
    };
  }, []);

  const handleTFAFormSubmit = async (values: FormValuesModel) => {
    if (values.code) {
      const response = await securityAPI.fetchIdentityVerificationCode(
        values.code,
      );
      setOtpCode(response.code);
    }
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201014': {
        formikHelpers.setFieldError(
          'code',
          t('1201014', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={handleTFAFormSubmit}
      onSubmitError={handleOnSubmitError}
      initialValues={isVisible ? initialFormValues : null}
      validationSchema={Enable2FAuthenticatorValidationSchema}
      renderForm={
        <ModalDialog
          width={650}
          title={t('security.identity_verification.get_code_title')}
          isVisible={isVisible}
          closeModalAfterError={false}
          closeCallback={closeCallback}
          hideFooterButtons
        >
          <LoadingWrapper loading={loading}>
            {response || otpCode ? (
              <VerificationCode code={response?.code || otpCode || ''} />
            ) : (
              <TFAVerificationForm />
            )}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default GetVerificationCodeModal;
