import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { Route } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { TWO_COL_XL_GRID_SIZES } from 'constants/grids';
import { LocalizationNamespaces } from 'constants/localization';
import { StateModel as UserAccessStateModel } from 'redux/reducers/userAccess';

// components
import NavButton from './NavButton';
import { Outlet } from 'react-router-dom';
import { Col, Row } from '@ui';

const SectionDashboardPage = () => {
  const { t } = useTranslation(LocalizationNamespaces);
  const currentRoute = useCurrentRoute();
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );

  const renderRoutes = (routes: Route[]) => {
    const allowedRoutes = routes.filter(
      ({ access }) =>
        !access?.permission ||
        (access.permission && permissions[access.permission].isAllowed),
    );

    return allowedRoutes.map(({ path, title }) => (
      <StyledCol key={path} {...TWO_COL_XL_GRID_SIZES}>
        <NavButton path={path}>{t(title.key, { ns: title.ns })}</NavButton>
      </StyledCol>
    ));
  };

  return (
    <>
      <RowWrapper justify="space-between">
        {currentRoute ? renderRoutes(currentRoute.children || []) : null}
      </RowWrapper>
      <Outlet />
    </>
  );
};

const RowWrapper = styled(Row)`
  margin: -${({ theme }) => theme.paddingMd} -${({ theme }) => theme.paddingXl};
`;

const StyledCol = styled(Col)`
  padding: ${({ theme }) => theme.paddingXl};
`;

export default SectionDashboardPage;
