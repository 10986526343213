import * as yup from 'yup';
import {
  STRING_VALIDATION_RULE,
  NULLABLE_NUMBER_VALIDATION_RULE,
} from '../common';

export const ApplicationDocumentationValidationSchema = yup.object().shape({
  additionalFields: yup.array().of(
    yup.object().shape({
      value: yup.mixed().when('isNotProvidedReason', {
        is: false,
        then: (schema) =>
          schema.when((value: unknown[], schema: any) => {
            if (!value.length) {
              return schema;
            }

            // YUP returns a value in a array
            // TODO: rework this switch to depend on type of the additional field (not typeof)
            switch (typeof value[0]) {
              case 'object': {
                if (Array.isArray(value[0])) {
                  return yup.array();
                } else {
                  return schema;
                }
              }
              case 'number': {
                return NULLABLE_NUMBER_VALIDATION_RULE;
              }
              case 'string': {
                return STRING_VALIDATION_RULE;
              }
              default:
                return schema;
            }
          }),
      }),

      notProvidedReason: STRING_VALIDATION_RULE.max(250, {
        key: 'form:validation_messages.max_characters',
        values: { number: 250 },
      })
        .required({ key: 'form:validation_messages.required' })
        .when('isNotProvidedReason', {
          is: false,
          then: () => STRING_VALIDATION_RULE,
        }),
    }),
  ),

  additionalDocuments: yup.array().of(
    yup.object().shape({
      name: STRING_VALIDATION_RULE.required({
        key: 'form:validation_messages.required',
      }),
      description: STRING_VALIDATION_RULE,
      documents: yup
        .array()
        .min(1, { key: 'form:validation_messages.required' }),
    }),
  ),
});
