import * as Yup from 'yup';
import { PASSWORD_VALIDATION_RULE, STRING_VALIDATION_RULE } from '../common';

export const ChangePasswordValidationSchema = Yup.object().shape({
  oldPassword: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  newPassword: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  confirmNewPassword: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('newPassword'), ''],
    'form:validation_messages.required',
  ).required('form:validation_messages.required'),
});
