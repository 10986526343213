import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormField } from '@ui';
import { FormInput } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('profile');
  return (
    <FormField
      name="name"
      component={FormInput}
      label={t(
        'security.two_step_verification.security_key.edit_key.form_fields.name',
      )}
    />
  );
};

export default InnerForm;
