import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { ContentProps } from './types';

/**
 * The `Content` component represents the main area of the page layout,
 * typically containing the primary content of the page.
 *
 * @param {ContentProps} props - The properties defined in `ContentProps`.
 */
const Content: React.FC<ContentProps> = ({ className, children, style }) => {
  return (
    <AntdLayout.Content className={className} style={style}>
      {children}
    </AntdLayout.Content>
  );
};

export default Content;
