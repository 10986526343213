import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { AccountTypes } from '../../enums/onboarding/crm';
import { IAccountModel } from '../../typings/onboarding/account';
import { AccountProcessingTypes } from '../../enums/accounting/transfers';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import { FormValuesModel as SendWireTransactionFormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/Accounts/ReceiveMoneyNativeModalDialog';

interface AccountSupplementaryModel {
  type: AccountTypes | null;
  purpose: string;
  initialDepositOrigin: string;
  initialDepositFrom: number;
  initialDepositTo: number;
  incomingWiresNumberFrom: number;
  incomingWiresNumberTo: number;
  outgoingWiresNumberFrom: number;
  outgoingWiresNumberTo: number;
  estimatedIncomingFundsFrom: number;
  estimatedIncomingFundsTo: number;
  estimatedOutgoingFundsFrom: number;
  estimatedOutgoingFundsTo: number;
  jurisdictions: string[];
}

export interface NewAccountModel {
  currencyId: number;
  accountName: string;
  accountSupplementary?: AccountSupplementaryModel;
}

interface FetchAccountsParamsModel extends FetchParamsModel {
  search?: string;
  status?: string;
  processingType?: AccountProcessingTypes;
}

export interface WireDetailsModel {
  beneficiaryCountry: string;
  beneficiaryAddress: string;
  beneficiaryCity: string;
  beneficiaryDistrict: string;
  beneficiaryPostCode: string;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;
  beneficiaryBankName: string;
  beneficiaryBankCode: string;
  beneficiaryBankCity: string;
  beneficiaryBankDistrict: string;
  beneficiaryBankPostCode: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress: string;
  beneficiaryBankCodeType: string;
  intermediaryBankName: string;
  intermediaryBankCode: string;
  intermediaryBankCountry: string;
  intermediaryBankAddress: string;
  intermediaryBankCodeType: string;
  intermediaryBankCity: string;
  intermediaryBankDistrict: string;
  intermediaryBankPostCode: string;
  isManualBeneficiary: boolean;
  reference: string;
}

interface FetchEligibleBankAccountsForInternalTransferProps
  extends FetchParamsModel {
  search: string;
}

interface FetchApprovalGroupsByAccountNumberResponse {
  items: { name: string }[];
}

const accountsAPI = {
  fetchAccounts: (params: FetchAccountsParamsModel) => {
    return APIService.get(`${APIConfig.accountingApi}/accounts`, {
      params,
    }).then<FetchResponseModel<IAccountModel>>(({ data }) => data);
  },

  fetchEligibleAccountsForInternalTransfer: (
    accountNumber: string,
    params: FetchEligibleBankAccountsForInternalTransferProps,
  ) => {
    return APIService.get(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/internal-transfer`,
      { params },
    ).then<FetchResponseModel<IAccountModel>>(({ data }) => data);
  },

  getAccountProcessingType: (accountNumber: string) => {
    return APIService.get<{ processingType: AccountProcessingTypes }>(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/processing-type`,
    ).then(({ data }) => data);
  },

  fetchAccountByNumber: (accountNumber: string) => {
    return APIService.get(
      `${APIConfig.accountingApi}/accounts/${accountNumber}`,
    ).then<IAccountModel>(({ data }) => data);
  },

  createAccount: (newAccount: NewAccountModel) => {
    return APIService.post(
      `${APIConfig.accountingApi}/accounts`,
      newAccount,
    ).then(({ data }) => data);
  },

  getApprovalGroupsByAccountNumber: (accountNumber: string) => {
    return APIService.get<FetchApprovalGroupsByAccountNumberResponse>(
      `${APIConfig.approvalApi}/transaction/groups/by-account-number`,
      {
        params: { accountNumber },
      },
    ).then(({ data }) => data);
  },

  fetchWireInstructionsByAccount: (accountNumber: string) => {
    return APIService.get<WireDetailsModel>(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/wire/details`,
    ).then(({ data }) => data);
  },

  sendWireInstructions: (
    values: SendWireTransactionFormValuesModel,
    accountNumber: string,
  ) => {
    return APIService.post(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/wire/details`,
      values,
    ).then(({ data }) => data);
  },
};

export { accountsAPI };
