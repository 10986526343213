import React, { ReactNode, useEffect, useRef, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { uploadFiles } from 'redux/actions/upload';
import { DocumentHelpers } from 'helpers/documents';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as UploadStateModel } from 'redux/reducers/upload';
import {
  IDocumentAssociationInfo,
  IDocumentBase,
  ShortDocumentModel,
} from 'typings/documents/documents';

// components
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Text, Row, Col, Modal } from '@ui';

interface IProps {
  name: string;
  association: IDocumentAssociationInfo;
  tags?: string[];
  accept?: string;
  setLoading?: (isLoading: boolean) => void;
  onUpload: (documents: ShortDocumentModel[]) => void;
  children?: ReactNode;
}
// TODO: we need to delete this component since we already have one more UploadDocumentButton
// Need to do a code refactoring
const UploadDocumentButton = ({
  name,
  association,
  tags = [],
  accept,
  setLoading,
  onUpload,
  children,
}: IProps) => {
  const { t } = useTranslation('documents');
  const dispatch = useDispatch();
  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const uploadInputRef = useRef<any>(null);

  const [shouldSaveDocuments, setShouldSaveDocuments] = useState(false);

  // Handle upload finished event
  // Since we're using core upload document service, the files can be uploaded from another field
  // e.g. we have 2 or more upload document fields in one form:
  // so in this after upload we should update only the field from where user started uploading some files
  // So for this we have shouldSaveDocuments variable, which allows us to know whether we need to update the value
  useEffect(() => {
    const { isFinished, files } = uploadState;

    // Check if the upload process was finished and whether the upload process was started from this field
    if (isFinished && shouldSaveDocuments) {
      const relatedFiles = Object.keys(files)
        .map((key) => {
          let result: IDocumentBase | null = null;

          if (files[key].relatedToFieldName === name) {
            result = files[key].dataBaseEntry;
          }

          return result;
        })
        .filter(Boolean) as IDocumentBase[];

      const filteredFiles: ShortDocumentModel[] = relatedFiles.map((e) => ({
        id: e._id,
        name: e.name,
        file: e.files && e.files[0],
      }));

      onUpload(filteredFiles);
      setLoading && setLoading(false);
      setShouldSaveDocuments(false);
    }
  }, [uploadState.isFinished]);

  const onBrowseClick = () => {
    uploadInputRef.current.click();
  };

  const onFileSelect = () => {
    if (uploadInputRef.current && uploadInputRef.current.files.length) {
      handleFilesSelect(
        Array.prototype.slice.call(uploadInputRef.current.files),
      );

      uploadInputRef.current.value = '';
    }
  };

  const handleFilesSelect = (files: File[]) => {
    if (!files.length) {
      return;
    }

    const allowedFiles = DocumentHelpers.verifyFilesThatCanBeUploaded(files);

    if (allowedFiles.rejected.length) {
      Modal.error({
        width: 650,
        title: t('upload_documents_warning_modal.title'),
        content: (
          <>
            {allowedFiles.rejected.map((e, i) => (
              <Row key={i} wrap={false} gutter={[16, 16]}>
                <Col span={6}>
                  <Text key={i} variant="body1">
                    <EllipsisTooltip
                      maxTextContainerWidth="100%"
                      title={e.file.name}
                    >
                      {e.file.name}
                    </EllipsisTooltip>
                  </Text>
                </Col>
                <Col flex="auto">
                  <Text key={i} variant="body1">
                    {t(`upload_documents_warning_modal.errors.${e.reason}`)}
                  </Text>
                </Col>
              </Row>
            ))}
          </>
        ),
      });
    }

    if (allowedFiles.approved.length) {
      const formattedDocuments = allowedFiles.approved.map((file) =>
        DocumentHelpers.formatFileToLocalDocument(file, tags),
      );
      dispatch(uploadFiles([...formattedDocuments], association, name));
      setLoading && setLoading(false);
      setShouldSaveDocuments(true);
    }
  };

  return (
    <div onClick={onBrowseClick}>
      {children}
      <input
        ref={uploadInputRef}
        type="file"
        multiple
        accept={accept}
        style={{ display: 'none' }}
        onChange={() => onFileSelect()}
      />
    </div>
  );
};

export default UploadDocumentButton;
