import * as yup from 'yup';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  OTP_VALIDATION_RULE,
} from '../common';

export const InvitationCodeValidation = yup.object().shape({
  code: OTP_VALIDATION_RULE.required('form:validation_messages.required'),

  termsOfUse: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:warnings.terms_and_conditions_must_be_accepted',
  ).oneOf([true], 'form:warnings.terms_and_conditions_must_be_accepted'),
});
