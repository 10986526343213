import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ApprovalEntryNewModel } from 'typings/approvalManagement/accountManagement';
import { AccountForApprovalModel } from 'typings/accounting/accounts';

// constants
import { ApprovalWorkflowChangeTypes } from 'enums/approvalManagement/approvalManagement';

// components
import { SectionIntro } from '@ui';
import InnerTemplate from './InnerTemplate';

export interface BankAccountTemplateData {
  bankAccount: AccountForApprovalModel;
}

interface IProps {
  approvalEntry: ApprovalEntryNewModel<BankAccountTemplateData>;
}

const BankAccount = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderContent = () => {
    switch (approvalEntry.entry.changeType) {
      case ApprovalWorkflowChangeTypes.Create:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as BankAccountTemplateData}
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Delete:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.removed')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as BankAccountTemplateData}
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Edit:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.old')}
            />
            <InnerTemplate
              data={approvalEntry.changes.old as BankAccountTemplateData}
            />

            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as BankAccountTemplateData}
            />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default BankAccount;
