import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import { FormField } from '@ui';
import { FormInput } from '@ui';
import BlockchainCurrencySelect, {
  CurrencyAutocompleteProps,
} from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/BlockchainCurrencySelect';

const CircleBankAccountForm = () => {
  const { t } = useTranslation('accounts');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <FormField
        label={t('account_summary.create_account.form_fields.account_name')}
        name="circleAccount.accountName"
        component={FormInput}
      />

      <FormField<CurrencyAutocompleteProps>
        disabled
        label={t('account_summary.create_account.form_fields.currency')}
        name="circleAccount.currencyId"
        component={BlockchainCurrencySelect}
      />
    </>
  );
};

export default CircleBankAccountForm;
