import React, { useCallback } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { IKeyRelationshipTemplate } from 'typings/application/key-relationship-templates';

// components
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import TitleSection from '../TitleSection';
import { Text, IconSVG, Col, Row, Link } from '@ui';
import { ReactComponent as ArrowRight } from 'resources/icons/remix-icons/arrow-right-s-line.svg';

interface IProps {
  organizationTypes: IKeyRelationshipTemplate[];
  onSelect: (organizationTypeId: string, description: string) => void;
}

const ChooseOrganizationTypeStep = ({
  organizationTypes,
  onSelect,
}: IProps) => {
  const { t } = useTranslation(['auth', 'common']);

  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const renderOrganizationTypes = useCallback(() => {
    return organizationTypes.map((e) => (
      <Col span={12} key={e._id}>
        <InfoTooltip tooltipContent={e.description}>
          <StyledOption onClick={() => onSelect(e._id, e.description)}>
            <ColumnWrapper>
              <Text variant="h3" weight="semi-bold">
                {e.name}
              </Text>
            </ColumnWrapper>
            <IconSVG
              component={ArrowRight}
              size="middle"
              color={colorsTheme.colorWhite}
            />
          </StyledOption>
        </InfoTooltip>
      </Col>
    ));
  }, [organizationTypes.length]);

  const goBack = () => {
    setFieldValue('step', values.step - 1);
  };

  return (
    <>
      <TitleSection title={t('open_an_account.choose_entity_type')} />
      {!!organizationTypes.length && (
        <StyledRow gutter={[24, 24]}>{renderOrganizationTypes()}</StyledRow>
      )}
      <StyledLink onClick={goBack} variant="h5">
        Go {t('back', { ns: 'common' })}
      </StyledLink>
    </>
  );
};

const StyledOption = styled.div`
  height: 100px;

  padding: ${({ theme }) => `${theme.paddingMd} ${theme.paddingMd}`};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border: ${({ theme }) => `2px solid ${theme.colorDarkL2}`};
  border-radius: ${({ theme }) => theme.borderRadiusRegular};
  background-color: ${({ theme }) => theme.colorDarkL2};

  cursor: pointer;
  transition: ${({ theme }) => theme.defaultTransition};

  &:hover {
    border-color: ${({ theme }) => theme.colorPrimary};

    .ant-typography {
      color: ${({ theme }) => theme.colorWhite};
    }
  }
`;

const StyledRow = styled(Row)`
  padding-bottom: ${({ theme }) => theme.paddingXl};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
`;

export default ChooseOrganizationTypeStep;
