import React from 'react';

// helpers
import { useTranslation } from 'react-i18next';

// components
import { FormField } from '@ui';
import { FormInput } from '@ui';
import BlockchainNetworkSelect from '../../../../../../Forms/FormComponents/SelectInputs/Transfers/BlockchainNetworkSelect';
import AccountProcessingTypeSelect from '../../../../../../Forms/FormComponents/SelectInputs/Transfers/AccountProcessingTypeSelect';

interface IProps {
  isViewOnly?: boolean;
}

const BlockchainTransferTemplateForm = ({ isViewOnly }: IProps) => {
  const { t } = useTranslation('transfers');
  const gridSizes = { xl: 4, lg: 4, md: 5, sm: 24, xs: 24 };

  return (
    <>
      <FormField
        name="transferFields.processingType"
        component={AccountProcessingTypeSelect}
        disabled={isViewOnly}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.circle_template',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.networkChain"
        component={BlockchainNetworkSelect}
        disabled={isViewOnly}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.blockchain_transfer_template.network',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.beneficiaryAddress"
        component={FormInput}
        disabled={isViewOnly}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.blockchain_transfer_template.address',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.beneficiaryTag"
        component={FormInput}
        disabled={isViewOnly}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.blockchain_transfer_template.address_tag',
        )}
        labelCol={gridSizes}
      />
    </>
  );
};

export default BlockchainTransferTemplateForm;
