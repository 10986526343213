import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { InputPhoneNumberProps } from './types';

/**
 * The `InputPhoneNumber` component provides a specialized input for entering and formatting phone numbers.
 * It leverages `react-phone-number-input` to handle international formats and validation.
 * Users can optionally disable the field, specify a placeholder, and handle changes via callbacks.
 *
 * @param {InputPhoneNumberProps} props - The properties defined in `InputPhoneNumberProps`.
 */
const InputPhoneNumber: React.FC<InputPhoneNumberProps> = ({
  value,
  onChange,
  onBlur,
  disabled,
  placeholder,
  name,
  ...rest
}) => {
  return (
    <StyledPhoneInput
      {...rest}
      name={name}
      value={value as string}
      onChange={onChange as any}
      onBlur={onBlur as any}
      disabled={disabled}
      placeholder={placeholder}
      defaultCountry="KY"
      international
      className="ant"
      numberInputProps={{ className: 'ant-input ant-input-lg' }}
    />
  );
};

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputCountry {
    margin-right: 0px;
    padding: 0px 10px;
    border: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
    border-right: 0px;
  }
`;

export default React.memo(InputPhoneNumber);
