import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ErrorModel } from '..';

// components
import { Text, Card } from '@ui';

interface IProps {
  error: ErrorModel;
}

const WarningCard = ({ error }: IProps) => {
  const { t } = useTranslation('server_errors');

  const ErrorDescription = useMemo(() => {
    switch (error.code) {
      case '1016001':
        return (
          <Text variant="body1">
            {t(error.code, {
              relationshipName:
                error.details.relationshipTemplateParentName || '',
              organizationName: error.details.itemName || '',
            })}
          </Text>
        );

      case '1016006':
        return (
          <Text variant="body1">
            {t(error.code, { organizationName: error.details.itemName || '' })}
          </Text>
        );

      case '1016004':
        return (
          <Text variant="body1">
            {t(error.code, {
              relationshipName:
                error.details.relationshipTemplateParentName || '',
              organizationName: error.details.itemName || '',
            })}
          </Text>
        );

      case '1016003':
        return (
          <Text variant="body1">
            {t(error.code, {
              entryName: error.details.itemName,
              actualPercentage: error.details.actualPercentage || '',
              minPercentage: error.details.minPercentage || '',
            })}
          </Text>
        );

      case '1011019':
        return (
          <Text variant="body1">
            {t(error.code, { contactName: error.details.itemName })}
          </Text>
        );

      case '1011020':
        return (
          <Text variant="body1">
            {t(error.code, { organizationName: error.details.itemName })}
          </Text>
        );

      case '1011022':
        return (
          <Text variant="body1">
            {t(error.code, { entryName: error.details.itemName })}
          </Text>
        );

      case '1011023':
        return (
          <Text variant="body1">
            {t(error.code, { entryName: error.details.itemName })}
          </Text>
        );

      case '1011024':
        return (
          <Text variant="body1">
            {t(error.code, { entryName: error.details.itemName })}
          </Text>
        );

      case '1011021':
        return (
          <Text variant="body1">
            {t(error.code, { entryName: error.details.itemName })}
          </Text>
        );

      case '1011025':
        return (
          <Text variant="body1">
            {t(error.code, { contactName: error.details.itemName })}
          </Text>
        );
    }
  }, [error]);

  return <Card>{ErrorDescription}</Card>;
};

export default WarningCard;
