import React from 'react';
import { BreadcrumbProps } from './types';
import { Breadcrumb as AntdBreadcrumb } from 'antd';

/**
 * The `Breadcrumb` component displays a navigation path, showing users where they are
 * in the site's hierarchy and allowing them to navigate back if needed.
 *
 * @param {BreadcrumbProps} props - The properties defined in `BreadcrumbProps`.
 */
const Breadcrumb: React.FC<BreadcrumbProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdBreadcrumb className={className} {...rest}>
      {children}
    </AntdBreadcrumb>
  );
};

export default Breadcrumb;
