import React from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { IconSVG, Popover } from '@ui';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

interface IProps {
  popoverContent: string | React.ReactNode;
}

const WarningWithPopover = ({ popoverContent }: IProps) => {
  return (
    <Popover
      placement="bottom"
      content={popoverContent}
      overlayStyle={{ zIndex: 99999 }}
    >
      <StyledIconSVG color={colorsTheme.colorWarning} component={InfoIcon} />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
  cursor: pointer;
`;

export default WarningWithPopover;
