import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { connectCircleValidationSchema } from 'validations/external-services/circle';

// components
import InnerForm from './InnerForm';
import {
  Form,
  RequiredPropsForFormModel,
  ModalDialog,
  RequiredPropsForModalDialogModel,
} from '@ui';

export interface FormValuesModel {
  circleAPIKey: string | null;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

const ConnectCircleModal = ({
  isVisible,
  closeCallback,
  initialValues,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation('account_management');

  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={connectCircleValidationSchema}
      renderForm={
        <ModalDialog
          title={t('external_services.circle.connect_circle_modal.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default ConnectCircleModal;
