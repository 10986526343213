import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormInputPhoneNumberProps } from './types';

// components
import { InputPhoneNumber } from '@ui';

const FormInputPhoneNumber: React.FC<FormInputPhoneNumberProps> = ({
  validationRules,
  ...rest
}) => {
  const { t } = useTranslation('form');

  const placeholder = useMemo(() => {
    if (!validationRules || !validationRules.length) {
      return t('field_placeholders.input_optional');
    }

    const isRequired = validationRules.find((e) => e.type === 'required');

    if (isRequired) {
      return t('field_placeholders.input_required');
    } else {
      return t('field_placeholders.input_optional');
    }
  }, [validationRules]);

  return <InputPhoneNumber {...rest} placeholder={placeholder} />;
};

export default React.memo(FormInputPhoneNumber);
