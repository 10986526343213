import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';

// components
import { Link } from '@ui';
import AuthContainer from '../../components/Additional/AuthContainer';
import InvitationCode from '../../modules/Invitation/InvitationVerify';

const InvitationVerifyPage = () => {
  const { t } = useTranslation('invitation');

  return (
    <AuthContainer
      footerText={
        <StyledFooterWrapper>
          <Link to={RoutePaths.Auth_Root}>{t('footer_text')}</Link>
        </StyledFooterWrapper>
      }
    >
      <InvitationCode />
    </AuthContainer>
  );
};

const StyledFooterWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginXs};
  text-align: center;
`;

export default InvitationVerifyPage;
