import React, { memo, useState } from 'react';

// helpers
import { IAccountModel } from '../../../../../typings/onboarding/account';

// components
import AddBlockchainAddress from './AddBlockchainAddress';
import AccountAddressesTable from './AccountAddressesTable';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  account: IAccountModel;
}

const ReceiveMoneyModalDialog = memo(
  ({ title, isVisible, account, closeCallback }: IProps) => {
    const [tableUpdateTrigger, updateTable] = useState({});

    return (
      <ModalDialog
        title={title}
        isVisible={isVisible}
        closeCallback={closeCallback}
        hideFooterButtons
      >
        <AccountAddressesTable
          accountNumber={account.accountNumber}
          updateTableTrigger={tableUpdateTrigger}
        />
        <AddBlockchainAddress account={account} onAdd={() => updateTable({})} />
      </ModalDialog>
    );
  },
);

export default ReceiveMoneyModalDialog;
