import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// constants
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import { Text, FormField, FormInputTextArea, Col, Row } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import TrueFalseSelect from '../../../../../Forms/FormComponents/SelectInputs/TrueFalseSelect';
import AreYouPepTooltip from '../../../../../Tooltips/TemplateTooltips/AreYouPepTooltip';
import RelationshipsSection from './RelationshipsSection';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={
              <DivAlignCenter>
                {t('personal_info_modal.form_fields.is_pep')}{' '}
                <AreYouPepTooltip />
              </DivAlignCenter>
            }
            name="isPEP"
            component={TrueFalseSelect}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          {values.isPEP && (
            <FormField
              label={t('personal_info_modal.form_fields.pep_information')}
              name="pepInformation"
              component={FormInputTextArea}
            />
          )}
        </Col>
      </Row>

      <StyledText variant="body1" gutterBottom>
        {t('personal_info_modal.provide_relationships_description', {
          parentType: values.organizationTypeName,
        })}
      </StyledText>
      <RelationshipsSection />
    </>
  );
};

const StyledText = styled(Text)`
  display: block;
`;

export default InnerForm;
