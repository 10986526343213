import React from 'react';

// helpers
import { transactionsAPI } from 'api/accounting/transactionsAPI';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Autocomplete, AutocompleteProps } from '@ui';

type TransactionAccountNumbersAutocompleteProps = AutocompleteProps<string>;

const TransactionAccountNumbersAutocomplete = (
  props: TransactionAccountNumbersAutocompleteProps,
) => {
  const fetchOptionsCallback = async (
    searchQuery: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } =
      await transactionsAPI.fetchTransactionAccountNumbers({
        search: searchQuery,
        limit,
        page,
      });

    return {
      total,
      options: data.map((option) => {
        return {
          id: option,
          model: option,
          label: (
            <DivAlignCenter>
              <EllipsisTooltip
                placement="right"
                overlayStyle={{ maxWidth: '300px' }}
                title={option}
              >
                {option}
              </EllipsisTooltip>
            </DivAlignCenter>
          ),
        };
      }),
    };
  };

  return <Autocomplete {...props} fetchData={fetchOptionsCallback} />;
};

export default TransactionAccountNumbersAutocomplete;
