import * as yup from 'yup';

// constants
import { WEBSITE_REG } from '../../constants/regularExpressions';
import { AdditionalFieldValidationSchema } from './structure';
import {
  CRM_CONTACT_NAME_MAX_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '../../constants/global';
import {
  EMAIL_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  NULLABLE_DATE_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NOT_REQUIRED_BOOLEAN_VALIDATION_RULE,
  CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE,
} from '../common';

export const ApplicantInformationOrganizationShortValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: NULLABLE_BOOLEAN_VALIDATION_RULE.defined(),

    legalName: CRM_NAME_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: NAME_MAX_LENGTH,
      },
    }).required('form:validation_messages.required'),
  });

export const ApplicantInformationOrganizationValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: NULLABLE_BOOLEAN_VALIDATION_RULE.defined(),

    legalName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.max(
      NAME_MAX_LENGTH,
      {
        key: 'form:validation_messages.max_characters',
        values: {
          number: NAME_MAX_LENGTH,
        },
      },
    ).required('form:validation_messages.required'),

    tradeName: STRING_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
      key: 'form:validation_messages.min_characters',
      values: {
        number: NAME_MIN_LENGTH,
      },
    }).max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: NAME_MAX_LENGTH,
      },
    }),

    registrationNumber: CRM_NAME_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: NAME_MAX_LENGTH,
      },
    }).when('isSave', {
      is: false,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

    dateOfIncorporation: yup
      .mixed()
      .nullable()
      .when('isSave', {
        is: false,
        then: () =>
          yup
            .date()
            .typeError('form:validation_messages.required')
            .required('form:validation_messages.required'),
      }),

    jurisdiction: yup.mixed().when('isSave', {
      is: false,
      then: () =>
        STRING_VALIDATION_RULE.required('form:validation_messages.required'),
    }),

    industryCodes: yup.array().when('isSave', {
      is: false,
      then: () =>
        yup
          .array()
          .of(STRING_VALIDATION_RULE)
          .required('form:validation_messages.required')
          .min(1, 'form:validation_messages.required'),
    }),

    natureOfBusiness: yup.mixed().when('isSave', {
      is: false,
      then: () =>
        STRING_VALIDATION_RULE.max(500, {
          key: 'form:validation_messages.max_characters',
          values: {
            number: 500,
          },
        }).required('form:validation_messages.required'),
    }),

    numberOfEmployees: yup
      .mixed()
      .nullable()
      .when('isSave', {
        is: false,
        then: (schema) => schema.required('form:validation_messages.required'),
      }),

    numberOfOffices: yup
      .mixed()
      .nullable()
      .when('isSave', {
        is: false,
        then: (schema) => schema.required('form:validation_messages.required'),
      }),

    socialMedia: yup.object().shape({
      facebookLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.facebook_link' },
        },
      }),

      linkedInLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.linkedin_link' },
        },
      }),

      twitterLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.twitter_link' },
        },
      }),

      websiteLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.website_link' },
        },
      }),
    }),
  });

export const ApplicantInformationIndividualShortValidationSchema = yup
  .object()
  .shape({
    firstName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters',
              values: {
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters',
              values: {
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required('form:validation_messages.required'),
      },
    ),

    middleName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters',
              values: {
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters',
              values: {
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            }),
      },
    ),

    lastName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters',
              values: {
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters',
              values: {
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required('form:validation_messages.required'),
      },
    ),

    emails: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        value: EMAIL_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
      }),
    ),

    phoneNumbers: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        value: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
      }),
    ),
  });

export const ApplicantInformationIndividualValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: NULLABLE_BOOLEAN_VALIDATION_RULE.defined(),

    firstName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters',
              values: {
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters',
              values: {
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required('form:validation_messages.required'),
      },
    ),

    middleName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters',
              values: {
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters',
              values: {
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            }),
      },
    ),

    lastName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters',
              values: {
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters',
              values: {
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required('form:validation_messages.required'),
      },
    ),

    dateOfBirth: NULLABLE_DATE_VALIDATION_RULE.when(
      ['isSave', 'isContactBaseInfoFormDisabled'],
      {
        is: (isSave: boolean, isContactBaseInfoFormDisabled: boolean | null) =>
          !isContactBaseInfoFormDisabled ? !isSave : false,
        then: (schema) =>
          schema
            .typeError('form:validation_messages.required')
            .required('form:validation_messages.required'),
      },
    ),

    countryOfBirth: STRING_VALIDATION_RULE.when(
      ['isSave', 'isContactBaseInfoFormDisabled'],
      {
        is: (
          isSave: boolean,
          isContactBaseInfoFormDisabled: boolean | undefined,
        ) => (!isContactBaseInfoFormDisabled ? !isSave : false),
        then: (schema) => schema.required('form:validation_messages.required'),
      },
    ),

    gender: NULLABLE_STRING_VALIDATION_RULE.when(
      ['isSave', 'isContactBaseInfoFormDisabled'],
      {
        is: (
          isSave: boolean,
          isContactBaseInfoFormDisabled: boolean | undefined,
        ) => (!isContactBaseInfoFormDisabled ? !isSave : false),
        then: (schema) => schema.required('form:validation_messages.required'),
      },
    ),

    emails: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        value: EMAIL_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
      }),
    ),

    phoneNumbers: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        value: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
      }),
    ),

    socialMedia: yup.object().shape({
      facebookLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.facebook_link' },
        },
      }),

      linkedInLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.linkedin_link' },
        },
      }),

      twitterLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.twitter_link' },
        },
      }),
    }),
  });

// TODO: set type - yup.SchemaOf<SetupInitialRelationshipsFormValuesModel>
export const SetupInitialRelationshipsFormValidationSchema = yup
  .object()
  .shape({
    organizationTypeName: STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    organizationTypeCategoryId: STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    accountAdmin: NOT_REQUIRED_BOOLEAN_VALIDATION_RULE,
    accountPermissions: yup.mixed().notRequired().nullable(),
    isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),

    pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
      is: true,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),
    relationships: yup.array().of(
      yup.object().shape({
        id: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        additionalFields: yup.array().of(AdditionalFieldValidationSchema),
      }),
    ),
  });
