import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Spin } from '@ui';

const LoadingPage = () => {
  return (
    <ContentWrapper>
      <Spin size="large" />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default LoadingPage;
