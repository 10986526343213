import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import { FormField } from '@ui';
import FromNewContact from './FromNewContact';
import TrueFalseSelect from '../../../../../Forms/FormComponents/SelectInputs/TrueFalseSelect';
import FromExistingContact from './FromExistingContact';

const InnerForm = () => {
  const { t } = useTranslation('account_management');
  const { values } = useFormikContext<FormValuesModel>();

  const renderContent = (isFromExistingContact: boolean | null) => {
    if (isFromExistingContact === null) {
      return null;
    }
    return isFromExistingContact ? <FromExistingContact /> : <FromNewContact />;
  };

  return (
    <>
      <FormField
        name="isFromExistingContact"
        label={t(
          'user_management.add_user.form_fields.is_from_existing_contact',
        )}
        component={TrueFalseSelect}
      />

      {renderContent(values.isFromExistingContact)}
    </>
  );
};

export default InnerForm;
