import React, { useMemo } from 'react';

// constants
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import {
  AddressItemModel,
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/IdentificationForOrganizationForm';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import IdentificationForOrganizationForm from 'components/Forms/TemplateForms/Onboarding/IdentificationForOrganizationForm';
import { Message } from '@ui';

interface IProps {
  onAction: (key: ActionKeys | null) => void;
  onboardingData: OnboardingStatusModel;
  isViewOnly: boolean;
}

const Identification = ({ onAction, onboardingData, isViewOnly }: IProps) => {
  const { t } = useTranslation('common');

  const { response, loading } = useFetch(() =>
    onboardingAPI.fetchAddressForOrganization({
      entityId: onboardingData.crmItemId,
      page: 1,
      limit: 100,
    }),
  );

  const initialFormValues = useMemo<FormValuesModel>(() => {
    let addresses: AddressItemModel[] = [
      {
        isPrimary: true,
        type: null,
        country: null,
        city: '',
        street: '',
        state: '',
        postalCode: '',
      },
    ];

    if (onboardingData.item && response) {
      const organizationData = onboardingData.item as IEntity;

      if (response.data.length) {
        addresses = response.data.map((address) => ({
          _id: address._id,
          isPrimary: !!address.isPrimary,
          type: address.type || null,
          country: address.country || null,
          city: address.city,
          street: address.street,
          state: address.state,
          postalCode: address.postalCode,
        }));
      }

      return {
        submitActionType: null,
        isSave: false,
        isRegulated:
          typeof organizationData.isRegulated === 'boolean'
            ? organizationData.isRegulated
            : null,
        regulationCountry: organizationData.regulationCountry,
        canIssueBearerShares:
          typeof organizationData.canIssueBearerShares === 'boolean'
            ? organizationData.canIssueBearerShares
            : null,
        addresses: addresses,
      };
    } else {
      return {
        submitActionType: null,
        isSave: false,
        isRegulated: null,
        regulationCountry: [],
        canIssueBearerShares: null,
        addresses,
      };
    }
  }, [onboardingData, response]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody = onboardingAPIAdapter.submitIdentificationStep(values);
    await onboardingAPI.submitIdentificationStep(
      formattedBody,
      onboardingData.applicationId,
    );
    Message.success(t('success_save'));
    await onAction(values.submitActionType);
  };

  return (
    <LoadingWrapper loading={loading}>
      <IdentificationForOrganizationForm
        initialFormValues={initialFormValues}
        onSubmit={onSubmit}
        disabled={isViewOnly}
      />
    </LoadingWrapper>
  );
};

export default Identification;
