import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';

// components
import {
  Text,
  DescriptionSection,
  ModalDialog,
  RequiredPropsForModalDialogModel,
} from '@ui';
import { AsyncButton } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { Trans } from 'react-i18next';

export type HandoffRequestModalData = {
  clientGroup: string;
  invitedBy: string;
  requestedAt: string;
};

export interface IProps extends RequiredPropsForModalDialogModel {
  data: HandoffRequestModalData | null;
  onAction: (action: 'approve' | 'reject') => Promise<void>;
}

const HandoffRequestModal = memo(
  ({ isVisible, data, closeCallback, onAction }: IProps) => {
    const { t } = useTranslation('connections');
    const [hasApproveAccess, hasRejectAccess] = useUserAccess([
      'Connections_Requests_Approve',
      'Connections_Requests_Reject',
    ]);

    return (
      <ModalDialog
        title={t(
          'requests.view_request_modal.handoff_application_request.title',
        )}
        isVisible={isVisible}
        closeCallback={closeCallback}
        hideFooterButtons={!data}
        submitButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        extendedFooterButtonsPosition="before"
        extendedFooterButtons={
          <DivAlignCenter>
            {hasRejectAccess && (
              <StyledAsyncButton
                fullWidth
                type="bordered"
                size="large"
                onClick={() => onAction('reject')}
              >
                {t(
                  'requests.view_request_modal.handoff_application_request.reject_btn',
                )}
              </StyledAsyncButton>
            )}
            {hasApproveAccess && (
              <AsyncButton
                fullWidth
                size="large"
                onClick={() => onAction('approve')}
              >
                {t(
                  'requests.view_request_modal.handoff_application_request.approve_btn',
                )}
              </AsyncButton>
            )}
          </DivAlignCenter>
        }
      >
        <LoadingWrapper loading={!data}>
          <Text>
            {t(
              'requests.view_request_modal.handoff_application_request.description',
            )}
          </Text>
          <StyledTextWithTopMargin variant="h6" weight="semi-bold">
            {t(
              'requests.view_request_modal.handoff_application_request.application_details.title',
            )}
          </StyledTextWithTopMargin>
          <DescriptionSection
            size="small"
            bordered={false}
            background="transparent"
            data={[
              {
                label: t(
                  'requests.view_request_modal.handoff_application_request.application_details.client_group',
                ),
                description: data?.clientGroup || '',
              },
              {
                label: t(
                  'requests.view_request_modal.handoff_application_request.application_details.invited_by',
                ),
                description: data?.invitedBy || '',
              },
              {
                label: t(
                  'requests.view_request_modal.handoff_application_request.application_details.requested_at',
                ),
                description: data?.requestedAt || '',
              },
            ]}
          />
          <StyledTextWithTopMargin gutterTop>
            {t(
              'requests.view_request_modal.handoff_application_request.accept_request_description',
            )}
          </StyledTextWithTopMargin>
          <StyledTextWithTopMargin gutterTop>
            {t(
              'requests.view_request_modal.handoff_application_request.notes.title',
            )}
          </StyledTextWithTopMargin>
          <Text>
            <Trans
              t={t}
              values={{ contact: data?.invitedBy || '' }}
              i18nKey="requests.view_request_modal.handoff_application_request.notes.note_1"
              components={[
                <Text key="contact_name" weight="semi-bold" variant="span" />,
              ]}
            />
          </Text>
        </LoadingWrapper>
      </ModalDialog>
    );
  },
);

const StyledTextWithTopMargin = styled(Text)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledAsyncButton = styled(AsyncButton)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default HandoffRequestModal;
