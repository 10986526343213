import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { UpdatePhoneValidationSchema } from '../../../../../validations/profile/auth';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

export interface FormValuesModel {
  phone: string;
}

const EditPhoneModal = ({
  isVisible,
  closeCallback,
  initialValues,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation('auth');

  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={UpdatePhoneValidationSchema}
      renderForm={
        <ModalDialog
          width={600}
          title={t('phone_verification.edit.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{ text: t('phone_verification.edit.submit') }}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};
export default EditPhoneModal;
