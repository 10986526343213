import React from 'react';
import { SpinProps } from './types';
import { Spin as AntdSpin } from 'antd';

/**
 * The `Spin` component provides a loading state indicator, commonly used to
 * show that content is being loaded or processed.
 *
 * @param {SpinProps} props - The properties defined in `SpinProps`.
 */
const Spin: React.FC<SpinProps> = ({ className, indicator, ...rest }) => {
  return <AntdSpin className={className} indicator={indicator} {...rest} />;
};

export default Spin;
