import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';

// components
import { Text } from '@ui';

const SubmitFormStep = () => {
  const { t } = useTranslation('auth');

  return (
    <ContentWrapper>
      <Text variant="h5" gutterBottom>
        {t('account_recovery.final_step.verifying_id')}
      </Text>
      <StyledDescriptionText variant="body1">
        {t('account_recovery.final_step.description')}
      </StyledDescriptionText>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledDescriptionText = styled(Text)`
  max-width: 500px;
  text-align: center;
`;

export default SubmitFormStep;
