/* eslint-disable no-useless-escape */
// ------ Social media validation regex ------

export const WEBSITE_REG =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|^(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|^(www\.)?[a-zA-Z0-9]+\.[^\s]{2,})/g;

// ------ Formatting regex ------
export const FORMAT_AMOUNT_REG = /\B(?=(\d{3})+(?!\d))/g;

// ------ Validation rules ------

// Password validation rules
export const PASSWORD_MIN_LENGTH = 10;
export const PASSWORD_MAX_LENGTH = 100;
export const ONE_TIME_PASSWORD_LENGTH = 6;
export const ONE_NUMBER = /[0-9]/;
export const ONE_UPPER_LETTER = /[A-Z]/;
export const ONE_LOWER_LETTER = /[a-z]/;
export const ONE_SPECIAL_CHARACTER = /[^A-Za-z0-9]/;

// Account
export const SPECIAL_CHARACTERS_REG = /^[_a-zA-Z0-9.,-<>\n\s]*$/;
export const SWIFT_CHARACTERS_REG = /^[A-Za-z0-9/\-?:().,+'\s\u180E\u200B]*$/;
export const MIN_ACCOUNT_NAME_LENGTH = 3;
export const MAX_ACCOUNT_NAME_LENGTH = 100;

// CRM
export const CRM_NAME_REG =
  /^[\w\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}=\-\`~()]+$/g;

// Transfers
export const CHECK_COUNTRY = /^(CA|US)$/;
