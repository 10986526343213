import React from 'react';
import { Steps as AntdSteps } from 'antd';
import { StepProps } from './types';

const { Step: AntdStep }: any = AntdSteps;

/**
 * The `Step` component represents a single step in a Steps workflow.
 */
const Step: React.FC<StepProps> = (props) => {
  return <AntdStep {...props} />;
};

export default Step;
