import React, { useCallback, useEffect, useState } from 'react';

// helpers
import { ContactHelpers } from 'helpers/crm/contact';
import { approvalGroupsAPI } from 'api/approvalManagement/approvalGroupsAPI';
import { AutocompleteOption } from '@ui';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { userManagementAPINew } from 'api/accountManagement/userManagementAPINew';
import { ApprovalWorkflowType } from 'components/ModalDialogs/TemplateModalDialogs/Approval/ApprovalGroupModal';
import { IGroupParticipantShort } from 'typings/approvalManagement/accountManagement';
import { accountManagementRulesAPI } from 'api/accountManagement/accountManagementRulesAPI';

// components
import { FormAutocomplete } from '@ui';

interface IProps {
  value: IGroupParticipantShort[];
  approvalWorkflowType: ApprovalWorkflowType;
  onChange: (newValue: IGroupParticipantShort[]) => void;
}

const ApprovalGroupParticipantsAutocomplete = ({
  approvalWorkflowType,
  value,
  onChange,
  ...rest
}: IProps) => {
  const [privateValue, setPrivateValue] = useState<string[]>(
    value ? value.map((e) => e.userId) : [],
  );
  const [options, setOptions] = useState<{
    total: number;
    options: AutocompleteOption<IGroupParticipantShort>[];
  }>({ total: 0, options: [] });

  //  Reinitialize local value if the original value was cleared
  useEffect(() => {
    if (!value || (!value.length && privateValue.length)) {
      setPrivateValue([]);
    }
  }, [value]);

  const getParticipantsBasedOnWorkflowType = async (
    workflowType: ApprovalWorkflowType,
    searchQuery: string,
    page: number,
    limit: number,
  ): Promise<{
    total: number;
    options: AutocompleteOption<IGroupParticipantShort>[];
  }> => {
    switch (workflowType) {
      case 'transactions': {
        const response = await approvalGroupsAPI.fetchApprovalGroupParticipants(
          {
            page,
            limit,
            userFullName: searchQuery,
          },
        );
        return {
          total: response.total,
          options: response.data.map((e) => ({
            id: e.userId,
            model: e,
            label: e.userFullName,
          })),
        };
      }

      case 'account-management-transactions': {
        const response =
          await accountManagementRulesAPI.fetchApprovalGroupParticipants({
            page,
            limit,
            userFullName: searchQuery,
          });

        return {
          total: response.total,
          options: response.data.map((e) => ({
            id: e.userId,
            model: e,
            label: e.userFullName,
          })),
        };
      }

      case 'onboarding-approval-group': {
        const response = await userManagementAPINew.fetchClientGroupMembers({
          page,
          limit,
          type: OnboardingEntryTypes.Contact,
          isPossibleMemberOfInitialApprovalGroup: true,
          search: searchQuery,
        });

        return {
          total: response.total,
          options: response.data.map((e) => {
            const userFullName = ContactHelpers.getFormattedContactName(
              e.contact.firstName,
              e.contact.lastName,
              e.contact.middleName,
            );

            return {
              id: e.id,
              model: {
                userId: e.id,
                userFullName,
              },
              label: userFullName,
            };
          }),
        };
      }
    }
  };

  const handleChange = (newValue: string[]) => {
    let arrayCopy = value.slice();

    // delete item case
    if (newValue.length < arrayCopy.length) {
      arrayCopy = arrayCopy.filter((e) => newValue.includes(e.userId));
    } else {
      // take only new items
      const newItems = newValue.filter((el) => !privateValue.includes(el));
      // push new items to array
      newItems.forEach((newItemKey) => {
        const itemIndex = options.options.findIndex(
          (el) => el.id === newItemKey,
        );
        options.options[itemIndex].model &&
          arrayCopy.push(options.options[itemIndex].model);
      });
    }

    onChange(arrayCopy);
    setPrivateValue(newValue);
  };

  const fetchData = useCallback(
    async (searchQuery: string, page: number, limit: number) => {
      const options = await getParticipantsBasedOnWorkflowType(
        approvalWorkflowType,
        searchQuery,
        page,
        limit,
      );
      setOptions(options);
      return options;
    },
    [approvalWorkflowType],
  );

  return (
    <FormAutocomplete<IGroupParticipantShort>
      {...rest}
      mode="multiple"
      value={privateValue}
      onChange={handleChange as any}
      fetchData={fetchData}
    />
  );
};

export default ApprovalGroupParticipantsAutocomplete;
