import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ResetTabs } from '../..';

// components
import { FormField } from '@ui';
import { FormInputPassword } from '@ui';

const RequestResetPassword = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <FormField
        name="newPassword"
        label={t(
          `reset_password.steps.${ResetTabs.ResetPassword}.form_fields.password`,
        )}
        component={FormInputPassword}
      />

      <FormField
        name="confirmNewPassword"
        label={t(
          `reset_password.steps.${ResetTabs.ResetPassword}.form_fields.confirm_password`,
        )}
        component={FormInputPassword}
      />
    </>
  );
};

export default RequestResetPassword;
