import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { LinkProps } from './types';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useNavigate } from 'react-router-dom';

// components
import { Text } from '@ui';

const Link: React.FC<LinkProps> = memo(({ children, onClick, to, ...rest }) => {
  const navigate = useNavigate();

  const handleOnClick = (e?: any) => {
    to && navigate(to);
    onClick && onClick(e);
  };

  return (
    <StyledText
      color={colorsTheme.colorPrimary}
      variant="link1"
      onClick={handleOnClick}
      {...rest}
    >
      {children}
    </StyledText>
  );
});

const StyledText = styled(Text)`
  cursor: pointer;
`;

export default Link;
