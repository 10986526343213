import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '..';
import { FieldArray, useFormikContext } from 'formik';
import { FormValuesModel as EmploymentHistoryFormValues } from '../../EmploymentHistoryShortForm';

// constants
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import {
  Divider,
  FormField,
  Col,
  Row,
  SectionIntro,
  FormInputTextArea,
} from '@ui';
import EmploymentHistoryShortForm from '../../EmploymentHistoryShortForm';
import SourceOfWealthSelect, {
  IProps as SourceOfWealthSelectProps,
} from '../../../../FormComponents/SelectInputs/CRM/SourceOfWealthSelect';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const renderEmploymentHistory = () => {
    return values.employmentHistory.map(
      (employmentHistory: EmploymentHistoryFormValues, index: number) => (
        <>
          <EmploymentHistoryShortForm
            key={index}
            fieldName={`employmentHistory.${index}`}
            disabled={!!employmentHistory.isInheritedFromRelationships}
          />
          <Divider />
        </>
      ),
    );
  };

  return (
    <>
      <SectionIntro
        title={t('source_of_wealth.employment_history_section_title')}
      />
      <FieldArray name="employmentHistory" render={renderEmploymentHistory} />
      <SectionIntro title={t('source_of_wealth.title')} />
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField<SourceOfWealthSelectProps>
            name="sourceOfWealthCategories"
            component={SourceOfWealthSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            name="sourceOfWealthDescription"
            component={FormInputTextArea}
            additionalProps={{ autoSize: { minRows: 5, maxRows: 10 } }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InnerForm;
