import React, { memo, useEffect, useState } from 'react';

// helpers
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { ONE_MINUTE } from 'constants/onboarding';
import { useSelector } from 'react-redux';
import { FormValuesModel } from 'components/Forms/TemplateForms/Onboarding/VerifyPhoneNumberForm';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { PhoneConfirmationValidationSchema } from 'validations/profile/auth';

// components
import ResendOtp from 'components/Additional/ResendOtp';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import VerifyPhoneNumberForm from 'components/Forms/TemplateForms/Onboarding/VerifyPhoneNumberForm/InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  onResendOTP: () => Promise<void>;
}

export type VerifyPhoneNumberFormValuesModel = FormValuesModel;

const VerifyPhoneNumberModal = memo(
  ({
    isVisible,
    initialValues,
    onSubmit,
    onResendOTP,
    onSubmitError,
    closeCallback,
  }: IProps) => {
    const { t } = useTranslation('profile');

    const [loading, setLoading] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(0);
    const { contactData } = useSelector<StateModel, AuthStateModel>(
      (state) => state.auth,
    );

    const handleResendOTP = async () => {
      setLoading(true);
      await onResendOTP();
      setLoading(false);
    };

    useEffect(() => {
      if (isVisible) {
        const differenceDateSeconds = moment(new Date())
          .utc()
          .diff(
            moment(
              contactData?.phoneNumber.verification?.verificationStartOn,
            ).utc(),
            'second',
          );
        if (differenceDateSeconds <= ONE_MINUTE) {
          setSecondsLeft(ONE_MINUTE - differenceDateSeconds);
        } else {
          handleResendOTP();
        }
      }
    }, [isVisible, contactData]);

    return (
      <Form<FormValuesModel>
        initialValues={initialValues}
        validationSchema={PhoneConfirmationValidationSchema}
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        enableReinitialize
        renderForm={
          <ModalDialog
            width={500}
            shouldResetFormAfterSubmit={false}
            title={t('personal_details.verify_phone.modal_title')}
            isVisible={isVisible}
            hideFooterButtons
            closeCallback={closeCallback}
          >
            <LoadingWrapper loading={loading}>
              <VerifyPhoneNumberForm />

              <ResendOtp
                startTimerFromDate={
                  secondsLeft && secondsLeft < ONE_MINUTE
                    ? contactData?.phoneNumber.verification?.verificationStartOn
                    : undefined
                }
                shouldStartTimer={true}
                successMessage={t(
                  'personal_details.verify_phone.resend_success_message',
                )}
                resendOtpCallback={onResendOTP}
              />
            </LoadingWrapper>
          </ModalDialog>
        }
      />
    );
  },
);

export default VerifyPhoneNumberModal;
