import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { IconSVG } from '@ui';
import { ReactComponent as TimeIcon } from 'resources/icons/remix-icons/time-line.svg';

const ApplicationInReviewStatus = () => {
  const { t } = useTranslation('onboarding');
  return (
    <StyledWrapper>
      <StyledIconSVG
        component={TimeIcon}
        color={colorsTheme.colorWarning}
        size="middle"
      />
      {t('application.application_in_review_status')}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
  color: ${({ theme }) => theme.colorWarning};
  font-size: ${({ theme }) => theme.fontSizeLg};
  display: flex;
  align-items: center;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default ApplicationInReviewStatus;
