import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { FormikHelpers } from 'formik';
import { Enable2FAuthenticatorValidationSchema } from '../../../../../validations/profile/security';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

export interface FormValuesModel {
  code: string | null;
}

const ConfirmChangeModal = ({
  isVisible,
  initialValues,
  closeCallback,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation(['profile', 'common', 'custom_errors']);

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201014': {
        formikHelpers.setFieldError(
          'code',
          t('1201014', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      onSubmitError={handleOnSubmitError}
      initialValues={isVisible ? initialValues : null}
      validationSchema={Enable2FAuthenticatorValidationSchema}
      renderForm={
        <ModalDialog
          width={600}
          closeModalAfterError={false}
          title={t(
            'security.two_step_verification.authenticator.confirm_settings_change.title',
          )}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{ text: t('submit', { ns: 'common' }) }}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default ConfirmChangeModal;
