import * as Yup from 'yup';
import { NULLABLE_STRING_VALIDATION_RULE } from 'validations/common';

export const UserPermissionsValidationSchema = Yup.object().shape({
  accountPermissions: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  adminPermissionType: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});
