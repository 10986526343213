import React from 'react';

import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { PageButtonProps } from './types';

// components
import InfoTooltip from '../../../../Tooltips/InfoTooltip';
import DivAlignCenter from '../../../../Additional/DivAlignCenter';
import { Button } from '@ui';

const PageButton: React.FC<PageButtonProps> = ({
  children,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation('common');

  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  return (
    <DivAlignCenter>
      <Button
        type="primary"
        disabled={!isTwoFactorVerificationEnabled || disabled}
        {...rest}
      >
        {children}
      </Button>
      {!isTwoFactorVerificationEnabled ? (
        <StyledInfoTooltip
          tooltipContent={t('disabled_by_2fa_button_information')}
        />
      ) : null}
    </DivAlignCenter>
  );
};

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default React.memo(PageButton);
