import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { KYCVerificationSchema } from 'validations/connections';
import { QuestionnaireTemplateModel } from 'modules/Connections/Requests/Request/ViewClientGroupRequest/ShareContactRequestModalDialog';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForFormModel<FormValuesModel>,
    RequiredPropsForModalDialogModel {
  closeModalAfterError?: boolean;
}

export type FormValuesModel = {
  questions: QuestionnaireTemplateModel[];
  agreement: boolean | null;
};

const KYCVerificationModal = memo(
  ({
    onSubmit,
    closeModalAfterError = true,
    isVisible,
    initialValues,
    closeCallback,
  }: IProps) => {
    const { t } = useTranslation('connections');

    return (
      <Form<FormValuesModel>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={isVisible ? initialValues : null}
        validationSchema={KYCVerificationSchema}
        renderForm={
          <ModalDialog
            closeModalAfterError={closeModalAfterError}
            width="700px"
            title={t('requests.kyc_verification_modal.title')}
            isVisible={isVisible}
            closeCallback={closeCallback}
          >
            <InnerForm />
          </ModalDialog>
        }
      />
    );
  },
);

export default KYCVerificationModal;
