import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { PageButton } from '@ui';
import AddTravelNotificationRuleModal from './AddTravelNotificationRuleModal';

interface IProps {
  onAdd?: () => void;
}

const AddTravelNotificationRule = ({ onAdd }: IProps) => {
  const { t } = useTranslation('profile');
  const [isModalVisible, setVisibleModal] = useState(false);

  const closeCallback = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd && onAdd();
    }

    setVisibleModal(false);
  };

  return (
    <>
      <PageButton onClick={() => setVisibleModal(true)}>
        {t('travel_notifications.add_travel_notification_button')}
      </PageButton>
      <AddTravelNotificationRuleModal
        isVisible={isModalVisible}
        closeCallback={closeCallback}
      />
    </>
  );
};

export default AddTravelNotificationRule;
