import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { IKeyRelationshipTemplate } from '../../typings/application/key-relationship-templates';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchKeyRelationshipTemplatesParams extends FetchParamsModel {
  search?: string;
  isVisible?: boolean;
  visibleFor?: OnboardingEntryTypes;
}

const keyRelationshipTemplatesAPI = {
  fetchKeyRelationshipTemplates: (
    params: FetchKeyRelationshipTemplatesParams,
  ) => {
    return APIService.get<FetchResponseModel<IKeyRelationshipTemplate>>(
      `${APIConfig.crmApi}/key-relationship-templates`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchOnboardingKeyRelationshipTemplates: (
    params: FetchKeyRelationshipTemplatesParams,
  ) => {
    return APIService.get<FetchResponseModel<IKeyRelationshipTemplate>>(
      `${APIConfig.crmPublicApi}/key-relationship-templates`,
      {
        params,
      },
    ).then(({ data }) => data);
  },
};

export { keyRelationshipTemplatesAPI };
