import React, { memo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { transactionsAPI } from 'api/accounting/transactionsAPI';
import { AssociationEntries } from 'enums/onboarding/crm';
import { ShortDocumentModel } from 'typings/documents/documents';
import { TransactionDocument } from 'typings/accounting/transaction';
import { StateModel as UploadStateModel } from 'redux/reducers/upload';
import { ALLOWED_UPLOAD_TYPES, UploadStatus } from 'constants/documents';

// components
import DocumentLink from 'components/Typography/DocumentLink';
import UploadDocumentButton from 'components/Additional/UploadDocumentButton';
import { IconSVG, Button, Col } from '@ui';
import { ReactComponent as UploadIcon } from 'resources/icons/remix-icons/upload-2-line.svg';

interface IProps {
  transactionDocuments: TransactionDocument[];
  journalEntryId: number | null;
  onUpload?: () => void;
}

const DocumentsValue = memo(
  ({ transactionDocuments, journalEntryId, onUpload }: IProps) => {
    const uploadState = useSelector<StateModel, UploadStateModel>(
      (state) => state.upload,
    );
    const { t } = useTranslation('common');
    const [isLoading, setLoading] = useState(false);
    const [documents, setDocuments] =
      useState<TransactionDocument[]>(transactionDocuments);
    const [hasUploadDocumentsAccess] = useUserAccess([
      'Accounts_TransactionModal_UploadDocuments',
    ]);
    const [isDocumentUpload, setDocumentUpload] = useState(false);

    const canUpload = journalEntryId && hasUploadDocumentsAccess;

    const handleOnUpload = async (documents: ShortDocumentModel[]) => {
      if (journalEntryId) {
        try {
          setDocumentUpload(true);
          const relatedDocuments = documents.map((e) => ({
            dmsId: e.id,
            name: e.name,
            size: String(e.file?.size) as string,
            type: e.file?.contentType as string,
            linkToDownload: e.file?.id as string,
          }));
          await transactionsAPI.uploadDocumentToApprovedTransaction(
            relatedDocuments,
            journalEntryId,
          );
          setDocuments((prev) => [...prev, ...relatedDocuments]);
          onUpload && onUpload();
        } finally {
          setDocumentUpload(false);
        }
      }
    };

    return (
      <>
        {documents.map(({ dmsId, name, linkToDownload }) => (
          <div key={dmsId}>
            <DocumentLink
              text={name}
              fileId={linkToDownload}
              documentId={dmsId}
            />
          </div>
        ))}

        {canUpload && (
          <StyledCol xl={6} lg={6} md={8} sm={8} xs={24}>
            <UploadDocumentButton
              tags={[]}
              name="document"
              accept={ALLOWED_UPLOAD_TYPES}
              onUpload={handleOnUpload}
              setLoading={setLoading}
              association={{
                id: String(journalEntryId),
                type: AssociationEntries.JournalEntry,
              }}
            >
              <StyledButton
                size="middle"
                disabled={uploadState.status === UploadStatus.ACTIVE}
                loading={
                  (uploadState.status === UploadStatus.ACTIVE && isLoading) ||
                  isDocumentUpload
                }
              >
                <StyledIconSVG
                  component={UploadIcon}
                  color={colorsTheme.colorDark}
                />{' '}
                {t('upload_button')}
              </StyledButton>
            </UploadDocumentButton>
          </StyledCol>
        )}
      </>
    );
  },
);

const StyledCol = styled(Col)`
  margin-top: 4px;
  max-width: 200px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 0px;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default DocumentsValue;
