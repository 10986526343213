import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import {
  AssertionForLoginModel,
  GetChallengeForLoginResponseModel,
} from '../../../../../typings/profile/fido';

// components
import InitialStep from './InitialStep';
import SignChallenge from './SignChallengeStep';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  getChallenge: () => Promise<GetChallengeForLoginResponseModel>;
  submitAssertion: (assertion: AssertionForLoginModel) => Promise<any>;
}

export enum RegisterSecurityKeySteps {
  Initial,
  RegisterKey,
}

const SignChallengeWithSecurityKeyModal = ({
  title,
  isVisible,
  closeCallback,
  getChallenge,
  submitAssertion,
}: IProps) => {
  const [currentStep, setCurrentStep] = useState(
    RegisterSecurityKeySteps.Initial,
  );

  const handleOnAssert = () => {
    closeCallback(true);
  };

  const renderStep = (step: RegisterSecurityKeySteps) => {
    switch (step) {
      case RegisterSecurityKeySteps.Initial:
        return (
          <InitialStep
            onNextClick={() =>
              setCurrentStep(RegisterSecurityKeySteps.RegisterKey)
            }
          />
        );

      case RegisterSecurityKeySteps.RegisterKey:
        return (
          <SignChallenge
            getChallenge={getChallenge}
            submitAssertion={submitAssertion}
            onAssert={handleOnAssert}
          />
        );
    }
  };

  return (
    <ModalDialog
      width={600}
      title={title}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
    >
      <ContentWrapper>{renderStep(currentStep)}</ContentWrapper>
    </ModalDialog>
  );
};

const ContentWrapper = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export default SignChallengeWithSecurityKeyModal;
