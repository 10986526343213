import React from 'react';

// helpers
import { accountsAPI } from 'api/accounting/accountsAPI';
import { IAccountModel } from 'typings/onboarding/account';
import { AccountsHelpers } from 'helpers/accounts';
import { BankAccountStatuses } from 'enums/accounts/accounts';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Text, Autocomplete, AutocompleteProps } from '@ui';

type BankAccountAutocompleteProps = AutocompleteProps<IAccountModel>;

const TransactionBankAccountAutocomplete = (
  props: BankAccountAutocompleteProps,
) => {
  const fetchOptionsCallback = async (
    search: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } = await accountsAPI.fetchAccounts({
      page,
      limit,
      search,
      status: BankAccountStatuses.Active,
    });

    return {
      total,
      options: data.map((e) => {
        const { accountNumber, accountName, currencyCode, balance } = e;
        const accountBalance = `${currencyCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(balance)}`;
        const name = AccountsHelpers.generateAccountFullName(
          accountName,
          accountNumber,
          currencyCode,
          balance,
        );

        return {
          id: e.accountNumber,
          model: e,
          label: (
            <DivAlignCenter>
              <EllipsisTooltip
                placement="right"
                overlayStyle={{ maxWidth: '300px' }}
                title={
                  <>
                    <Text variant="body1">{accountNumber}</Text>
                    <Text variant="body1">{accountName}</Text>
                    <Text variant="body1">{accountBalance}</Text>
                  </>
                }
              >
                {name}
              </EllipsisTooltip>
            </DivAlignCenter>
          ),
        };
      }),
    };
  };

  return <Autocomplete {...props} fetchData={fetchOptionsCallback} />;
};

export default TransactionBankAccountAutocomplete;
