// helpers
import styled from 'styled-components';

// components
import { Card } from '@ui';

export const CardWrapper = styled(Card)<{ noMargin?: boolean }>`
  background: ${({ theme }) => theme.colorDarkD1};
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px;
  margin: ${({ theme, noMargin }) => (!noMargin ? theme.marginMd : 0)} 0;

  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    padding: ${({ theme }) => theme.paddingMd};
  }

  & > .ant-card-head {
    padding-left: 0;
    padding-right: 0;
    .ant-card-head-title {
      margin-bottom: ${({ theme }) => theme.marginSm};
      padding: 0;
    }
  }

  & > .ant-card-body {
    padding: 0;
  }

  .ant-table,
  .ant-table-thead > tr > th,
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right,
  .ant-table-tbody > tr.ant-table-placeholder > td,
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: ${({ theme }) => theme.colorDarkD1};
  }
`;
