import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import { Text, DescriptionSection } from '@ui';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { CardWrapper } from 'components/Additional/CardWrapper';

const BulkCompleted = () => {
  const { t } = useTranslation(['accounts', 'common']);
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <Text>
        {values.action === 'approve'
          ? values.transactions.length > 1
            ? t(
                'pending_transactions.review_multiple_txns_modal.completed_step.approve.description_plural',
                {
                  numberOfApproved: values.result?.completed,
                  total: values.transactions.length,
                },
              )
            : t(
                'pending_transactions.review_multiple_txns_modal.completed_step.approve.description',
              )
          : values.transactions.length > 1
            ? t(
                'pending_transactions.review_multiple_txns_modal.completed_step.approve.description_plural',
                {
                  numberOfApproved: values.result?.completed,
                  total: values.transactions.length,
                },
              )
            : t(
                'pending_transactions.review_multiple_txns_modal.completed_step.approve.description',
              )}
      </Text>

      <CardWrapper>
        <DescriptionSection
          bordered={false}
          size="small"
          background="transparent"
          data={[
            {
              label: t(
                'pending_transactions.review_multiple_txns_modal.completed_step.details',
              ),
              description: '',
            },
            {
              label:
                values.action === 'approve'
                  ? t(
                      'pending_transactions.review_multiple_txns_modal.completed_step.approve.transactions_approved',
                    )
                  : t(
                      'pending_transactions.review_multiple_txns_modal.completed_step.reject.transactions_rejected',
                    ),
              description: values.result?.completed,
            },
            {
              label:
                values.action === 'approve'
                  ? t(
                      'pending_transactions.review_multiple_txns_modal.completed_step.approve.transactions_failed',
                    )
                  : t(
                      'pending_transactions.review_multiple_txns_modal.completed_step.reject.transactions_failed',
                    ),
              description: values.result?.failedTransactions?.length && (
                <StyledDescriptionSection
                  bordered={false}
                  size="small"
                  background="transparent"
                  data={values.result.failedTransactions.map(
                    (name: string, i) => ({
                      label: i + 1,
                      description: (
                        <EllipsisTooltip
                          maxTextContainerWidth="400px"
                          title={name}
                        >
                          {name}
                        </EllipsisTooltip>
                      ),
                    }),
                  )}
                />
              ),
            },
          ]}
        />
      </CardWrapper>

      <Text>
        {t(
          'pending_transactions.review_multiple_txns_modal.completed_step.bottom_text',
        )}
      </Text>
    </>
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
  }
`;

export default BulkCompleted;
