import React, { useMemo } from 'react';

// helpers
import { SelectOption, SelectProps } from '@ui';
// constants
import { COUNTRY_CODES } from 'constants/countryCodes';

// components
import { FormSelect } from '@ui';

export type IProps = SelectProps;

const CountrySelect = (props: IProps) => {
  const options = useMemo<SelectOption[]>(
    () =>
      Object.keys(COUNTRY_CODES).map((key) => ({
        id: key,
        label: COUNTRY_CODES[key],
      })),
    [],
  );

  return <FormSelect {...props} options={options} />;
};

export default CountrySelect;
