/* eslint-disable react/function-component-definition */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { debounce, throttle } from 'lodash';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from 'constants/global';
import { Spinner, IconSVG } from '@ui';
import { Select as SelectAntD } from 'antd';
import { ReactComponent as CloseIcon } from 'resources/icons/remix-icons/close-line.svg';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { AutocompleteOption, AutocompleteProps } from './types';

export type AutocompleteSizes = 'large' | 'middle' | 'small';
export const FETCH_OPTIONS_LIMIT = 10;
const LOADER_KEY = '_LOADER_OPTION';

/**
 * The `Autocomplete` component provides an async-enabled dropdown input
 * that fetches and filters options as the user types. It supports optional
 * multiple selection, customizable minimum search length, exclusion of certain
 * options, and dynamically refreshed data sets. The user’s query triggers a
 * fetch operation debounced by a configurable delay.
 *
 * @param {AutocompleteProps} props - The properties defined in `AutocompleteProps`.
 */
function Autocomplete<Option = unknown>({
  mode,
  value,
  size = 'large',
  disabled,
  placeholder,
  appendToMenu,
  initialValue,
  minSearchLength,
  excludeElementIds,
  noInitialOptionsText,
  refreshAutocompleteTrigger,
  onBlur,
  onClear,
  onChange,
  onSelect,
  fetchData,
  onDeselect,
  ...rest
}: AutocompleteProps<Option>) {
  const { t } = useTranslation(['form', 'common']);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [availableOptions, setAvailableOptions] = useState<
    AutocompleteOption<Option>[]
  >([]);

  const itemsToRender = useMemo(() => {
    let result: AutocompleteOption<Option>[] = [...availableOptions];

    if (excludeElementIds && excludeElementIds.length) {
      result = result.filter((e) => !excludeElementIds.includes(e.id));
    }

    if (initialValue) {
      const initialOptions = Array.isArray(initialValue)
        ? initialValue
        : [initialValue];
      const existingIds = new Set(result.map((e) => e.id));
      const uniqueInitialOptions = initialOptions.filter(
        (e) => !existingIds.has(e.id),
      );
      result = [...uniqueInitialOptions, ...result];
    }

    return result;
  }, [availableOptions, excludeElementIds, initialValue]);

  useEffect(() => {
    if (!isLoading && refreshAutocompleteTrigger) {
      setSearchQuery('');
      fetchAndSaveOptions('', 1);
    }
  }, [refreshAutocompleteTrigger]);

  const formattedValue = useMemo(() => {
    if (value) {
      return value;
    }

    return undefined;
  }, [value]);

  const fieldPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    if (mode === 'multiple') {
      return t('select_multiple_options');
    } else {
      return t('select_option');
    }
  }, [mode, placeholder, t]);

  const fetchAndSaveOptions = async (
    searchQuery: string,
    pageToFetch: number,
  ) => {
    if (fetchData) {
      const response = await fetchData(
        searchQuery,
        pageToFetch,
        FETCH_OPTIONS_LIMIT,
      );

      const { options, total } = response;
      setAvailableOptions((prev) => {
        const newOptions = pageToFetch === 1 ? options : [...prev, ...options];
        setHasMore(newOptions.length < total);
        setPage(pageToFetch);
        return newOptions;
      });

      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce(fetchAndSaveOptions, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [fetchData],
  );

  const onSearch = (search: string) => {
    if (minSearchLength && search.length < minSearchLength) {
      return;
    }

    setPage(1);
    setHasMore(true);
    setLoading(true);
    setSearchQuery(search);
    setAvailableOptions([]);

    if (fetchData) {
      debouncedFetchData(search, 1);
    }
  };

  const handlePopupScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
    throttle((e) => {
      const target = e.target as HTMLDivElement;
      if (
        target.scrollTop + target.offsetHeight >= target.scrollHeight - 40 &&
        hasMore &&
        !isLoading
      ) {
        setLoading(true);
        debouncedFetchData(searchQuery, page + 1);
      }
    }, 200),
    [hasMore, isLoading, searchQuery, page],
  );

  const handleFocus: React.FocusEventHandler<HTMLElement> = () => {
    if (!availableOptions.length) {
      onSearch(searchQuery);
    }
  };

  const handleOnClear = () => {
    if (searchQuery) {
      onSearch('');
    }

    onClear && onClear();
  };

  const handleOnSelect = (value: any, option: any) => {
    onSelect && onSelect(value, option);
  };

  return (
    <StyledSelectAntD
      {...rest}
      showArrow
      showSearch
      allowClear
      filterOption={false}
      clearIcon={
        <StyledIconSVG
          component={CloseIcon}
          color={colorsTheme.colorWhite}
          size="extra-small"
        />
      }
      value={formattedValue}
      mode={mode}
      size={size}
      disabled={disabled}
      onFocus={handleFocus}
      onBlur={onBlur}
      onClear={handleOnClear}
      onChange={onChange as any}
      onSelect={handleOnSelect}
      onDeselect={onDeselect as any}
      onSearch={onSearch}
      placeholder={fieldPlaceholder}
      onPopupScroll={handlePopupScroll}
      loading={isLoading}
      dropdownRender={(menu) => (
        <>
          {appendToMenu}
          {menu}
        </>
      )}
      notFoundContent={
        isLoading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <StyledDivAlignCenter>
            {noInitialOptionsText && !searchQuery
              ? noInitialOptionsText
              : t('no_data', { ns: 'common' })}
          </StyledDivAlignCenter>
        )
      }
    >
      {itemsToRender.map((e) => (
        <SelectAntD.Option
          key={e.id}
          value={e.id}
          model={e.model}
          disabled={e.disabled}
        >
          {e.label}
        </SelectAntD.Option>
      ))}

      {hasMore ? (
        <SelectAntD.Option disabled key={LOADER_KEY} value={LOADER_KEY}>
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        </SelectAntD.Option>
      ) : null}
    </StyledSelectAntD>
  );
}

const StyledSelectAntD = styled(SelectAntD)`
  .ant-select-clear {
    background-color: ${({ theme }) => theme.colorWarning};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  justify-content: center;
  height: 130px;
`;

export default Autocomplete;
