import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { TransferTemplateTypes } from 'enums/accounting/transfers';
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

export interface IProps extends SelectProps {
  hideWireTemplateOption?: boolean;
  hideInternalTemplateOption?: boolean;
  hideBlockchainTemplateOption?: boolean;
}

const TransferTemplateTypeSelect = ({
  hideWireTemplateOption,
  hideInternalTemplateOption,
  hideBlockchainTemplateOption,
  ...rest
}: IProps) => {
  const { t } = useTranslation('transfers');

  const options = useMemo<SelectOption[]>(() => {
    return [
      {
        id: TransferTemplateTypes.Internal,
        label: t(
          `transfer_templates.transfer_template_types.${TransferTemplateTypes[TransferTemplateTypes.Internal]}`,
        ),
        hidden: hideInternalTemplateOption,
      },

      {
        id: TransferTemplateTypes.Wire,
        label: t(
          `transfer_templates.transfer_template_types.${TransferTemplateTypes[TransferTemplateTypes.Wire]}`,
        ),
        hidden: hideWireTemplateOption,
      },

      {
        id: TransferTemplateTypes.Blockchain,
        label: t(
          `transfer_templates.transfer_template_types.${TransferTemplateTypes[TransferTemplateTypes.Blockchain]}`,
        ),
        hidden: hideBlockchainTemplateOption,
      },
    ];
  }, [
    hideWireTemplateOption,
    hideInternalTemplateOption,
    hideBlockchainTemplateOption,
    t,
  ]);

  return <FormSelect {...rest} options={options} />;
};

export default TransferTemplateTypeSelect;
