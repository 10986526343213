import React from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useTranslation } from 'react-i18next';

// components
import { Text, IconSVG } from '@ui';
import { ReactComponent as KeyIcon } from 'resources/icons/remix-icons/key-line.svg';

const BrowserIsNotSupported = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <StyledIconSVG
        component={KeyIcon}
        size="large"
        color={colorsTheme.colorWhite}
      />
      <StyledText variant="body1">
        {t('two_factor_authentication.fido.browser_is_not_supported.title')}
      </StyledText>
      <StyledText variant="body1">
        {t(
          'two_factor_authentication.fido.browser_is_not_supported.description',
        )}
      </StyledText>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

export default BrowserIsNotSupported;
