import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormSelectProps } from './types';

// components
import { Select } from '@ui';

function FormSelect<Option = unknown>({
  validationRules,
  ...rest
}: FormSelectProps<Option>) {
  const { t } = useTranslation('form');

  const placeholder = useMemo(() => {
    const isMultiSelect = rest.mode === 'multiple';
    const notRequiredPlaceholder = isMultiSelect
      ? t('field_placeholders.multi_select_optional')
      : t('field_placeholders.select_optional');

    if (!validationRules || !validationRules.length) {
      return notRequiredPlaceholder;
    }

    const isRequired = validationRules.find((e) => e.type === 'required');

    if (isRequired) {
      return isMultiSelect
        ? t('field_placeholders.multi_select_required')
        : t('field_placeholders.select_required');
    } else {
      return notRequiredPlaceholder;
    }
  }, [validationRules]);

  return <Select {...rest} placeholder={placeholder} />;
}

export default FormSelect;
