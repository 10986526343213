import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { enumToMap } from 'helpers/utils';
import { RoutePaths } from 'routes/routes';
import { colorsTheme } from 'resources/theme/styled/colors';
import { DateHelpers } from 'helpers/date';
import { IAccountModel } from 'typings/onboarding/account';
import { AccountsHelpers } from 'helpers/accounts';
import { BankAccountStatuses } from 'enums/accounts/accounts';
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/global';

// components
import {
  Text,
  IconSVG,
  Table,
  RequiredPropsForTableModel,
  TableColumnModel,
  Link,
  Popover,
} from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import BankAccountStatus from 'components/Additional/Statuses/BankAccountStatus';
import WarningWithPopover from '../../../../Tooltips/TemplateTooltips/WarningIconWithPopover';
import { ReactComponent as TimeIcon } from 'resources/icons/remix-icons/time-line.svg';
import { ReactComponent as ArrowRightIcon } from 'resources/icons/remix-icons/arrow-right-s-line.svg';

interface IProps extends RequiredPropsForTableModel<IAccountModel> {
  filters: Record<string, any>;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

const AccountsTable = ({ filters, ...rest }: IProps) => {
  const { t } = useTranslation(['accounts', 'custom_errors']);
  const [hasAccessForPendingTransactions] = useUserAccess([
    'Accounts_PendingTransactions_View',
  ]);

  const renderAccountNumber = ({
    accountNumber,
    hasTransactionRules,
    hasWorkflowPending,
    status,
  }: IAccountModel) => {
    if (status === BankAccountStatuses.PendingApproval) {
      return (
        <Text variant="body1">
          <DivAlignCenter>
            {accountNumber}
            <Popover
              placement="bottom"
              content={t('account_summary.no_approval_rule_warning', {
                ns: 'accounts',
              })}
              overlayStyle={{ zIndex: 99999 }}
            >
              <StyledIconSVG
                component={TimeIcon}
                color={colorsTheme.colorWarning}
              />
            </Popover>
          </DivAlignCenter>
        </Text>
      );
    } else {
      let popover = null;

      if (hasWorkflowPending || !hasTransactionRules) {
        if (hasWorkflowPending) {
          popover = (
            <WarningWithPopover
              popoverContent={
                <StyledWarningText variant="body1">
                  {t('accounts.has_pending_workflow_warning', {
                    ns: 'custom_errors',
                  })}
                </StyledWarningText>
              }
            />
          );
        } else if (!hasTransactionRules) {
          popover = (
            <WarningWithPopover
              popoverContent={
                <StyledWarningText variant="body1">
                  {t('accounts.no_transaction_rule_warning', {
                    ns: 'custom_errors',
                  })}
                </StyledWarningText>
              }
            />
          );
        }
      }

      return (
        <AccountNumberColWrapper>
          <Link
            to={{
              pathname: RoutePaths.Accounts_AccountActivity,
              search: `?accountNumber=${accountNumber}`,
            }}
          >
            {accountNumber}
          </Link>
          {popover}
        </AccountNumberColWrapper>
      );
    }
  };

  const renderActualBalance = (record: IAccountModel) => {
    const { balance, availableBalance, accountNumber } = record;
    const shouldRenderAvailableBalance = availableBalance !== balance;

    return !shouldRenderAvailableBalance ? (
      AccountsHelpers.convertAmountBigIntToLocaleString(balance)
    ) : (
      <Popover
        placement="bottom"
        content={
          <>
            <Text variant="body1">
              {`${t('account_summary.available_balance')}: ${AccountsHelpers.convertAmountBigIntToLocaleString(availableBalance)}`}
            </Text>

            <Text variant="body1">
              {t('account_summary.available_balance_helper')}
            </Text>

            {hasAccessForPendingTransactions && (
              <Link
                to={{
                  pathname: RoutePaths.Accounts_PendingTransactions,
                  search: `?accountNumber=${accountNumber}`,
                }}
              >
                <DivAlignCenter>
                  {t('account_summary.go_to_pending_transfers_button')}
                  <IconSVG
                    component={ArrowRightIcon}
                    color={colorsTheme.colorWhite}
                  />
                </DivAlignCenter>
              </Link>
            )}
          </>
        }
      >
        <StyledDottedText variant="body1">
          {AccountsHelpers.convertAmountBigIntToLocaleString(balance)}
        </StyledDottedText>
      </Popover>
    );
  };

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('account_summary.table.account_number'),
        key: 'accountNumber',
        width: '200px',
        render: renderAccountNumber,
      },
      {
        title: t('account_summary.table.account_name'),
        key: 'accountName',
        width: '200px',
        render: (record: IAccountModel) => record.accountName,
      },
      {
        title: t('account_summary.table.currency'),
        key: 'currencyCode',
        width: '50px',
        render: (record: IAccountModel) => record.currencyCode,
      },
      {
        title: t('account_summary.table.status'),
        key: 'status',
        width: '170px',
        render: (record: IAccountModel) => (
          <BankAccountStatus status={record.status} />
        ),
        filteredValue: filters.status,
        filterSearch: true,
        filters: Array.from(enumToMap(BankAccountStatuses)).map((e) => ({
          text: t(`bank_account_statuses.${e[1]}`),
          value: e[1],
        })),
      },
      {
        title: t('account_summary.table.balance'),
        key: 'balance',
        width: '100px',
        render: renderActualBalance,
      },
      {
        title: t('account_summary.table.balance_home'),
        key: 'balanceUSD',
        width: '100px',
        render: (record: IAccountModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.balanceUSD),
      },
      {
        title: t('account_summary.table.last_transaction_date'),
        key: 'lastTransactionDateTimestamp',
        width: '200px',
        render: (record: IAccountModel) =>
          DateHelpers.formatTimestampDateToString(
            record.lastTransactionDate,
            DEFAULT_FULL_DATE_FORMAT,
          ),
      },
    ];
  }, [filters]);

  return <Table {...rest} columns={columns} />;
};

const StyledWarningText = styled(Text)`
  white-space: pre-line;
`;

const StyledDottedText = styled(Text)`
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px dotted ${theme.colorWarning}`};
  width: fit-content;
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const AccountNumberColWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default AccountsTable;
