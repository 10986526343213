import { Modal as AntdModal } from 'antd';
import { ModalApi } from './types';

/**
 * The `Modal` object provides access to AntD's static modal methods like:
 * - Modal.confirm()
 * - Modal.info()
 * - Modal.success()
 * - Modal.error()
 * - Modal.warning()
 *
 * Wrapping these methods allows you to maintain a consistent API, simplify future changes
 * (e.g., modifying default behavior or styling), and ensure that all modals opened this way
 * follow your design system's conventions.
 */
const Modal: ModalApi = {
  confirm: AntdModal.confirm,
  info: AntdModal.info,
  success: AntdModal.success,
  error: AntdModal.error,
  warning: AntdModal.warning,
};

export default Modal;
