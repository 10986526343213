import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { AccountFormItemModel, FormValuesModel } from '..';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

// constants
import { AccountTypes } from 'enums/onboarding/crm';
import { MAX_ACCOUNTS_COUNT } from 'constants/onboarding';

// components
import { AddButton } from '@ui';
import { SectionIntro } from '@ui';
import BankAccountForm from './BankAccountForm';
import HideIfDisabledForm from '../../../../HideIfDisabledForm';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const renderAccounts = (arrayHelper: FieldArrayRenderProps) => {
    const initialOption: AccountFormItemModel = {
      type: AccountTypes.current,
      purpose: '',
      currencyId: null,
      initialDeposit: null,
      initialDepositOrigin: null,
      jurisdictions: [],
      incomingWiresNumber: null,
      estimatedIncomingFunds: null,
      outgoingWiresNumber: null,
      estimatedOutgoingFunds: null,
    };

    return (
      <>
        {values.accounts.map((_, i) => (
          <BankAccountForm
            key={i}
            index={i}
            onRemoveClick={() => arrayHelper.remove(i)}
          />
        ))}

        {values.accounts.length < MAX_ACCOUNTS_COUNT && (
          <HideIfDisabledForm hideIfSubmitting>
            <AddButton onClick={() => arrayHelper.push(initialOption)}>
              {t('account_information.add_account')}
            </AddButton>
          </HideIfDisabledForm>
        )}
      </>
    );
  };

  return (
    <>
      <SectionIntro
        title={t('account_information.title')}
        description={t('account_information.description')}
      />
      <FieldArray name="accounts" render={renderAccounts} />
    </>
  );
};

export default InnerForm;
