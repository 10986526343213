import React from 'react';
import styled from 'styled-components';
import { Descriptions } from 'antd';
import { DescriptionSectionProps } from './types';

/**
 * The `DescriptionSection` component displays a list of items with labels and corresponding descriptions.
 * It provides a structured layout for presenting key-value information, optionally with a custom background,
 * borders, and different sizes.
 *
 * @param {DescriptionSectionProps} props - The properties defined in `DescriptionSectionProps`.
 */
const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  data,
  size = 'middle',
  bordered = true,
  background,
  ...rest
}) => {
  return (
    <StyledDescriptions
      size={size}
      bordered={bordered}
      background={background}
      colon={false}
      column={1}
      labelStyle={{ width: 265, maxWidth: 265 }}
      {...rest}
    >
      {data.map((e, i) =>
        !e.hidden ? (
          <Descriptions.Item key={i} label={e.label} {...rest}>
            {e.description}
          </Descriptions.Item>
        ) : null,
      )}
    </StyledDescriptions>
  );
};

const StyledDescriptions = styled(Descriptions)<{ background?: string }>`
  .ant-descriptions-view,
  .ant-descriptions-row,
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    border-color: ${({ theme }) => theme.colorDarkL4};
  }

  .ant-descriptions-item-label {
    background-color: unset !important;
    color: ${({ theme }) => theme.colorLight};
  }

  .ant-descriptions-item-content {
    background-color: unset;
    color: ${({ theme }) => theme.colorWhite};
  }

  background: ${({ theme, background }) =>
    background || theme.dragAndDropBackground};
`;

export default React.memo(DescriptionSection);
