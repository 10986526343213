import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IAccountModel } from 'typings/onboarding/account';

// components
import { AddButton } from '@ui';
import AddBlockchainAddressModal from './AddBlockchainAddressModal';

interface IProps {
  account: IAccountModel;
  onAdd: () => void;
}

const AddBlockchainAddress = ({ account, onAdd }: IProps) => {
  const { t } = useTranslation('accounts');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalClose = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd();
    }
    setIsModalVisible(false);
  };

  return (
    <>
      <StyledAddButton onClick={() => setIsModalVisible(true)}>
        {t(
          'account_activity.send_wire_instructions.circle.blockchain_address_modal.add.add_button',
        )}
      </StyledAddButton>

      <AddBlockchainAddressModal
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        account={account}
      />
    </>
  );
};

const StyledAddButton = styled(AddButton)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default AddBlockchainAddress;
