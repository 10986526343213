import React, { useEffect, useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useQueryParam from 'hooks/useQueryParam';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { StateModel } from 'redux/reducers';
import { MessageModel } from 'typings/messaging/messages';
import { MessagesHelpers } from 'helpers/messages';
import { getMessagesStatus } from 'redux/actions/notifications';
import { clientMessagingAPI } from 'api/messaging/clientMessagingAPI';
import { useDispatch, useSelector } from 'react-redux';

// constants
import { RoutePaths } from 'routes/routes';
import { colorsTheme } from 'resources/theme/styled/colors';
import { MESSAGES_RESULT_LIMIT } from 'constants/global';

// components
import { Text, Divider, Spin } from '@ui';
import ThreadList from 'components/Tables/TableTemplates/Messages/ThreadList';
import ReplyInThread from 'modules/Messaging/ReplyInThread';

const MessageThread = () => {
  const dispatch = useDispatch();

  const threadId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.UserProfile_Messages,
  });
  const messageContainerRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [isReplyFormVisible, setIsReplyFormVisible] = useState(false);
  const [isThreadInitialized, setIsThreadInitialized] = useState(false);

  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id as string,
  );

  const { response: fetchThreadRes } = useFetch(
    () => (threadId ? clientMessagingAPI.fetchThreadById(threadId) : null),
    [threadId],
  );

  const fetchMessages = async () => {
    if (isLoading || !hasMore) return;
    setLoading(true);
    const response = await clientMessagingAPI.fetchMessagesByThreadId(
      threadId,
      { page: currentPage, limit: MESSAGES_RESULT_LIMIT },
    );

    if (messages.length + response.data.length < response.total) {
      setMessages((prevState: MessageModel[]) => [
        ...response.data,
        ...prevState,
      ]);
      setHasMore(true);
      setCurrentPage(currentPage + 1);
    } else {
      setMessages((prevState: MessageModel[]) => [
        ...response.data,
        ...prevState,
      ]);
      setHasMore(false);
    }
    setLoading(false);
  };

  useInfinityScroll(
    fetchMessages,
    messageContainerRef,
    hasMore,
    'top',
    !threadId,
  );

  useEffect(() => {
    const readThread = async (id: string) => {
      await clientMessagingAPI.readThread(id);
      dispatch(getMessagesStatus());
    };

    if (messages.length && !isThreadInitialized) {
      const newMessage = messages.some((e) =>
        MessagesHelpers.isMessageNew(e, currentUserId),
      );

      if (newMessage) {
        readThread(threadId);
      }

      setIsThreadInitialized(true);
    }
  }, [threadId, messages, isThreadInitialized, currentUserId]);

  useEffect(() => {
    if (messageContainerRef.current && isThreadInitialized) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight -
        messageContainerRef.current.offsetHeight;
    }
  }, [isThreadInitialized]);

  const handleReply = (message: MessageModel) => {
    setMessages((prevState: MessageModel[]) => [...prevState, message]);
  };

  return (
    <div>
      {fetchThreadRes && (
        <>
          <StyledSubjectName
            variant="h6"
            weight="semi-bold"
            color={colorsTheme.colorWhite}
          >
            {fetchThreadRes.subject}
          </StyledSubjectName>
          <StyledDivider />
        </>
      )}

      <Spin spinning={isLoading}>
        <ThreadList
          ref={messageContainerRef}
          isChangeHeight={isReplyFormVisible}
          threadMessages={messages || []}
        />
      </Spin>

      {messages.length ? (
        <ReplyWrapper>
          <ReplyInThread
            setIsReplyFormVisible={setIsReplyFormVisible}
            isReplyFormVisible={isReplyFormVisible}
            threadId={threadId}
            onReply={handleReply}
          />
        </ReplyWrapper>
      ) : null}
    </div>
  );
};

const StyledSubjectName = styled(Text)`
  padding: ${({ theme }) => `0px ${theme.paddingSm}`};
  margin-bottom: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0px`};
`;

const ReplyWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default MessageThread;
