import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useFormikContext } from 'formik';

// components
import { colorsTheme } from 'resources/theme/styled/colors';
import { IconSVG, Button, Col, Row, Tooltip } from '@ui';
import { ReactComponent as ArrowLeft } from 'resources/icons/remix-icons/arrow-left-s-line.svg';

export interface LastStepSubmitButtonsFormValuesModel {
  submitActionType: ActionKeys | null;
}

const ButtonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

export type ActionKeys = 'save-back' | 'save' | 'submit' | 'save-exit';

interface IProps {
  showBackButton?: boolean;
  showOnlySaveButton?: boolean;
  canSubmitForReview?: boolean;
  submitButtonText?: string;
}

const LastStepSubmitButtons = ({
  showOnlySaveButton,
  canSubmitForReview,
  submitButtonText,
  showBackButton,
}: IProps) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const { values, submitForm, isSubmitting, setValues } =
    useFormikContext<LastStepSubmitButtonsFormValuesModel>();

  const onSaveClick = async () => {
    await setValues({ ...values, submitActionType: 'save' }, false);
    submitForm();
  };

  const onSubmitClick = async () => {
    await setValues({ ...values, submitActionType: 'submit' }, false);
    submitForm();
  };

  const onBackClick = async () => {
    await setValues({ ...values, submitActionType: 'save-back' }, false);
    submitForm();
  };

  return (
    <Row justify="end" gutter={[16, 16]}>
      {showOnlySaveButton ? (
        <Col {...ButtonGridSizes}>
          <StyledButton
            size="large"
            onClick={onSaveClick}
            loading={!!(values.submitActionType === 'save' && isSubmitting)}
            disabled={isSubmitting}
          >
            {t('save')}
          </StyledButton>
        </Col>
      ) : (
        <>
          {showBackButton && (
            <Col {...ButtonGridSizes}>
              <StyledButton
                size="large"
                onClick={onBackClick}
                loading={
                  !!(values.submitActionType == 'save-back' && isSubmitting)
                }
                disabled={isSubmitting}
              >
                <IconSVG component={ArrowLeft} color={colorsTheme.colorWhite} />{' '}
                {t('back')}
              </StyledButton>
            </Col>
          )}

          <Col {...ButtonGridSizes}>
            <StyledButton
              size="large"
              onClick={onSaveClick}
              loading={!!(values.submitActionType === 'save' && isSubmitting)}
              disabled={isSubmitting}
            >
              {t('save')}
            </StyledButton>
          </Col>

          <Col {...ButtonGridSizes}>
            {!canSubmitForReview ? (
              <StyledTooltip
                placement="top"
                title={t(
                  'application_documentation.disabled_submit_action_tooltip_text',
                  { ns: 'onboarding' },
                )}
              >
                <StyledButton
                  size="large"
                  onClick={onSubmitClick}
                  loading={
                    !!(values.submitActionType === 'submit' && isSubmitting)
                  }
                  disabled
                >
                  {submitButtonText || t('submit_for_review')}
                </StyledButton>
              </StyledTooltip>
            ) : (
              <StyledButton
                size="large"
                onClick={onSubmitClick}
                loading={
                  !!(values.submitActionType === 'submit' && isSubmitting)
                }
                disabled={isSubmitting}
              >
                {submitButtonText || t('submit_for_review')}
              </StyledButton>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

export default LastStepSubmitButtons;
