import React from 'react';

// helpers
import { Moment } from 'moment';
import { AddTravelNotificationSchema } from '../../../../../validations/profile/travelNotifications';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  loadingInitialData?: boolean;
}

export interface FormValuesModel {
  countries: IFormCountry[];
}

export interface IFormCountry {
  country: string | null;
  departureDate: Moment | string | null;
  returnDate: Moment | string | null;
}

const TravelNotificationRuleModal = ({
  title,
  isVisible,
  closeCallback,
  initialValues,
  onSubmit,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={AddTravelNotificationSchema}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default TravelNotificationRuleModal;
