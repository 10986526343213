import * as yup from 'yup';

// constants
import { NULLABLE_STRING_VALIDATION_RULE } from '../common';

const TravelNotificationSchema = yup.object().shape({
  country: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  departureDate: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  returnDate: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});

export const AddTravelNotificationSchema = yup.object().shape({
  countries: yup.array().of(TravelNotificationSchema),
});
