import React, { memo, useEffect, useMemo } from 'react';

// helpers
import { StyledComponentProps } from '../../../../../../typings/common';
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

export type AllowedCurrency = {
  id: number;
  name: string;
};

interface IProps extends SelectProps<AllowedCurrency>, StyledComponentProps {
  currencies: AllowedCurrency[];
}

const AllowedCurrenciesSelect = memo(({ currencies, ...rest }: IProps) => {
  const options = useMemo<SelectOption<AllowedCurrency>[]>(() => {
    if (!currencies) {
      return [];
    }

    return currencies.map((e) => ({
      id: e.id,
      label: e.name,
      model: e,
    }));
  }, [currencies]);

  useEffect(() => {
    if (options.length === 1 && !rest.value) {
      rest.onChange && rest.onChange(options[0].id as any);
    }
  }, [options, rest.value]);

  return (
    <FormSelect
      {...rest}
      // Disable field if length of an array of options is equal to 1 (Business Logic)
      disabled={rest.disabled || options.length === 1}
      options={options}
    />
  );
});

export default AllowedCurrenciesSelect;
