import { message } from 'antd';
import { MessageArgs, MessageApi } from './types';

/**
 * The `Message` object provides a wrapper around Ant Design’s `message` API,
 * allowing you to manage and display global messages (like notifications)
 * in a consistent way across your application.
 *
 * By encapsulating `message`, you retain the flexibility to alter its behavior,
 * styling, or even replace it with a different library in the future.
 */
const Message: MessageApi = {
  info(content, duration, onClose) {
    message.info(content, duration, onClose);
  },

  success(content, duration, onClose) {
    message.success(content, duration, onClose);
  },

  error(content, duration, onClose) {
    message.error(content, duration, onClose);
  },

  warning(content, duration, onClose) {
    message.warning(content, duration, onClose);
  },

  loading(content, duration, onClose) {
    message.loading(content, duration, onClose);
  },

  open(args: MessageArgs) {
    message.open(args);
  },

  config(options) {
    message.config(options);
  },

  destroy() {
    message.destroy();
  },
};

export default Message;
