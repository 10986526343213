import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { useDispatch } from 'react-redux';
import { securityAPI } from 'api/profile/securityAPI';
import { FormikHelpers } from 'formik';
import { setPrimaryEmail } from 'redux/actions/auth';
import { RequiredPropsForModalDialogModel } from '@ui';

// components
import {
  default as TemplateUpdatePrimaryEmailModal,
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Auth/UpdatePrimaryEmailModal';
import { Message, Modal } from '@ui';

type IProps = RequiredPropsForModalDialogModel;

const UpdatePrimaryEmailModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation(['profile', 'server_errors']);
  const dispatch = useDispatch();

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    return {
      otp: '',
      email: '',
      otpSent: false,
    };
  }, [isVisible]);

  const handleOnSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (!values.otpSent) {
      Modal.confirm({
        content: t(
          'security.update_primary_email.modal_dialog.confirmation_modal.description',
        ),
        okText: t('ok', { ns: 'common' }),
        width: 500,
        cancelText: t('cancel', { ns: 'common' }),
        onOk: async () => {
          try {
            await securityAPI.sendOTPToEmail(values.email);
            helpers.setFieldValue('otpSent', true);
            Message.success(
              t('security.update_primary_email.modal_dialog.otp_sent_message'),
            );
          } catch (error: any) {
            const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
            switch (errorCode) {
              case '1200401': {
                helpers.setFieldError(
                  'email',
                  t('1200401', { ns: 'server_errors' }),
                );
                break;
              }
              case '1200403': {
                helpers.setFieldError(
                  'email',
                  t('1200403', { ns: 'server_errors' }),
                );
              }
            }
          }
        },
      });
    } else {
      await securityAPI.updatePrimaryEmail({
        email: values.email,
        otp: values.otp,
      });

      dispatch(setPrimaryEmail(values.email));

      Message.success(
        t('security.update_primary_email.modal_dialog.success_submit_message'),
      );
      closeCallback();
    }
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201020': {
        formikHelpers.setFieldError(
          'otp',
          t('1201020', { ns: 'server_errors' }),
        );
        break;
      }
      case '12010003': {
        formikHelpers.setFieldError(
          'otp',
          t('12010003', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <TemplateUpdatePrimaryEmailModal
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onSubmit={handleOnSubmit}
      onSubmitError={handleOnSubmitError}
    />
  );
};
export default UpdatePrimaryEmailModal;
