import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { Trans } from 'react-i18next';
import { Text, ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

export type IProps = RequiredPropsForModalDialogModel;

const ShareContactWithoutKYCRequestModal = memo(
  ({ isVisible, closeCallback }: IProps) => {
    const { t } = useTranslation('connections');

    return (
      <ModalDialog
        title={t(
          'requests.view_request_modal.share_contact_request_no_kyc.title',
        )}
        isVisible={isVisible}
        closeCallback={closeCallback}
        hideFooterButtons
      >
        <Text>
          {t(
            'requests.view_request_modal.share_contact_request_no_kyc.description',
          )}
        </Text>
        <ol>
          <li>
            <Trans
              t={t}
              i18nKey="requests.view_request_modal.share_contact_request_no_kyc.step_1"
              components={[
                <Text variant="span" key="span" weight="semi-bold" />,
              ]}
            />
          </li>
          <li>
            {t(
              'requests.view_request_modal.share_contact_request_no_kyc.step_2',
            )}
          </li>
          <li>
            <Trans
              t={t}
              i18nKey="requests.view_request_modal.share_contact_request_no_kyc.step_3"
              components={[
                <Text variant="span" key="span" weight="semi-bold" />,
              ]}
            />
          </li>
        </ol>
        <Text>
          {t(
            'requests.view_request_modal.share_contact_request_no_kyc.sub_description',
          )}
        </Text>
      </ModalDialog>
    );
  },
);

export default ShareContactWithoutKYCRequestModal;
