import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { TransactionApprovalTemplateModel } from 'typings/approvalManagement/accountManagement';

// components
import {
  Text,
  Table,
  Divider,
  RequiredPropsForTableModel,
  TableColumnModel,
  Col,
  Row,
} from '@ui';
import StatusLabel from 'components/Typography/StatusLabel';
import TableActions from 'components/Tables/TableActions';
import TransactionApprovalRulesMatrix from 'components/Tables/TransactionApprovalRulesMatrix';

interface IProps
  extends RequiredPropsForTableModel<TransactionApprovalTemplateModel> {
  hasEditAccess: boolean;
  disabledActions?: boolean;
  onActionsClick: (key: string, id: string) => void;
}

const InnerTable = ({
  onActionsClick,
  hasEditAccess,
  disabledActions,
  ...rest
}: IProps) => {
  const { t } = useTranslation('account_management');

  const renderBankAccountName = ({
    bankAccounts,
  }: TransactionApprovalTemplateModel) => {
    return (
      <>
        {bankAccounts.map((e, i) => (
          <>
            <Row key={i}>
              <Col span={24}>
                <Text variant="body1">{e.bankAccountName}</Text>
              </Col>
              <Col span={24}>
                <Text variant="body1">{e.bankAccountNumber}</Text>
              </Col>
              <Col span={24}>
                <Text variant="body1">{e.bankAccountCurrency}</Text>
              </Col>
            </Row>
            {i < bankAccounts.length - 1 && <StyledDivider />}
          </>
        ))}
      </>
    );
  };

  const renderActions = ({
    id,
    isActive,
  }: TransactionApprovalTemplateModel) => {
    if (!isActive) {
      return null;
    }

    return (
      <TableActions
        menu={[
          {
            key: 'edit',
            name: t('approval_rules.edit_rule_button'),
            hidden: !hasEditAccess,
          },
        ]}
        disabled={disabledActions}
        onSelect={(key: string) => onActionsClick(key, id)}
      />
    );
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('approval_rules.bank_accounts.table.bank_account'),
        key: 'account_number',
        width: 300,
        render: renderBankAccountName,
      },
      {
        key: 'transaction_approval_matrix',
        render: (record: TransactionApprovalTemplateModel) => (
          <TransactionApprovalRulesMatrix transactionRule={record} />
        ),
        title: t('approval_rules.bank_accounts.table.approval_rule'),
      },
      {
        title: t('approval_rules.bank_accounts.table.status'),
        key: 'transaction_rule_status',
        render: (record: TransactionApprovalTemplateModel) =>
          record.isActive ? (
            <StatusLabel
              status="success"
              content={t('approval_rules.approval_rule_statuses.Active')}
            />
          ) : (
            <StatusLabel
              status="warning"
              content={t('approval_rules.approval_rule_statuses.PendingReview')}
            />
          ),
      },
      {
        key: 'actions',
        width: '70px',
        render: renderActions,
      },
    ];
  }, [hasEditAccess, disabledActions, onActionsClick]);

  return <Table disableMobileMode columns={tableColumns} {...rest} />;
};

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.paddingSm} 0px`};
`;

export default InnerTable;
