import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '../../../../../helpers/date';
import { ApprovalEntryModel } from '../../../../../typings/approvalManagement/accountManagement';
import { DEFAULT_FULL_DATE_FORMAT } from '../../../../../constants/global';
import { ApprovalWorkflowChangeTypes } from '../../../../../enums/approvalManagement/approvalManagement';

// components
import { Link } from '@ui';
import ApprovalEntryStatus from 'components/Additional/Statuses/ApprovalEntryStatus';
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

interface IProps extends RequiredPropsForTableModel<ApprovalEntryModel> {
  onApprovalSelect: (approvalId: string) => void;
}

const ApprovalsTable = ({ onApprovalSelect, ...rest }: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('pending_approvals.table.date'),
        key: 'date',
        render: (record: ApprovalEntryModel) =>
          DateHelpers.formatDateToString(
            record.createdAt,
            DEFAULT_FULL_DATE_FORMAT,
          ),
      },
      {
        title: t('pending_approvals.table.entry'),
        key: 'changed-entry',
        render: (record: ApprovalEntryModel) => (
          <Link onClick={() => onApprovalSelect(record.id)}>
            {t(`pending_approvals.approval_entry_types.${record.entryType}`)}
          </Link>
        ),
      },
      {
        title: t('pending_approvals.table.type'),
        key: 'changed-type',
        render: (record: ApprovalEntryModel) =>
          t(
            `pending_approvals.change_types.${ApprovalWorkflowChangeTypes[record.value]}`,
          ),
      },
      {
        title: t('pending_approvals.table.status'),
        key: 'status',
        render: (record: ApprovalEntryModel) => (
          <ApprovalEntryStatus status={record.status} />
        ),
      },
    ];
  }, [onApprovalSelect]);

  return <Table {...rest} columns={tableColumns} />;
};

export default ApprovalsTable;
