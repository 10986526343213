import React from 'react';

// helpers
import { colorsTheme } from 'resources/theme/styled/colors';
import { DeleteButtonProps } from './types';

// components
import { IconSVG, IconButton } from '@ui';
import { ReactComponent as DeleteIcon } from 'resources/icons/remix-icons/delete-bin-6-line.svg';

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, className }) => {
  return (
    <IconButton
      className={className}
      icon={<IconSVG component={DeleteIcon} color={colorsTheme.colorError} />}
      type="bordered"
      danger
      onClick={onClick}
    />
  );
};

export default React.memo(DeleteButton);
