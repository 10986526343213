import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { ISession } from 'typings/profile/security';
import { DateHelpers } from 'helpers/date';
import { colorsTheme } from 'resources/theme/styled/colors';
import { SessionHelpers } from 'helpers/session';

// components
import {
  Text,
  Button,
  IconSVG,
  Table,
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@ui';
import { ReactComponent as ComputerIcon } from 'resources/icons/remix-icons/computer-line.svg';

interface IProps extends RequiredPropsForTableModel<ISession> {
  onDelete: (session: ISession) => void;
}

const ActiveSessionsTable = ({ onDelete, ...rest }: IProps) => {
  const { t } = useTranslation('profile');

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('security.sessions.current_sessions.table.signin_date'),
        key: 'signInDate',
        render: (record: ISession) =>
          DateHelpers.formatDateToString(record.createdAt, 'llll'),
      },

      {
        title: t('security.sessions.current_sessions.table.device'),
        key: 'device',
        render: (record: ISession) => (
          <Text variant="body1">
            <StyledIconSVG
              component={ComputerIcon}
              color={colorsTheme.colorWhite}
            />
            {SessionHelpers.getDeviceName(record.device)}
          </Text>
        ),
      },

      {
        title: t('security.sessions.current_sessions.table.session_expiration'),
        key: 'sessionExpiration',
        render: (record: ISession) =>
          DateHelpers.formatDateToString(record.sessionExpiration, 'llll'),
      },

      {
        align: 'right',
        width: '300px',
        key: 'delete',
        render: (record: ISession) => (
          <Button fullWidth danger onClick={() => onDelete(record)}>
            {t(
              'security.sessions.current_sessions.stop_session.stop_session_button',
            )}
          </Button>
        ),
      },
    ];
  }, [onDelete]);

  return <Table columns={columns} {...rest} />;
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default ActiveSessionsTable;
