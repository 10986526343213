import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '../../../../../helpers/date';
import { SecurityKeyModel } from '../../../../../typings/profile/fido';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import { Link } from '@ui';
import TableActions from '../../../TableActions';
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

export type ActionKeys = 'details' | 'delete';

interface IProps extends RequiredPropsForTableModel<SecurityKeyModel> {
  onActionsClick: (key: ActionKeys, record: SecurityKeyModel) => void;
}

const SecurityKeysTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation(['profile', 'common']);

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        key: 'name',
        width: '30%',
        title: t('security.two_step_verification.security_key.table.name'),
        render: (record: SecurityKeyModel) => (
          <Link onClick={() => onActionsClick('details', record)}>
            {record.name}
          </Link>
        ),
      },

      {
        key: 'registered_at',
        width: '30%',
        title: t(
          'security.two_step_verification.security_key.table.registered_at',
        ),
        render: (record: SecurityKeyModel) =>
          DateHelpers.formatDateToString(
            record.createdAt,
            DEFAULT_DATE_TIME_FORMAT,
          ),
      },

      {
        key: 'last_use_at',
        width: '30%',
        title: t(
          'security.two_step_verification.security_key.table.last_use_at',
        ),
        render: (record: SecurityKeyModel) =>
          DateHelpers.formatDateToString(
            record.updatedAt,
            DEFAULT_DATE_TIME_FORMAT,
          ),
      },

      {
        key: 'actions',
        align: 'right',
        render: (record: SecurityKeyModel) => (
          <TableActions
            menu={[
              {
                key: 'delete',
                name: t('delete', { ns: 'common' }),
                danger: true,
              },
            ]}
            onSelect={(key) => onActionsClick(key as ActionKeys, record)}
          />
        ),
      },
    ];
  }, [onActionsClick]);

  return <Table {...rest} columns={tableColumns} />;
};

export default SecurityKeysTable;
