import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import ErrorCard from './ErrorCard';
import { ModalDialog, RequiredPropsForModalDialogModel, Col, Row } from '@ui';

export type ErrorModel = {
  code: string;
  details: {
    itemName?: string;
    relationshipTemplateParentName?: string;
    minPercentage?: number;
    actualPercentage?: number;
  };
};

interface IProps extends RequiredPropsForModalDialogModel {
  errors: ErrorModel[] | null;
}

const SubmitForReviewErrorsModalDialog = ({
  isVisible,
  closeCallback,
  errors,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);

  return (
    <ModalDialog
      title={t('client_group_actions.submit_for_review_errors_modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      cancelButtonProps={{ hidden: true }}
      submitButtonProps={{
        text: t('ok', { ns: 'common' }),
        onClick: () => closeCallback(),
      }}
    >
      <Row gutter={[16, 16]}>
        {errors
          ? errors.map((error, index) => (
              <Col key={index} span={24}>
                <ErrorCard error={error} />
              </Col>
            ))
          : null}
      </Row>
    </ModalDialog>
  );
};

export default SubmitForReviewErrorsModalDialog;
