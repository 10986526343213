import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { MessageModel } from 'typings/messaging/messages';
import { FormValuesModel } from 'components/Forms/TemplateForms/UserProfile/Messages/ReplyForm';
import {
  clientMessagingAPI,
  NewMessageBodyModel,
} from 'api/messaging/clientMessagingAPI';

// components
import { Button, Message } from '@ui';
import ReplyForm from 'components/Forms/TemplateForms/UserProfile/Messages/ReplyForm';

interface IProps {
  threadId: string;
  onReply: (message: MessageModel) => void;
  isReplyFormVisible: boolean;
  setIsReplyFormVisible: (value: boolean) => void;
}

const ReplyInThread = ({
  threadId,
  onReply,
  isReplyFormVisible,
  setIsReplyFormVisible,
}: IProps) => {
  const { t } = useTranslation('profile');

  const initialFormValues = useMemo<FormValuesModel>(
    () => ({ message: '', attachments: [] }),
    [],
  );

  const handleOnSubmit = async (values: FormValuesModel) => {
    const newMessage: NewMessageBodyModel = {
      body: values.message,
      attachments: values.attachments?.map((attachment) => {
        return {
          documentId: attachment.id,
          contentType: attachment.file?.contentType || '',
          fileId: attachment.file?.id || '',
          name: attachment.file?.name || '',
        };
      }),
    };

    const data = await clientMessagingAPI.replyInThread(threadId, newMessage);
    Message.success(t('messages.send_message.success_submit_message'));
    onReply(data?.message);
    setIsReplyFormVisible(false);
  };

  return (
    <>
      {isReplyFormVisible ? (
        <ReplyForm
          onSubmit={handleOnSubmit}
          initialFormValues={initialFormValues}
        />
      ) : (
        <Button onClick={() => setIsReplyFormVisible(true)}>
          {t('messages.reply.reply_button')}
        </Button>
      )}
    </>
  );
};

export default ReplyInThread;
