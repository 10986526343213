import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { GraphData } from '../../../../../Charts/GraphChart';
import { EntityHelpers } from 'helpers/crm/entity';
import { RelationshipHelpers } from 'helpers/crm/relationship';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';

// components
import ChartView from './ChartView';
import { SectionIntro } from '@ui';

interface IProps {
  tree: GraphData;
  onTreeUpdate: () => void;
  onboardingData: OnboardingStatusModel;
}

const InnerForm = ({ onboardingData, tree, onTreeUpdate }: IProps) => {
  const { t } = useTranslation('onboarding');

  const titleData = useMemo(() => {
    const entity = onboardingData.item as IEntity;
    const organizationName = EntityHelpers.getEntityNameByNameType(
      entity.names,
    );

    const controllerTemplateName =
      entity.keyRelationshipInstance?.keyRelations
        .reduce<string[]>((acc, next) => {
          if (next.isController) {
            acc.push(
              RelationshipHelpers.getRelationshipName(
                next.relationshipTemplate,
                next.track,
              ),
            );
          }
          return acc;
        }, [])
        .join(', ') || '';

    const ownershipTemplateName =
      entity.keyRelationshipInstance?.keyRelations
        .reduce<string[]>((acc, next) => {
          if (RelationshipHelpers.isKeyRelationshipOwnership(next)) {
            acc.push(
              RelationshipHelpers.getRelationshipName(
                next.relationshipTemplate,
                next.track,
              ),
            );
          }
          return acc;
        }, [])
        .join(', ') || '';

    return {
      organizationName,
      controllerTemplateName,
      ownershipTemplateName,
    };
  }, [onboardingData]);

  return (
    <StyledWrapper>
      <SectionIntro
        title={t('structure.title')}
        description={
          titleData.ownershipTemplateName
            ? t('structure.description', {
                entityName: titleData.organizationName,
                ownershipTemplateName:
                  titleData.ownershipTemplateName.toLowerCase(),
                controllerTemplateName: titleData.controllerTemplateName,
              })
            : undefined
        }
      />

      <ChartView
        graph={tree}
        onboardingData={onboardingData}
        onModify={onTreeUpdate}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
