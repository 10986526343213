import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IconSVGProps } from './types';

/**
 * The `IconSVG` component renders a scalable vector icon with a specified size and color.
 * It can be optionally clickable, making it suitable for interactive elements like buttons or links.
 *
 * @param {IconSVGProps} props - The properties defined in `IconSVGProps`.
 */
const IconSVG: React.FC<IconSVGProps> = ({
  component,
  size = 'small',
  color,
  onClick,
  ...rest
}) => {
  const iconSize = useMemo(() => {
    // Maps each size token to corresponding width and height values.
    switch (size) {
      case 'extra-small':
        return { width: '12px', height: '12px' };
      case 'small':
        return { width: '18px', height: '18px' };
      case 'middle':
        return { width: '32px', height: '32px' };
      case 'large':
        return { width: '64px', height: '64px' };
      case 'extra-large':
        return { width: '84px', height: '84px' };
      default:
        return { width: '18px', height: '18px' };
    }
  }, [size]);

  const IconComponent = component;

  return (
    <Wrapper
      width={iconSize.width}
      height={iconSize.height}
      color={color}
      cursorPointer={!!onClick}
      onClick={onClick}
      {...rest}
    >
      <IconComponent />
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  width: string;
  height: string;
  color?: string;
  cursorPointer: boolean;
}>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'default')};

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: ${({ width }) => width} !important;
    height: ${({ height }) => height} !important;
    fill: ${({ color }) => color} !important;
  }
`;

export default React.memo(IconSVG);
