import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import { debounce } from 'lodash';
import { ApprovalStatusMap } from '..';
import { accountManagementRulesAPI } from 'api/accountManagement/accountManagementRulesAPI';

// constants
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from 'constants/global';

// components
import { Divider } from '@ui';
import FilterBar from './FilterBar';
import { default as TemplateApprovalsTable } from 'components/Tables/TableTemplates/AccountManagement/ApprovalsTable';

interface IProps {
  approvalStatuses: ApprovalStatusMap;
  onApprovalSelect: (approvalId: string) => void;
  updateTableTrigger?: any;
}

const ApprovalsTable = ({
  onApprovalSelect,
  approvalStatuses,
  updateTableTrigger,
}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [nameQuery, setNameQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string | null>(
    Object.keys(approvalStatuses)[0] || null,
  );

  const { response, loading } = useFetch(
    () =>
      accountManagementRulesAPI.fetchApprovalEntries({
        searchByUserNameQuery: nameQuery,
        status: selectedStatus,
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage, nameQuery, selectedStatus, updateTableTrigger],
  );

  const handlerFilterChange = (key: string, value: any) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search-by-name':
        setNameQuery(value.trim() || '');
        break;

      case 'filter-by-status':
        setSelectedStatus(value);
        break;
    }
  };

  return (
    <>
      <FilterBar
        onFilterChange={debounce(
          handlerFilterChange,
          AUTOCOMPLETE_DEBOUNCE_DELAY,
        )}
        selectedStatus={selectedStatus}
        approvalStatuses={approvalStatuses}
      />

      <Divider />

      <TemplateApprovalsTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onApprovalSelect={onApprovalSelect}
      />
    </>
  );
};

export default ApprovalsTable;
