import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Text } from '@ui';
import { Trans } from 'react-i18next';

const Introduction = () => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      <Text>
        {t('administration.administration_rules.introduction.section_title')}
      </Text>
      <Text gutterTop gutterBottom>
        <Trans
          t={t}
          i18nKey="administration.administration_rules.introduction.next_button_description"
          components={[
            <Text
              key="bolder-text"
              variant="span"
              weight="semi-bold"
              color={colorsTheme.colorWhite}
            />,
          ]}
        />
      </Text>
    </>
  );
};

export default Introduction;
