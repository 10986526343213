import * as yup from 'yup';
import { AccountProcessingTypes } from '../../enums/accounting/transfers';
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  TRANSFER_REFERENCE_MAX_LENGTH,
} from '../../constants/global';

// constants
import {
  EMAIL_VALIDATION_RULE,
  ACCOUNT_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  NULLABLE_NUMBER_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
} from '../common';

export const createAccountSchema = yup.object().shape({
  accountType: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  circleAccount: yup.object().when('accountType', {
    is: AccountProcessingTypes.Circle,
    then: (schema) =>
      schema.shape({
        accountName: ACCOUNT_NAME_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),

        accountNumber: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),

        currencyId: NULLABLE_NUMBER_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),

        accountCategoryId: NULLABLE_NUMBER_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
      }),
  }),

  fiatAccount: yup.object().when('accountType', {
    is: AccountProcessingTypes.Native,
    then: (schema) =>
      schema.shape({
        purpose: STRING_VALIDATION_RULE.min(DESCRIPTION_MIN_LENGTH, {
          key: 'form:validation_messages.min_characters',
          values: {
            number: DESCRIPTION_MIN_LENGTH,
          },
        })
          .max(DESCRIPTION_MAX_LENGTH, {
            key: 'form:validation_messages.max_characters',
            values: {
              number: DESCRIPTION_MAX_LENGTH,
            },
          })
          .required('form:validation_messages.required'),

        type: yup
          .mixed()
          .nullable()
          .required('form:validation_messages.required'),

        currencyId: NULLABLE_NUMBER_VALIDATION_RULE.typeError(
          'form:validation_messages.only_numbers',
        ).required('form:validation_messages.required'),

        initialDeposit: yup
          .mixed()
          .nullable()
          .required('form:validation_messages.required'),

        initialDepositOrigin: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),

        jurisdictions: yup
          .array()
          .min(NAME_MIN_LENGTH, 'form:validation_messages.required')
          .required('form:validation_messages.required'),

        incomingWiresNumber: yup
          .mixed()
          .nullable()
          .required('form:validation_messages.required'),

        estimatedIncomingFunds: yup
          .mixed()
          .nullable()
          .required('form:validation_messages.required'),

        outgoingWiresNumber: yup
          .mixed()
          .nullable()
          .required('form:validation_messages.required'),

        estimatedOutgoingFunds: yup
          .mixed()
          .nullable()
          .required('form:validation_messages.required'),
      }),
  }),
});

export const receiveMoneyNativeValidation = yup.object().shape({
  fullName: STRING_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: {
      number: NAME_MIN_LENGTH,
    },
  })
    .max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: NAME_MAX_LENGTH,
      },
    })
    .required('form:validation_messages.required'),

  email: EMAIL_VALIDATION_RULE.required('form:validation_messages.required'),

  message: STRING_VALIDATION_RULE,
  reference: STRING_VALIDATION_RULE.max(TRANSFER_REFERENCE_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: TRANSFER_REFERENCE_MAX_LENGTH,
    },
  }),
});
