import React from 'react';
import { Badge as AntdBadge } from 'antd';
import { BadgeProps } from './types';

/**
 * The `Badge` component displays count values or status icons on top of another element.
 * It is often used to show pending notifications or statuses.
 *
 * This wrapper ensures a consistent interface and styling with the rest of your design system,
 * and makes it easier to adjust or replace `Badge` in the future if necessary.
 *
 * @param {BadgeProps} props - The properties defined in `BadgeProps`.
 */
const Badge: React.FC<BadgeProps> = ({
  className,
  dot,
  count,
  overflowCount,
  showZero,
  title,
  children,
  status,
  color,
  ...rest
}) => {
  return (
    <AntdBadge
      className={className}
      dot={dot}
      count={count}
      overflowCount={overflowCount}
      showZero={showZero}
      title={title}
      status={status}
      color={color}
      {...rest}
    >
      {children}
    </AntdBadge>
  );
};

export default Badge;
