import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { signOut } from 'redux/actions/auth';
import { useDispatch } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Link } from '@ui';
import { IconSVG } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { ReactComponent as LogoutIcon } from 'resources/icons/remix-icons/logout-box-r-line.svg';

const SignOutButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <DivAlignCenter>
      <StyledIconSVG component={LogoutIcon} color={colorsTheme.colorWhite} />
      <Link onClick={() => dispatch(signOut())}>
        {t('signout.signout_button', { ns: 'auth' })}
      </Link>
    </DivAlignCenter>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default SignOutButton;
