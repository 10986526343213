import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { useField } from 'formik';

// components
import { FormField, Col, Row, FormInput } from '@ui';
import TrueFalseSelect from '../../../FormComponents/SelectInputs/TrueFalseSelect';
import CountrySelect, {
  IProps as CountrySelectProps,
} from '../../../FormComponents/SelectInputs/CRM/CountrySelect';

interface IProps {
  namePrefix: string;
  disabled?: boolean;
}

export interface FormValuesModel {
  id?: string;
  name: string;
  isRegulated: boolean | null;
  regulatedCountries: string[];
}

/* Related validation schema: onboarding-new/structure/OrganizationShortInfoFormValidationSchema **/
const OrganizationShortInfoForm = ({ namePrefix, disabled }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [field] = useField<FormValuesModel>(namePrefix);

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span="12">
          <FormField
            label={t(
              'structure.relationships.form_fields.organization.legal_name',
            )}
            name={`${namePrefix}.name`}
            component={FormInput}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span="12">
          <FormField
            label={t(
              'structure.relationships.form_fields.organization.is_regulated',
            )}
            name={`${namePrefix}.isRegulated`}
            component={TrueFalseSelect}
            disabled={disabled}
          />
        </Col>

        {field.value?.isRegulated && (
          <Col span="12">
            <FormField<CountrySelectProps>
              label={t(
                'structure.relationships.form_fields.organization.regulation_countries',
              )}
              name={`${namePrefix}.regulatedCountries`}
              component={CountrySelect}
              additionalProps={{ mode: 'multiple' }}
              disabled={disabled}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default OrganizationShortInfoForm;
