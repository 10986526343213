import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { FormikHelpers } from 'formik';
import { IDocumentForm } from '../../../../../typings/onboarding/onboarding';
import { NewUserValidationSchema } from '../../../../../validations/accountManagement/userManagement';
import { FormValuesModel as RelationshipFormValuesModel } from '../../../../Forms/TemplateForms/CRM/RelationshipForm';
import { FormValuesModel as ContactShortInfoFormValuesModel } from '../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';

// constants
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from '../../../../../enums/onboarding/crm';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

export interface AdditionalFieldFormItemModel {
  name: string;
  value: any | undefined;
  type: AdditionalFieldTypes;
  relatedTo: 'any' | 'contact' | 'organization';
  options: {
    onboardingRequired: boolean;
    isForRegulatedOnly: boolean;
    documentRequiresCertification: boolean;
    selectOptions: string[];
    formDocument: IDocumentForm;
  };
}

export interface FormValuesModel {
  relationshipScopeId: string;
  clientGroupEntryType: OnboardingEntryTypes;
  approvedRelationshipScopeId: string;
  isFromExistingContact: boolean | null;
  contact: ContactShortInfoFormValuesModel;
  relationships: RelationshipFormValuesModel[];
}

const NewUserModalDialog = ({
  isVisible,
  initialValues,
  onSubmit,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('account_management');

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1001007': {
        formikHelpers.setFieldError(
          'contact.email',
          t('1001007', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={NewUserValidationSchema}
      onSubmitError={handleOnSubmitError}
      renderForm={
        <ModalDialog
          closeModalAfterError={false}
          title={t('user_management.add_user.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default NewUserModalDialog;
