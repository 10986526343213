import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormikProps } from 'formik';
import { ApprovalRuleTriggerTypeModel } from 'typings/approvalManagement/accountManagement';
import { ApprovalRuleValidationSchema } from 'validations/accountManagement/approvalRuleTemplate';

// components
import {
  Button,
  Form,
  RequiredPropsForFormModel,
  Col,
  Row,
  PageButton,
} from '@ui';
import SubmitButton from 'components/Buttons/SubmitButton';
import AccountManagementRulesMatrixForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Approval/AccountManagementRulesMatrixForm';

export interface ApprovalRuleMatrixFormValuesModel extends FormValuesModel {
  id: string | null;
  isActive?: boolean;
}

interface IProps
  extends RequiredPropsForFormModel<ApprovalRuleMatrixFormValuesModel> {
  hasEditAccess: boolean;
  isEditActive: boolean;
  onEditStatusChange: (
    status: boolean,
    formHasChanges: boolean,
    form: FormikProps<ApprovalRuleMatrixFormValuesModel>,
  ) => void;
  approvalRuleTriggerTypes: ApprovalRuleTriggerTypeModel[];
}

const ApprovalRuleMatrix = ({
  isEditActive,
  initialValues,
  hasEditAccess,
  approvalRuleTriggerTypes,
  onSubmit,
  onEditStatusChange,
}: IProps) => {
  const { t } = useTranslation('common');
  return (
    <Form
      disabled={!isEditActive}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ApprovalRuleValidationSchema}
      confirmExitWithoutSaving
      renderForm={(form) => (
        <>
          {hasEditAccess && (
            <StyledHeaderRow justify="end" gutter={[16, 16]}>
              {!isEditActive && (
                <Col>
                  <PageButton
                    onClick={() => onEditStatusChange(true, false, form)}
                  >
                    {t('edit_button', { ns: 'common' })}
                  </PageButton>
                </Col>
              )}
              {isEditActive && (
                <>
                  <Col>
                    <Button
                      type="bordered"
                      onClick={() =>
                        onEditStatusChange(false, form.dirty, form)
                      }
                    >
                      {t('cancel', { ns: 'common' })}
                    </Button>
                  </Col>
                  <Col>
                    <SubmitButton size="middle">
                      {t('save', { ns: 'common' })}
                    </SubmitButton>
                  </Col>
                </>
              )}
            </StyledHeaderRow>
          )}
          <AccountManagementRulesMatrixForm
            triggerTypes={approvalRuleTriggerTypes}
          />
        </>
      )}
    />
  );
};

const StyledHeaderRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default ApprovalRuleMatrix;
