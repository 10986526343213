import React, { memo, useEffect, useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { connectionsAPI } from 'api/connections/connectionsAPI';
import { FetchResponseModel } from 'typings/common';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { RequestStatuses, RequestTypes } from 'enums/connections/connections';

// components
import Request from './Request';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import NoDataContainer from 'components/Additional/NoDataContainer';
import { Col, Row, Spin } from '@ui';

export interface RequestModel {
  id: string;
  name: string;
  type: RequestTypes;
  status: RequestStatuses;
  requestedAt: string;
  details: {
    clientGroupId: string;
    clientGroupName: string;
  };
}

const Requests = memo(() => {
  const { t } = useTranslation('connections');
  const requestContainerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<
    FetchResponseModel<RequestModel>
  >({ total: 0, data: [] });
  const [requestsUpdateTrigger, setRequestsUpdateTrigger] = useState({});

  const { response, loading } = useFetch(
    () =>
      page === 1
        ? connectionsAPI.fetchRequests({ page, limit: DEFAULT_TABLE_LIMIT })
        : null,
    [requestsUpdateTrigger, page],
  );

  useEffect(() => {
    if (response && page === 1) {
      setResponseData(response);
      setHasMore(true);
    }
  }, [response, page]);

  const fetchRequests = async () => {
    if (isLoading || !hasMore || !responseData.data.length) return;
    setLoading(true);

    const response = await connectionsAPI.fetchRequests({
      page: page + 1,
      limit: DEFAULT_TABLE_LIMIT,
    });

    if (responseData.data.length + response.data.length < response.total) {
      setResponseData((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...response.data],
      }));
      setHasMore(true);
      setPage(page + 1);
    } else {
      setResponseData((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...response.data],
      }));
      setHasMore(false);
    }
    setLoading(false);
  };

  useInfinityScroll(fetchRequests, requestContainerRef, hasMore, 'bottom');

  const updateRequests = () => {
    setRequestsUpdateTrigger({});
    setPage(1);
  };

  return (
    <RequestsContainer ref={requestContainerRef}>
      <LoadingWrapper loading={loading}>
        {!responseData.data.length ? (
          <NoDataContainer noDataText={t('requests.no_data_description')} />
        ) : (
          <Row wrap={true} gutter={[16, 16]}>
            {responseData.data.map((request, index) => (
              <React.Fragment key={request.id}>
                <Col span={24} key={request.id}>
                  <Request data={request} updateRequests={updateRequests} />
                </Col>
                {responseData.data.length < responseData.total &&
                  index === responseData.data.length - 1 && (
                    <LoaderContainer>
                      <Spin size="large" spinning={isLoading} />
                    </LoaderContainer>
                  )}
              </React.Fragment>
            ))}
          </Row>
        )}
      </LoadingWrapper>
    </RequestsContainer>
  );
});

const LoaderContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RequestsContainer = styled.div`
  max-height: calc(100vh - 340px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 1px;
`;

export default Requests;
