import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from 'constants/externalLinks';

// components
import OTPField from '../../../../FormComponents/OTPField';
import FormErrorTooltip from 'components/Forms/FormErrorTooltip';
import { Trans } from 'react-i18next';
import { Text, Checkbox, FormField, Col, Row } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <OTPFieldWrapper>
        <FormField
          name="code"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </OTPFieldWrapper>
      <Row wrap={false} justify="start">
        <Col flex="30px">
          <FormField
            name="termsOfUse"
            component={Checkbox}
            shouldShowErrorMessage={false}
          />
        </Col>
        <Col flex="auto">
          <Trans
            t={t}
            i18nKey="invitation_code.terms_of_use"
            components={[
              <Text
                key="submit_text"
                variant="span"
                weight="semi-bold"
                color={colorsTheme.colorWhite}
              />,
              <a
                key="terms_of_use"
                href={TERMS_OF_USE_LINK}
                target="_blank"
                rel="noreferrer"
              />,
              <a
                key="privacy_policy"
                href={PRIVACY_POLICY_LINK}
                target="_blank"
                rel="noreferrer"
              />,
            ]}
          />
        </Col>
      </Row>
      <FormErrorTooltip errorKey="termsOfUse" />
    </>
  );
};

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

export default InnerForm;
