import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import { styled } from 'styled-components';
import { ColumnKeys } from '..';
import { IAccountModel } from 'typings/onboarding/account';
import { FormValuesModel } from '..';
import { AccountsHelpers } from 'helpers/accounts';
import { useFormikContext } from 'formik';
import { AccountProcessingTypes } from 'enums/accounting/transfers';
import { ITransferTemplateShortModel } from 'typings/accounting/transfer';
import {
  FetchTransactionBalancesPayload,
  transfersAPI,
} from 'api/accounting/transfersAPI';

// components
import {
  Text,
  FormField,
  AutocompleteEventOption,
  AutocompleteOption,
} from '@ui';
import { FormInput } from '@ui';
import AmountField from './AmountField';
import { DeleteButton } from '@ui';
import { FormDatePicker } from '@ui';
import AvailableBalance from './AvailableBalance';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import TransactionCurrency from './TransactionCurrency';
import WireTransferTemplateAutocomplete, {
  WireTransferTemplateAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/Accounting/WireTransferTemplateAutocomplete';
import BankAccountAutocomplete, {
  BankAccountAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/Accounting/BankAccountAutocomplete';

interface IProps {
  columnKey: ColumnKeys;
  fieldName: string;
  rowIndex: number;
  onDelete: () => void;
  viewOnly?: boolean;
}

const ContentCell = ({
  columnKey,
  fieldName,
  rowIndex,
  viewOnly,
  onDelete,
}: IProps) => {
  const { values, setValues, setSubmitting } =
    useFormikContext<FormValuesModel>();

  const handleBankAccountSelect = async (
    value: string | undefined,
    option?: AutocompleteEventOption<IAccountModel>,
  ) => {
    const transactionsCopy = [...values.transactions];

    if (value && option?.model) {
      transactionsCopy[rowIndex] = {
        ...transactionsCopy[rowIndex],
        from: value || null,
        currencyId: option.model.currencyId,
        currency: option.model.currencyCode,
        balance: {
          actual: option.model.balance
            ? AccountsHelpers.convertAmountFromBigIntToInt(option.model.balance)
            : 0,
          // This value will be set by the value from the response
          available: null,
        },
      };
    } else {
      transactionsCopy[rowIndex] = {
        ...transactionsCopy[rowIndex],
        from: null,
        currency: null,
        balance: null,
      };
    }

    setValues({ transactions: transactionsCopy.slice() });

    const formattedTransactions =
      transactionsCopy.reduce<FetchTransactionBalancesPayload>((acc, curr) => {
        if (curr.from) {
          acc.push({
            sequenceNumber: curr.customId,
            fromAccountNumber: curr.from,
            currencyId: curr.currencyId as number,
            amount: curr.amount
              ? AccountsHelpers.convertAmountFromIntToBigInt(curr.amount)
              : 0,
          });
        }

        return acc;
      }, []);

    setSubmitting(true);
    const response = await transfersAPI.fetchBalancesForWireTransfers(
      formattedTransactions,
    );

    const updatedTransactions = transactionsCopy.map((transaction) => {
      const updatedTransaction = response.find(
        (responseTransaction) =>
          responseTransaction.sequenceNumber === transaction.customId,
      );

      if (updatedTransaction) {
        return {
          ...transaction,
          balance: transaction.balance
            ? {
                ...transaction.balance,
                available: AccountsHelpers.convertAmountFromBigIntToInt(
                  updatedTransaction.availableBalance,
                ),
              }
            : null,
        };
      }

      return transaction;
    });

    setValues({ transactions: updatedTransactions });
    setSubmitting(false);
  };

  const handleTransferTemplateSelect = async (
    value: string | undefined,
    option?: AutocompleteEventOption<ITransferTemplateShortModel>,
  ) => {
    const transactionsCopy = [...values.transactions];
    if (value && option?.model) {
      transactionsCopy[rowIndex] = {
        ...transactionsCopy[rowIndex],
        to: value || null,
        toFieldLabel: option.children as string,
        toReference: option.model.additionalData.recipientReference || '',
      };
    } else {
      transactionsCopy[rowIndex] = {
        ...transactionsCopy[rowIndex],
        to: null,
        toReference: '',
      };
    }

    setValues({ transactions: transactionsCopy.slice() });
  };

  const content = useMemo(() => {
    switch (columnKey) {
      case 'index':
        return (
          <IndexValueWrapper>
            <Text weight="semi-bold">{rowIndex + 1}</Text>
          </IndexValueWrapper>
        );

      case 'from':
        return (
          <FormField<BankAccountAutocompleteProps>
            disabled={viewOnly}
            name={fieldName}
            shouldShowErrorMessage={false}
            component={BankAccountAutocomplete}
            additionalProps={{
              filterAccountsByProcessingType: AccountProcessingTypes.Native,
              onSelect: (newValue: any, option) =>
                handleBankAccountSelect(newValue, option),
              onClear: () => handleBankAccountSelect(undefined),
            }}
          />
        );

      case 'available_balance':
        return <AvailableBalance fieldName={fieldName} />;

      case 'purpose':
        return (
          <FormField
            disabled={viewOnly}
            name={fieldName}
            component={FormInput}
            shouldShowErrorMessage={false}
          />
        );

      case 'amount':
        return (
          <AmountField
            fieldName={fieldName}
            rowIndex={rowIndex}
            disabled={viewOnly}
          />
        );

      case 'currency':
        return <TransactionCurrency fieldName={fieldName} />;

      case 'valueDate':
        return (
          <FormField
            disabled={viewOnly}
            shouldShowErrorMessage={false}
            name={fieldName}
            component={StyledDatePicker}
            additionalProps={{
              disabledDate: (current: any) =>
                current && current < moment().startOf('day'),
            }}
          />
        );

      case 'fromReference':
        return (
          <FormField
            disabled={viewOnly}
            name={fieldName}
            component={FormInput}
            shouldShowErrorMessage={false}
          />
        );

      case 'to':
        return (
          <FormField<WireTransferTemplateAutocompleteProps>
            disabled={viewOnly}
            name={fieldName}
            shouldShowErrorMessage={false}
            component={WireTransferTemplateAutocomplete}
            additionalProps={{
              initialValue: values.transactions[rowIndex]
                .toInitialOption as AutocompleteOption<ITransferTemplateShortModel>,
              onSelect: (newValue: any, option) =>
                handleTransferTemplateSelect(newValue, option),
            }}
          />
        );

      case 'toReference':
        return (
          <FormField
            disabled={viewOnly}
            name={fieldName}
            component={FormInput}
            shouldShowErrorMessage={false}
          />
        );

      case 'delete':
        return viewOnly ? null : (
          <HideIfDisabledForm>
            <DeleteButton onClick={onDelete} />
          </HideIfDisabledForm>
        );
    }
  }, [columnKey, fieldName, rowIndex, viewOnly, onDelete]);

  return <td>{content}</td>;
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const IndexValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ContentCell;
