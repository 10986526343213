import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StepKeys } from '..';
import { colorsTheme } from 'resources/theme/styled/colors';
import { clientGroupsAPI, DashboardStepKeys } from 'api/crm/clientGroupsAPI';

// components
import SetupClientUser from './StepContents/SetupClientUser';
import SetupBankAccount from './StepContents/SetupBankAccount';
import TwoFactorAuthentication from './StepContents/TwoFactorAuthentication';
import { Link, Text, Divider, Col, Row, Modal, Steps, Step } from '@ui';

interface IProps {
  stepStatuses: Partial<Record<StepKeys, boolean>>;
  onSkipStep: (stepKey: StepKeys) => void;
}

type Step = {
  title: string;
  content: React.ReactNode;
  isCompleted: boolean;
  key: DashboardStepKeys | 'two_factor_authentication';
};

type StepStatuses = Partial<Record<StepKeys, Step>>;

const UserStepsSection = ({ stepStatuses, onSkipStep }: IProps) => {
  const { t } = useTranslation('dashboard');
  const [selectedStep, setSelectedStep] = useState<keyof StepStatuses | null>(
    null,
  );

  const steps = useMemo<StepStatuses>(() => {
    const result: StepStatuses = {
      twoFactorAuthentication: {
        key: 'two_factor_authentication',
        title: t('welcome_section.2fa.title'),
        content: <TwoFactorAuthentication />,
        isCompleted: !!stepStatuses.twoFactorAuthentication,
      },
    };

    if (typeof stepStatuses.setupClientUser === 'boolean') {
      result['setupClientUser'] = {
        key: 'first_user_added',
        title: t('welcome_section.add_users.title'),
        content: <SetupClientUser />,
        isCompleted: stepStatuses.setupClientUser,
      };
    }

    if (typeof stepStatuses.setupBankAccount === 'boolean') {
      result['setupBankAccount'] = {
        key: 'first_account_created',
        title: t('welcome_section.setup_accounts.title'),
        content: <SetupBankAccount />,
        isCompleted: stepStatuses.setupBankAccount,
      };
    }

    return result;
  }, [stepStatuses]);

  useEffect(() => {
    if (selectedStep === null) {
      const completedStep = Object.keys(steps).find(
        (key) => !steps[key as keyof StepStatuses]?.isCompleted,
      ) as keyof StepStatuses;
      setSelectedStep(completedStep);
    }
  }, []);

  const currentStepIndex = selectedStep
    ? Object.keys(steps).indexOf(selectedStep)
    : 0;
  const canShowSkipButton = selectedStep
    ? !steps[selectedStep]?.isCompleted &&
      steps[selectedStep]?.key !== 'two_factor_authentication'
    : false;

  const handleStepSkip = () => {
    if (selectedStep) {
      Modal.confirm({
        content: t('welcome_section.skip_step.description'),
        okText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
        async onOk() {
          const key = steps[selectedStep]?.key;

          if (key && key !== 'two_factor_authentication') {
            await clientGroupsAPI.updateStepForDashboardStep(key);
            onSkipStep(selectedStep);
          }
        },
      });
    }
  };

  return (
    <>
      <Text
        variant="subtitle1"
        weight="semi-bold"
        color={colorsTheme.colorLightD1}
      >
        {t('welcome_section.subTitle')}
      </Text>

      <StyledRow>
        <Col span={8}>
          <StyledSteps current={currentStepIndex} direction="vertical">
            {Object.keys(steps).map((key) => (
              <Step
                key={key}
                title={
                  <StyledStepTitle
                    onClick={() => setSelectedStep(key as keyof StepStatuses)}
                  >
                    {steps[key as keyof StepStatuses]?.title}
                  </StyledStepTitle>
                }
                status={
                  steps[key as keyof StepStatuses]?.isCompleted
                    ? 'finish'
                    : 'wait'
                }
              />
            ))}
          </StyledSteps>
        </Col>

        <DividerCol span={1}>
          <StyledDivider type="vertical" />
        </DividerCol>

        <ContentCol span={15}>
          <Text variant="h5" color={colorsTheme.colorWhite} weight="semi-bold">
            {selectedStep ? steps[selectedStep]?.title : ''}
          </Text>

          {selectedStep ? steps[selectedStep]?.content : ''}

          {canShowSkipButton && (
            <Row justify="end">
              <Col>
                <Link onClick={handleStepSkip}>
                  {t('welcome_section.skip_step.button')}
                </Link>
              </Col>
            </Row>
          )}
        </ContentCol>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledSteps = styled(Steps)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const DividerCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDivider = styled(Divider)`
  height: 100% !important;
  margin: 0px !important;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colorWhite};
`;

const ContentCol = styled(Col)`
  padding: 15px 20px;
  background: linear-gradient(
    180deg,
    rgba(36, 57, 67, 0.95) 0%,
    rgba(33, 43, 55, 0.95) 100%
  );
`;

const StyledStepTitle = styled.div`
  cursor: pointer;
`;

export default UserStepsSection;
