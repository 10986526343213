import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Text, IconSVG, Col, Row } from '@ui';
import { ReactComponent as DotIcon } from 'resources/icons/remix-icons/checkbox-blank-circle-fill.svg';
import { ReactComponent as ShieldUserIcon } from 'resources/icons/remix-icons/shield-user-line.svg';

const InfoContainer = () => {
  const { t } = useTranslation('auth');

  const helperOptions = [
    t(
      'account_recovery.id_verification_step.upload_document_methods.camera.take_photo_helper_options.option_1',
    ),
    t(
      'account_recovery.id_verification_step.upload_document_methods.camera.take_photo_helper_options.option_2',
    ),
    t(
      'account_recovery.id_verification_step.upload_document_methods.camera.take_photo_helper_options.option_3',
    ),
  ];

  const renderOptions = (options: string[]) => {
    return options.map((e, i) => (
      <Row key={i} wrap={false}>
        <Col flex="30px">
          <IconSVG component={DotIcon} color={colorsTheme.colorWhite} />
        </Col>

        <Col flex="auto">
          <Text variant="body1">{e}</Text>
        </Col>
      </Row>
    ));
  };

  return (
    <>
      <StyledIconSVG
        component={ShieldUserIcon}
        color={colorsTheme.colorWhite}
        size="large"
      />
      <ListWrapper>{renderOptions(helperOptions)}</ListWrapper>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const ListWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.paddingXl}`};
`;

export default InfoContainer;
