import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormField } from '@ui';
import { FormInputTextArea } from '@ui';

const ConfirmAction = () => {
  const { t } = useTranslation('accounts');

  return (
    <FormField
      label={t(
        'pending_transactions.review_multiple_txns_modal.form_fields.reason',
      )}
      name="reason"
      component={FormInputTextArea}
      additionalProps={{ autoSize: { minRows: 3, maxRows: 6 } }}
    />
  );
};

export default ConfirmAction;
