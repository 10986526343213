import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { GenderTypes } from 'enums/onboarding/crm';
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

export type IProps = SelectProps;

const GenderSelect = (props: IProps) => {
  const { t } = useTranslation('onboarding');

  const options = useMemo<SelectOption[]>(
    () =>
      Object.keys(GenderTypes).map((key) => ({
        id: GenderTypes[key as keyof typeof GenderTypes],
        label: t(
          `applicant_information.gender_options.${GenderTypes[key as keyof typeof GenderTypes]}`,
        ),
      })),
    [t],
  );

  return <FormSelect {...props} options={options} />;
};

export default GenderSelect;
