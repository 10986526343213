import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';

// components
import TitleSection from '../TitleSection';
import { Text, IconSVG, Col, Row } from '@ui';
import { ReactComponent as ArrowRight } from 'resources/icons/remix-icons/arrow-right-s-line.svg';

interface IProps {
  onSelect: (type: OnboardingEntryTypes) => void;
}

const ChooseTypeStep = ({ onSelect }: IProps) => {
  const { t } = useTranslation('auth');

  return (
    <>
      <TitleSection
        title={t('open_an_account.welcome_to_prova')}
        subTitle={t('open_an_account.choose_account_type')}
      />

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <StyledOption onClick={() => onSelect(OnboardingEntryTypes.Contact)}>
            <ColumnWrapper>
              <Text variant="h3" weight="semi-bold">
                {t('open_an_account.individual_option.title')}
              </Text>
              <Text variant="body1">
                {t('open_an_account.individual_option.description')}
              </Text>
            </ColumnWrapper>
            <IconSVG
              component={ArrowRight}
              size="middle"
              color={colorsTheme.colorWhite}
            />
          </StyledOption>
        </Col>

        <Col span={24}>
          <StyledOption
            onClick={() => onSelect(OnboardingEntryTypes.Organization)}
          >
            <ColumnWrapper>
              <Text variant="h3" weight="semi-bold">
                {t('open_an_account.entity_option.title')}
              </Text>
              <Text>{t('open_an_account.entity_option.description')}</Text>
            </ColumnWrapper>
            <IconSVG
              component={ArrowRight}
              size="middle"
              color={colorsTheme.colorWhite}
            />
          </StyledOption>
        </Col>
      </Row>
    </>
  );
};

const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: ${({ theme }) => `2px solid ${theme.colorDarkL2}`};

  padding: ${({ theme }) => `${theme.paddingMd} ${theme.paddingMd}`};
  border-radius: 5px;

  transition: ${({ theme }) => theme.defaultTransition};
  background-color: ${({ theme }) => theme.colorDarkL2};
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colorPrimary};

    .ant-typography {
      color: ${({ theme }) => theme.colorWhite};
    }
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ChooseTypeStep;
