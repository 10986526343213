import React from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioProps } from './types';

/**
 * The `Radio` component represents a single radio input, allowing the user to
 * select exactly one option in a group.
 */
const Radio: React.FC<RadioProps> = ({ className, children, ...rest }) => {
  return (
    <AntdRadio className={className} {...rest}>
      {children}
    </AntdRadio>
  );
};

export default Radio;
