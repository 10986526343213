import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { GraphNode } from '../../../../../../../Charts/GraphChart';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { IconSVG, Tabs } from '@ui';
import ManageKYC from 'components/Additional/CRM/ManageKYC';
import Identification from './Tabs/Identification';
import GeneralInformation from './Tabs/GeneralInformation';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

type TabKeys = 'generalInformation' | 'identification';

interface IProps {
  graphNode: GraphNode;
  onAddKYC: () => void;
  onAction?: (actionType: ActionKeys | null) => void;
}

const OrganizationWithoutKeyRelationship = ({
  graphNode,
  onAddKYC,
  onAction,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>('generalInformation');

  const tabs = useMemo<
    { key: TabKeys; title: React.ReactNode; content: React.ReactNode }[]
  >(() => {
    return [
      {
        key: 'generalInformation',
        title: (
          <>
            {t('applicant_information.section_title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        content: (
          <GeneralInformation
            organization={graphNode.model as IEntity}
            onAction={onAction}
          />
        ),
      },

      {
        key: 'identification',
        title: (
          <>
            {t('identification.title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        content: (
          <Identification
            organization={graphNode.model as IEntity}
            onAction={onAction}
          />
        ),
      },
    ];
  }, [graphNode, onAction, t]);

  return (
    <>
      <ManageKYC
        onUpdate={onAddKYC}
        data={{
          nodeId: graphNode.id,
          onboardingProcessId: null,
          isOnboardingProcessRemovable:
            graphNode.metadata.isOnboardingProcessRemovable,
          isOnboardingProcessCreatable:
            graphNode.metadata.isOnboardingProcessCreatable,
        }}
      />
      <Tabs
        useChangeView
        tabs={tabs}
        activeKey={currentStep}
        defaultActiveKey={currentStep}
        onChange={setCurrentStep}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default OrganizationWithoutKeyRelationship;
