import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TextProps, TextVariants } from './types';

/**
 * The `Text` component renders text with customizable styling, semantics, and spacing.
 * It supports multiple variants (e.g., headings, body, label, link) and different font weights.
 * Margins can be added above or below the text for spacing.
 *
 * @param {TextProps} props - The properties defined in `TextProps`.
 */
const Text = ({ children, ...rest }: TextProps) => {
  const Component = useMemo(() => {
    switch (rest.variant) {
      case 'h1':
        return <h1 {...rest} />;
      case 'h2':
        return <h2 {...rest} />;
      case 'h3':
        return <h3 {...rest} />;
      case 'h4':
        return <h4 {...rest} />;
      case 'h5':
        return <h5 {...rest} />;
      case 'h6':
        return <h6 {...rest} />;
      case 'subtitle1':
        return <h6 {...rest} />;
      case 'body1':
        return <p {...rest} />;
      case 'label1':
        return <p {...rest} />;
      case 'link1':
        return <a {...rest} />;
      case 'span':
        return <span {...rest} />;
      default:
        return <p {...rest} />;
    }
  }, [rest.variant]);

  return (
    <StyledTextComponent
      component={Component}
      onClick={rest.onClick}
      className={rest.className}
      $color={rest.color}
      $weight={rest.weight}
      $variant={rest.variant}
      $gutterTop={rest.gutterTop}
      $gutterBottom={rest.gutterBottom}
    >
      {children}
    </StyledTextComponent>
  );
};

const StyledTextComponent = styled(({ component, ...props }: any) =>
  React.cloneElement(component, props),
)<{
  $color?: string;
  $weight?: string;
  $variant?: TextVariants;
  $gutterTop?: boolean;
  $gutterBottom?: boolean;
}>`
  margin-top: ${({ $gutterTop }) => ($gutterTop ? '0.35em' : '0px')};
  margin-bottom: ${({ $gutterBottom }) => ($gutterBottom ? '0.35em' : '0px')};

  ${({ $color }) => $color && `color: ${$color}`};

  font-weight: ${({ $weight }) => {
    switch ($weight) {
      case 'regular':
        return '400';
      case 'semi-bold':
        return '600';
      case 'bold':
        return '700';
      default:
        return '400';
    }
  }};

  font-size: ${({ $variant }) => {
    switch ($variant) {
      case 'h1':
        return '3rem';
      case 'h2':
        return '2.5rem';
      case 'h3':
        return '2rem';
      case 'h4':
        return '1.5rem';
      case 'h5':
        return '1.25rem';
      case 'h6':
        return '1rem';
      case 'subtitle1':
        return '1rem';
      case 'body1':
        return '1rem';
      case 'label1':
        return '1rem';
      case 'link1':
        return '1rem';
      case 'span':
        return '1rem';
      default:
        return '1rem';
    }
  }};

  line-height: ${({ $variant }) => {
    switch ($variant) {
      case 'h1':
        return '3.5rem';
      case 'h2':
        return '3rem';
      case 'h3':
        return '2.5rem';
      case 'h4':
        return '2rem';
      case 'h5':
        return '1.75rem';
      case 'h6':
        return '1.5rem';
      case 'subtitle1':
        return '1.25rem';
      case 'body1':
        return '1.5rem';
      case 'label1':
        return '1.5rem';
      case 'link1':
        return '1.5rem';
      case 'span':
        return '1.5rem';
      default:
        return '1.5rem';
    }
  }};
`;

export default React.memo(Text);
