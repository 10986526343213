import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { AccountTypes } from 'enums/onboarding/crm';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import AccountInformationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/AccountInformationForm';
import { Message } from '@ui';

interface IProps {
  isViewOnly: boolean;
  onAction: (key: ActionKeys | null) => void;
  onboardingData: {
    applicationId: string;
    contactId: string;
  };
}

const AccountInformation = ({
  onAction,
  onboardingData,
  isViewOnly,
}: IProps) => {
  const { t } = useTranslation('common');

  const { response, loading } = useFetch(
    () =>
      onboardingAPI.fetchAccountInformationStepData(
        onboardingData.applicationId,
      ),
    [onboardingData],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response) {
      return null;
    }

    const accounts = response.length
      ? response
      : [
          {
            type: AccountTypes.current,
            purpose: '',
            currencyId: null,
            initialDeposit: null,
            initialDepositOrigin: null,
            jurisdictions: [],
            incomingWiresNumber: null,
            estimatedIncomingFunds: null,
            outgoingWiresNumber: null,
            estimatedOutgoingFunds: null,
          },
        ];

    return {
      isSave: false,
      submitActionType: null,
      accounts,
    };
  }, [response]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitAccountInformationStep(values);
    await onboardingAPI.submitAccountInformationStep(
      formattedBody,
      onboardingData.applicationId,
    );
    Message.success(t('success_save'));
    onAction(values.submitActionType);
  };

  return (
    <LoadingWrapper loading={loading}>
      {initialFormValues && (
        <AccountInformationForm
          initialFormValues={initialFormValues}
          onSubmit={onSubmit}
          disabled={isViewOnly}
        />
      )}
    </LoadingWrapper>
  );
};

export default AccountInformation;
