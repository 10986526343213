import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import { PageButton } from '@ui';
import AddUserModal from './AddUserModal';

interface IProps {
  onAdd: () => void;
}

const AddUser = ({ onAdd }: IProps) => {
  const { t } = useTranslation('account_management');
  const [isVisible, setVisible] = useState(false);

  const handleClose = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd();
    }

    setVisible(false);
  };

  return (
    <>
      <PageButton onClick={() => setVisible(true)}>
        {t('user_management.add_user.add_user_button')}
      </PageButton>
      <AddUserModal isVisible={isVisible} closeCallback={handleClose} />
    </>
  );
};

export default AddUser;
