import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { DateHelpers } from 'helpers/date';
import { IAccountModel } from 'typings/onboarding/account';
import { AccountsHelpers } from 'helpers/accounts';
import { AccountProcessingTypes } from 'enums/accounting/transfers';
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/global';

// components
import ReceiveMoney from '../ReceiveMoney';
import BankAccountStatus from 'components/Additional/Statuses/BankAccountStatus';
import AvailableBalanceText from 'components/Forms/TemplateForms/Transfers/TransferFromSideForm/AvailableBalanceText';
import {
  Text,
  DescriptionSection,
  DescriptionItem,
  Col,
  Row,
  Select,
  SelectOption,
} from '@ui';

interface IProps {
  accounts: IAccountModel[];
  activeAccount?: IAccountModel;
  processingType: AccountProcessingTypes | undefined;
  onChangeAccount: (account: IAccountModel) => void;
}

const AccountDetailSection = ({
  accounts,
  activeAccount,
  processingType,
  onChangeAccount,
}: IProps) => {
  const { t } = useTranslation('accounts');

  const handleChange = (accountNumber: any) => {
    const selectedAccount = accounts.find(
      (account) => account.accountNumber === accountNumber,
    );
    if (selectedAccount) {
      onChangeAccount(selectedAccount);
    }
  };

  const bankAccountOptions: SelectOption[] = useMemo(() => {
    return accounts.map((e) => ({
      id: e.accountNumber,
      label: AccountsHelpers.generateAccountFullName(
        e.accountName,
        e.accountNumber,
        e.currencyCode,
        e.balance,
      ),
    }));
  }, [accounts]);

  const renderBalance = () => {
    let AvailableBalanceNode = null;
    if (activeAccount) {
      AvailableBalanceNode = activeAccount.availableBalance !==
        activeAccount.balance && (
        <AvailableBalanceText account={activeAccount} />
      );
    }
    return (
      <>
        {AccountsHelpers.convertAmountBigIntToLocaleString(
          activeAccount?.balance || 0,
        )}
        {AvailableBalanceNode}
      </>
    );
  };

  const descriptionData = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t('account_activity.account_details.account_name'),
        description: activeAccount?.accountName,
      },
      {
        label: t('account_activity.account_details.account_number'),
        description: activeAccount?.accountNumber,
      },
      {
        label: t('account_activity.account_details.balance'),
        description: renderBalance(),
      },
      {
        label: t('account_activity.account_details.currency'),
        description: activeAccount?.currencyCode,
      },
      {
        label: t('account_activity.account_details.opening_date'),
        description: activeAccount?.creationDateTimestamp
          ? DateHelpers.formatTimestampDateToString(
              activeAccount.creationDateTimestamp,
              DEFAULT_FULL_DATE_FORMAT,
            )
          : '-',
      },
      {
        label: t('account_activity.account_details.status'),
        description: activeAccount ? (
          <BankAccountStatus status={activeAccount.status} />
        ) : null,
      },
    ];
  }, [activeAccount]);

  return (
    <>
      <Row gutter={[16, 16]} align="middle">
        <StyledAccountSelectCol xl={16} lg={24} md={24} sm={24} xs={24}>
          <AccountSelectLabel variant="h5">{`${t('account_activity.account_details.account')}:`}</AccountSelectLabel>
          <StyledSelect
            allowClear={false}
            onChange={handleChange}
            size="large"
            value={activeAccount?.accountNumber}
            options={bankAccountOptions}
          />
        </StyledAccountSelectCol>

        <Col xl={8} lg={24} md={24} sm={24} xs={24}>
          <ReceiveMoney
            account={activeAccount}
            processingType={processingType as AccountProcessingTypes}
          />
        </Col>
      </Row>

      <StyledDescriptionSection
        data={descriptionData}
        background="transparent"
      />
    </>
  );
};

const StyledAccountSelectCol = styled(Col)`
  display: flex;
  flex-direction: row;
`;

const AccountSelectLabel = styled(Text)`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.paddingMd};
`;

const StyledSelect = styled(Select)`
  width: 100%;
  max-width: 100%;
  min-width: 0px;
`;

const StyledDescriptionSection = styled(DescriptionSection)`
  padding-top: ${({ theme }) => theme.paddingXl};
`;

export default AccountDetailSection;
