import React, { useEffect, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { setChangeRequest } from 'redux/actions/personalDetails';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';
import {
  ChangeRequestStatuses,
  personalDetailsAPI,
} from 'api/profile/personalDetailsAPI';

// components
import ViewContact from './ViewContact';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import KYCContactForm from '../KYCContactForm';
import { Text, Col, Row, Modal, Button } from '@ui';

type ContentKeys = 'view' | 'edit-personal-details' | 'get-kyc';

const ContentForNonKYCUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['profile', 'common']);
  const { changeRequest, canSubmitPersonalDetails } = useSelector<
    StateModel,
    PersonalDetailsStateModel
  >((state) => state.personalDetails);

  const [selectedContentKey, setSelectedContentKey] = useState<ContentKeys>(
    changeRequest && changeRequest.status !== ChangeRequestStatuses.InReview
      ? 'get-kyc'
      : 'view',
  );

  useEffect(() => {
    if (
      selectedContentKey === 'view' &&
      changeRequest &&
      changeRequest.status !== ChangeRequestStatuses.InReview
    ) {
      setSelectedContentKey('get-kyc');
    }
  }, [selectedContentKey, changeRequest]);

  const renderContent = (key: ContentKeys) => {
    switch (key) {
      case 'view':
      case 'edit-personal-details':
        return (
          <ViewContact
            onDiscard={() => setSelectedContentKey('view')}
            isEditable={selectedContentKey === 'edit-personal-details'}
          />
        );

      case 'get-kyc':
        return <KYCContactForm />;
    }
  };

  const handleClickOnGetKYC = () => {
    Modal.confirm({
      title: t('personal_details.kyc_form.begin_kyc_modal.title'),
      width: 600,
      icon: null,
      closable: true,
      maskClosable: true,
      content: (
        <Text variant="body1" gutterBottom>
          {t('personal_details.kyc_form.begin_kyc_modal.description')}
        </Text>
      ),
      okText: t('continue', { ns: 'common' }),
      cancelText: t('cancel', { ns: 'common' }),
      async onOk() {
        const response =
          await personalDetailsAPI.initiateVerificationProcessForContact();
        dispatch(setChangeRequest(response));
      },
    });
  };

  return (
    <>
      {selectedContentKey === 'view' &&
        changeRequest?.status !== ChangeRequestStatuses.InReview && (
          <Row gutter={[16, 16]} justify="end">
            <Col>
              {canSubmitPersonalDetails ? (
                <Button
                  size="large"
                  onClick={() => setSelectedContentKey('edit-personal-details')}
                >
                  {t('personal_details.edit.edit_details_pre_get_verified')}
                </Button>
              ) : (
                <InfoTooltip
                  placement="left"
                  tooltipContent={t(
                    'personal_details.kyc_form.update_personal_details_feature_is_disabled',
                  )}
                >
                  <div>
                    <Button disabled size="large">
                      {t('personal_details.edit.edit_details_pre_get_verified')}
                    </Button>
                  </div>
                </InfoTooltip>
              )}
            </Col>
            <Col>
              {canSubmitPersonalDetails ? (
                <Button
                  size="large"
                  type="primary"
                  onClick={handleClickOnGetKYC}
                >
                  {t('personal_details.kyc_form.button')}
                </Button>
              ) : (
                <InfoTooltip
                  placement="left"
                  tooltipContent={t(
                    'personal_details.kyc_form.update_personal_details_feature_is_disabled',
                  )}
                >
                  <div>
                    <Button disabled size="large" type="primary">
                      {t('personal_details.kyc_form.button')}
                    </Button>
                  </div>
                </InfoTooltip>
              )}
            </Col>
          </Row>
        )}

      {renderContent(selectedContentKey)}
    </>
  );
};

export default ContentForNonKYCUser;
