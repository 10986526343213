import React from 'react';

// helpers
import { TWO_COL_XL_GRID_SIZES } from 'constants/grids';
import { AccountProcessingTypes } from 'enums/accounting/transfers';

// components
import TransferToSideForm from './TransferToSideForm';
import TransferFromSideForm from '../../TransferFromSideForm';
import DividerBetweenSections from '../../DividerBetweenSections';
import { Col, Row } from '@ui';

const InnerForm = () => {
  return (
    <Row gutter={[64, 0]}>
      <Col {...TWO_COL_XL_GRID_SIZES}>
        <TransferFromSideForm
          transferType="blockchain"
          filterAccountsByProcessingType={AccountProcessingTypes.Circle}
        />
      </Col>

      <DividerBetweenSections />

      <Col {...TWO_COL_XL_GRID_SIZES}>
        <TransferToSideForm />
      </Col>
    </Row>
  );
};

export default InnerForm;
