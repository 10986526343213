import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ContactModel } from 'typings/application/contact';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { userManagementAPINew } from 'api/accountManagement/userManagementAPINew';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';

// components
import { IconSVG, Tabs } from '@ui';
import Identification from './Tabs/Identification';
import SourceOfWealth from './Tabs/SourceOfWealth';
import ApplicantInformation from './Tabs/ApplicantInformation';
import ApplicationDocumentation from './Tabs/ApplicationDocumentation';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

interface IProps {
  isViewOnly: boolean;
  onboardingStatus: OnboardingStatusModel;
  onSubmit: () => void;
}

type TabKeys =
  | 'applicantInformation'
  | 'identification'
  | 'sourceOfWealth'
  | 'applicationDocumentation';

const Individual = ({ onboardingStatus, isViewOnly, onSubmit }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>(
    'applicantInformation',
  );
  const [stepStatuses, setStepStatuses] = useState<Partial<
    Record<TabKeys, boolean | undefined>
  > | null>(null);

  const shouldFillSourceOfWealth = useMemo(() => {
    const item = onboardingStatus.item as ContactModel;
    return (
      onboardingStatus.isPrimary || onboardingStatus.isOwnership || item.isPEP
    );
  }, [onboardingStatus]);

  useEffect(() => {
    if (!stepStatuses) {
      const { informationFilled } = onboardingStatus;
      const statuses = shouldFillSourceOfWealth
        ? {
            applicantInformation: informationFilled.generalInformation,
            identification: informationFilled.identification,
            sourceOfWealth: informationFilled.employmentHistory,
            applicationDocumentation:
              informationFilled.applicationDocumentation,
          }
        : {
            applicantInformation: informationFilled.generalInformation,
            identification: informationFilled.identification,
            applicationDocumentation:
              informationFilled.applicationDocumentation,
          };

      const notCompletedStep = Object.keys(statuses).find(
        (key) => !statuses[key as TabKeys],
      );
      setStepStatuses(statuses);
      setCurrentStep(
        (notCompletedStep as TabKeys) || 'applicationDocumentation',
      );
    }
  }, [onboardingStatus, shouldFillSourceOfWealth]);

  const handleStepSave = async (savedStep: TabKeys) => {
    if (stepStatuses) {
      let stepStatusesCopy = {
        ...stepStatuses,
        [savedStep]: true,
      };
      if (savedStep === 'applicationDocumentation') {
        const response = await userManagementAPINew.fetchClientDetails(
          onboardingStatus.crmItemId as string,
          onboardingStatus.type as OnboardingEntryTypes,
        );

        const informationFilled = response.onboardingProcess.informationFilled;
        stepStatusesCopy = {
          ...stepStatuses,
          [savedStep]: informationFilled.applicationDocumentation,
        };
      }
      const nextNotCompletedStep = Object.keys(stepStatusesCopy).find(
        (key) => !stepStatusesCopy[key as TabKeys],
      );
      if (nextNotCompletedStep) {
        setStepStatuses(stepStatusesCopy);
        setCurrentStep(nextNotCompletedStep as TabKeys);
      } else {
        setStepStatuses(stepStatusesCopy);
      }
    }
  };

  const tabs = useMemo<
    { key: TabKeys; title: React.ReactNode; content: React.ReactNode }[]
  >(() => {
    const areAllStepsCompleted =
      !!stepStatuses &&
      !Object.keys(stepStatuses).find((key) => !stepStatuses[key as TabKeys]);
    const renderIcon = (key: TabKeys) => {
      if (!stepStatuses) {
        return;
      }

      return stepStatuses[key] ? (
        <StyledIconSVG component={CheckIcon} color={colorsTheme.colorPrimary} />
      ) : (
        <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
      );
    };

    return [
      {
        key: 'applicantInformation',
        title: (
          <>
            {t('applicant_information.general.title')}{' '}
            {renderIcon('applicantInformation')}
          </>
        ),
        content: (
          <ApplicantInformation
            onboardingStatus={onboardingStatus}
            isViewOnly={
              isViewOnly ||
              (onboardingStatus.item as ContactModel).approval.isKYCProvided
            }
            onSave={() => handleStepSave('applicantInformation')}
          />
        ),
      },

      {
        key: 'identification',
        title: (
          <>
            {t('identification.title')} {renderIcon('identification')}
          </>
        ),
        content: (
          <Identification
            onboardingStatus={onboardingStatus}
            isViewOnly={
              isViewOnly ||
              (onboardingStatus.item as ContactModel).approval.isKYCProvided
            }
            onSave={() => handleStepSave('identification')}
          />
        ),
      },

      {
        key: 'sourceOfWealth',
        hidden: !shouldFillSourceOfWealth,
        title: (
          <>
            {t('source_of_wealth.title')} {renderIcon('sourceOfWealth')}
          </>
        ),
        content: (
          <SourceOfWealth
            onboardingStatus={onboardingStatus}
            isViewOnly={isViewOnly}
            onSave={() => handleStepSave('sourceOfWealth')}
          />
        ),
      },
      {
        key: 'applicationDocumentation',
        title: (
          <>
            {t('application_documentation.title')}{' '}
            {renderIcon('applicationDocumentation')}
          </>
        ),
        content: (
          <ApplicationDocumentation
            onboardingStatus={onboardingStatus}
            isViewOnly={isViewOnly}
            canComplete={areAllStepsCompleted}
            onSave={() => handleStepSave('applicationDocumentation')}
            onComplete={onSubmit}
          />
        ),
      },
    ];
  }, [onboardingStatus, stepStatuses, shouldFillSourceOfWealth, isViewOnly]);

  return (
    <Tabs
      useChangeView
      tabs={tabs}
      activeKey={currentStep}
      defaultActiveKey={currentStep}
      onChange={setCurrentStep}
    />
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default Individual;
