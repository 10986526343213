import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { FormValuesModel } from 'components/ModalDialogs/TemplateModalDialogs/Profile/EnableAuthenticatorModal';

// components
import { Button } from '@ui';
import SectionDescriptionText from '../../SectionDescriptionText';
import EnableAuthenticatorModal from '../../../../../Profile/Security/TwoFactorAuthentication/Authenticator/EnableAuthenticatorModal';

const TwoFactorAuthentication = () => {
  const { t } = useTranslation('dashboard');
  const [isEnableAuthenticatorModal, setIsEnableAuthenticatorModal] =
    useState(false);
  const initialFormValues: FormValuesModel = useMemo(
    () => ({ code: null }),
    [],
  );

  const isAuthenticatorEnabled = useSelector<StateModel, boolean>(
    (state) =>
      !!state.auth.profileData?.isTwoFactorAuthenticationEnabled || false,
  );

  const handleEnableAuthenticatorModalClose = () => {
    setIsEnableAuthenticatorModal(false);
  };

  return (
    <>
      <SectionDescriptionText>
        {t('welcome_section.2fa.description')}
      </SectionDescriptionText>

      {!isAuthenticatorEnabled && (
        <Button onClick={() => setIsEnableAuthenticatorModal(true)}>
          {t('welcome_section.setup_accounts.enable_2fa')}
        </Button>
      )}

      <EnableAuthenticatorModal
        isVisible={isEnableAuthenticatorModal}
        initialFormValues={initialFormValues}
        closeCallback={handleEnableAuthenticatorModalClose}
      />
    </>
  );
};

export default TwoFactorAuthentication;
