import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { UpdatePrimaryEmailValidationSchema } from 'validations/profile/security';

// components
import {
  Text,
  Form,
  RequiredPropsForFormModel,
  ModalDialog,
  RequiredPropsForModalDialogModel,
} from '@ui';
import InnerForm from './InnerForm';

export interface FormValuesModel {
  otp: string;
  email: string;
  otpSent: boolean;
}

type IProps = RequiredPropsForModalDialogModel &
  RequiredPropsForFormModel<FormValuesModel>;

const UpdatePrimaryEmailModal = ({
  onSubmit,
  isVisible,
  initialValues,
  closeCallback,
  onSubmitError,
}: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
      initialValues={isVisible ? initialValues : null}
      validationSchema={UpdatePrimaryEmailValidationSchema}
      renderForm={({ values }) => (
        <ModalDialog
          width={600}
          closeModalAfterSubmit={false}
          title={t('security.update_primary_email.modal_dialog.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{
            text: !values.otpSent
              ? t('security.update_primary_email.modal_dialog.submit_button')
              : t(
                  'security.update_primary_email.modal_dialog.otp_submit_button',
                ),
          }}
        >
          {!values.otpSent ? (
            <StyledText>
              {t('security.update_primary_email.modal_dialog.description')}
            </StyledText>
          ) : (
            <StyledText>
              {t('security.update_primary_email.modal_dialog.otp_description', {
                email: values.email,
              })}
            </StyledText>
          )}

          <InnerForm />
        </ModalDialog>
      )}
    />
  );
};

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default UpdatePrimaryEmailModal;
