import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import { userAPI } from 'api/profile/userAPI';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useNavigate } from 'react-router-dom';
import { SecurityKeyModel } from 'typings/profile/fido';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from 'enums/profile/auth';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import {
  setDefaultAuthenticator,
  setFidoAuthenticatorEnabledStatus,
} from 'redux/actions/auth';

// components
import EditSecurityKeyModal from '../EditSecurityKeyModal';
import SecurityKeysTable, {
  ActionKeys,
} from 'components/Tables/TableTemplates/User/SecurityKeysTable';
import DeleteSecurityKeyModal from './DeleteSecurityKeyModal';
import { Message } from '@ui';
import useTranslation from 'hooks/useTranslation';

interface IProps {
  updateTableTrigger: any;
}

const ManageSecurityKeysTable = ({ updateTableTrigger }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('profile');
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedKey, setSelectedKey] = useState<SecurityKeyModel | null>(null);
  const [localUpdateTableTrigger, updateTable] = useState({});
  const [selectedKeyIdForDeletion, setSelectedKeyIdForDeletion] = useState<
    string | null
  >(null);

  const { response, loading } = useFetch(
    () =>
      userAPI.fetchSecurityKeys({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage, localUpdateTableTrigger, updateTableTrigger],
  );

  const handleActionsClick = (key: ActionKeys, record: SecurityKeyModel) => {
    switch (key) {
      case 'details':
        setSelectedKey(record);
        break;

      case 'delete':
        handleKeyDelete(record);
        break;
    }
  };

  const handleKeyDelete = (securityKey: SecurityKeyModel) => {
    setSelectedKeyIdForDeletion(securityKey._id);
  };

  const handleModalClose = (wasUpdated?: boolean) => {
    if (wasUpdated) {
      updateTable({});
    }

    setSelectedKey(null);
  };

  const handleDeleteModalClose = (wasDeleted?: boolean) => {
    if (wasDeleted) {
      Message.success(
        t(
          'security.two_step_verification.security_key.remove_key.submit_success_message',
        ),
      );
      if (response?.total === 1) {
        dispatch(setFidoAuthenticatorEnabledStatus(false));
        if (
          profileData?.default2FAType === TwoFactorVerificationTypes.Fido &&
          profileData.isTwoFactorAuthenticationEnabled
        ) {
          dispatch(
            setDefaultAuthenticator(TwoFactorVerificationTypes.Authenticator),
          );
        }
        navigate(RoutePaths.UserProfile_SignInAndSecurity);
      } else {
        updateTable({});
      }
    }

    setSelectedKeyIdForDeletion(null);
  };

  return (
    <>
      <SecurityKeysTable
        current={currentPage}
        loading={loading}
        data={response?.data || []}
        total={response?.total || 0}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />

      <EditSecurityKeyModal
        isVisible={!!selectedKey}
        closeCallback={handleModalClose}
        securityKey={selectedKey}
      />
      <DeleteSecurityKeyModal
        isVisible={!!selectedKeyIdForDeletion}
        keyId={selectedKeyIdForDeletion}
        closeCallback={handleDeleteModalClose}
      />
    </>
  );
};

export default ManageSecurityKeysTable;
