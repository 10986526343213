// helpers
import moment, { MomentInput } from 'moment';
import { Relationship } from '../typings/relationshipsGraph';
import { IDocumentForm } from 'typings/onboarding/onboarding';
import { tryToParseJSON } from './utils';
import { ShortDocumentModel } from 'typings/documents/documents';
import { RelationshipTemplate } from '../typings/application/relationship-template';
import { AdditionalFieldFormItemModel } from '../components/Forms/FormComponents/AdditionalField';

// constants
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from '../enums/onboarding/crm';

const AdditionalFieldHelpers = {
  formatFromJSON: function (
    fieldType: AdditionalFieldTypes,
    value: string,
  ): any {
    const parsedValue = tryToParseJSON(value);

    if (!parsedValue.success) {
      return parsedValue.value;
    }

    switch (fieldType) {
      case AdditionalFieldTypes.Date: {
        return parsedValue.value
          ? moment(parsedValue.value as MomentInput)
          : undefined;
      }

      case AdditionalFieldTypes.Form:
      case AdditionalFieldTypes.Document: {
        let result: ShortDocumentModel[] = [];

        if (
          parsedValue.value &&
          Array.isArray(parsedValue.value) &&
          parsedValue.value.length
        ) {
          result = parsedValue.value.map((e: any) => ({
            id: e.id,
            name: e.files[0].name,
            fileId: e.files[0].id,
            file: e.files[0],
          }));
        }

        return result;
      }

      default:
        return parsedValue.value;
    }
  },

  formatToJSON: function (fieldType: AdditionalFieldTypes, value: any) {
    if (
      value === undefined ||
      value === '' ||
      (typeof value == 'string' && value.trim() === '')
    ) {
      return '';
    }

    let result = '';

    switch (fieldType) {
      case AdditionalFieldTypes.Document:
      case AdditionalFieldTypes.Form:
        {
          if (value?.length) {
            const documentIds = value.map(({ id }: ShortDocumentModel) => id);
            result = JSON.stringify(documentIds);
          }
        }
        break;

      default: {
        result = JSON.stringify(value);
      }
    }

    return result;
  },

  mapAdditionalFieldFromTemplateToFormField: function (
    templateAdditionalField: {
      name: string;
      type: AdditionalFieldTypes;
      valueJSON: string;
      relatedTo: 'any' | 'contact' | 'organization';
      options: {
        onboardingRequired: boolean;
        isForRegulatedOnly: boolean;
        formDocument: IDocumentForm | undefined;
        documentRequiresCertification: boolean;
        selectOptions: {
          valueJSON: string;
          type: AdditionalFieldTypes;
        }[];
      };
      id: string;
    },
    isRemoved?: boolean,
  ): AdditionalFieldFormItemModel {
    return {
      name: templateAdditionalField.name,
      type: templateAdditionalField.type,
      value: AdditionalFieldHelpers.formatFromJSON(
        templateAdditionalField.type,
        templateAdditionalField.valueJSON,
      ),
      relatedTo: templateAdditionalField.relatedTo,
      options: {
        ...templateAdditionalField.options,
        formDocument: templateAdditionalField.options.formDocument
          ? templateAdditionalField.options.formDocument
          : null,
        selectOptions: templateAdditionalField.options.selectOptions.map(
          (option) => JSON.parse(option.valueJSON),
        ),
      },
      isRemoved: !!isRemoved,
      id: templateAdditionalField.id,
    };
  },

  filterAndMapAdditionalFieldsFromTemplateToFormModel: function (
    relationshipTemplate: RelationshipTemplate,
    nodeType: OnboardingEntryTypes,
    relationship: Relationship,
  ): AdditionalFieldFormItemModel[] {
    const relationshipAdditionalFields = [...relationship.additionalFields];
    const result = relationshipTemplate.additionalFields.reduce<
      AdditionalFieldFormItemModel[]
    >((acc, next) => {
      if (next.relatedTo === 'any' || next.relatedTo === nodeType) {
        const predefinedAdditionalFieldIndex =
          relationshipAdditionalFields.findIndex((e) => e._id === next._id);
        const predefinedAdditionalField =
          relationshipAdditionalFields[predefinedAdditionalFieldIndex];
        // Map existed additional fields
        acc.push(
          this.mapAdditionalFieldFromTemplateToFormField({
            name: next.name,
            type: next.type,
            valueJSON: predefinedAdditionalField
              ? predefinedAdditionalField.valueJSON
              : '',
            relatedTo: next.relatedTo,
            options: {
              onboardingRequired: next.options.onboardingRequired,
              isForRegulatedOnly: next.options.isForRegulatedOnly,
              formDocument: next.options.formDocument,
              documentRequiresCertification:
                next.options.documentRequiresCertification,
              selectOptions: next.options.selectOptions,
            },
            id: next._id,
          }),
        );
        if (predefinedAdditionalFieldIndex !== -1) {
          relationshipAdditionalFields.splice(
            predefinedAdditionalFieldIndex,
            1,
          );
        }
      }

      return acc;
    }, []);
    // Map removed additional fields
    const removedRelationshipFields = relationshipAdditionalFields.map((e) =>
      this.mapAdditionalFieldFromTemplateToFormField(
        {
          name: e.name,
          type: e.type,
          valueJSON: e.valueJSON,
          relatedTo: 'any',
          options: {
            onboardingRequired: false,
            isForRegulatedOnly: false,
            formDocument: e.metadata?.formDocument,
            documentRequiresCertification:
              !!e.metadata?.documentRequiresCertification,
            selectOptions: e.metadata?.selectOptions || [],
          },
          id: e._id,
        },
        true,
      ),
    );
    result.push(...removedRelationshipFields);
    return result;
  },
};

export { AdditionalFieldHelpers };
