import React from 'react';
import { ButtonProps } from './types';
import { Button as AntDButton } from 'antd';
import { ButtonType as AntDButtonTypes } from 'antd/lib/button';

const Button: React.FC<ButtonProps> = ({
  children,
  size = 'middle',
  type = 'primary',
  disabled,
  loading,
  fullWidth,
  danger,
  onClick,
  htmlType,
  icon,
  className,
  ...rest
}) => {
  const typeMap: Record<NonNullable<ButtonProps['type']>, AntDButtonTypes> = {
    primary: 'primary',
    bordered: 'default',
    dashed: 'dashed',
    text: 'text',
  };
  const mappedType = type ? typeMap[type] : undefined;

  return (
    <AntDButton
      size={size}
      type={mappedType}
      disabled={disabled}
      loading={loading}
      block={fullWidth}
      onClick={onClick}
      danger={danger}
      htmlType={htmlType}
      icon={icon}
      className={className}
      {...rest}
    >
      {children}
    </AntDButton>
  );
};

export default React.memo(Button);
