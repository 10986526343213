import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { Moment } from 'moment';
import { transfersAPI } from 'api/accounting/transfersAPI';
import { transfersAdapter } from 'apiAdapters/accounting/transfersAdapter';
import { DocumentFieldValueModel } from 'components/Forms/FormComponents/DocumentsField';
import { FormikHelpers, FormikProps } from 'formik';
import { internalTransferValidationSchema } from 'validations/accounting/transfers';
import {
  IEstimatedTransferRate,
  InternalTransferRecipientModel,
  TransferSenderModel,
} from 'typings/accounting/transfer';

// components
import InnerForm from './InnerForm';
import SubmitButton from '../../../../Buttons/SubmitButton';
import EstimateRateModal from 'modules/Transfers/EstimateRateModal';
import { Form, RequiredPropsForFormModel, Col, Row } from '@ui';

export interface FormValuesModel {
  valueDate: Moment;
  amount: number | null;
  transactionPurpose: string;
  sender: TransferSenderModel;
  documents: DocumentFieldValueModel[];
  transactionCurrencyId: number | null;
  recipient: InternalTransferRecipientModel;
}

interface IProps extends RequiredPropsForFormModel<FormValuesModel> {
  emptyTransactionFormValues: FormValuesModel;
}

const SubmitButtonGridSizes = { xl: 4, lg: 6, md: 8, sm: 24, xs: 24 };

const InternalTransferForm = ({
  initialValues,
  emptyTransactionFormValues,
  onSubmit,
  disabled,
}: IProps) => {
  const { t } = useTranslation('common');

  const [estimatedExchangeRateData, setEstimatedExchangeRateData] =
    useState<IEstimatedTransferRate | null>(null);
  const [isLoader, setIsLoader] = useState(false);

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    const senderCurrency = values.sender.account?.currencyId;
    const recipientCurrency =
      values.recipient.account?.currencyId ||
      values.recipient.template?.currencyId;
    const shouldShowEstimateExchangeRateModal =
      senderCurrency !== recipientCurrency;
    const formattedTransferData =
      transfersAdapter.generateBodyForInternalTransfer(values);

    if (shouldShowEstimateExchangeRateModal) {
      const response = await transfersAPI.fetchEstimatedTransferInfo(
        formattedTransferData,
      );
      setEstimatedExchangeRateData(response);
    } else {
      await onSubmit(values, helpers);
      if (emptyTransactionFormValues) {
        helpers.resetForm({
          values: { ...emptyTransactionFormValues },
          errors: {},
        });
      }
    }
  };

  const closeCallback = async (
    shouldSubmit: boolean,
    form: FormikProps<FormValuesModel>,
  ) => {
    if (shouldSubmit) {
      setIsLoader(true);
      await onSubmit(form.values, form);
      if (emptyTransactionFormValues) {
        form.resetForm({
          values: { ...emptyTransactionFormValues },
          errors: {},
        });
      }
      setIsLoader(false);
    }

    setEstimatedExchangeRateData(null);
  };

  return (
    <Form<FormValuesModel>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={internalTransferValidationSchema}
      confirmExitWithoutSaving
      disabled={disabled}
      renderForm={(form) => (
        <>
          <InnerForm />

          <Row justify="end">
            <Col {...SubmitButtonGridSizes}>
              <StyledSubmitButton type="primary">
                {t('submit', { ns: 'common' })}
              </StyledSubmitButton>
            </Col>
          </Row>

          <EstimateRateModal
            loading={isLoader}
            isVisible={!!estimatedExchangeRateData}
            isTransactionFANNcy={
              form.values.sender.account?.currencyId ===
              form.values.transactionCurrencyId
            }
            closeCallback={(shouldSubmit?: boolean) =>
              closeCallback(!!shouldSubmit, form)
            }
            exchangeRateData={
              estimatedExchangeRateData as IEstimatedTransferRate
            }
          />
        </>
      )}
    />
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
  padding: 0px;
  min-width: 0px;
`;

export default InternalTransferForm;
