import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

// --------- Create Transaction Rules Validation --------
// Validation for limit columns
const limitSchema = yup.object().shape({
  isUsed: yup
    .boolean()
    .oneOf([true], 'custom_errors:accounts.must_have_condition_warning'),
  toInfinite: yup.boolean(),
  from: yup
    .number()
    .typeError('form:validation_messages.only_numbers')
    .required('form:validation_messages.required'),
  to: yup.number().when('toInfinite', {
    is: false,
    then: (schema) =>
      schema
        .typeError('form:validation_messages.only_numbers')
        .required('form:validation_messages.required')
        .moreThan(yup.ref('from'), {
          key: 'form:validation_messages.valueA_should_be_bigger_than_valueB',
          values: {
            valueA:
              'account_management:approval_rules.bank_accounts.approval_rule_modal.form_fields.to_range_value',
            valueB:
              'account_management:approval_rules.bank_accounts.approval_rule_modal.form_fields.from_range_value',
          },
        }),
  }),
});

// Validation for conditions
const innerConditionSchema = yup.object().shape({
  approvalGroup: STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  requiredApproversCount: yup
    .number()
    .transform((value) => (!value ? undefined : value))
    .typeError('form:validation_messages.only_numbers')
    .required('form:validation_messages.required'),
});

const innerLimitSchema = yup.object().shape({
  isChecked: yup.boolean().required('form:validation_messages.required'),
});

const conditionSchema = yup.object().shape({
  conditions: yup.array().of(innerConditionSchema),
  limitStatuses: yup.array().of(innerLimitSchema),
});

export const newTransactionRuleSchema = yup.object().shape({
  bankAccounts: yup
    .array()
    .min(1, 'form:validation_messages.required')
    .required('form:validation_messages.required'),

  limits: yup
    .array()
    .of(limitSchema)
    .test(
      'is-last-infinite',
      'custom_errors:accounts.last_limit_should_be_infinite',
      (limitsArray: any) => {
        if (limitsArray && Array.isArray(limitsArray)) {
          const lastLimitIndex = limitsArray.length - 1;
          const isLastToInfinite = limitsArray[lastLimitIndex].toInfinite;
          return isLastToInfinite;
        }
      },
    ),
  conditions: yup.array().of(conditionSchema),
});
