import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Text, IconSVG, Steps, Step } from '@ui';
import { ReactComponent as KeyIcon } from 'resources/icons/remix-icons/key-line.svg';
import { ReactComponent as UserIcon } from 'resources/icons/remix-icons/user-line.svg';
import { ReactComponent as ArticleIcon } from 'resources/icons/remix-icons/article-line.svg';

interface IProps {
  activeStep: number;
}

const HeaderSteps = ({ activeStep }: IProps) => {
  const { t } = useTranslation('auth');

  return (
    <Wrapper>
      <StepsWrapper>
        <Steps labelPlacement="vertical" current={activeStep}>
          <StyledStep
            title={
              <Text variant="body1">
                {t('account_recovery.information_step.title')}
              </Text>
            }
            icon={
              <IconWrapper isActive={activeStep >= 0}>
                <IconSVG
                  color={colorsTheme.colorWhite}
                  component={UserIcon}
                  size="small"
                />
              </IconWrapper>
            }
          />

          <StyledStep
            title={
              <Text variant="body1">
                {t('account_recovery.id_verification_step.title')}
              </Text>
            }
            icon={
              <IconWrapper isActive={activeStep >= 1}>
                <IconSVG
                  color={colorsTheme.colorWhite}
                  component={ArticleIcon}
                  size="small"
                />
              </IconWrapper>
            }
          />

          <StyledStep
            title={
              <Text variant="body1">
                {t('account_recovery.final_step.title')}
              </Text>
            }
            icon={
              <IconWrapper isActive={activeStep >= 2}>
                <IconSVG
                  color={colorsTheme.colorWhite}
                  component={KeyIcon}
                  size="small"
                />
              </IconWrapper>
            }
          />
        </Steps>
      </StepsWrapper>
    </Wrapper>
  );
};

const StyledStep = styled(Step)`
  .ant-steps-item-container {
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepsWrapper = styled.div`
  width: 100%;
  max-width: 500px;

  .ant-steps-item-content {
    margin-top: 0px !important;
  }

  .ant-steps-item-icon {
    width: 37px;
  }
`;

const IconWrapper = styled.div<{ isActive: boolean }>`
  width: 35px;
  height: 35px;
  padding: 5px;

  align-items: center;
  justify-content: center;

  transition: ${({ theme }) => theme.defaultTransition};

  border: 1.5px;
  border-radius: 50%;
  border: ${({ theme, isActive }) =>
    `1.5px solid ${isActive ? theme.colorPrimary : theme.cardBorderColor}`};

  display: flex;
`;

export default HeaderSteps;
