import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IDocumentForm } from 'typings/onboarding/onboarding';
import { IDocumentAssociationInfo } from 'typings/documents/documents';

// constants
import { AdditionalFieldTypes } from 'enums/onboarding/crm';

// components
import DocumentLink from '../../../Typography/DocumentLink';
import DocumentsField from '../DocumentsField';
import {
  Text,
  Checkbox,
  SelectOption,
  FormInput,
  FormSelect,
  FormDatePicker,
  FormInputNumber,
} from '@ui';

export type AdditionalFieldFormItemModel = {
  name: string;
  value: any | undefined;
  type: AdditionalFieldTypes;
  relatedTo: 'any' | 'contact' | 'organization';
  options: {
    onboardingRequired: boolean;
    isForRegulatedOnly: boolean;
    documentRequiresCertification: boolean;
    selectOptions: string[];
    formDocument: IDocumentForm | null;
  };
  isRemoved?: boolean;
  id: string;
};

interface AdditionalFieldModel {
  _id: string;
  type: AdditionalFieldTypes;
  documentAssociation: IDocumentAssociationInfo;
  options?: string[];
  tags?: string[];
  formDocument?: IDocumentForm;
}

interface IProps {
  name: string;
  value: any;
  field: AdditionalFieldModel;
  onChange: (newValue?: any) => void;
  disabled?: boolean;
}

const AdditionalField = ({ field, ...rest }: IProps) => {
  const { t } = useTranslation('form');

  const renderField = (type: AdditionalFieldTypes) => {
    switch (type) {
      case AdditionalFieldTypes.String: {
        return <FormInput {...rest} />;
      }

      case AdditionalFieldTypes.Date: {
        return <StyledFormDatePicker {...rest} />;
      }

      case AdditionalFieldTypes.Number:
      case AdditionalFieldTypes.Percentage: {
        return <StyledFormInputNumber {...rest} />;
      }

      case AdditionalFieldTypes.Select: {
        const { value } = rest;
        const optionNodes: SelectOption<string>[] =
          field.options && field.options.length
            ? field.options.map((e) => ({
                id: e,
                label: e,
                model: e,
              }))
            : rest.value
              ? [{ id: value, label: value, model: value }]
              : [];

        return <FormSelect {...rest} options={optionNodes} />;
      }

      case AdditionalFieldTypes.Document: {
        return (
          <DocumentsField
            {...rest}
            association={field.documentAssociation}
            tags={field.tags}
          />
        );
      }

      case AdditionalFieldTypes.Form: {
        return (
          <>
            <CurrentDocumentWrapper>
              {field.formDocument && (
                <>
                  <Text variant="body1">{`${t('additional_field.current_document', { ns: 'documents' })}:`}</Text>
                  <StyledDocumentLink
                    documentId={field.formDocument.id}
                    fileId={field.formDocument.files[0].id}
                    text={field.formDocument.files[0].name}
                    fileName={field.formDocument.files[0].name}
                  />
                </>
              )}
            </CurrentDocumentWrapper>
            <DocumentsField
              {...rest}
              association={field.documentAssociation}
              tags={field.tags}
            />
          </>
        );
      }

      case AdditionalFieldTypes.Boolean: {
        return <Checkbox {...rest} />;
      }

      default:
        return null;
    }
  };

  return <>{renderField(field.type)}</>;
};

const StyledFormDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledFormInputNumber = styled(FormInputNumber)`
  width: 100%;
`;

const CurrentDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    margin-right: ${({ theme }) => theme.marginXs};
  }
`;

const StyledDocumentLink = styled(DocumentLink)`
  display: block;
  width: max-content;
  margin: ${({ theme }) => theme.marginXs} 0;
`;

export default AdditionalField;
