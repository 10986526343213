import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';

// components
import { AsyncButton, Col, Row } from '@ui';

interface IProps {
  data: {
    nodeId: string;
    onboardingProcessId: string | null;
    isOnboardingProcessRemovable: boolean;
    isOnboardingProcessCreatable: boolean;
  };
  onUpdate: () => void;
}

const ManageKYC = ({ data, onUpdate }: IProps) => {
  const { t } = useTranslation('onboarding');

  const manageOnboardingProcess = async (action: 'add-kyc' | 'remove-kyc') => {
    switch (action) {
      case 'add-kyc':
        await onboardingAPI.createOnboardingProcess(data.nodeId);
        break;
      case 'remove-kyc':
        data.onboardingProcessId &&
          (await onboardingAPI.deleteOnboardingProcess(
            data.onboardingProcessId,
          ));
        break;
    }

    onUpdate();
  };

  return (
    <Row justify="end" gutter={[16, 16]}>
      {data.isOnboardingProcessRemovable ? (
        <Col>
          <AsyncButton onClick={() => manageOnboardingProcess('remove-kyc')}>
            {t('application_documentation.manage_kyc.remove_kyc.button')}
          </AsyncButton>
        </Col>
      ) : (
        data.isOnboardingProcessCreatable && (
          <Col>
            <AsyncButton onClick={() => manageOnboardingProcess('add-kyc')}>
              {t('application_documentation.manage_kyc.add_kyc.button')}
            </AsyncButton>
          </Col>
        )
      )}
    </Row>
  );
};

export default ManageKYC;
