import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { circleAPI } from 'api/accounting/circleAPI';
import { useDispatch } from 'react-redux';
import { setCircleStatus } from 'redux/actions/auth';

// components
import { Button, Message } from '@ui';
import ConnectCircleModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/ExternalServices/Circle/ConnectCircleModal';

interface IProps {
  disabled?: boolean;
}

const ConnectCircle = ({ disabled }: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('account_management');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return { circleAPIKey: null };
  }, []);

  const handleSubmit = async (values: FormValuesModel) => {
    await circleAPI.connectService(values.circleAPIKey as string);
    Message.success(
      t('external_services.circle.connect_circle_modal.success_message'),
    );
  };

  const handleCloseCallback = (wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      dispatch(setCircleStatus('pending_synchronization'));
    }

    setIsVisible(false);
  };

  return (
    <>
      {!disabled && (
        <StyledButton onClick={() => setIsVisible(true)}>
          {t('external_services.connect_button')}
        </StyledButton>
      )}

      <ConnectCircleModal
        onSubmit={handleSubmit}
        isVisible={isVisible}
        closeCallback={handleCloseCallback}
        initialValues={initialFormValues}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ConnectCircle;
