import React, { memo } from 'react';

// components
import { Col, Row } from '@ui';

interface IProps {
  labelWidth?: string;
  label: React.ReactNode;
  value: React.ReactNode;
}

const TwoColRow = memo(({ label, value, labelWidth = '100px' }: IProps) => (
  <Row>
    <Col flex={labelWidth}>{label}</Col>
    <Col flex="auto">{value}</Col>
  </Row>
));

export default TwoColRow;
