import React from 'react';
import useTranslation from 'hooks/useTranslation';
import { InputNumberProps } from './types';
import { InputNumber as AntDInputNumber } from 'antd';

/**
 * The `InputNumber` component allows users to input numeric values,
 * with optional control over size, disabling state, and a placeholder.
 * It supports controlled usage, allowing you to manage the value state externally.
 *
 * @param {InputNumberProps} props - The properties defined in `InputNumberProps`.
 */
const InputNumber: React.FC<InputNumberProps> = ({
  value,
  onChange,
  onBlur,
  disabled,
  placeholder,
  name,
  size,
  ...rest
}) => {
  const { t } = useTranslation('form');

  return (
    <AntDInputNumber
      size={size}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder || t('enter_value')}
      {...rest}
    />
  );
};

export default InputNumber;
