import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useDispatch } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import {
  CHANGE_VIEW_TYPES,
  removeChangeViewHandler,
  setChangeViewHandler,
} from 'redux/actions/view';

// components
import SuccessButton from 'components/Buttons/SuccessButton';
import { Modal } from 'antd';
import { Button, IconSVG, Col, Row } from '@ui';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

interface IChangeView {
  changeView?: () => void;
  type?: CHANGE_VIEW_TYPES;
}

interface IProps {
  onSave?: () => Promise<void>;
}

// Show confirmation modal if form is dirty and user leaves without saving
const ConfirmationExitWithoutSaving = ({ onSave }: IProps) => {
  const { t } = useTranslation(['form', 'common']);
  const form = useFormikContext();
  const dispatch = useDispatch();

  const {
    dirty,
    isValid,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    isSubmitting,
  } = form;

  const [changeView, setChangeView] = useState<IChangeView | undefined>();
  const [lastSubmitCount, setLastSubmitCount] = useState(submitCount);

  useEffect(() => {
    if (dirty || !isValid) {
      dispatch(setChangeViewHandler(onOpenModal));
    } else {
      dispatch(removeChangeViewHandler());
    }
  }, [dirty, isValid]);

  useEffect(() => {
    if (lastSubmitCount !== submitCount) {
      setLastSubmitCount(submitCount);
      resetForm(form);
    }
  }, [submitCount]);

  const onOpenModal = (changeView?: () => void, type?: CHANGE_VIEW_TYPES) => {
    setChangeView({ changeView, type });
  };

  const onCancel = useCallback(() => {
    setChangeView(undefined);
  }, [changeView]);

  const onNo = useCallback(() => {
    setChangeView(undefined);
    resetForm(form);
    changeView?.changeView && changeView.changeView();
  }, [changeView]);

  const onYes = useCallback(async () => {
    if (onSave) {
      await onSave();
    } else {
      await submitForm();
    }

    // If form has errors close modal
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      return setChangeView(undefined);
    }

    setChangeView(undefined);
    changeView?.changeView && changeView.changeView();
  }, [changeView]);

  const confirmMessage = useMemo(() => {
    switch (changeView?.type) {
      case CHANGE_VIEW_TYPES.MODAL:
        return t('confirm_exit_without_save.title');

      default:
        return t('confirm_exit_without_save.sub_title');
    }
  }, [changeView?.type]);

  return (
    <Modal
      zIndex={9999}
      closable={false}
      visible={!!changeView}
      onCancel={onCancel}
      footer={
        <Row justify="end" gutter={[16, 0]}>
          <Col>
            <Button onClick={onCancel} disabled={isSubmitting}>
              {t('cancel', { ns: 'common' })}
            </Button>
          </Col>
          <Col>
            <Button onClick={onNo} danger disabled={isSubmitting}>
              {t('no', { ns: 'common' })}
            </Button>
          </Col>
          <Col>
            <SuccessButton onClick={onYes} loading={isSubmitting}>
              {t('yes', { ns: 'common' })}
            </SuccessButton>
          </Col>
        </Row>
      }
    >
      <StyledModalContent>
        <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
        {confirmMessage}
      </StyledModalContent>
    </Modal>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledModalContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

export default memo(ConfirmationExitWithoutSaving);
