import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormField } from '@ui';
import { FormInput } from '@ui';
import { FormInputPhoneNumber } from '@ui';

const InformationStep = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <FormField
        label={t('account_recovery.information_step.form_fields.email')}
        name="email"
        disabled
        component={FormInput}
      />
      <FormField
        label={t('account_recovery.information_step.form_fields.phone')}
        name="phone"
        component={FormInputPhoneNumber}
      />
    </>
  );
};

export default InformationStep;
