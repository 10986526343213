import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { FooterProps } from './types';

/**
 * The `Footer` component represents the bottom area of the page layout,
 * often containing site info, links, or secondary navigation.
 *
 * @param {FooterProps} props - The properties defined in `FooterProps`.
 */
const Footer: React.FC<FooterProps> = ({ className, children, style }) => {
  return (
    <AntdLayout.Footer className={className} style={style}>
      {children}
    </AntdLayout.Footer>
  );
};

export default Footer;
