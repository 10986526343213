import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ContactModel } from 'typings/application/contact';

// components
import { IconSVG, Tabs } from '@ui';
import Identification from './Tabs/Identification';
import ApplicantInformation from './Tabs/ApplicantInformation';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

type TabKeys = 'applicantInformation' | 'identification';

interface IProps {
  contact: ContactModel;
}

const IndividualWithoutKeyRelationship = ({ contact }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>(
    'applicantInformation',
  );

  const tabs = useMemo<
    { key: TabKeys; title: React.ReactNode; content: React.ReactNode }[]
  >(() => {
    return [
      {
        key: 'applicantInformation',
        title: (
          <>
            {t('applicant_information.general.title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        content: <ApplicantInformation contact={contact} />,
      },

      {
        key: 'identification',
        title: (
          <>
            {t('identification.title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        content: <Identification contact={contact} />,
      },
    ];
  }, [contact]);

  return (
    <Tabs
      useChangeView
      tabs={tabs}
      activeKey={currentStep}
      defaultActiveKey={currentStep}
      onChange={setCurrentStep}
    />
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default IndividualWithoutKeyRelationship;
