import React, { useState } from 'react';

// helpers
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { setUserTags } from 'redux/actions/auth';
import { UserProfileModel } from 'typings/profile/profile';
import { OnboardingEntryTypes } from '../../../../../enums/onboarding/crm';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers, FormikProps } from 'formik';
import { AddNewNodeFormValidationSchema } from '../../../../../validations/onboarding-new/structure';
import { FormValuesModel as ContactFormValuesModel } from '../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as RelationshipFormValuesModel } from '../../../../Forms/TemplateForms/CRM/RelationshipForm';
import { FormValuesModel as OrganizationFormValuesModel } from '../../../../Forms/TemplateForms/CRM/OrganizationShortInfoForm';
import { USER_TAG_IS_OFF_POTENTIAL_CONTACT_DUPLICATION_MODAL } from 'constants/userTags';

// components
import InnerForm from './InnerForm';
import PotentialContactDuplicationModal from '../PotentialContactDuplicationModal';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

export type FormValuesModel = {
  activeApplicationScopeId: string;

  template: {
    id: string | null;
    visibleFor: OnboardingEntryTypes | null;
  };
  clientGroupEntryType: OnboardingEntryTypes;
  contact: ContactFormValuesModel | null;
  organization: OrganizationFormValuesModel | null;

  relationships: RelationshipFormValuesModel[];
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

const AddNewNodeModalDialog = ({
  title,
  isVisible,
  initialValues,
  onSubmit,
  onSubmitError,
  closeCallback,
}: IProps) => {
  const dispatch = useDispatch();
  const profileData = useSelector<StateModel, UserProfileModel | null>(
    (store) => store.auth.profileData,
  );
  const [isDuplicatedModalOpened, setIsDuplicatedModalOpened] = useState(false);
  const [isPotentialDuplicationModalVisible, setIsVisible] = useState(false);

  const onDontShowButton = async (
    formikProps: FormikProps<FormValuesModel>,
  ) => {
    await userAPI.setUserTags([
      USER_TAG_IS_OFF_POTENTIAL_CONTACT_DUPLICATION_MODAL,
    ]);
    dispatch(
      setUserTags([
        ...(profileData?.tags as string[]),
        USER_TAG_IS_OFF_POTENTIAL_CONTACT_DUPLICATION_MODAL,
      ]),
    );
    setIsDuplicatedModalOpened(true);
    formikProps.submitForm();
    setIsVisible(false);
  };

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (
      values.template.visibleFor === OnboardingEntryTypes.Contact &&
      !profileData?.tags.includes(
        USER_TAG_IS_OFF_POTENTIAL_CONTACT_DUPLICATION_MODAL,
      )
    ) {
      if (!isDuplicatedModalOpened) {
        setIsVisible(true);
      } else {
        await onSubmit(values, helpers);
        await onResetForm(helpers);
        closeCallback(true);
        setIsDuplicatedModalOpened(false);
      }
    } else {
      await onSubmit(values, helpers);
      await onResetForm(helpers);
      closeCallback(true);
    }
  };

  const onOk = async (formikProps: FormikProps<FormValuesModel>) => {
    setIsDuplicatedModalOpened(true);
    formikProps.submitForm();
    setIsVisible(false);
  };

  const onResetForm = async (helpers: FormikHelpers<FormValuesModel>) => {
    // TODO: for now this is quick fix, the problem is that modal dialog doesn't reset form after submittion by extended buttons
    await helpers.resetForm({
      values: {} as any,
      errors: {},
      touched: {},
      isSubmitting: false,
      isValidating: false,
      status: undefined,
      submitCount: 0,
    });
  };

  return (
    <Form
      enableReinitialize
      onSubmit={handleSubmit}
      onSubmitError={onSubmitError}
      initialValues={isVisible ? initialValues : null}
      validationSchema={AddNewNodeFormValidationSchema}
      renderForm={(form) => (
        <ModalDialog
          closeModalAfterError={false}
          closeModalAfterSubmit={false}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
          {!profileData?.tags.includes(
            USER_TAG_IS_OFF_POTENTIAL_CONTACT_DUPLICATION_MODAL,
          ) && (
            <PotentialContactDuplicationModal
              isVisible={isPotentialDuplicationModalVisible}
              closeCallback={() => setIsVisible(false)}
              onDontShowButton={() => onDontShowButton(form)}
              onOk={() => onOk(form)}
            />
          )}
        </ModalDialog>
      )}
    />
  );
};

export default AddNewNodeModalDialog;
