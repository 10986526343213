import React, { memo, useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { securityAPI } from 'api/profile/securityAPI';
import { FormValuesModel } from '..';
import { FormikHelpers, useField } from 'formik';

// components
import { Button, Message } from '@ui';
import VerifyEmailModal, {
  VerifyEmailFormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Profile/VerifyEmailModal';

interface IProps {
  fieldName: string;
  onVerify: () => void;
}

const VerifyEmail = memo(({ fieldName = '', onVerify }: IProps) => {
  const { t } = useTranslation('profile');

  const [field] = useField<FormValuesModel>(fieldName);

  const [isVisible, setIsVisible] = useState(false);

  const verifyEmailInitialFormValues = useMemo<VerifyEmailFormValuesModel>(
    () => ({
      otp: '',
      email: field.value.value,
    }),
    [field],
  );

  const handleVerify = async (values: VerifyEmailFormValuesModel) => {
    const data = {
      email: values.email || '',
      otp: values.otp,
    };
    await securityAPI.verifyEmail(data);

    setIsVisible(false);
    Message.success(t('email_verification.success_submit_message'));
    onVerify();
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: VerifyEmailFormValuesModel,
    formikHelpers: FormikHelpers<VerifyEmailFormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201018': {
        formikHelpers.setFieldError(
          'otp',
          t('1201018', { ns: 'server_errors' }),
        );
        break;
      }
      case '1201020': {
        formikHelpers.setFieldError(
          'otp',
          t('1201020', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  const onResendOTP = async () => {
    await securityAPI.resendOTP();
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        {t('personal_details.verify_email.verify_button')}
      </Button>
      <VerifyEmailModal
        isVisible={isVisible}
        closeCallback={() => setIsVisible(false)}
        initialValues={verifyEmailInitialFormValues}
        onSubmit={handleVerify}
        onResendOTP={onResendOTP}
        onSubmitError={handleOnSubmitError}
      />
    </>
  );
});

export default VerifyEmail;
