import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';

// components
import { Text } from '@ui';

const IntroStep = () => {
  const { t } = useTranslation('auth');

  return (
    <Wrapper>
      <Text variant="body1" gutterBottom>
        {t('account_recovery.intro_step.recover_authenticator_description')}
      </Text>
      <Text variant="body1">
        {t(
          'account_recovery.intro_step.recover_authenticator_options.primary_email',
        )}
      </Text>
      <Text variant="body1">
        {t('account_recovery.intro_step.recover_authenticator_options.camera')}
      </Text>
      <Text variant="body1">
        {t(
          'account_recovery.intro_step.recover_authenticator_options.document',
        )}
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 ${({ theme }) => theme.paddingXXl};

  ${({ theme }) => `
    @media screen and (max-width: ${theme.mobileScreen}) {
      padding: 0;
    }
  `}
`;

export default IntroStep;
