import React, { useState } from 'react';

// helpers
import ErrorHandlerService from 'services/error-handler/service';
import { AsyncButtonProps } from './types';
import { ApiErrorResponseModel } from 'api/axiosInstance';

// components
import { Button } from '@ui';

const AsyncButton: React.FC<AsyncButtonProps> = ({
  onClick,
  children,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await onClick();
    } catch (e) {
      ErrorHandlerService.handleError(e as ApiErrorResponseModel);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button onClick={handleClick} loading={isLoading} {...rest}>
      {children}
    </Button>
  );
};

export default React.memo(AsyncButton);
