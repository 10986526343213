import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';

// components
import { AddButton, Message } from '@ui';
import ApprovalGroupModal, {
  ApprovalGroupFormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Approval/ApprovalGroupModal';

interface IProps {
  onAction?: () => void;
}

const CreateApprovalGroup = ({ onAction }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const initialFormValues: ApprovalGroupFormValuesModel = useMemo(
    () => ({ name: '', description: '', participants: [] }),
    [],
  );

  const handleFormSubmit = async (values: ApprovalGroupFormValuesModel) => {
    await onboardingAPI.createApprovalGroup({
      name: values.name,
      description: values.description as string,
      participants: values.participants.map((e) => e.userId),
      type: 'onboarding-transaction',
    });
    Message.success(
      t('administration.transaction_rules.approval_groups.new.success_message'),
    );
  };

  const handleCloseCallback = (wasCreated?: boolean) => {
    setIsModalVisible(false);
    wasCreated && onAction && onAction();
  };

  return (
    <>
      <AddButton onClick={() => setIsModalVisible(true)}>
        {t('administration.transaction_rules.approval_groups.create_btn')}
      </AddButton>
      <ApprovalGroupModal
        approvalWorkflowType="onboarding-approval-group"
        title={t('administration.transaction_rules.approval_groups.new.title')}
        onSubmit={handleFormSubmit}
        isVisible={isModalVisible}
        closeCallback={handleCloseCallback}
        initialValues={initialFormValues}
      />
    </>
  );
};

export default CreateApprovalGroup;
