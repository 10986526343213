import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ISession } from 'typings/profile/security';
import { securityAPI } from 'api/profile/securityAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import ActiveSessionsTable from 'components/Tables/TableTemplates/User/ActiveSessionsTable';
import { Modal } from '@ui';

interface IProps {
  updateViewTrigger: any;
  updateView: () => void;
}

const CurrentSessions = ({ updateViewTrigger, updateView }: IProps) => {
  const { t } = useTranslation('profile');
  const [currentPage, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      securityAPI.fetchUserSessions({
        inactive: false,
        limit: DEFAULT_TABLE_LIMIT,
        page: currentPage,
      }),
    [currentPage, updateViewTrigger],
  );

  const handleSessionDelete = async (session: ISession) => {
    Modal.confirm({
      content: t(
        'security.sessions.current_sessions.stop_session.stop_session_confirmation',
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await securityAPI.deleteSession(session._id);
        updateView();
      },
    });
  };

  return (
    <ActiveSessionsTable
      loading={loading}
      current={currentPage}
      total={response?.total}
      data={response?.data || []}
      onDelete={handleSessionDelete}
      onPaginationChange={setPage}
    />
  );
};

export default CurrentSessions;
