import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { Tooltip, RadioGroup, RadioButton } from '@ui';

// TODO: create RadioSelect base component and use it here
const TemplatePrivateStatusRadioSelect = (props: any) => {
  const { t } = useTranslation('transfers');

  return (
    <RadioGroup buttonStyle="solid" {...props}>
      <Tooltip
        placement="bottom"
        title={t(
          'transfer_templates.transfer_template_permissions.Private_description',
        )}
      >
        <RadioButton value="onlyMe">
          {t('transfer_templates.transfer_template_permissions.Private')}
        </RadioButton>
      </Tooltip>

      <Tooltip
        placement="bottom"
        title={t(
          'transfer_templates.transfer_template_permissions.Public_description',
        )}
      >
        <RadioButton value="clientGroup">
          {t('transfer_templates.transfer_template_permissions.Public')}
        </RadioButton>
      </Tooltip>
    </RadioGroup>
  );
};

export default TemplatePrivateStatusRadioSelect;
