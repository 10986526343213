import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormField } from '@ui';
import { FormInputPhoneNumber } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('auth');

  return (
    <FormField
      name="phone"
      component={FormInputPhoneNumber}
      label={t('phone_verification.edit.phone')}
    />
  );
};

export default InnerForm;
