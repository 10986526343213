import React, { useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { colorsTheme } from 'resources/theme/styled/colors';
import { setWelcomeModalVisibilityStatus } from 'redux/actions/app';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updateUserFirstTimeLoggedInStatus } from 'redux/actions/auth';

// components
import { Link, Card, Text } from '@ui';

const WelcomeCard = () => {
  const { t } = useTranslation('dashboard');

  const dispatch = useDispatch();
  const userName = useSelector<StateModel>(
    (state) => state.auth.profileData?.name || '',
  );

  // Update the isFirstTimeLoggedIn status in db (to false)
  useEffect(() => {
    dispatch(updateUserFirstTimeLoggedInStatus());
  }, []);

  const onCloseClick = () => {
    dispatch(setWelcomeModalVisibilityStatus(false));
  };

  return (
    <>
      <StyledCard bordered={false}>
        <StyledCardContent>
          <StyledHeaderWrapper>
            <Text variant="h4" weight="semi-bold">
              {t('welcome_modal.title', { userName })}
            </Text>
            <Text
              variant="h4"
              weight="semi-bold"
            >{`${t('welcome_modal.second_title')} 🎉`}</Text>
          </StyledHeaderWrapper>

          <StyledContentWrapper>
            <Text variant="h6" color={colorsTheme.colorLightD1}>
              {t('welcome_modal.content_title')}
            </Text>
            <Text variant="h6" color={colorsTheme.colorLightD1}>
              {t('welcome_modal.content_description')}
            </Text>
          </StyledContentWrapper>

          <StyledFooterWrapper>
            <SkipButton onClick={onCloseClick}>
              {t('welcome_modal.close_btn')}
            </SkipButton>
          </StyledFooterWrapper>
        </StyledCardContent>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  width: 500px;
  max-height: 400px;
  border-radius: 7px;

  .ant-card-body {
    padding: 30px;
    height: 100%;
  }
`;

const StyledCardContent = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SkipButton = styled(Link)`
  cursor: pointer;
  text-align: center;
  color: ${({ theme }) => theme.colorLightD1} !important;
  transition: ${({ theme }) => theme.defaultTransition};

  &:hover {
    color: ${({ theme }) => theme.colorWhite} !important;
  }
`;

export default WelcomeCard;
