import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { MessageModel } from 'typings/messaging/messages';

// component
import { Text } from '@ui';
import NewMessageMarker from '../../../../../../Additional/Messaging/NewMessageMarker';

interface IProps {
  message: MessageModel;
  isUnread: boolean;
  isActive?: boolean;
}

const MessageAuthor = memo(({ message, isActive, isUnread }: IProps) => {
  const { t } = useTranslation('profile');
  const senderName = message.from.name;

  const recipients = useMemo(() => {
    if (!message || !message.to) {
      return '';
    }

    return message.to.map((user) => user.name).join(', ');
  }, [message]);

  return (
    <>
      <StyledSenderName
        variant="body1"
        color={colorsTheme.colorWhite}
        weight="semi-bold"
        gutterBottom
      >
        {senderName}
        {isUnread && <StyledNewMessageMarker />}
      </StyledSenderName>

      {isActive && (
        <StyledRecipientText variant="body1" color={colorsTheme.colorLight}>
          {`${t('messages.view_message.to')}: `}
          <Text variant="span" color={colorsTheme.colorWhite}>
            {recipients}
          </Text>
        </StyledRecipientText>
      )}
    </>
  );
});

const StyledSenderName = styled(Text)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  pointer-events: none;
`;

const StyledRecipientText = styled(Text)`
  pointer-events: none;
`;

const StyledNewMessageMarker = styled(NewMessageMarker)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default MessageAuthor;
