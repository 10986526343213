import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import OTPField from 'components/Forms/FormComponents/OTPField';
import SubmitButton from 'components/Buttons/SubmitButton';
import { Text, FormField, IconSVG } from '@ui';
import { ReactComponent as MailSendIcon } from 'resources/icons/remix-icons/mail-send-line.svg';

const InnerForm = () => {
  const { t } = useTranslation('auth');

  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <StyledIconWrapper>
        <IconSVG
          component={MailSendIcon}
          color={colorsTheme.colorWhite}
          size="large"
        />
      </StyledIconWrapper>

      <StyledText variant="body1">
        {t('email_verification.otp_description')}
        <StyledEmail variant="body1" gutterBottom>
          {values.email}
        </StyledEmail>
      </StyledText>

      <StyledOTPFieldWrapper>
        <FormField
          name="otp"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
          disabled={false}
        />
      </StyledOTPFieldWrapper>

      <StyledSubmitButton fullWidth disabled={false}>
        {t('email_verification.submit_button')}
      </StyledSubmitButton>
    </>
  );
};

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.marginSm};
  padding-left: 15px;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledEmail = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin: ${({ theme }) => theme.marginXs} 0px;
`;

const StyledOTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default InnerForm;
