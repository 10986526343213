import React, { useRef } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { darkTheme } from 'resources/theme/styled';
import { VerificationDocumentPhotoTypes } from 'enums/profile/accountRecovery';
import { ALLOWED_VERIFICATION_DOCUMENT_TYPES } from 'constants/documents';

// components
import { Text, IconSVG, Link, DeleteButton, Message } from '@ui';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { ReactComponent as FileIcon } from 'resources/icons/remix-icons/file-line.svg';

interface IProps {
  file: File | null;
  verificationDocumentType: VerificationDocumentPhotoTypes;
  onSelect: (type: VerificationDocumentPhotoTypes, image: File) => void;
  onDeleteClick: (type: VerificationDocumentPhotoTypes) => void;
}

const IMAGE_FILE_TYPES = ['image/png', 'image/jpeg'];

const UploadDocumentCard = ({
  file,
  verificationDocumentType,
  onSelect,
  onDeleteClick,
}: IProps) => {
  const { t } = useTranslation(['common', 'auth', 'form']);
  const inputRef = useRef<any>();

  const onFileSelect = () => {
    if (inputRef.current && inputRef.current.files.length) {
      const file = inputRef.current.files[0];
      if (!file.size) {
        Message.info(t('warnings.upload_empty_document', { ns: 'form' }));
      } else {
        onSelect(verificationDocumentType, inputRef.current.files[0]);
      }
    }
  };

  const handleBrowseClick = () => {
    inputRef.current.click();
  };

  return (
    <WrapperCard document={file}>
      {!file ? (
        <>
          <Text variant="body1" weight="semi-bold">
            {t(
              `account_recovery.id_verification_step.upload_document_methods.upload.side_types.${verificationDocumentType}`,
              { ns: 'auth' },
            )}
          </Text>
          <Link onClick={handleBrowseClick}>{t('browse')}</Link>
          <HiddenUploadInput
            ref={inputRef}
            type="file"
            accept={ALLOWED_VERIFICATION_DOCUMENT_TYPES}
            onChange={onFileSelect}
          />
        </>
      ) : (
        <>
          {IMAGE_FILE_TYPES.includes(file.type) ? (
            <SelectedImage src={URL.createObjectURL(file)} />
          ) : (
            <>
              <IconSVG
                size="large"
                color={darkTheme.colorWhite}
                component={FileIcon}
              />
              <Text>
                <EllipsisTooltip
                  maxTextContainerWidth="100px"
                  title={file.name}
                >
                  {file.name}
                </EllipsisTooltip>
              </Text>
            </>
          )}
          <StyledDeleteButtonWrapper>
            <DeleteButton
              onClick={() => onDeleteClick(verificationDocumentType)}
            />
          </StyledDeleteButtonWrapper>
        </>
      )}
    </WrapperCard>
  );
};

const HiddenUploadInput = styled.input`
  display: none;
`;

const WrapperCard = styled.div<{ document: File | null }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // 120px - width of the image block when the image is uploaded.
  // 140px - width of the image block when the image is not uploaded.
  // 150px - height of the image block when the image is uploaded.
  width: ${({ document }) => (document ? '120px' : '140px')};
  height: ${({ document }) => (document ? '150px' : '100%')};

  border: 1px dashed ${({ theme }) => theme.colorWhite};
  border-radius: 5px;
  overflow: hidden;
`;

const SelectedImage = styled.img`
  width: 100%;
  height: auto;
`;

const StyledDeleteButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default UploadDocumentCard;
