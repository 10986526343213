import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { useField } from 'formik';
import { RelationshipTemplate } from 'typings/application/relationship-template';
import { IDocumentAssociationInfo } from 'typings/documents/documents';

// constants
import { OnboardingEntryTypes } from 'enums/onboarding/crm';

// components
import AdditionalField, {
  AdditionalFieldFormItemModel,
} from '../../../FormComponents/AdditionalField';
import RelationshipTemplatesAutocomplete, {
  RelationshipTemplatesAutocompleteProps,
} from '../../../FormComponents/Autocompletes/CRM/RelationshipTemplatesAutocomplete';
import { FormField, Col, Row } from '@ui';

interface IProps {
  fieldName: string;
  activeScopeId: string;
  hideRelationshipIds: string[];
  documentAssociation: IDocumentAssociationInfo;
  parentNode: { id?: string; type: OnboardingEntryTypes } | null;
  childNode: { id: string; type: OnboardingEntryTypes } | null;
}

export interface FormValuesModel {
  id: string;
  additionalFields: AdditionalFieldFormItemModel[];
  relationshipTemplate: RelationshipTemplate | null;
}

/* Related validation schema: onboarding-new/structure/RelationshipTemplateFormValidationSchema **/
const RelationshipTemplateForm = ({
  fieldName,
  activeScopeId,
  hideRelationshipIds,
  documentAssociation,
  parentNode,
  childNode,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [field, , helpers] = useField<FormValuesModel>(fieldName);
  const gridSizes = { xl: 12, lg: 12, md: 12, sm: 24, xs: 24 };

  const handleRelationshipSelect = (
    newValue: string,
    template: RelationshipTemplate,
  ) => {
    if (!parentNode) {
      return;
    }

    const additionalFields: AdditionalFieldFormItemModel[] =
      template.additionalFields
        .filter(
          ({ relatedTo }) =>
            relatedTo === 'any' || relatedTo === parentNode.type,
        )
        .map((e) => ({
          name: e.name,
          type: e.type,
          value: undefined,
          relatedTo: e.relatedTo,
          options: {
            onboardingRequired: e.options.onboardingRequired,
            isForRegulatedOnly: e.options.isForRegulatedOnly,
            documentRequiresCertification:
              e.options.documentRequiresCertification,
            selectOptions: e.options.selectOptions.length
              ? e.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
              : [],
            formDocument: e.options.formDocument,
          },
          id: e._id,
        }));

    const newFormValues: FormValuesModel = {
      id: newValue,
      relationshipTemplate: template,
      additionalFields,
    };

    helpers.setValue(newFormValues);
  };

  const renderAdditionalForm = (
    additionalFields: AdditionalFieldFormItemModel[],
  ) => {
    return additionalFields.map((ad, i) => (
      <Col span="12" key={i}>
        <FormField
          label={ad.name}
          name={`${fieldName}.additionalFields.${i}.value`}
          component={AdditionalField}
          additionalProps={{
            field: {
              type: ad.type,
              options: ad.options.selectOptions,
              formDocument: ad.options.formDocument,
              documentAssociation,
            },
          }}
        />
      </Col>
    ));
  };

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...gridSizes}>
          <FormField<RelationshipTemplatesAutocompleteProps>
            disabled={!parentNode || !childNode}
            name={`${fieldName}.id`}
            placeholder={t('select_option', { ns: 'form' })}
            component={RelationshipTemplatesAutocomplete}
            additionalProps={{
              excludeElementIds: hideRelationshipIds,
              relationshipScopeId: activeScopeId,
              childId: childNode?.id as string,
              parentId: parentNode?.id as string,
              parentType: parentNode?.type as OnboardingEntryTypes,
              onSelect: (newValue, option) =>
                option.model &&
                handleRelationshipSelect(newValue as string, option.model),
            }}
          />
        </Col>
      </Row>

      {!!field.value.additionalFields.length && (
        <Row gutter={[16, 0]}>
          {renderAdditionalForm(field.value.additionalFields)}
        </Row>
      )}
    </>
  );
};

export default RelationshipTemplateForm;
