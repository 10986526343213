import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { changeView } from 'redux/actions/view';
import { RoutePaths } from 'routes/routes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorsTheme } from 'resources/theme/styled/colors';
import { transactionsAPI } from 'api/accounting/transactionsAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { IconSVG, Col, Row, Link } from '@ui';
import { ReactComponent as ArrowRightIcon } from 'resources/icons/remix-icons/arrow-right-s-line.svg';
import { default as TemplateTransfersTable } from 'components/Tables/TableTemplates/Dashboard/TransfersTable';

interface IProps {
  selectedAccountNumber: string;
}

const TransfersTable = ({ selectedAccountNumber }: IProps) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => navigate(path, state)));

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchJournalEntries({
        page,
        accountNumber: selectedAccountNumber,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [selectedAccountNumber, page],
  );

  return (
    <>
      <TemplateTransfersTable
        data={response?.data || []}
        total={response?.total || 0}
        current={page}
        loading={loading}
        onPaginationChange={setPage}
      />

      <StyledFooterRow justify="end">
        <Col>
          <Link
            onClick={() =>
              historyPush({
                pathname: RoutePaths.Accounts_AccountActivity,
                search: `?accountNumber=${selectedAccountNumber}`,
              })
            }
          >
            <DivAlignCenter>
              {t('last_transfers_section.go_to_account_details_button')}
              <StyledIconSVG
                component={ArrowRightIcon}
                color={colorsTheme.colorPrimary}
              />
            </DivAlignCenter>
          </Link>
        </Col>
      </StyledFooterRow>
    </>
  );
};

const StyledFooterRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default TransfersTable;
