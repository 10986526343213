import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { securityAPI } from 'api/profile/securityAPI';

// components
import { Text, Divider, FormField } from '@ui';
import OTPField from '../../../../../FormComponents/OTPField';
import ResendOtp from '../../../../../../Additional/ResendOtp';
import SubmitButton from '../../../../../../Buttons/SubmitButton';
import { FormInputPassword } from '@ui';

interface IProps {
  username: string;
}

const InnerForm = ({ username }: IProps) => {
  const { t } = useTranslation('auth');

  const resendOTP = async () => {
    await securityAPI.resendActivateEmailOTP(username);
  };

  return (
    <>
      <StyledMainTitleText variant="h3" weight="semi-bold" gutterBottom>
        {t('signin.steps.activate_account.title')}
      </StyledMainTitleText>

      <StyledTitleText variant="h5" weight="semi-bold" gutterBottom>
        {t('signin.steps.activate_account.title_otp')}
      </StyledTitleText>

      <OTPFieldWrapper>
        <FormField
          name="otpCode"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </OTPFieldWrapper>

      <ResendOtp
        successMessage={t('signin.steps.activate_account.otp_sent_message')}
        resendOtpCallback={resendOTP}
      />

      <Divider />

      <StyledTitleText variant="h5" weight="semi-bold" gutterBottom>
        {t('signin.steps.activate_account.title_password')}
      </StyledTitleText>

      <FormField
        name="newPassword"
        label={t('signin.steps.activate_account.form_fields.new_password')}
        component={FormInputPassword}
      />

      <FormField
        name="confirmNewPassword"
        label={t(
          'signin.steps.activate_account.form_fields.confirm_new_password',
        )}
        component={FormInputPassword}
      />

      <SubmitButton fullWidth>
        {t('signin.steps.activate_account.submit_button')}
      </SubmitButton>
    </>
  );
};

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const StyledTitleText = styled(Text)`
  display: block;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;
`;

const StyledMainTitleText = styled(Text)`
  display: block;
  text-align: center;
`;

export default InnerForm;
