import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { useFormikContext } from 'formik';
import { ApprovalGroupFormValuesModel, ApprovalWorkflowType } from '..';

// components
import { FormField } from '@ui';
import { FormInput } from '@ui';
import { FormInputTextArea } from '@ui';
import ApprovalGroupParticipantsAutocomplete from '../../../../../Forms/FormComponents/Autocompletes/AccountManagement/ApprovalGroupParticipantsAutocomplete';

interface IProps {
  approvalWorkflowType: ApprovalWorkflowType;
  isEditMode?: boolean;
}

const InnerForm = ({ approvalWorkflowType, isEditMode }: IProps) => {
  const { t } = useTranslation('account_management');
  const { values } = useFormikContext<ApprovalGroupFormValuesModel>();

  return (
    <>
      <FormField
        name="name"
        label={t('approval_groups.approval_group_modal.form_fields.name')}
        component={FormInput}
      />

      <FormField
        name="participants"
        label={t('approval_groups.approval_group_modal.form_fields.members')}
        component={ApprovalGroupParticipantsAutocomplete}
        disabled={!!isEditMode}
        additionalProps={{
          mode: 'multiple',
          approvalWorkflowType,
          initialValue: values.initialParticipantOptions,
        }}
      />

      <FormField
        name="description"
        label={t(
          'approval_groups.approval_group_modal.form_fields.description',
        )}
        component={FormInputTextArea}
        additionalProps={{ autoSize: { minRows: 6, maxRows: 6 } }}
      />
    </>
  );
};

export default InnerForm;
