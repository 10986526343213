import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// Components
import { IconSVG, Popover } from '@ui';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

const CertificationRequiredTooltip = () => {
  const { t } = useTranslation('onboarding');

  const tooltipRows = [
    t('application_documentation.certification_required_tooltip.item_1'),
    t('application_documentation.certification_required_tooltip.item_2'),
    t('application_documentation.certification_required_tooltip.item_3'),
    t('application_documentation.certification_required_tooltip.item_4'),
  ];

  const tooltipText = (
    <div>
      {t('application_documentation.certification_required_tooltip.text')}

      <ul>
        {tooltipRows.map((content, i) => (
          <li key={i}>{content}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <Popover content={tooltipText} placement="leftTop">
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default CertificationRequiredTooltip;
