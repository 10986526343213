import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { PendingTransaction } from 'typings/accounting/transaction';

// components
import {
  Button,
  RequiredPropsForTableModel,
  SortProps,
  Col,
  Row,
  Message,
} from '@ui';
import ReviewTransactionsModal from './ReviewTransactionsModal';
import ViewPendingTransactionModal from './ViewPendingTransactionModal';
import { default as TemplatePendingTransactionsTable } from 'components/Tables/TableTemplates/Accounts/PendingTransactionsTable/index';

interface IProps extends RequiredPropsForTableModel<PendingTransaction> {
  filters: Record<string, any>;
  onSortChange?: (sorter: SortProps) => void;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
  updateTableCallback: (switchToFirstPage?: boolean) => void;
}

const MAX_TRANSACTIONS_FOR_REVIEW = 200;

const PendingTransactionsTable = ({
  data,
  total,
  current,
  loading,
  filters,
  onSortChange,
  onFilterChange,
  onPaginationChange,
  updateTableCallback,
}: IProps) => {
  const { t } = useTranslation('common');
  const [hasReviewAccess] = useUserAccess([
    'AccountManagement_TransactionRules_Workflow_ApproveReject',
  ]);
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [selectedTxnsForReview, setSelectedTxnsForReview] = useState<{
    action: 'approve' | 'reject' | null;
    selectedTransactions: Record<string, PendingTransaction>;
  }>({ action: null, selectedTransactions: {} });

  const isSelectAllChecked = useMemo(() => {
    const transactionsWithPendingReviewStatus = data.filter(
      (e) => e.canProcess,
    );

    if (!transactionsWithPendingReviewStatus.length) {
      return false;
    }

    return !transactionsWithPendingReviewStatus.some(
      (transaction) =>
        !selectedTxnsForReview.selectedTransactions[transaction.id],
    );
  }, [data, selectedTxnsForReview.selectedTransactions]);

  const showReviewButtons = !!Object.keys(
    selectedTxnsForReview.selectedTransactions,
  ).length;

  const onActionsClick = (key: string, transactionId: number) => {
    switch (key) {
      case 'view': {
        setSelectedTransactionId(transactionId);
        break;
      }
    }
  };

  const handleCloseDetailDialog = () => {
    setSelectedTransactionId(null);
  };

  const handleTransactionSelect = (
    records: { record: PendingTransaction; checked: boolean }[],
  ) => {
    const numberOfSelectedTransactions = Object.keys(
      selectedTxnsForReview.selectedTransactions,
    ).length;

    const formattedRecords = records.reduce<{
      toAdd: PendingTransaction[];
      toRemove: PendingTransaction[];
    }>(
      (acc, e) => {
        if (e.checked) {
          acc.toAdd.push(e.record);
        } else {
          acc.toRemove.push(e.record);
        }
        return acc;
      },
      { toAdd: [], toRemove: [] },
    );

    if (
      numberOfSelectedTransactions == MAX_TRANSACTIONS_FOR_REVIEW &&
      formattedRecords.toAdd.length > 0
    ) {
      Message.error(
        t(
          'pending_transactions.review_multiple_txns_modal.max_transactions_error',
          { limit: MAX_TRANSACTIONS_FOR_REVIEW },
        ),
      );
    }

    if (
      numberOfSelectedTransactions -
        formattedRecords.toRemove.length +
        formattedRecords.toAdd.length >
      MAX_TRANSACTIONS_FOR_REVIEW
    ) {
      formattedRecords.toAdd = formattedRecords.toAdd.slice(
        0,
        MAX_TRANSACTIONS_FOR_REVIEW -
          numberOfSelectedTransactions +
          formattedRecords.toRemove.length,
      );
    }

    setSelectedTxnsForReview((prevState) => {
      const newState = { ...prevState.selectedTransactions };

      if (formattedRecords.toAdd.length > 0) {
        formattedRecords.toAdd.forEach((transaction) => {
          newState[transaction.id] = transaction;
        });
      }

      if (formattedRecords.toRemove.length > 0) {
        formattedRecords.toRemove.forEach((transaction) => {
          delete newState[transaction.id];
        });
      }

      return {
        ...prevState,
        selectedTransactions: newState,
      };
    });
  };

  const handleReviewTransactionsModalClose = (wasSubmitted?: boolean) => {
    setSelectedTxnsForReview((prev) =>
      wasSubmitted
        ? { selectedTransactions: {}, action: null }
        : { ...prev, action: null },
    );
    wasSubmitted && updateTableCallback(true);
  };

  const handleTransactionStatusChange = (transactionId: number) => {
    if (selectedTxnsForReview.selectedTransactions[transactionId]) {
      setSelectedTxnsForReview((prevState) => {
        const newState = { ...prevState.selectedTransactions };
        delete newState[transactionId];
        return { ...prevState, selectedTransactions: newState };
      });
    }
    updateTableCallback();
  };

  return (
    <>
      <TemplatePendingTransactionsTable
        current={current}
        total={total}
        data={data}
        loading={loading}
        onSelect={hasReviewAccess ? handleTransactionSelect : undefined}
        onActionsClick={onActionsClick}
        isSelectAllChecked={isSelectAllChecked}
        filters={filters}
        onSortChange={onSortChange}
        onFilterChange={onFilterChange}
        onPaginationChange={onPaginationChange}
        selectedTransactions={selectedTxnsForReview.selectedTransactions}
      />

      {showReviewButtons && (
        <StyledRow justify="end" gutter={[16, 16]}>
          <Col flex="200px">
            <StyledButton
              type="bordered"
              danger
              onClick={() =>
                setSelectedTxnsForReview((prev) => ({
                  ...prev,
                  action: 'reject',
                }))
              }
            >
              {t('reject')}
            </StyledButton>
          </Col>
          <Col flex="200px">
            <StyledButton
              onClick={() =>
                setSelectedTxnsForReview((prev) => ({
                  ...prev,
                  action: 'approve',
                }))
              }
            >
              {t('approve')}
            </StyledButton>
          </Col>
        </StyledRow>
      )}

      <ViewPendingTransactionModal
        isVisible={!!selectedTransactionId}
        closeCallback={handleCloseDetailDialog}
        transactionId={selectedTransactionId}
        onApproveOrReject={handleTransactionStatusChange}
        onCancelTransaction={handleTransactionStatusChange}
      />

      <ReviewTransactionsModal
        action={selectedTxnsForReview.action}
        closeCallback={handleReviewTransactionsModalClose}
        selectedTransactions={selectedTxnsForReview.selectedTransactions}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default PendingTransactionsTable;
