import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import { StateModel } from 'redux/reducers';
import { ActionKeys } from '../../Components/LastStepSubmitButtons';
import { useSelector } from 'react-redux';
import { GraphHelpers } from 'helpers/graph';
import { IApplication } from 'typings/application/applications';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { GraphData, GraphNode } from '../../../../../Charts/GraphChart';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';

// components
import Individual from './TreeItems/Individual';
import Organization from './TreeItems/Organization';
import LoadingWrapper from '../../../../../WrapperComponents/LoadingWrapper';
import RelationshipsTree from './RelationshipsTree';
import IndividualWithoutKeyRelationship from './TreeItems/IndividualWithoutKeyRelationship';
import OrganizationWithoutKeyRelationship from './TreeItems/OrganizationWithoutKeyRelationship';
import { Col, Row, Card } from '@ui';

interface IProps {
  application: IApplication;
  isViewOnly?: boolean;
  canSendMessage?: boolean;
  onAction?: (key: ActionKeys | null) => void;
}

const ContentForm = ({
  application,
  isViewOnly,
  canSendMessage,
  onAction,
}: IProps) => {
  const [updateTreeTrigger, updateTree] = useState({});
  const [selectedGraphNode, setSelectedGraphNode] = useState<GraphNode | null>(
    null,
  );
  const [areAllRelationshipsCompleted, setAllRelationshipsCompleted] =
    useState(false);
  const { contactData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const { response: graphResponse, loading: treeLoader } =
    useFetch(async () => {
      const response = await onboardingAPI.fetchStructureStepRelationships(
        application.model._id,
        application.model.relationshipScopes?.active as string,
        application.onboardingProcess.crmItemId,
        contactData?._id || '',
      );

      return {
        ...response,
        nodes: response.nodes.map((node) => ({
          ...node,
          allInformationFilled: GraphHelpers.isNodeItemFilled(node),
        })),
      };
    }, [application, contactData, updateTreeTrigger]);

  useEffect(() => {
    // set initial tree item
    if (graphResponse) {
      const notFilledItem = findNotCompletedTreeItem(graphResponse);

      if (!selectedGraphNode) {
        setSelectedGraphNode(notFilledItem || graphResponse.nodes[0]);
      } else {
        setSelectedGraphNode(
          graphResponse.nodes.find((e) => e.id === selectedGraphNode.id) ||
            null,
        );
      }

      if (!notFilledItem) {
        setAllRelationshipsCompleted(true);
      }
    }
  }, [graphResponse]);

  const handleOnEntryComplete = () => {
    updateTree({});
  };

  const findNotCompletedTreeItem = (
    graph: GraphData,
  ): GraphNode | undefined => {
    if (graph.nodes.length) {
      return graph.nodes.find((e) => !GraphHelpers.isNodeItemFilled(e));
    }
  };

  const handleSubmitForReview = () => {
    onAction && onAction('submit');
  };

  const renderContent = () => {
    if (!selectedGraphNode) {
      return null;
    }

    const hasOnboardingProcess = !!selectedGraphNode.onboardingProcess;
    switch (selectedGraphNode.entryType) {
      case OnboardingEntryTypes.Contact:
        return hasOnboardingProcess ? (
          <Individual
            allInformationFilled={!!selectedGraphNode.allInformationFilled}
            updateNodeStatuses={handleOnEntryComplete}
            graphNode={selectedGraphNode}
            application={application}
            onAction={onAction}
            isViewOnly={isViewOnly}
            canSendMessage={canSendMessage}
            onRemoveKYC={() => updateTree({})}
          />
        ) : (
          <IndividualWithoutKeyRelationship
            onAction={onAction}
            graphNode={selectedGraphNode}
            onAddKYC={() => updateTree({})}
          />
        );

      case OnboardingEntryTypes.Organization:
        return hasOnboardingProcess ? (
          <Organization
            allInformationFilled={!!selectedGraphNode.allInformationFilled}
            updateNodeStatuses={handleOnEntryComplete}
            isViewOnly={isViewOnly}
            onAction={onAction}
            graphNode={selectedGraphNode}
            application={application}
            isCurrentOrganization={
              application.model._id === selectedGraphNode.id
            }
            canSubmitForReview={areAllRelationshipsCompleted}
            onSubmitForReview={handleSubmitForReview}
            canSendMessage={canSendMessage}
            updateStructureCallback={() => updateTree({})}
          />
        ) : (
          <OrganizationWithoutKeyRelationship
            onAction={onAction}
            graphNode={selectedGraphNode}
            onAddKYC={() => updateTree({})}
          />
        );
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <StyledCol span={19} push={5}>
        <StyledCard>
          <LoadingWrapper loading={treeLoader || !selectedGraphNode}>
            {selectedGraphNode ? renderContent() : null}
          </LoadingWrapper>
        </StyledCard>
      </StyledCol>

      <Col span={5} pull={19}>
        <StyledCard>
          <LoadingWrapper loading={!graphResponse}>
            {graphResponse && (
              <RelationshipsTree
                hideStatuses={isViewOnly}
                graph={graphResponse}
                onSelect={setSelectedGraphNode}
                selectedItemId={selectedGraphNode ? selectedGraphNode.id : null}
              />
            )}
          </LoadingWrapper>
        </StyledCard>
      </Col>
    </Row>
  );
};

const StyledCard = styled(Card)`
  min-height: 280px;
`;

const StyledCol = styled(Col)`
  padding-left: ${({ theme }) => theme.paddingXl};

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    padding-left: 0;
    padding-top: ${({ theme }) => theme.paddingXl};
  }
`;

export default ContentForm;
