import React from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { Input as AntDInput } from 'antd';
import { InputTextAreaProps } from './types';

/**
 * The `InputTextArea` component provides a multi-line text input field.
 * It can be controlled by passing a `value` and handling the `onChange` event,
 * or used as an uncontrolled component if no `value` is provided.
 * The size, disabled state, and placeholder can be customized.
 *
 * @param {InputTextAreaProps} props - The properties defined in `InputTextAreaProps`.
 */
const InputTextArea: React.FC<InputTextAreaProps> = ({
  value,
  onChange,
  onBlur,
  disabled,
  placeholder,
  name,
  size,
  ...rest
}) => {
  const { t } = useTranslation('form');

  return (
    <AntDInput.TextArea
      size={size}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder || t('enter_value')}
      {...rest}
    />
  );
};

export default React.memo(InputTextArea);
