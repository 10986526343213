import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SpinnerIcon } from 'resources/icons/remix-icons/loader-4-line.svg';
import { colorsTheme } from 'resources/theme/styled/colors';
import { SpinnerProps } from './types';
import { IconSVG } from '@ui';

/**
 * The `Spinner` component provides a simple rotating loader icon to indicate
 * ongoing processing or loading. The icon’s color can be customized, and it
 * smoothly animates to draw the user’s attention.
 *
 * @param {SpinnerProps} props - The properties defined in `SpinnerProps`.
 */
const Spinner = ({
  color = colorsTheme.colorWhite,
  className,
}: SpinnerProps) => {
  return (
    <LoaderWrapper className={className}>
      <IconSVG component={SpinnerIcon} color={color} />
    </LoaderWrapper>
  );
};

const spinAnimation = keyframes`
  100% { transform: rotate(360deg); }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    animation-name: ${spinAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export default React.memo(Spinner);
