import React, { useEffect } from 'react';

// helpers
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';
import { ChangeRequestStatuses } from 'api/profile/personalDetailsAPI';

// components
import { Divider } from '@ui';
import AuthenticationData from 'modules/Profile/PersonalDetails/AuthenticationData';
import PersonalDetailsForm from 'modules/Profile/PersonalDetails/PersonalDetailsForm';
import ChangeRequestInProcessingTooltip from 'components/Tooltips/TemplateTooltips/ChangeRequestInProcessingTooltip';

const PersonalDetailsPage = () => {
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const isChangerRequestInReview = useSelector<StateModel, boolean>(
    (state) =>
      state.personalDetails.changeRequest?.status ===
      ChangeRequestStatuses.InReview,
  );

  useEffect(() => {
    const description = isChangerRequestInReview ? (
      <ChangeRequestInProcessingTooltip />
    ) : null;
    setPageLayoutOptions((prev) => ({ ...prev, description }));
    return () => clearContext();
  }, [isChangerRequestInReview, setPageLayoutOptions, clearContext]);

  return (
    <>
      <AuthenticationData />
      <Divider />
      <PersonalDetailsForm />
    </>
  );
};

export default PersonalDetailsPage;
