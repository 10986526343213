import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useNavigate } from 'react-router-dom';
import { IApplication } from 'typings/application/applications';
import { applicationsAPI } from 'api/application/applicationsAPI';
import { selectApplication } from 'redux/actions/applications';
import { FetchResponseModel } from 'typings/common';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as ApplicationsStateModel } from 'redux/reducers/applications';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import NoDataContainer from 'components/Additional/NoDataContainer';
import ClientGroupItem from './ClientGroupItem';
import { Col, Row, Message, Spin, Modal } from '@ui';

const ClientGroups = memo(() => {
  const requestContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('connections');
  const { activeApplication } = useSelector<StateModel, ApplicationsStateModel>(
    (state) => state.applications,
  );
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<
    FetchResponseModel<IApplication>
  >({ total: 0, data: [] });

  const { response, loading } = useFetch(
    () =>
      applicationsAPI.fetchApplications({ page, limit: DEFAULT_TABLE_LIMIT }),
    [],
  );

  useEffect(() => {
    if (response && page == 1) {
      setResponseData(response);
      setHasMore(true);
    }
  }, [response, page]);

  const fetchRequests = async () => {
    if (isLoading || !hasMore || !responseData.data.length) {
      return;
    }

    setLoading(true);
    const response = await applicationsAPI.fetchApplications({
      page: page + 1,
      limit: DEFAULT_TABLE_LIMIT,
    });
    if (responseData.data.length + response.data.length < response.total) {
      setResponseData((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...response.data],
      }));
      setHasMore(true);
      setPage(page + 1);
    } else {
      setResponseData((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...response.data],
      }));
      setHasMore(false);
    }
    setLoading(false);
  };

  useInfinityScroll(fetchRequests, requestContainerRef, hasMore, 'bottom');

  const switchClientGroup = useCallback(
    (application: IApplication) => {
      Modal.confirm({
        title: t('client_groups.switch_client_group_confirmation_title'),
        width: 600,
        icon: null,
        closable: true,
        maskClosable: true,
        content: t('client_groups.switch.title', {
          clientGroup: application.clientGroup.clientGroupName,
        }),
        okText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
        onOk() {
          dispatch(
            selectApplication(application, true, true, () => {
              navigate(RoutePaths.Root);
              Message.success(
                t('client_groups.switch.success_message', {
                  clientGroup: application.clientGroup.clientGroupName,
                }),
              );
            }),
          );
        },
      });
    },
    [t],
  );

  return (
    <Wrapper ref={requestContainerRef}>
      <LoadingWrapper loading={loading}>
        {!responseData.data.length ? (
          <NoDataContainer />
        ) : (
          <Row wrap={true} gutter={[16, 16]}>
            {responseData.data.map((e, i) => (
              <React.Fragment key={e._id}>
                <Col span={24}>
                  <ClientGroupItem
                    key={e._id}
                    application={e}
                    isSelected={
                      e.clientGroupId == activeApplication?.clientGroupId
                    }
                    onSwitchClick={() => switchClientGroup(e)}
                  />
                </Col>

                {responseData.data.length < responseData.total &&
                  i == responseData.data.length - 1 && (
                    <LoaderContainer>
                      <Spin size="large" spinning={isLoading} />
                    </LoaderContainer>
                  )}
              </React.Fragment>
            ))}
          </Row>
        )}
      </LoadingWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  max-height: calc(100vh - 340px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 1px;
`;

const LoaderContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ClientGroups;
