import React from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { securityAPI } from 'api/profile/securityAPI';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from 'enums/profile/auth';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import {
  setAuthenticatorEnabledStatus,
  setDefaultAuthenticator,
} from 'redux/actions/auth';

// components
import { Message } from '@ui';
import {
  default as TemplateEnableAuthenticatorModal,
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Profile/EnableAuthenticatorModal';

interface IProps {
  isVisible: boolean;
  initialFormValues: FormValuesModel;
  closeCallback: (wasEnabled?: boolean) => void;
}

const EnableAuthenticatorModal = ({
  isVisible,
  initialFormValues,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation(['profile', 'custom_errors']);

  const dispatch = useDispatch();
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );
  const { response, loading } = useFetch(
    () => (!isVisible ? null : securityAPI.fetchAuthenticatorConfig()),
    [isVisible],
  );

  const handleSubmit = async ({ code }: FormValuesModel) => {
    // This case shouldn't happend because we have a required validation for this field
    if (!code) {
      return;
    }

    const response = await securityAPI.enableAuthenticator(code);

    if (response) {
      dispatch(setAuthenticatorEnabledStatus(true));
      Message.success(
        t(
          'security.two_step_verification.authenticator.success_enable_message',
        ),
      );

      if (!profileData?.isTwoFactorFidoEnabled) {
        dispatch(
          setDefaultAuthenticator(TwoFactorVerificationTypes.Authenticator),
        );
      }

      closeCallback(true);
    }
  };

  return (
    <TemplateEnableAuthenticatorModal
      isVisible={isVisible}
      closeCallback={closeCallback}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      responseData={{ data: response, loading }}
    />
  );
};

export default EnableAuthenticatorModal;
