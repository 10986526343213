import React from 'react';

// helpers
import styled from 'styled-components';

// components
import InfoContainer from './InfoContainer';
import WebcamSnapshot from './WebcamSnapshot';
import { Col, Row } from '@ui';

const WebcamMethodForm = () => {
  const gridSizes = { xl: 12, lg: 12, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[20, 20]}>
      <StyledImageCol {...gridSizes}>
        <InfoContainer />
      </StyledImageCol>

      <Col {...gridSizes}>
        <WebcamSnapshot />
      </Col>
    </Row>
  );
};

const StyledImageCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default WebcamMethodForm;
