import React from 'react';

// components
import ManageCircleService from '../../../../../../ExternalServices/Circle/ManageCircleSection';
import { Card } from '@ui';

interface IProps {
  disabled: boolean;
}

const Services = ({ disabled }: IProps) => {
  return (
    <Card>
      <ManageCircleService disabled={disabled} />
    </Card>
  );
};

export default Services;
