import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { travelNotificationsAPI } from 'api/profile/travelNotificationsAPI';
import { TravelNotificationModel } from 'typings/profile/travelNotifications';

// components
import TravelNotificationRuleModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/UserProfile/AddTravelNotificationModal';
import { Message } from '@ui';

interface IProps {
  initialNotification: TravelNotificationModel | null;
  isVisible: boolean;
  closeCallback: (wasAdded?: boolean) => void;
}

const EditTravelNotificationRuleModal = ({
  initialNotification,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('profile');

  const initialFormValues: FormValuesModel | null = useMemo(() => {
    if (initialNotification) {
      const countries = initialNotification.countries.map((e) => ({
        ...e,
        departureDate: moment(e.departureDate),
        returnDate: moment(e.returnDate),
      }));
      return { countries };
    }
    return null;
  }, [initialNotification]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    if (initialNotification) {
      await travelNotificationsAPI.editTrip(
        { ...initialNotification, countries: values.countries },
        initialNotification.id,
      );
      Message.success(
        t(
          'travel_notifications.edit_travel_notification_modal.success_submit_message',
        ),
      );
    }
  };
  return (
    <TravelNotificationRuleModal
      title={t('travel_notifications.edit_travel_notification_modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default EditTravelNotificationRuleModal;
