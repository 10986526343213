import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ClientGroupUserChange } from '..';

// components
import DescriptionItem from 'components/Typography/DescriptionItem';
import { DescriptionSection } from '@ui';

interface IProps {
  record: ClientGroupUserChange;
}

const UpdatedPermissionsInfo = memo(({ record }: IProps) => {
  const { t } = useTranslation(['account_management', 'onboarding', 'common']);

  return (
    <DescriptionSection
      data={[
        {
          label: t(
            'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.from',
          ),
          description: (
            <>
              <DescriptionItem
                title={t(
                  'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.permissions',
                )}
                content={t(
                  `personal_info_modal.account_permission_types.${record.oldPermissions?.type}`,
                  { ns: 'onboarding' },
                )}
              />
              <br />
              <DescriptionItem
                title={t(
                  'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.is_admin',
                )}
                content={t(
                  `user_management.administration_permissions.${record.oldPermissions?.adminPermissionType}`,
                  {
                    ns: 'account_management',
                  },
                )}
              />
            </>
          ),
        },
        {
          label: t(
            'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.to',
          ),
          description: (
            <>
              <DescriptionItem
                title={t(
                  'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.permissions',
                )}
                content={t(
                  `personal_info_modal.account_permission_types.${record.newPermissions?.type}`,
                  { ns: 'onboarding' },
                )}
              />
              <br />
              <DescriptionItem
                title={t(
                  'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.is_admin',
                )}
                content={t(
                  `user_management.administration_permissions.${record.newPermissions?.adminPermissionType}`,
                  {
                    ns: 'account_management',
                  },
                )}
              />
            </>
          ),
        },
      ]}
    />
  );
});

export default UpdatedPermissionsInfo;
