import React, { useCallback, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { generateUniqId } from 'helpers/utils';
import { ContactHelpers } from 'helpers/crm/contact';
import { FormValuesModel } from '../../..';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

// constants
import { OnboardingEntryTypes } from 'enums/onboarding/crm';

// components
import { SectionIntro, Card, AddButton } from '@ui';
import FormErrorTooltip from '../../../../../../../Forms/FormErrorTooltip';
import HideIfDisabledForm from '../../../../../../../Forms/HideIfDisabledForm';
import RelationshipForm, {
  FormValuesModel as RelationshipFormValuesModel,
} from '../../../../../../../Forms/TemplateForms/CRM/RelationshipForm';

const RelationshipsSection = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const relationshipFromValue = useMemo(() => {
    let result = '';

    if (!values.isFromExistingContact) {
      result = ContactHelpers.getFormattedContactName(
        values.contact.firstName,
        values.contact.lastName,
        values.contact.middleName,
      );
    }

    return result;
  }, [values.contact, values.isFromExistingContact]);

  const selectedRelationshipTemplates = useMemo<
    Record<string, string[]>
  >(() => {
    return values.relationships.reduce<Record<string, string[]>>(
      (acc, next) => {
        if (next.relationshipTemplate.id && next.child.id) {
          if (acc[next.child.id] && acc[next.child.id].length) {
            acc[next.child.id].push(next.relationshipTemplate.id);
          } else {
            acc[next.child.id] = [next.relationshipTemplate.id];
          }
        }

        return acc;
      },
      {},
    );
  }, [values.relationships]);

  const renderRelationships = useCallback(
    (fieldArrayRenderProps: FieldArrayRenderProps) => {
      function getNewRelationship(): RelationshipFormValuesModel {
        return {
          customId: generateUniqId(),

          id: null,

          relationshipTemplate: {
            id: null,
            childRelationshipLabel: '',
          },

          parent: {
            id: null,
            type: OnboardingEntryTypes.Contact,
          },

          child: {
            id: null,
            type: null,
            label: '',
          },

          additionalFields: [],
        };
      }

      return (
        <>
          {values.relationships.map((rel, i) => (
            <StyledCard key={rel.customId}>
              <RelationshipForm
                fieldName={`relationships.${i}`}
                activeScopeId={values.relationshipScopeId}
                clientGroupEntryType={
                  values.clientGroupEntryType as OnboardingEntryTypes
                }
                removeCallback={() => fieldArrayRenderProps.remove(i)}
                relationshipIndex={i + 1}
                relationshipFromValue={relationshipFromValue}
                selectedRelationshipTemplateIds={
                  rel.child.id
                    ? selectedRelationshipTemplates[rel.child.id] || []
                    : []
                }
              />
            </StyledCard>
          ))}

          <HideIfDisabledForm hideIfSubmitting>
            <AddButton
              onClick={() => fieldArrayRenderProps.push(getNewRelationship())}
            >
              {t('node_modal.relationships.add_new_button')}
            </AddButton>
          </HideIfDisabledForm>
        </>
      );
    },
    [values, selectedRelationshipTemplates, relationshipFromValue],
  );

  return (
    <>
      <SectionIntro
        title={t('node_modal.relationships.section_title')}
        titleVariant="h4"
      />
      <StyledErrorWrapper>
        <FormErrorTooltip<FormValuesModel> errorKey="relationships" />
      </StyledErrorWrapper>
      <FieldArray name="relationships" render={renderRelationships} />
    </>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledErrorWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default RelationshipsSection;
