import React, { useMemo, useState } from 'react';

// helpersF
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import {
  onboardingAPI,
  ValidateStructureErrorModel,
} from 'api/onboarding/onboardingAPI';

// components
import StructureBlockedStatus from 'modules/Onboarding/Components/StructureBlockedStatus';
import ValidateStructureModalDialog from 'components/ModalDialogs/TemplateModalDialogs/Onboarding/ValidateStructureModalDialog';
import StructureForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/StructureForm';
import { Col, Row, Button } from '@ui';

interface IProps {
  onAction: (key: ActionKeys | null) => void;
  onboardingData: OnboardingStatusModel;
  isViewOnly: boolean;
  isEditRelationshipStructureDisabled: boolean;
}

const Structure = ({
  onAction,
  onboardingData,
  isViewOnly,
  isEditRelationshipStructureDisabled,
}: IProps) => {
  const { t } = useTranslation('common');

  const [structureStepModalData, setStructureStepModalData] = useState<null | {
    errors: ValidateStructureErrorModel[];
    submitActionType: ActionKeys | null;
  }>(null);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return { isSave: null, submitActionType: null };
  }, []);

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.submitActionType === 'submit') {
      const { errors } = await onboardingAPI.validateStructureChart(
        onboardingData.item._id,
        onboardingData.item.relationshipScopes?.active as string,
      );

      if (!errors.length) {
        await onboardingAPI.submitStructureStep(onboardingData.applicationId);
        await onAction(values.submitActionType);
      } else {
        setStructureStepModalData({
          errors,
          submitActionType: values.submitActionType,
        });
      }
    } else {
      onAction(values.submitActionType);
    }
  };

  const handleContinueClick = async () => {
    if (structureStepModalData) {
      await onboardingAPI.submitStructureStep(onboardingData.applicationId);
      await onAction(structureStepModalData.submitActionType);
    }
  };

  return (
    <>
      {isEditRelationshipStructureDisabled && <StructureBlockedStatus />}

      <StructureForm
        onboardingData={onboardingData}
        initialFormValues={initialFormValues}
        onSubmit={handleSubmit}
        disabled={isViewOnly}
      />

      {isViewOnly && isEditRelationshipStructureDisabled && (
        <Row justify="end">
          <Col>
            <Button
              size="large"
              type="bordered"
              onClick={() => onAction('save-back')}
              loading={false}
              disabled={false}
            >
              {t('back')}
            </Button>
          </Col>
        </Row>
      )}
      <ValidateStructureModalDialog
        isVisible={
          !!structureStepModalData?.errors &&
          !!structureStepModalData.errors.length
        }
        errors={structureStepModalData?.errors || []}
        closeCallback={() => setStructureStepModalData(null)}
        continueCallback={handleContinueClick}
      />
    </>
  );
};

export default Structure;
