import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';

// components
import DescriptionItem from 'components/Typography/DescriptionItem';

const AuthenticationData = memo(() => {
  const { t } = useTranslation('profile');
  const username = useSelector(
    (state: StateModel) => state.auth.profileData?.username || '',
  );

  return (
    <DescriptionItem
      title={t('personal_details.auth_data_section.username')}
      content={username}
    />
  );
});

export default AuthenticationData;
