import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { enumToMap } from 'helpers/utils';
import { DateHelpers } from 'helpers/date';
import { AccountsHelpers } from 'helpers/accounts';
import { PendingTransaction } from 'typings/accounting/transaction';
import { TransactionStatuses } from 'enums/accounts/accounts';
import { FilterDropdownProps } from 'antd/lib/table/interface';

// components
import {
  Checkbox,
  InputAmount,
  Table,
  RequiredPropsForTableModel,
  SortProps,
  TableColumnModel,
  Col,
  Row,
  Link,
  RangePicker,
} from '@ui';
import DocumentLink from '../../../../Typography/DocumentLink';
import CurrencySelect from 'components/Forms/FormComponents/SelectInputs/Accounting/CurrencySelect';
import TransactionStatus from 'components/Additional/Statuses/TransactionStatus';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import TransactionTypeSelect from 'components/Forms/FormComponents/SelectInputs/Transfers/TransactionTypeSelect';
import TransactionBankAccountAutocomplete from 'components/Forms/FormComponents/Autocompletes/Accounting/TransactionBankAccountAutocomplete';
import TransactionAccountNamesAutocomplete from 'components/Forms/FormComponents/Autocompletes/Accounting/TransactionAccountNamesAutocomplete';
import TransactionAccountNumbersAutocomplete from 'components/Forms/FormComponents/Autocompletes/Accounting/TransactionAccountNumbersAutocomplete';

interface IProps extends RequiredPropsForTableModel<PendingTransaction> {
  onActionsClick: (key: string, id: number) => void;
  onSelect?: (
    records: { record: PendingTransaction; checked: boolean }[],
  ) => void;
  isSelectAllChecked?: boolean;
  selectedTransactions?: Record<string, PendingTransaction>;
  filters: Record<string, any>;
  onSortChange?: (sorter: SortProps) => void;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

const PendingTransactionsTable = ({
  onActionsClick,
  onSelect,
  filters,
  selectedTransactions,
  isSelectAllChecked,
  ...rest
}: IProps) => {
  const { t } = useTranslation(['accounts', 'common']);

  const renderDescription = ({ id, description }: PendingTransaction) => {
    if (!description) return;

    return (
      <Link onClick={() => onActionsClick('view', id)}>{description}</Link>
    );
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    function handleAmountChange(
      field: 'from' | 'to',
      value: number,
      filterProps: FilterDropdownProps,
      currentValue: number[] | undefined,
    ) {
      const newValue = currentValue
        ? [...currentValue]
        : [undefined, undefined];

      switch (field) {
        case 'from':
          {
            newValue[0] = value;
            if (typeof newValue[1] !== 'undefined' && value > newValue[1]) {
              newValue[1] = value;
            }
          }
          break;

        case 'to':
          {
            newValue[1] = value;
            if (typeof newValue[0] !== 'undefined' && value < newValue[0]) {
              newValue[0] = value;
            }
          }
          break;
      }

      filterProps.setSelectedKeys(newValue as any);
    }

    const result: TableColumnModel[] = [
      {
        title: t('pending_transactions.table.requested_date'),
        key: 'requested_date',
        render: (record: PendingTransaction) =>
          DateHelpers.formatTimestampDateToString(record.creationDate),
        // --- filter ---
        filteredValue: filters.requestedDate,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledRangePicker
                allowClear
                size="large"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.value_date'),
        key: 'value_date',
        render: (record: PendingTransaction) =>
          DateHelpers.formatTimestampDateToString(record.valueDate),
        // --- filter ---
        filteredValue: filters.valueDate,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledRangePicker
                allowClear
                size="large"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.transaction_status'),
        key: 'status',
        render: (record: PendingTransaction) => (
          <TransactionStatus
            transaction={{
              status: record.statusId,
              expiresAt: record.workflowExpirationDate || undefined,
            }}
          />
        ),
        filteredValue: filters.status,
        filterSearch: true,
        filters: Array.from(enumToMap(TransactionStatuses)).map((e) => ({
          text: t(`pending_transactions.table.filter_bar.statuses.${e[1]}`),
          value: e[1],
        })),
      },
      {
        title: t('pending_transactions.table.beneficiary_account_name'),
        key: 'beneficiary_account_name',
        render: (transaction: PendingTransaction) =>
          transaction.beneficiaryAccountName,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        filteredValue: filters.beneficiaryAccountName,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledTransactionAccountNamesAutocomplete
                mode="multiple"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.beneficiary_account_number'),
        key: 'beneficiary_account_number',
        render: (transaction: PendingTransaction) =>
          transaction.beneficiaryAccountNumber,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        filteredValue: filters.beneficiaryAccountNumber,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledTransactionAccountNumbersAutocomplete
                mode="multiple"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.from_account'),
        key: 'from_account',
        width: 400,
        render: (record: PendingTransaction) =>
          AccountsHelpers.generateAccountFullName(
            record.fromAccountName,
            record.fromAccountNumber,
            record.fromCurrencyISOCode,
          ),
        mobileCardProps: { hideFromMainSection: true },
        filteredValue: filters.fromAccount,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledTransactionBankAccountAutocomplete
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.description'),
        key: 'description',
        width: 350,
        render: renderDescription,
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('pending_transactions.table.documents'),
        key: 'documents',
        render: (record: PendingTransaction) =>
          record.relatedDocuments.map(({ dmsId, linkToDownload, name }) => (
            <div key={dmsId}>
              <DocumentLink
                text={name}
                fileId={linkToDownload}
                documentId={dmsId}
              />
            </div>
          )),
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('pending_transactions.table.transaction_type'),
        key: 'type',
        mobileCardProps: { isCardTitle: true, hide: true },
        render: (record: PendingTransaction) => record.sourceTypeName,
        // --- filter ---
        filteredValue: filters.transactionType,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledTransactionTypeSelect
                mode="multiple"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.currency'),
        key: 'currency',
        render: (record: PendingTransaction) => record.currencyIsoCode,
        // --- filter ---
        filteredValue: filters.currency,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledCurrencySelect
                mode="multiple"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.amount'),
        key: 'amount',
        render: (record: PendingTransaction) =>
          record.amount
            ? AccountsHelpers.convertAmountBigIntToLocaleString(record.amount)
            : '',
        // --- filter ---
        filteredValue: filters.amount,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          const value = filterProps.selectedKeys as any;
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <Row wrap={false} justify="space-between" align="middle">
                <Col flex="48%">
                  <StyledInputNumber
                    size="large"
                    placeholder={t('enter_from', { ns: 'common' })}
                    value={value ? value[0] : undefined}
                    onChange={(newValue) =>
                      handleAmountChange(
                        'from',
                        newValue as number,
                        filterProps,
                        value,
                      )
                    }
                  />
                </Col>
                <Col flex="48%">
                  <StyledInputNumber
                    size="large"
                    placeholder={t('enter_to', { ns: 'common' })}
                    value={value ? value[1] : undefined}
                    onChange={(newValue) =>
                      handleAmountChange(
                        'to',
                        newValue as number,
                        filterProps,
                        value,
                      )
                    }
                  />
                </Col>
              </Row>
            </FilterDropdownOverlay>
          );
        },
      },
    ];

    if (onSelect) {
      result.unshift({
        title: (
          <Checkbox
            value={isSelectAllChecked}
            onChange={() => {
              const transactionsWithPendingReviewStatus = rest.data.filter(
                (e) => e.canProcess,
              );

              if (transactionsWithPendingReviewStatus.length) {
                const formattedTransactions =
                  transactionsWithPendingReviewStatus.map((transaction) => ({
                    record: transaction,
                    checked: !isSelectAllChecked,
                  }));

                onSelect(formattedTransactions);
              }
            }}
          />
        ),
        key: 'select_checkbox',
        render: (record: PendingTransaction) => (
          <Checkbox
            disabled={!record.canProcess}
            value={
              selectedTransactions ? !!selectedTransactions[record.id] : false
            }
            onChange={(value) =>
              onSelect([{ record, checked: !!value.target.checked }])
            }
          />
        ),
      });
    }

    return result;
  }, [
    onSelect,
    selectedTransactions,
    onActionsClick,
    isSelectAllChecked,
    filters,
  ]);

  return <Table columns={tableColumns} {...rest} />;
};

const StyledTransactionBankAccountAutocomplete = styled(
  TransactionBankAccountAutocomplete,
)`
  width: 100%;
`;

const StyledTransactionAccountNamesAutocomplete = styled(
  TransactionAccountNamesAutocomplete,
)`
  width: 100%;
`;

const StyledTransactionAccountNumbersAutocomplete = styled(
  TransactionAccountNumbersAutocomplete,
)`
  width: 100%;
`;

const StyledCurrencySelect = styled(CurrencySelect)`
  width: 100%;
`;

const StyledTransactionTypeSelect = styled(TransactionTypeSelect)`
  width: 100%;
`;

const StyledRangePicker = styled(RangePicker)`
  width: 100%;
`;

const StyledInputNumber = styled(InputAmount)`
  width: 100%;
`;

export default PendingTransactionsTable;
