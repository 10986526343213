import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Button, ButtonProps } from '@ui';

interface IProps extends ButtonProps {
  children: React.ReactNode;
}

const SuccessButton = ({ children, ...rest }: IProps) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};

const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.colorPrimaryD2};
  background-color: ${({ theme }) => theme.colorPrimaryD2};
  color: ${({ theme }) => theme.colorLightL1};

  &:hover {
    color: ${({ theme }) => theme.colorLightL1};
    border-color: ${({ theme }) => theme.colorPrimaryD1};
    background-color: ${({ theme }) => theme.colorPrimaryD1};
  }
`;

export default SuccessButton;
