import React, { memo, useEffect, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from 'components/Forms/TemplateForms/Onboarding/VerifyEmailForm';
import { VerifyEmailValidationSchema } from 'validations/profile/auth';

// components
import ResendOtp from 'components/Additional/ResendOtp';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import VerifyEmailForm from 'components/Forms/TemplateForms/Onboarding/VerifyEmailForm/InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  onResendOTP: () => Promise<void>;
}

export type VerifyEmailFormValuesModel = FormValuesModel;

const VerifyEmailModal = memo(
  ({
    isVisible,
    initialValues,
    onSubmit,
    onResendOTP,
    onSubmitError,
    closeCallback,
  }: IProps) => {
    const { t } = useTranslation('profile');

    const [loading, setLoading] = useState(false);

    const handleResendOTP = async () => {
      setLoading(true);
      await onResendOTP();
      setLoading(false);
    };

    useEffect(() => {
      if (isVisible) {
        handleResendOTP();
      }
    }, [isVisible]);

    return (
      <Form<FormValuesModel>
        initialValues={initialValues}
        validationSchema={VerifyEmailValidationSchema}
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        renderForm={
          <ModalDialog
            width={500}
            shouldResetFormAfterSubmit={false}
            title={t('personal_details.verify_email.modal_title')}
            isVisible={isVisible}
            hideFooterButtons
            closeCallback={closeCallback}
          >
            <LoadingWrapper loading={loading}>
              <VerifyEmailForm />
              <ResendOtp
                successMessage={t(
                  'personal_details.verify_email.resend_success_message',
                )}
                resendOtpCallback={onResendOTP}
              />
            </LoadingWrapper>
          </ModalDialog>
        }
      />
    );
  },
);

export default VerifyEmailModal;
