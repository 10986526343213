import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ValidateStructureErrorModel } from 'api/onboarding/onboardingAPI';

// components
import { Text, IconSVG, Card } from '@ui';
import DivAlignCenter from '../../../../../Additional/DivAlignCenter';
import { ReactComponent as WarningErrorIcon } from 'resources/icons/remix-icons/error-warning-line.svg';
import { ReactComponent as CriticalErrorIcon } from 'resources/icons/remix-icons/close-circle-line.svg';

interface IProps {
  error: ValidateStructureErrorModel;
}

// TODO: make the content better and more understandable, currently just showing the basic error message
const WarningCard = ({ error }: IProps) => {
  const { t } = useTranslation('onboarding');

  const ErrorLevel = useMemo(() => {
    switch (error.type) {
      case 'critical':
        return (
          <DivAlignCenter>
            <StyledIconSVG
              component={CriticalErrorIcon}
              color={colorsTheme.colorError}
            />
            <Text
              variant="body1"
              weight="semi-bold"
              color={colorsTheme.colorError}
            >
              {t('structure.validate_structure_modal.error_types.critical')}
            </Text>
          </DivAlignCenter>
        );

      case 'warning':
        return (
          <DivAlignCenter>
            <StyledIconSVG
              component={WarningErrorIcon}
              color={colorsTheme.colorWarning}
            />
            <Text
              variant="body1"
              weight="semi-bold"
              color={colorsTheme.colorWarning}
            >
              {t('structure.validate_structure_modal.error_types.warning')}
            </Text>
          </DivAlignCenter>
        );
    }
  }, [error]);

  const ErrorDescription = useMemo(() => {
    const item = error.item as IEntity;
    const entityName = item.names[0].name;
    const keyRelation = error.keyRelationshipTemplate.keyRelations.find(
      (keyRelation) =>
        keyRelation.relationshipTemplateId === error.relationshipTemplateId,
    );

    switch (error.code) {
      case '1016001': {
        return (
          <Text variant="body1">
            {t(`structure.validate_structure_modal.error_codes.${error.code}`, {
              countOfRelationships: 1,
              parentRelationshipName:
                keyRelation?.relationshipTemplate.parentName,
              entityName,
            })}
          </Text>
        );
      }

      case '1016002':
        return (
          <Text variant="body1">
            {t(`structure.validate_structure_modal.error_codes.${error.code}`, {
              total: error.details?.total,
              equalTo: error.details?.min,
              entityName,
            })}
          </Text>
        );

      case '1016003':
        return (
          <Text variant="body1">
            {t(`structure.validate_structure_modal.error_codes.${error.code}`, {
              total: error.details?.actualPercentage,
              parentRelationshipName:
                keyRelation?.relationshipTemplate.parentName,
              entityName,
              min: error.details?.minPercentage,
            })}
          </Text>
        );

      case '1016004':
        return (
          <Text variant="body1">
            {t(`structure.validate_structure_modal.error_codes.${error.code}`, {
              countOfRelationships: error.details?.min,
              parentRelationshipName:
                keyRelation?.relationshipTemplate.parentName,
              entityName,
            })}
          </Text>
        );

      case '1016006':
        return (
          <Text variant="body1">
            {t(`structure.validate_structure_modal.error_codes.${error.code}`, {
              total: error.details?.actualPercentage,
              parentRelationshipName:
                keyRelation?.relationshipTemplate.parentName,
              entityName,
            })}
          </Text>
        );
    }
  }, [error]);

  return (
    <Card>
      {ErrorLevel}
      {ErrorDescription}
    </Card>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default WarningCard;
