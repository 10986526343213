import React, { useState } from 'react';

// helpers
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';

// components
import ApprovalGroupsTable from './ApprovalGroupsTable';
import AddApprovalGroupModal from './AddApprovalGroupModal';
import EditApprovalGroupModal from './EditApprovalGroupModal';
import { PageButton, Col, Row, Text } from '@ui';

const ApprovalGroupsTab = () => {
  const { t } = useTranslation('account_management');
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [isAddApprovalGroupModal, setIsAddApprovalGroupModal] = useState(false);

  const [hasCreateAccess, hasEditAccess] = useUserAccess([
    'AccountManagement_Rules_ApprovalGroups_Create',
    'AccountManagement_Rules_ApprovalGroups_Edit',
  ]);

  const handleAddGroupClick = () => {
    setIsAddApprovalGroupModal(true);
  };

  const handleModalClose = (wasCreated?: boolean) => {
    wasCreated && updateTable({});
    setIsAddApprovalGroupModal(false);
  };

  const handleCloseEditModal = (shouldUpdate?: boolean) => {
    shouldUpdate && updateTable({});
    setSelectedGroup(null);
  };

  const handleOnActionsClick = (key: string, groupId: string) => {
    switch (key) {
      case 'edit':
        setSelectedGroup(groupId);
        break;
    }
  };

  return (
    <>
      <Text variant="body1" gutterBottom>
        {t('account_management_rules.approval_groups_description')}
      </Text>

      {hasCreateAccess && (
        <Row justify="end">
          <Col flex="150px">
            <PageButton fullWidth onClick={handleAddGroupClick}>
              {t('approval_groups.add_group_button')}
            </PageButton>
          </Col>
        </Row>
      )}

      <ApprovalGroupsTable
        onActionsClick={handleOnActionsClick}
        updateTableTrigger={updateTableTrigger}
        hasPermissionForEditing={hasEditAccess}
      />

      {hasCreateAccess && (
        <AddApprovalGroupModal
          isVisible={isAddApprovalGroupModal}
          closeCallback={handleModalClose}
        />
      )}

      {hasEditAccess && (
        <EditApprovalGroupModal
          isVisible={!!selectedGroup}
          closeCallback={handleCloseEditModal}
          groupId={selectedGroup as string}
        />
      )}
    </>
  );
};

export default ApprovalGroupsTab;
