import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useField } from 'formik';
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import TrueFalseSelect from '../../../FormComponents/SelectInputs/TrueFalseSelect';
import { Text, FormField, Checkbox, Col, Row, FormInput } from '@ui';

export interface FormValuesModel {
  isInheritedFromRelationships: boolean;
  isSelfEmployed: boolean | null;
  employerName: string;
  occupation: string;
  natureOfBusiness: string;
  isBusinessUnderOwnName: boolean | null;
  isBasedOnExistingRelationship?: boolean;
}

interface IProps {
  disabled: boolean;
  fieldName: string;
}

const EmploymentHistoryShortForm = memo(({ disabled, fieldName }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [field] = useField<FormValuesModel>(fieldName);
  const isSelfEmployed = !!field.value.isSelfEmployed;
  const isSelfEmployedAnswered =
    typeof field.value.isSelfEmployed === 'boolean';

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('source_of_wealth.form_fields.is_self_employed')}
            name={`${fieldName}.isSelfEmployed`}
            component={TrueFalseSelect}
            disabled={disabled || field.value.isBasedOnExistingRelationship}
          />
        </Col>
      </Row>

      {isSelfEmployedAnswered &&
        (isSelfEmployed ? (
          <>
            <Row gutter={[16, 16]} align="middle">
              <Col {...TWO_COL_GRID_SIZES}>
                <FormField
                  name={`${fieldName}.employerName`}
                  label={t('source_of_wealth.form_fields.company_name')}
                  component={FormInput}
                  disabled={disabled || !!field.value.isBusinessUnderOwnName}
                />
              </Col>
              <Col {...TWO_COL_GRID_SIZES}>
                <CheckboxFieldWrapper>
                  <FormField
                    shouldShowErrorMessage={false}
                    name={`${fieldName}.isBusinessUnderOwnName`}
                    disabled={disabled}
                    component={StyledCheckbox}
                  />
                  <Text variant="body1">
                    {t(
                      'source_of_wealth.form_fields.is_business_under_own_name',
                    )}
                  </Text>
                </CheckboxFieldWrapper>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col {...TWO_COL_GRID_SIZES}>
                <FormField
                  name={`${fieldName}.natureOfBusiness`}
                  label={t('source_of_wealth.form_fields.nature_of_business')}
                  component={FormInput}
                  disabled={disabled}
                />
              </Col>

              <Col {...TWO_COL_GRID_SIZES}>
                <FormField
                  name={`${fieldName}.occupation`}
                  label={t('source_of_wealth.form_fields.occupation')}
                  component={FormInput}
                  disabled={disabled || !!field.value.isBusinessUnderOwnName}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row gutter={[16, 16]}>
            <Col {...TWO_COL_GRID_SIZES}>
              <FormField
                name={`${fieldName}.employerName`}
                label={t('source_of_wealth.form_fields.employer')}
                component={FormInput}
                disabled={disabled || field.value.isBasedOnExistingRelationship}
              />
            </Col>
            <Col {...TWO_COL_GRID_SIZES}>
              <FormField
                name={`${fieldName}.occupation`}
                label={t('source_of_wealth.form_fields.occupation')}
                component={FormInput}
                disabled={disabled || field.value.isBasedOnExistingRelationship}
              />
            </Col>
          </Row>
        ))}
    </>
  );
});

const StyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

const CheckboxFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme }) => theme.marginSm};
`;

export default EmploymentHistoryShortForm;
