import React, { Ref } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { Moment } from 'moment';
import { FormikHelpers, FormikProps } from 'formik';

// constants
import { GenderTypes } from 'enums/onboarding/crm';
import { FormValuesModel as PhoneNumberFormValuesModel } from '../../CRM/PhoneNumberForm';
import { FormValuesModel as EmailAddressFormValuesModel } from '../../CRM/EmailAddressForm';
import { ApplicantInformationIndividualValidationSchema } from 'validations/onboarding-new/applicant-information';

// components
import InnerForm from './InnerForm';
import { SectionIntro } from '@ui';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';
import { Form } from '@ui';

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Moment | null;
  countryOfBirth: string;
  gender: GenderTypes | null;
  emails: EmailAddressFormValuesModel[];
  phoneNumbers: PhoneNumberFormValuesModel[];
  socialMedia: {
    twitterLink: string;
    facebookLink: string;
    linkedInLink: string;
  };
  isContactBaseInfoFormDisabled?: boolean | null;
  canVerifyPrimaryEmail?: boolean;
  canVerifyPrimaryPhoneNumber?: boolean;
  displayTooltipsForPhoneAndEmailSections?: boolean;
}

type SubmitType = (
  values: FormValuesModel,
  helpers: FormikHelpers<FormValuesModel>,
) => Promise<void>;

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel;
  onSubmit: SubmitType | null;
  disabled?: boolean;
  showOnlySaveButton?: boolean;
  title?: string;
}

const ApplicantInformationForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
  title,
}: IProps) => {
  const { t } = useTranslation(['onboarding', 'common']);

  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      onSubmit={onSubmit ? onSubmit : console.log}
      initialValues={initialFormValues}
      validationSchema={ApplicantInformationIndividualValidationSchema}
      confirmExitWithoutSaving
      disabled={disabled}
      renderForm={({ values }) => (
        <>
          <SectionIntro
            title={title || t('applicant_information.general.title')}
            description={
              !values.isContactBaseInfoFormDisabled
                ? t('applicant_information.description_contact')
                : ''
            }
          />
          <InnerForm />
          <HideIfDisabledForm>
            <SubmitButtons
              showBackButton={false}
              showOnlySaveButton={showOnlySaveButton}
            />
          </HideIfDisabledForm>
        </>
      )}
    />
  );
};

export default ApplicantInformationForm;
