import React from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { InputProps } from './types';
import { Input as AntDInput } from 'antd';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, onBlur, disabled, name, size, ...rest }, ref) => {
    const { t } = useTranslation('form');

    return (
      <AntDInput
        ref={ref as any}
        size={size}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        placeholder={t('enter_value')}
        {...rest}
      />
    );
  },
);

export default React.memo(Input);
