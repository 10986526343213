import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';

// components
import { FormField, Link, FormInputPassword, Col, Row } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('auth');

  return (
    <FormField
      name="password"
      component={FormInputPassword}
      label={
        <StyledRow justify="space-between">
          <Col>{t('signin.steps.signin.form_fields.password')}</Col>
          <Col>
            <Link to={RoutePaths.Auth_ResetPassword}>
              {t('signin.reset_password')}
            </Link>
          </Col>
        </StyledRow>
      }
    />
  );
};

const StyledRow = styled(Row)`
  width: 100%;
`;

export default InnerForm;
