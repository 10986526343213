import React, { memo, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService from 'services/error-handler/service';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IApprovalMatrixRecord } from 'typings/accounting/transaction';
import { WireTransactionSenderData } from 'components/Additional/DetailsSections/Accounting/WireTransaction/Sender';
import { WireTransactionRecipientData } from 'components/Additional/DetailsSections/Accounting/WireTransaction/Recipient';
import { InternalTransactionSenderData } from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Sender';
import { InternalTransactionRecipientData } from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Recipient';
import {
  TransactionSourceTypes,
  TransferApprovalStatuses,
} from 'enums/accounting/transfers';

// components
import InnerContent from './InnerContent';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import DivAlignCenter from '../../../../Additional/DivAlignCenter';
import WarningIconWithText from '../../../../Additional/WarningIconWithText';
import {
  ModalDialog,
  RequiredPropsForModalDialogModel,
  Col,
  Button,
  Message,
  Modal,
} from '@ui';

export type TransactionTypes =
  | 'internal-sender'
  | 'internal-recipient'
  | 'wire-sender'
  | 'wire-recipient';

export type TransactionData = {
  transactionId: number | null;
  journalEntryId: number | null;

  type: TransactionTypes | null;
  sourceType: TransactionSourceTypes;
  requestorId: string;
  transactionStatus: TransferApprovalStatuses;
  approvalWorkflowId: string | null;
  transaction:
    | InternalTransactionSenderData
    | InternalTransactionRecipientData
    | WireTransactionSenderData
    | WireTransactionRecipientData
    | null;
  transactionApprovals: IApprovalMatrixRecord[][] | null;
};

interface IProps extends RequiredPropsForModalDialogModel {
  initialData: TransactionData | null;
  isTFAEnabled: boolean;
  onCancelTransaction: () => void;
  onReview?: () => void;
}

const TransactionDetailsModalDialog = memo(
  ({
    isVisible,
    closeCallback,
    onReview,
    initialData,
    onCancelTransaction,
    isTFAEnabled,
  }: IProps) => {
    const { t } = useTranslation(['accounts', 'common']);
    const navigate = useNavigate();
    const currentUserId = useSelector<StateModel, string>(
      (state) => state.auth.profileData?._id as string,
    );
    const [isDocumentAdded, setDocumentAdded] = useState(false);
    const [hasInternalTransferCreateAccess, hasWireTransferCreateAccess] =
      useUserAccess([
        'Transfers_InternalTransfer_Create',
        'Transfers_WireTransfer_Create',
      ]);

    useEffect(() => {
      if (isDocumentAdded && !isVisible) {
        setDocumentAdded(false);
      }
    }, [isVisible]);

    const handleTransferAgainClick = () => {
      if (!initialData) {
        return;
      }

      const navigateToPath =
        initialData.sourceType === TransactionSourceTypes.InternalTransfer
          ? RoutePaths.Transfers_InternalTransfer
          : RoutePaths.Transfers_WireTransfer;

      navigate({
        pathname: navigateToPath,
        search: initialData.transactionId
          ? `?transactionId=${initialData.transactionId}`
          : `?journalEntryId=${initialData.journalEntryId}`,
      });
    };

    const isTransferAgainButton = useMemo(() => {
      if (!initialData) {
        return false;
      }

      let isApproved = false;
      let hasPermissionAccess = false;
      let hasAccessToBeneficiaryData = false;

      if (initialData.transaction) {
        isApproved =
          initialData.transactionStatus === TransferApprovalStatuses.Completed;
      }

      if (initialData?.sourceType === TransactionSourceTypes.InternalTransfer) {
        hasPermissionAccess = hasInternalTransferCreateAccess;

        if (initialData.type == 'internal-sender') {
          const internalSenderTransaction =
            initialData.transaction as InternalTransactionSenderData;
          hasAccessToBeneficiaryData = internalSenderTransaction.template
            ? internalSenderTransaction.template.canAccessTemplate
            : true;
        }
      } else {
        hasPermissionAccess = hasWireTransferCreateAccess;

        if (initialData.type == 'wire-sender') {
          const wireSenderTransaction =
            initialData.transaction as WireTransactionSenderData;
          hasAccessToBeneficiaryData =
            !!wireSenderTransaction.template?.canAccessTemplate;
        }
      }

      return isApproved && hasPermissionAccess && hasAccessToBeneficiaryData;
    }, [
      initialData,
      hasInternalTransferCreateAccess,
      hasWireTransferCreateAccess,
    ]);

    const buttonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

    const canCancelTransaction = useMemo(() => {
      if (!initialData) {
        return null;
      }

      return (
        currentUserId === initialData.requestorId &&
        [
          TransferApprovalStatuses.PendingApprovals,
          TransferApprovalStatuses.Scheduled,
        ].includes(initialData.transactionStatus)
      );
    }, [currentUserId, initialData]);

    const handleCancelTransaction = () => {
      Modal.confirm({
        content: t(
          'transaction_details_modal.transaction_details.cancel_transaction.description',
        ),
        okText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
        async onOk() {
          try {
            await onCancelTransaction();
            Message.success(
              t(
                'transaction_details_modal.transaction_details.cancel_transaction.success_cancel',
              ),
            );
            closeCallback(true);
          } catch (err: any) {
            ErrorHandlerService.handleError(err);
          }
        },
      });
    };

    const modalTitle = useMemo(() => {
      if (!isTFAEnabled) {
        return (
          <DivAlignCenter>
            <StyledTitle>
              {t('transaction_details_modal.transaction_details.title')}
            </StyledTitle>
            {onReview && (
              <StyledWarningInfoNot2FA
                text={t(
                  'transaction_details_modal.transaction_details.warning_not_2fa',
                )}
              />
            )}
          </DivAlignCenter>
        );
      }

      return t('transaction_details_modal.transaction_details.title');
    }, [isTFAEnabled, onReview]);

    return (
      <ModalDialog
        width="80%"
        title={modalTitle}
        isVisible={isVisible}
        closeCallback={() => closeCallback(isDocumentAdded)}
        extendedFooterButtonsPosition="before"
        extendedFooterButtons={
          canCancelTransaction && (
            <Col {...buttonGridSizes}>
              <StyledButton
                danger
                size="large"
                disabled={!isTFAEnabled}
                onClick={handleCancelTransaction}
              >
                {t(
                  'transaction_details_modal.transaction_details.cancel_transaction.text',
                )}
              </StyledButton>
            </Col>
          )
        }
        submitButtonProps={{
          hidden: !isTransferAgainButton,
          text: t('transaction_details_modal.transfer_again_button'),
          disabled:
            !(initialData?.transaction && initialData?.transactionApprovals) ||
            !isTFAEnabled,
          onClick: handleTransferAgainClick,
        }}
      >
        <LoadingWrapper loading={!initialData}>
          {initialData && (
            <InnerContent
              initialData={initialData}
              onUploadDocuments={() => setDocumentAdded(true)}
              onReview={onReview}
            />
          )}
        </LoadingWrapper>
      </ModalDialog>
    );
  },
);

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default TransactionDetailsModalDialog;
