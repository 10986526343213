import React from 'react';
import { Steps as AntdSteps } from 'antd';
import { StepsProps } from './types';

/**
 * The `Steps` component displays a progression through a series of steps.
 */
const Steps: React.FC<StepsProps> = ({ className, children, ...rest }) => {
  return (
    <AntdSteps className={className} {...rest}>
      {children}
    </AntdSteps>
  );
};

export default Steps;
