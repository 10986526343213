import React from 'react';

// helpers
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { IKeyRelationshipTemplate } from 'typings/application/key-relationship-templates';
import { keyRelationshipTemplatesAPI } from 'api/crm/keyRelationshipTemplatesAPI';
import { AutocompleteOption, AutocompleteProps } from '@ui';

// components
import { FormAutocomplete } from '@ui';

export interface EntityTypeCategoryAutocompleteProps
  extends AutocompleteProps<IKeyRelationshipTemplate> {
  isVisible?: boolean;
  clientGroupEntryType: OnboardingEntryTypes;
}

const EntityTypeCategoryAutocomplete = ({
  isVisible,
  clientGroupEntryType,
  ...rest
}: EntityTypeCategoryAutocompleteProps) => {
  const fetchTypeCategories = async (
    searchText: string,
    page: number,
    limit: number,
  ) => {
    let result: AutocompleteOption<IKeyRelationshipTemplate>[] = [];

    const { data, total } =
      await keyRelationshipTemplatesAPI.fetchKeyRelationshipTemplates({
        page,
        limit,
        search: searchText || undefined,
        isVisible,
        visibleFor:
          clientGroupEntryType === OnboardingEntryTypes.Contact
            ? OnboardingEntryTypes.Contact
            : undefined,
      });

    result = data.map((typeCategory) => ({
      id: typeCategory._id,
      label: typeCategory.name,
      model: typeCategory,
    }));

    return {
      total,
      options: result,
    };
  };

  return <FormAutocomplete {...rest} fetchData={fetchTypeCategories} />;
};

export default EntityTypeCategoryAutocomplete;
