import React from 'react';
import { Form as AntdForm } from 'antd';
import { FormItemProps } from './types';

const FormItem: React.FC<FormItemProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdForm.Item className={className} {...rest}>
      {children}
    </AntdForm.Item>
  );
};

export default FormItem;
