import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import { GraphData } from '../../../../Charts/GraphChart';
import { FormikHelpers } from 'formik';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';

// components
import { Form } from '@ui';
import InnerForm from './InnerForm';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

interface IProps {
  onboardingData: OnboardingStatusModel;
  initialFormValues: FormValuesModel;
  onSubmit?: (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
    graph: GraphData,
  ) => Promise<void>;
  disabled?: boolean;
  hideSubmitButtons?: boolean;
}

export type FormValuesModel = SubmitButtonsFormValuesModel;

const StructureForm = ({
  onboardingData,
  initialFormValues,
  onSubmit,
  hideSubmitButtons,
  disabled,
}: IProps) => {
  const [updateTreeTrigger, updateTree] = useState({});

  const { response, loading } = useFetch(
    () =>
      onboardingAPI.fetchStructureStepRelationships(
        onboardingData.item._id,
        onboardingData.item.relationshipScopes?.active as string,
        onboardingData.crmItemId,
        onboardingData.contactId,
      ),
    [onboardingData, updateTreeTrigger],
  );

  return (
    <LoadingWrapper loading={loading}>
      <Form<FormValuesModel>
        confirmExitWithoutSaving
        disabled={disabled}
        onSubmit={(values, helpers) =>
          onSubmit && onSubmit(values, helpers, response as GraphData)
        }
        initialValues={initialFormValues}
        renderForm={
          <>
            {response && (
              <InnerForm
                onboardingData={onboardingData}
                tree={response}
                onTreeUpdate={() => updateTree({})}
              />
            )}

            {!hideSubmitButtons && (
              <HideIfDisabledForm>
                <SubmitButtons />
              </HideIfDisabledForm>
            )}
          </>
        }
      />
    </LoadingWrapper>
  );
};

export default StructureForm;
