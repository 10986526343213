import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Text, Button, IconSVG } from '@ui';
import { ReactComponent as KeyIcon } from 'resources/icons/remix-icons/key-line.svg';

interface IProps {
  onTryClick: () => void;
}

const ContentWithUseKeyButton = ({ onTryClick }: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <>
      <StyledIconSVG
        component={KeyIcon}
        size="large"
        color={colorsTheme.colorWhite}
      />

      <StyledText variant="body1" gutterBottom>
        {t(
          'security.two_step_verification.security_key.register_new_key.register_key_step.use_security_key_button',
        )}
      </StyledText>

      <Button fullWidth size="large" onClick={onTryClick}>
        {t(
          'security.two_step_verification.security_key.register_new_key.register_key_step.use_security_key_description',
        )}
      </Button>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

export default ContentWithUseKeyButton;
