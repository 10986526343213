import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Text } from '@ui';

interface IProps {
  code: string;
}

const VerificationCode = ({ code }: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <ContentWrapper>
      <Text variant="body1" gutterBottom>
        {t('security.identity_verification.your_verification_code')}
      </Text>
      <StyledText variant="h5" color={colorsTheme.colorWhite}>
        {code}
      </StyledText>
    </ContentWrapper>
  );
};

const StyledText = styled(Text)`
  padding: ${({ theme }) => theme.paddingXl};
  letter-spacing: 10px;
  background-color: ${({ theme }) => theme.colorDark};
  border-radius: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default VerificationCode;
