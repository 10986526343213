import React, { useMemo } from 'react';

// helpers
import { RequiredPropsForModalDialogModel } from '@ui';

// components
import SubmitTransactionsBulkModal, {
  FormValuesModel,
} from '../../../../../components/ModalDialogs/TemplateModalDialogs/Transfers/SubmitTransactionsBulkModal';

interface IProps extends RequiredPropsForModalDialogModel {
  transactions: FormValuesModel['transactions'] | null;
  closeCallback: (wasSubmitted?: boolean, values?: FormValuesModel) => void;
}

const SubmitTransactionsModal = ({
  isVisible,
  closeCallback,
  transactions,
}: IProps) => {
  const initialValues = useMemo<FormValuesModel | null>(() => {
    if (!transactions) {
      return null;
    }

    return {
      step: 'confirm-action',
      completed: 0,
      result: null,
      transactions: transactions.map((e) => ({
        ...e,
        toInitialOption:
          e.toFieldLabel && e.to
            ? {
                id: e.to,
                label: e.toFieldLabel,
              }
            : undefined,
      })),
    };
  }, [transactions]);

  return (
    <SubmitTransactionsBulkModal
      onSubmit={console.log}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialValues}
    />
  );
};

export default SubmitTransactionsModal;
