import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from 'enums/profile/auth';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import {
  setDefaultAuthenticator,
  setFidoAuthenticatorEnabledStatus,
} from 'redux/actions/auth';

// components
import { Button, Message } from '@ui';
import RegisterSecurityKeyModal, {
  FormValuesModel,
  RegisterSecurityKeySteps,
} from 'components/ModalDialogs/TemplateModalDialogs/UserProfile/RegisterSecurityKeyModal';

const EnableSecurityKey = () => {
  const { t } = useTranslation(['profile', 'custom_errors', 'common']);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      name: '',
      currentStep: RegisterSecurityKeySteps.Initial,
      securityKeyCredInfo: null,
    };
  }, []);

  const handleModalClose = (wasRegistered?: boolean) => {
    if (wasRegistered) {
      dispatch(setFidoAuthenticatorEnabledStatus(true));

      if (!profileData?.isTwoFactorAuthenticationEnabled) {
        dispatch(setDefaultAuthenticator(TwoFactorVerificationTypes.Fido));
      }
    }

    setIsModalVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.securityKeyCredInfo) {
      await userAPI.registerSecurityKey({
        ...values.securityKeyCredInfo,
        name: values.name,
      });
      Message.success(
        t(
          'security.two_step_verification.security_key.register_new_key.success_submit_message',
        ),
      );
      handleModalClose(true);
    }
  };

  return (
    <>
      <StyledButton size="large" onClick={() => setIsModalVisible(true)}>
        {t('security.two_step_verification.enable_button')}
      </StyledButton>

      <RegisterSecurityKeyModal
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        title={t(
          'security.two_step_verification.security_key.register_new_key.title',
        )}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default EnableSecurityKey;
