import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '../..';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

// constants
import { MAX_PHONE_NUMBERS_COUNT } from 'constants/onboarding';

// components
import { AddButton } from '@ui';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import { SectionIntro } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import HideIfDisabledForm from '../../../../../HideIfDisabledForm';
import PhoneNumberForm, {
  FormValuesModel as PhoneNumberFormValuesModel,
} from '../../../../CRM/PhoneNumberForm';

interface IProps {
  displayTooltipInTitle?: boolean;
}

const PhoneNumbersSection = ({ displayTooltipInTitle }: IProps) => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const renderPhoneNumbers = (arrayHelper: FieldArrayRenderProps) => {
    const newPhoneNumber: PhoneNumberFormValuesModel = {
      type: null,
      value: '',
      primary: {
        status: false,
        canEdit: true,
      },
    };

    return (
      <>
        {values.phoneNumbers.map((phone, i) => (
          <PhoneNumberForm
            key={i}
            fieldName={`phoneNumbers.${i}`}
            canVerify={
              phone.primary.status &&
              values.canVerifyPrimaryPhoneNumber &&
              !phone.isVerified
            }
            removeCallback={() => arrayHelper.remove(i)}
          />
        ))}

        {values.phoneNumbers.length < MAX_PHONE_NUMBERS_COUNT ? (
          <HideIfDisabledForm hideIfSubmitting>
            <AddButton onClick={() => arrayHelper.push(newPhoneNumber)}>
              {t('applicant_information.add_phone_number_button')}
            </AddButton>
          </HideIfDisabledForm>
        ) : null}
      </>
    );
  };

  return (
    <>
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={
          displayTooltipInTitle ? (
            <DivAlignCenter>
              {t('applicant_information.phone_number_section.title')}
              <StyledInfoTooltip
                tooltipContent={t(
                  'applicant_information.cannot_update_primary_phone_number_tooltip',
                )}
              />
            </DivAlignCenter>
          ) : (
            t('applicant_information.phone_number_section.title')
          )
        }
      />
      <FieldArray name="phoneNumbers" render={renderPhoneNumbers} />
    </>
  );
};

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

export default PhoneNumbersSection;
