import React from 'react';

//  helpers utils
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

//  components
import { Text, IconSVG, FormField } from '@ui';
import TransactionRulesMatrixForm from 'components/Forms/TemplateForms/Approval/TransactionRulesMatrixForm';
import OnboardingBankAccountsSelect, {
  OnboardingBankAccountsAutocompleteProps,
} from 'components/Forms/FormComponents/SelectInputs/CRM/OnboardingBankAccountsSelect';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

const InnerForm = () => {
  const { t } = useTranslation('account_management');
  const { values, errors, submitCount } = useFormikContext<FormValuesModel>();

  const renderFormError = () => {
    if (!errors || !errors.limits) return null;

    let warning = null;

    if (typeof errors.limits === 'string') {
      warning = errors.limits;
    } else {
      const hasNoUsedWarning =
        errors.limits.findIndex((e: any) => e && e.isUsed) !== -1;

      if (hasNoUsedWarning) {
        warning =
          'approval_rules.bank_accounts.approval_rule_modal.must_have_condition_warning';
      }
    }

    return (
      warning && (
        <FormErrorWrapper>
          <StyledIconSVG component={InfoIcon} color={colorsTheme.colorError} />
          <Text variant="body1" color={colorsTheme.colorError}>
            {t(warning)}
          </Text>
        </FormErrorWrapper>
      )
    );
  };

  return (
    <>
      <FormField<OnboardingBankAccountsAutocompleteProps>
        name="bankAccounts"
        label={t(
          'approval_rules.bank_accounts.approval_rule_modal.form_fields.bank_account',
        )}
        component={OnboardingBankAccountsSelect}
        additionalProps={{
          mode: 'multiple',
          applicationId: values.applicationId,
          skipAssociatedWithApprovalRule: true,
          predefinedOptions: values.initialBankAccounts,
        }}
      />

      {!!(values.bankAccounts && values.bankAccounts.length) && (
        <>
          {submitCount > 0 && renderFormError()}
          <TransactionRulesMatrixForm />
        </>
      )}
    </>
  );
};

const FormErrorWrapper = styled.div`
  padding: 20px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
