import React from 'react';

// helpers
import { useTranslation } from 'react-i18next';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import { FormField } from '@ui';
import BlockchainCurrencySelect, {
  CurrencyAutocompleteProps,
} from '../../../../../Forms/FormComponents/SelectInputs/Accounting/BlockchainCurrencySelect';
import BlockchainNetworkSelect, {
  IProps as BlockchainNetworkSelectProps,
} from '../../../../../Forms/FormComponents/SelectInputs/Transfers/BlockchainNetworkSelect';

const InnerForm = () => {
  const { t } = useTranslation('accounts');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const onNetworkSelect = () => {
    setFieldValue('currency', null);
  };

  return (
    <>
      <FormField<BlockchainNetworkSelectProps>
        label={t(
          'account_activity.send_wire_instructions.circle.blockchain_address_modal.form_fields.network',
        )}
        name="network"
        component={BlockchainNetworkSelect}
        additionalProps={{ onSelect: onNetworkSelect }}
      />

      <FormField<CurrencyAutocompleteProps>
        disabled={!values.network}
        label={t(
          'account_activity.send_wire_instructions.circle.blockchain_address_modal.form_fields.currency',
        )}
        name="currency"
        component={BlockchainCurrencySelect}
        additionalProps={{ chain: values.network as string }}
      />
    </>
  );
};

export default InnerForm;
