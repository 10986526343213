import React, { useCallback, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useUserAccess from 'hooks/useUserAccess';
import { getBadges } from 'redux/actions/app';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { accountManagementRulesAPI } from 'api/accountManagement/accountManagementRulesAPI';
import {
  ApprovalRuleTriggerTypeModel,
  IApprovalGroupShort,
} from 'typings/approvalManagement/accountManagement';

// components
import { Text, Spin } from '@ui';
import TransfersApprovalSection from './TransfersApprovalSection';

const ApprovalRulesTab = () => {
  const { t } = useTranslation('account_management');
  const [updateRulesTrigger, updateRules] = useState({});
  const dispatch = useDispatch();

  const [hasCreateAccess, hasEditAccess] = useUserAccess([
    'AccountManagement_Rules_ApprovalRules_Create',
    'AccountManagement_Rules_ApprovalRules_Edit',
  ]);

  const {
    response: approvalRuleTemplate,
    loading: approvalRuleTemplateLoader,
  } = useFetch(
    () => accountManagementRulesAPI.fetchTemplateApprovalRules(),
    [updateRulesTrigger],
  );

  const {
    response: approvalRuleTriggerTypes,
    loading: approvalRuleTriggerLoader,
  } = useFetch(
    () => accountManagementRulesAPI.fetchApprovalRuleTriggerTypes(),
    [updateRulesTrigger],
  );

  // TODO: need to predefine approval groups in autocomplete
  // need to remove this when API will be updated (API should return detailed information about each approval group)
  const { response: approvalGroups, loading: approvalGroupsLoader } = useFetch(
    () =>
      accountManagementRulesAPI.fetchApprovalGroups({ limit: 100, page: 1 }),
    [updateRulesTrigger],
  );

  const handleOnModifyRules = useCallback(() => {
    updateRules({});
    dispatch(getBadges());
  }, []);

  if (
    approvalRuleTemplateLoader ||
    approvalGroupsLoader ||
    approvalRuleTriggerLoader
  ) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  return (
    <>
      <Text variant="body1" gutterBottom>
        {t('account_management_rules.approval_rules_description')}
      </Text>

      <TransfersApprovalSection
        initialTemplate={approvalRuleTemplate}
        approvalGroups={approvalGroups?.data as IApprovalGroupShort[]}
        approvalRuleTriggerTypes={
          approvalRuleTriggerTypes as ApprovalRuleTriggerTypeModel[]
        }
        hasCreateAccess={hasCreateAccess}
        hasEditAccess={hasEditAccess && !!approvalRuleTemplate?.isActive}
        onModify={handleOnModifyRules}
      />
    </>
  );
};

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-top: ${({ theme }) => theme.paddingMd};
`;

export default ApprovalRulesTab;
