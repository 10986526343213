import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormikHelpers } from 'formik';
import { SignUpValidationSchema } from 'validations/profile/auth';

// components
import InnerForm from './InnerForm';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { Trans } from 'react-i18next';
import { Text, Form, Modal } from '@ui';

export interface FormValuesModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  step: number;
  entityDescription: string;
  passwordConfirm: string;
  accountType: string | null;
  keyRelationshipTemplateId: string | null;
}

interface IProps {
  initialFormValues: FormValuesModel;
  onSubmit: (
    values: FormValuesModel,
    formHelpers: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;
}

const SignUpForm = ({ onSubmit, initialFormValues }: IProps) => {
  const { t } = useTranslation(['server_errors', 'auth', 'common']);

  const handleConfirm = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (values.step === 2 && values.keyRelationshipTemplateId) {
      Modal.confirm({
        content: (
          <Trans
            t={t}
            i18nKey="open_an_account.confirmation_signup_message"
            components={[
              <div key="confirmation_message">
                <StyledText>
                  {t('open_an_account.confirmation_signup_message')}
                </StyledText>
              </div>,
            ]}
          />
        ),
        okText: t('confirm', { ns: 'common' }),
        cancelText: t('back', { ns: 'common' }),
        async onOk() {
          try {
            await onSubmit(values, helpers);
          } catch (err: any) {
            ErrorHandlerService.handleError(err);
          }
        },
        onCancel() {
          helpers.setFieldValue('step', values.step - 1);
        },
      });
    } else {
      await onSubmit(values, helpers);
    }
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
    switch (errorCode) {
      case '1001007': {
        formikHelpers.setFieldError('email', t('1001007'));
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form<FormValuesModel>
      onSubmit={handleConfirm}
      renderForm={<InnerForm />}
      initialValues={initialFormValues}
      onSubmitError={handleOnSubmitError}
      validationSchema={SignUpValidationSchema}
    />
  );
};

const StyledText = styled(Text)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default SignUpForm;
