import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';

// components
import { FormField, Link, Col, Row, FormInput } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('auth');

  return (
    <FormField
      name="username"
      component={FormInput}
      label={
        <StyledRow justify="space-between">
          <Col>{t('signin.steps.check_username.form_fields.username')}</Col>
          <Col>
            <Link to={RoutePaths.Auth_ForgotUsername}>
              {t('reset_username.forgot_username_btn')}
            </Link>
          </Col>
        </StyledRow>
      }
    />
  );
};

const StyledRow = styled(Row)`
  width: 100%;
`;

export default InnerForm;
