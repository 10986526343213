import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { SelectOption, SelectProps } from '@ui';
// components
import { FormSelect } from '@ui';

export type IProps = SelectProps;

const PhoneTypeSelect = (props: IProps) => {
  const { t } = useTranslation('onboarding');

  const options = useMemo<SelectOption[]>(() => {
    const items = [
      { key: 'home', name: t('applicant_information.phone_types.home') },
      { key: 'work', name: t('applicant_information.phone_types.work') },
      { key: 'mobile', name: t('applicant_information.phone_types.mobile') },
      { key: 'other', name: t('applicant_information.phone_types.other') },
    ];

    return items.map((e) => ({
      id: e.key,
      label: e.name,
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
};

export default PhoneTypeSelect;
