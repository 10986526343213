import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormField } from '@ui';
import { FormInput } from '@ui';
import { FormInputTextArea } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('accounts');

  return (
    <>
      <FormField
        name="fullName"
        component={FormInput}
        label={t(
          'account_activity.send_wire_instructions.native.form_fields.fullName',
        )}
      />
      <FormField
        name="email"
        component={FormInput}
        label={t(
          'account_activity.send_wire_instructions.native.form_fields.email',
        )}
      />
      <FormField
        name="message"
        component={FormInputTextArea}
        additionalProps={{ autoSize: { minRows: 2, maxRows: 6 } }}
        label={t(
          'account_activity.send_wire_instructions.native.form_fields.message',
        )}
      />
    </>
  );
};

export default InnerForm;
