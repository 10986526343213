import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { accountsAPI } from 'api/accounting/accountsAPI';
import { IAccountModel } from 'typings/onboarding/account';
import { BankAccountStatuses } from 'enums/accounts/accounts';
import { AccountProcessingTypes } from 'enums/accounting/transfers';

// components
import { Button } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import BankAccountIsNotApproved from 'components/Tooltips/TemplateTooltips/BankAccountIsNotApproved';
import ReceiveMoneyCircleModalDialog from 'components/ModalDialogs/TemplateModalDialogs/Accounts/ReceiveMoneyCircleModalDialog';
import ReceiveMoneyNativeModalDialog, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Accounts/ReceiveMoneyNativeModalDialog';
import { Message } from '@ui';

interface IProps {
  account?: IAccountModel;
  processingType: AccountProcessingTypes;
}

const ReceiveMoney = ({ account, processingType }: IProps) => {
  const { t } = useTranslation('accounts');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const initialValuesForFiatAccounts = useMemo<FormValuesModel>(
    () => ({
      email: '',
      message: t(
        'account_activity.send_wire_instructions.native.predefined_message_field_value',
      ),
      fullName: '',
      reference: undefined,
    }),
    [t],
  );

  const onReceiveMoneyNativeSubmit = async (values: FormValuesModel) => {
    await accountsAPI.sendWireInstructions(
      values,
      account?.accountNumber as string,
    );
    Message.success(
      t(
        'account_activity.send_wire_instructions.native.success_submit_message',
      ),
    );
  };

  return (
    <>
      <DivAlignCenter>
        <Button
          fullWidth
          size="large"
          onClick={handleOpenModal}
          disabled={account?.status == BankAccountStatuses.PendingApproval}
        >
          {t('account_activity.send_wire_instructions.send_button')}
        </Button>
        {account?.status == BankAccountStatuses.PendingApproval && (
          <BankAccountIsNotApproved />
        )}
      </DivAlignCenter>

      {processingType === AccountProcessingTypes.Circle && (
        <ReceiveMoneyCircleModalDialog
          title={t('account_activity.send_wire_instructions.title')}
          isVisible={!!(isModalVisible && account)}
          closeCallback={handleCloseModal}
          account={account as IAccountModel}
        />
      )}

      {processingType === AccountProcessingTypes.Native && (
        <ReceiveMoneyNativeModalDialog
          title={t('account_activity.send_wire_instructions.title')}
          isVisible={!!isModalVisible}
          onSubmit={onReceiveMoneyNativeSubmit}
          accountNumber={account?.accountNumber as string}
          initialValues={initialValuesForFiatAccounts}
          closeCallback={handleCloseModal}
        />
      )}
    </>
  );
};

export default ReceiveMoney;
