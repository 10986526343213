import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { IOnboardingAccount } from 'typings/onboarding/account';

// components
import { FormSelect, SelectOption, SelectProps } from '@ui';

export interface OnboardingBankAccountsAutocompleteProps
  extends SelectProps<IOnboardingAccount> {
  applicationId: string;
  predefinedOptions?: SelectOption<IOnboardingAccount>[];
  skipAssociatedWithApprovalRule?: boolean;
}

const OnboardingBankAccountsSelect = ({
  applicationId,
  predefinedOptions,
  skipAssociatedWithApprovalRule,
  ...rest
}: OnboardingBankAccountsAutocompleteProps) => {
  const { t } = useTranslation('onboarding');
  const activeClientGroupName = useSelector<StateModel, string>(
    (state) =>
      state.applications.activeApplication?.clientGroup?.clientGroupName || '',
  );

  const { response, loading } = useFetch(
    () =>
      onboardingAPI.fetchOnboardingBankAccounts(
        applicationId,
        skipAssociatedWithApprovalRule,
      ),
    [applicationId, skipAssociatedWithApprovalRule],
  );

  const options = useMemo<SelectOption<IOnboardingAccount>[]>(() => {
    const result = predefinedOptions ? [...predefinedOptions] : [];

    if (response) {
      let bankAccounts = [...response.data];

      if (predefinedOptions) {
        const predefinedOptionsIds = predefinedOptions.map((e) => e.id);
        bankAccounts = bankAccounts.filter(
          (e) => !predefinedOptionsIds.includes(e._id),
        );
      }

      const formattedOptions = bankAccounts.map((e) => ({
        id: e._id,
        label: `${activeClientGroupName} ${e.currencyIsoCode} (${t(
          'administration.transaction_rules.introduction.purpose_of_bank_account',
        )} ${e.purpose})`,
        model: e,
      }));

      result.push(...formattedOptions);
    }

    return result;
  }, [response, predefinedOptions]);

  return <FormSelect {...rest} options={options} loading={loading} />;
};

export default OnboardingBankAccountsSelect;
