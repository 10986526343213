import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { AccountTypes } from 'enums/onboarding/crm';
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

type IProps = SelectProps<any>;

const AccountTypeSelect = (props: IProps) => {
  const { t } = useTranslation('onboarding');

  const options = useMemo<SelectOption[]>(
    () =>
      Object.keys(AccountTypes).map((key: string) => ({
        id: key,
        label: t(`account_information.types.${key}`),
      })),
    [t],
  );

  return <FormSelect {...props} options={options} />;
};

export default AccountTypeSelect;
