import React from 'react';
import { Progress as AntdProgress } from 'antd';
import { ProgressProps } from './types';

/**
 * The `Progress` component displays the completion status of a task or operation.
 * It can be shown as a line, circle, or dashboard.
 *
 * @param {ProgressProps} props - The properties defined in `ProgressProps`.
 */
const Progress: React.FC<ProgressProps> = ({
  className,
  type = 'line',
  percent,
  success,
  showInfo = true,
  width,
  status,
  strokeWidth,
  strokeColor,
  ...rest
}) => {
  return (
    <AntdProgress
      className={className}
      type={type}
      percent={percent}
      success={success}
      showInfo={showInfo}
      width={width}
      status={status}
      strokeWidth={strokeWidth}
      strokeColor={strokeColor}
      {...rest}
    />
  );
};

export default Progress;
