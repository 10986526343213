import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { CircleButtonProps } from './types';
import { ButtonType as AntDButtonTypes } from 'antd/lib/button';

/**
 * The `CircleButton` component is a circular button designed for icon-only actions.
 * It supports multiple visual styles, a danger state, and accepts an icon to display.
 *
 * @param {CircleButtonProps} props - The properties defined in `CircleButtonProps`.
 */
const IconButton: React.FC<CircleButtonProps> = ({
  icon,
  danger,
  type = 'primary',
  onClick,
  ...rest
}) => {
  const mappedType = useMemo<AntDButtonTypes | undefined>(() => {
    switch (type) {
      case 'primary':
        return 'primary';
      case 'bordered':
        return 'default';
      case 'dashed':
        return 'dashed';
      default:
        return undefined;
    }
  }, [type]);

  return (
    <StyledButton
      {...rest}
      shape="circle"
      type={mappedType}
      icon={icon}
      danger={danger}
      onClick={onClick}
    />
  );
};

const StyledButton = styled(Button)`
  position: relative;
  min-width: 0px;
  padding: 0px;
  line-height: 0px;
  cursor: pointer;

  & > .anticon {
    line-height: 0px;
  }

  & > * {
    cursor: pointer;
  }
`;

export default React.memo(IconButton);
