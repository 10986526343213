import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { TransferTemplateStatuses } from 'enums/accounting/templates';

// components
import { Select, SelectProps } from '@ui';

type IProps = SelectProps<any>;

export type TransferTemplateStatusesFilterBar =
  | 'All'
  | keyof typeof TransferTemplateStatuses;

const TransferTemplateStatusSelect = (props: IProps) => {
  const { t } = useTranslation(['transfers', 'common']);

  const options = useMemo<
    { id: TransferTemplateStatusesFilterBar; label: string }[]
  >(
    () => [
      {
        id: 'All',
        label: t('all', { ns: 'common' }),
      },
      {
        id: 'Active',
        label: t('transfer_templates.statuses.Active'),
      },
      {
        id: 'Archived',
        label: t('transfer_templates.statuses.Archived'),
      },
    ],
    [t],
  );
  return <StyledSelect {...props} options={options} />;
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default TransferTemplateStatusSelect;
