import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import { LocalizationNamespaces } from 'constants/localization';

// components
import { Text } from '@ui';

// This component is responsible of showing form errors by error key (field name)
// Input:
// - errorKey: this is the name of the field in form
// Output:
// - Error message in the case if form has some error message for related errorKey

interface IProps<T> {
  errorKey: keyof T;
}

function FormErrorTooltip<T = unknown>({ errorKey }: IProps<T>) {
  const { t } = useTranslation(LocalizationNamespaces);
  const { errors } = useFormikContext<T>();

  const errorMessage = useMemo(() => {
    if (errors && errors[errorKey]) {
      if (typeof errors[errorKey] === 'string') {
        return t(errors[errorKey] as string);
      } else if (typeof errors[errorKey] === 'object') {
        const error = errors[errorKey] as any;
        const values: { [key: string]: string } = {};

        if (error.values) {
          Object.keys(error.values).forEach((key) => {
            values[key] = t(error.values[key], error.values[key]) as string;
          });
        }

        return t(error.key, values);
      }
    }
  }, [errors[errorKey]]);

  return (
    <>
      {errorMessage ? (
        <StyledText variant="body1" color={colorsTheme.colorError}>
          <div>{errorMessage}</div>
        </StyledText>
      ) : (
        ''
      )}
    </>
  );
}

const StyledText = styled(Text)`
  text-transform: lowercase;
  div:first-letter {
    text-transform: uppercase;
  }
`;

export default FormErrorTooltip;
