import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { travelNotificationsAPI } from 'api/profile/travelNotificationsAPI';

// components
import TravelNotificationRuleModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/UserProfile/AddTravelNotificationModal';
import { Message } from '@ui';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasAdded?: boolean) => void;
}

const AddTravelNotificationRuleModal = ({
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('profile');

  const initialFormValues: FormValuesModel = useMemo(() => {
    return {
      countries: [{ country: null, departureDate: null, returnDate: null }],
    };
  }, []);

  const handleOnSubmit = async (values: FormValuesModel) => {
    await travelNotificationsAPI.addTrip(values);
    Message.success(
      t(
        'travel_notifications.add_travel_notification_modal.success_submit_message',
      ),
    );
  };

  return (
    <TravelNotificationRuleModal
      title={t('travel_notifications.add_travel_notification_modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default AddTravelNotificationRuleModal;
