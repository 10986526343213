import React from 'react';
import { Tree as AntdTree } from 'antd';
import { TreeProps } from './types';

/**
 * The `Tree` component displays hierarchical data in a tree structure,
 * allowing nodes to be expanded or collapsed. It supports various
 * interactions like selection, checking, and dragging.
 * @param {TreeProps} props - The properties defined in `TreeProps`.
 */
const Tree: React.FC<TreeProps> = ({ className, children, ...rest }) => {
  return (
    <AntdTree className={className} {...rest}>
      {children}
    </AntdTree>
  );
};

export default Tree;
