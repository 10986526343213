import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { ContactModel } from 'typings/application/contact';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import SourceOfWealthForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/SourceOfWealthForm';
import { Message } from '@ui';

interface IProps {
  onAction: (key: ActionKeys | null) => void;
  isViewOnly: boolean;
  onboardingData: {
    applicationId: string;
    contactId: string;
  };
}

const SourceOfWealth = ({ onAction, onboardingData, isViewOnly }: IProps) => {
  const { t } = useTranslation('common');

  const {
    response: onboardingStatusResponse,
    loading: onboardingStatusLoading,
  } = useFetch(
    () =>
      onboardingAPI.fetchStatusForOnboardingItem(
        onboardingData.contactId,
        onboardingData.applicationId,
      ),
    [onboardingData],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!onboardingStatusResponse) {
      return null;
    }

    const { item } = onboardingStatusResponse;
    const contact = item as ContactModel;

    const employmentHistoryData = contact.employmentInformation
      ? {
          isInheritedFromRelationships: false,
          employerName: contact.employmentInformation.employerName || '',
          occupation: contact.employmentInformation.occupation || '',
          natureOfBusiness:
            contact.employmentInformation.natureOfBusiness || '',
          isSelfEmployed:
            typeof contact.employmentInformation.isSelfEmployed === 'boolean'
              ? contact.employmentInformation.isSelfEmployed
              : null,
          isBusinessUnderOwnName:
            typeof contact.employmentInformation.isBussinessUnderOwnName ===
            'boolean'
              ? contact.employmentInformation.isBussinessUnderOwnName
              : null,
        }
      : {
          isInheritedFromRelationships: false,
          employerName: '',
          occupation: '',
          natureOfBusiness: '',
          isSelfEmployed: null,
          isBusinessUnderOwnName: false,
        };

    return {
      isSave: false,
      submitActionType: null,
      isBasedOnExistingRelationship:
        contact.employmentInformation?.isBasedOnExistingRelationship,
      sourceOfWealthCategories: contact.sourceOfWealthCategories || [],
      sourceOfWealthDescription: contact.sourceOfWealthDescription || '',
      employmentHistory: [employmentHistoryData],
    };
  }, [onboardingStatusResponse]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody = onboardingAPIAdapter.submitSourceOfWealthStep(values);
    await onboardingAPI.submitSourceOfWealthForIndividualStep(
      formattedBody,
      onboardingData.applicationId,
    );
    Message.success(t('success_save'));
    onAction(values.submitActionType);
  };

  return (
    <LoadingWrapper loading={onboardingStatusLoading}>
      <SourceOfWealthForm
        initialFormValues={initialFormValues}
        onSubmit={onSubmit}
        disabled={isViewOnly}
      />
    </LoadingWrapper>
  );
};

export default SourceOfWealth;
