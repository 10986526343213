import React, { useEffect, useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { securityAPI } from 'api/profile/securityAPI';
import { FormikHelpers } from 'formik';
import { UserProfileModel } from 'typings/profile/profile';
import { LocalStorageHelpers } from 'helpers/storages/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL } from 'constants/userTags';
import {
  setIsEmailVerifiedStatus,
  setUserTags,
  signOut,
} from 'redux/actions/auth';

// components
import { Text, Link, AsyncButton, Message } from '@ui';
import ResendOtp from 'components/Additional/ResendOtp';
import AuthContainer from 'components/Additional/AuthContainer';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import VerifyEmailForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/VerifyEmailForm';

const VerifyEmail = () => {
  const { t } = useTranslation(['auth', 'server_errors']);
  const dispatch = useDispatch();
  const attemptsOfResendingEmailOTP = Number(
    LocalStorageHelpers.getAttemptsOfResendingEmailOTP(),
  );
  const [amountOfResendAttempts, setAmountOfResendAttempts] = useState(
    attemptsOfResendingEmailOTP,
  );
  const profileData = useSelector<StateModel, UserProfileModel | null>(
    (store) => store.auth.profileData,
  );

  const [timerConfig, setTimerConfig] = useState<{
    isInitialized: boolean;
    timerTillDate: Date | null;
  }>({
    isInitialized: false,
    timerTillDate: null,
  });

  const initialFormValues: FormValuesModel = useMemo(
    () => ({
      otp: '',
      email: profileData?.email,
    }),
    [profileData],
  );

  const sendEmail = async () => {
    const response = await securityAPI.resendOTP();
    setTimerConfig({
      isInitialized: true,
      timerTillDate: new Date(response.tempToken.aliveTo),
    });
  };

  useEffect(() => {
    // When user made 2 resend attempts then we don't need to show the timer
    // Because user will be able to only skip verification process
    if (attemptsOfResendingEmailOTP >= 2) {
      setTimerConfig({ isInitialized: true, timerTillDate: null });
    } else {
      const emailSendDate = profileData?.expiredOtp;

      // Case when the email was not sent yet (basically it's right after sign up)
      if (!emailSendDate) {
        sendEmail();
      } else {
        const difference = moment(emailSendDate).diff(moment(), 'seconds');
        // OTP already expired, resend email and start the timer
        if (difference <= 0) {
          sendEmail();
        } else {
          // OTP code is still alive, just continue timer
          setTimerConfig({
            isInitialized: true,
            timerTillDate: new Date(emailSendDate),
          });
        }
      }
    }
  }, [profileData]);

  const handleSubmit = async (values: FormValuesModel) => {
    const data = {
      email: profileData?.email || '',
      otp: values.otp,
    };
    await securityAPI.verifyEmail(data);
    Message.success(t('email_verification.success_submit_message'));
    dispatch(setIsEmailVerifiedStatus(true));
  };

  const resendOTP = async () => {
    await sendEmail();
    const newAmountOfResendingAttempts = amountOfResendAttempts + 1;
    setAmountOfResendAttempts(newAmountOfResendingAttempts);
    LocalStorageHelpers.setAttemptsOfResendingEmailOTP(
      newAmountOfResendingAttempts,
    );
  };

  const handleSkip = async () => {
    await userAPI.setUserTags([USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL]);
    dispatch(
      setUserTags([
        ...(profileData?.tags as string[]),
        USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL,
      ]),
    );
    LocalStorageHelpers.setAttemptsOfResendingEmailOTP(0);
  };

  const onSignOut = () => {
    dispatch(signOut());
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201018': {
        formikHelpers.setFieldError(
          'otp',
          t('1201018', { ns: 'server_errors' }),
        );
        break;
      }
      case '1201020': {
        formikHelpers.setFieldError(
          'otp',
          t('1201020', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <AuthContainer
      marginTop="50px"
      footerText={
        <StyledLinkWrapper>
          <Link onClick={onSignOut}>
            {t('email_verification.sign_out_button')}
          </Link>
        </StyledLinkWrapper>
      }
    >
      <div>
        <Title variant="h5" gutterBottom>
          {t('email_verification.title')}
        </Title>
      </div>
      <LoadingWrapper loading={!timerConfig.isInitialized}>
        <VerifyEmailForm
          onSubmit={handleSubmit}
          onSubmitError={handleOnSubmitError}
          initialValues={initialFormValues}
        />
        <ResendOtp
          timeout={0}
          startTimerFromDate={timerConfig?.timerTillDate ?? undefined}
          successMessage={t('email_verification.resend_success_message')}
          resendOtpCallback={resendOTP}
          renderResendButton={
            amountOfResendAttempts < 2
              ? undefined
              : () => (
                  <StyledAsyncButton
                    type="bordered"
                    fullWidth
                    size="large"
                    onClick={handleSkip}
                  >
                    {t('email_verification.skip_button')}
                  </StyledAsyncButton>
                )
          }
        />
      </LoadingWrapper>
    </AuthContainer>
  );
};

const Title = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginXs};
`;

const StyledAsyncButton = styled(AsyncButton)`
  margin-top: ${({ theme }) => theme.marginSm};
`;
export default VerifyEmail;
