import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import { TransferTemplateTypes } from 'enums/accounting/transfers';
import {
  BlockchainTransferTemplate,
  BlockchainTransferTemplateModel,
  NewBlockchainTransferModel,
} from 'typings/accounting/transfer';

// components
import { Link } from '@ui';
import { FormField, IconSVG } from '@ui';
import DivAlignCenter from '../../../../../../Additional/DivAlignCenter';
import AddTransferTemplateModal from 'modules/Transfers/Templates/AddTransferTemplateModal';
import BlockchainTransferTemplateInfo from './BlockchainTransferTemplateInfo';
import BlockchainTransferTemplatesAutocomplete, {
  BlockchainTransferTemplatesAutocompleteProps,
} from '../../../../../FormComponents/Autocompletes/Accounting/BlockchainTransferTemplatesAutocomplete';
import { ReactComponent as PlusIcon } from 'resources/icons/remix-icons/add-line.svg';

const TransferToSideForm = () => {
  const { t } = useTranslation('transfers');
  const [updateTemplatesTrigger, updateTemplates] = useState({});
  const [isAddTemplateModalVisible, setIsAddTemplateModalVisible] =
    useState(false);
  const { values, setValues } = useFormikContext<NewBlockchainTransferModel>();

  const onAddTemplateClick = () => {
    setIsAddTemplateModalVisible(true);
  };

  const handleAddTemplateModalClose = (
    wasCreated?: boolean,
    newTemplate?: BlockchainTransferTemplate,
  ) => {
    wasCreated && updateTemplates({});

    if (newTemplate) {
      setTransferTemplateData(newTemplate);
    }

    setIsAddTemplateModalVisible(false);
  };

  const setTransferTemplateData = (
    template: BlockchainTransferTemplate | null,
  ) => {
    setValues({
      ...values,
      recipient: {
        ...values.recipient,
        templateId: template?.id as number,
        template,
      },
    });
  };

  const handleTemplateSelect = (
    newTemplateId: number,
    newTemplate?: BlockchainTransferTemplateModel,
  ) => {
    if (newTemplate) {
      const formattedTemplate: BlockchainTransferTemplate = {
        id: newTemplate.id,
        isOnlyMe: newTemplate.isOnlyMe,
        beneficiaryAddress: newTemplate.additionalData.beneficiaryAddress,
        beneficiaryTag: newTemplate.additionalData.beneficiaryTag,
        clientGroupId: 0,
        networkChain: newTemplate.additionalData.networkChain,
        processingType: newTemplate.additionalData.processingType,
        templateName: newTemplate.name,
        templateTypeId: TransferTemplateTypes.Blockchain,
        templateTypeName: newTemplate.templateTypeName,
        templateTypeDescription: '',
      };

      setValues({
        ...values,
        recipient: {
          templateId: newTemplateId,
          template: formattedTemplate,
          reference: '',
        },
      });
    }
  };

  return (
    <>
      <FormField<BlockchainTransferTemplatesAutocompleteProps>
        name="recipient.templateId"
        label={t('internal_transfer.form_fields.transfer_template')}
        component={BlockchainTransferTemplatesAutocomplete}
        additionalProps={{
          onSelect: (_, option) =>
            option.model && handleTemplateSelect(option.model.id, option.model),
          refreshAutocompleteTrigger: updateTemplatesTrigger,
          appendToMenu: (
            <OptionWrapper
              className="ant-select-item ant-select-item-option"
              onClick={onAddTemplateClick}
            >
              <Link weight="semi-bold">
                <DivAlignCenter>
                  <StyledIconSVG
                    component={PlusIcon}
                    color={colorsTheme.colorWhite}
                  />
                  {t('blockchain_transfer.add_transfer_template_button')}
                </DivAlignCenter>
              </Link>
            </OptionWrapper>
          ),
        }}
      />

      {!!values.recipient.template && (
        <BlockchainTransferTemplateInfo template={values.recipient.template} />
      )}

      <AddTransferTemplateModal
        returnFullModelAfterSubmit
        templateType={TransferTemplateTypes.Blockchain}
        isVisible={isAddTemplateModalVisible}
        closeCallback={(wasAdded, newTemplate) =>
          handleAddTemplateModalClose(
            wasAdded,
            newTemplate as BlockchainTransferTemplate,
          )
        }
      />
    </>
  );
};

const OptionWrapper = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colorLightD1}`};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default TransferToSideForm;
