import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { Moment } from 'moment';
import { SortOrder } from 'antd/lib/table/interface';
import { AccountsHelpers } from 'helpers/accounts';
import { transactionsAPI } from 'api/accounting/transactionsAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { TransactionStatuses } from 'enums/accounts/accounts';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import PendingTransactionsTable from './PendingTransactionsTable';
import { Divider, SortProps, Row, Col, SearchInput } from '@ui';

const PendingTransactions = () => {
  const { t } = useTranslation();
  const [currentPage, setPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSorter, setActiveSorter] = useState<{
    key: 'beneficiaryAccountName' | 'beneficiaryAccountNumber';
    direction: SortOrder;
  } | null>(null);
  const [activeFilters, setActiveFilters] = useState<{
    status: TransactionStatuses[] | undefined;
    fromAccount: string | undefined;
    requestedDate: [Moment, Moment] | undefined;
    valueDate: [Moment, Moment] | undefined;
    amount: number[] | undefined;
    currency: number[] | undefined;
    transactionType: number[] | undefined;
    beneficiaryAccountName: string[] | undefined;
    beneficiaryAccountNumber: string[] | undefined;
  }>({
    status: [
      TransactionStatuses.Pending,
      TransactionStatuses.Processing,
      TransactionStatuses.Scheduled,
    ],
    fromAccount: undefined,
    requestedDate: undefined,
    valueDate: undefined,
    amount: undefined,
    currency: undefined,
    transactionType: undefined,
    beneficiaryAccountName: undefined,
    beneficiaryAccountNumber: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchPendingTransactions({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        search: searchQuery.trim(),
        accountNumber: activeFilters.fromAccount || '',
        statusGroups:
          activeFilters.status && activeFilters.status.length
            ? activeFilters.status.join(',')
            : '',
        fromRequestedDate:
          activeFilters.requestedDate && activeFilters.requestedDate[0]
            ? activeFilters.requestedDate[0].startOf('day').unix()
            : undefined,
        toRequestedDate:
          activeFilters.requestedDate && activeFilters.requestedDate[1]
            ? activeFilters.requestedDate[1].endOf('day').unix()
            : undefined,
        fromValueDate:
          activeFilters.valueDate && activeFilters.valueDate[0]
            ? activeFilters.valueDate[0].startOf('day').unix()
            : undefined,
        toValueDate:
          activeFilters.valueDate && activeFilters.valueDate[1]
            ? activeFilters.valueDate[1].endOf('day').unix()
            : undefined,
        fromAmount:
          activeFilters.amount && activeFilters.amount[0]
            ? AccountsHelpers.convertAmountFromIntToBigInt(
                activeFilters.amount[0],
              )
            : undefined,
        toAmount:
          activeFilters.amount && activeFilters.amount[1]
            ? AccountsHelpers.convertAmountFromIntToBigInt(
                activeFilters.amount[1],
              )
            : undefined,
        currency: activeFilters.currency
          ? activeFilters.currency.join(',')
          : '',
        sourceTypes: activeFilters.transactionType
          ? activeFilters.transactionType.join(',')
          : '',
        beneficiaryAccountNames: activeFilters.beneficiaryAccountName
          ? activeFilters.beneficiaryAccountName.join(',')
          : undefined,
        beneficiaryAccountNumbers: activeFilters.beneficiaryAccountNumber
          ? activeFilters.beneficiaryAccountNumber.join(',')
          : undefined,
        sortBy: activeSorter ? activeSorter.key : undefined,
        sortDirection: activeSorter
          ? activeSorter.direction === 'ascend'
            ? false
            : true
          : undefined,
      }),
    [currentPage, searchQuery, activeFilters, activeSorter, updateTableTrigger],
  );

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'from_account':
        setActiveFilters((prev) => ({
          ...prev,
          fromAccount: (value as string) || undefined,
        }));
        break;

      case 'status':
        setActiveFilters((prev) => ({
          ...prev,
          status: value as TransactionStatuses[],
        }));
        break;

      case 'requested_date':
        setActiveFilters((prev) => ({
          ...prev,
          requestedDate: (value as [Moment, Moment]) || undefined,
        }));
        break;

      case 'value_date':
        setActiveFilters((prev) => ({
          ...prev,
          valueDate: (value as [Moment, Moment]) || undefined,
        }));
        break;

      case 'amount':
        setActiveFilters((prev) => ({
          ...prev,
          amount: (value as number[]) || undefined,
        }));
        break;

      case 'currency':
        setActiveFilters((prev) => ({
          ...prev,
          currency: (value as number[]) || undefined,
        }));
        break;

      case 'type':
        setActiveFilters((prev) => ({
          ...prev,
          transactionType: (value as number[]) || undefined,
        }));
        break;
      case 'beneficiary_account_name':
        setActiveFilters((prev) => ({
          ...prev,
          beneficiaryAccountName: (value as string[]) || undefined,
        }));
        break;
      case 'beneficiary_account_number':
        setActiveFilters((prev) => ({
          ...prev,
          beneficiaryAccountNumber: (value as string[]) || undefined,
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  const updateTableValues = (switchToFirstPage?: boolean) => {
    if (switchToFirstPage) {
      setPage(1);
    }

    updateTable({});
  };

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort)) {
      return;
    }

    switch (sort.columnKey) {
      case 'beneficiary_account_name':
        {
          setActiveSorter((prevState) =>
            sort.order
              ? {
                  ...prevState,
                  key: 'beneficiaryAccountName',
                  direction: sort.order,
                }
              : null,
          );
        }
        break;

      case 'beneficiary_account_number':
        setActiveSorter((prevState) =>
          sort.order
            ? {
                ...prevState,
                key: 'beneficiaryAccountNumber',
                direction: sort.order,
              }
            : null,
        );
        break;
    }
  };

  return (
    <>
      <Row>
        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <SearchInput
            onSearch={setSearchQuery}
            size="large"
            placeholder={t(
              'pending_transactions.table.filter_bar.search_placeholder',
            )}
          />
        </Col>
      </Row>
      <Divider />
      <PendingTransactionsTable
        total={response?.total || 0}
        current={currentPage}
        loading={loading}
        data={response?.data || []}
        onPaginationChange={setPage}
        filters={activeFilters}
        updateTableCallback={updateTableValues}
        onFilterChange={onFilterChange}
        onSortChange={handleSortChange}
      />
    </>
  );
};

export default PendingTransactions;
