import React from 'react';
import { Input as AntdInput } from 'antd';
import { InputGroupProps } from './types';

const { Group: AntdInputGroup } = AntdInput;

const InputGroup: React.FC<InputGroupProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdInputGroup className={className} {...rest}>
      {children}
    </AntdInputGroup>
  );
};

export default InputGroup;
