import React from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioGroupProps } from './types';

/**
 * The `RadioGroup` component groups multiple Radio components together.
 * Only one Radio can be selected within a group.
 */
const RadioGroup: React.FC<RadioGroupProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdRadio.Group className={className} {...rest}>
      {children}
    </AntdRadio.Group>
  );
};

export default RadioGroup;
