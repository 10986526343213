import React from 'react';
import { Menu as AntdMenu } from 'antd';
import { MenuItemProps } from './types';

/**
 * The `MenuItem` component represents a single clickable option in a Menu.
 *
 * @param {MenuItemProps} props - The properties defined in `MenuItemProps`.
 */
const MenuItem: React.FC<MenuItemProps> = ({
  key,
  disabled,
  icon,
  className,
  children,
  ...rest
}) => {
  return (
    <AntdMenu.Item
      key={key}
      disabled={disabled}
      icon={icon}
      className={className}
      {...rest}
    >
      {children}
    </AntdMenu.Item>
  );
};

export default MenuItem;
