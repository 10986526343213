import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IBank } from 'typings/accounting/bank';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { useFormikContext } from 'formik';
import { TransfersHelpers } from 'helpers/transfers/transfers';
import { INewTransferTemplateFormValues } from 'typings/accounting/transfer';

// components
import { FormField, FormInput, FormItem, InputGroup } from '@ui';
import CountrySelect from '../../../../../../Forms/FormComponents/SelectInputs/CRM/CountrySelect';
import BankAutocomplete, {
  BankAutocompleteProps,
} from '../../../../../../Forms/FormComponents/Autocompletes/Accounting/BankAutocomplete';
import BankCodeTypeSelect, {
  IProps as BankCodeTypeSelectProps,
} from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/BankCodeTypeSelect';

interface IProps {
  isViewOnly?: boolean;
  isEditMode?: boolean;
  fieldBaseName: string;
}

const BankDetailsForm = ({ fieldBaseName, isEditMode, isViewOnly }: IProps) => {
  const { t } = useTranslation('transfers');
  const form = useFormikContext<INewTransferTemplateFormValues>();
  const selectedBankCode = form.getFieldProps(
    `${fieldBaseName}.bankCodeType`,
  ).value;
  const isOtherBankCode = selectedBankCode == 3;

  const gridSizes = { xl: 4, lg: 4, md: 5, sm: 24, xs: 24 };

  const handleBankSelect = (_: any, option: { model?: IBank }) => {
    if (option.model) {
      const {
        bankCode,
        bankName,
        country,
        bankAddress1,
        city = '',
        postCode = '',
        district = '',
      } = option.model;

      form.setFieldValue(fieldBaseName, {
        ...form.getFieldProps(fieldBaseName).value,
        bankCode,
        bankName,
        country: COUNTRY_CODES[country],
        address: bankAddress1,
        city,
        district: TransfersHelpers.getDistrict(country, district) || undefined,
        postCode,
      });
    }
  };

  const handleBankCodeTypeSelect = (newValue: string) => {
    form.setFieldValue(fieldBaseName, {
      bankCodeType: newValue,
      bankCode: undefined,
      bankName: undefined,
      country: undefined,
      address: undefined,
      city: undefined,
      district: undefined,
      postCode: undefined,
    });
  };

  return (
    <>
      <FormItem
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.bank_code',
        )}
        labelAlign="left"
        labelCol={gridSizes}
        style={{ marginBottom: 0 }}
      >
        <InputGroupWrapper compact>
          <FormField<BankCodeTypeSelectProps>
            name={`${fieldBaseName}.bankCodeType`}
            component={StyledBankCodeTypeSelect}
            disabled={isViewOnly || isEditMode}
            additionalProps={{
              onSelect: (newValue: string) =>
                handleBankCodeTypeSelect(newValue),
            }}
          />
          <FormField<BankAutocompleteProps>
            name={`${fieldBaseName}.bankCode`}
            component={
              isOtherBankCode ? StyledFormInput : StyledBankAutocomplete
            }
            disabled={isViewOnly || !selectedBankCode || isEditMode}
            additionalProps={{
              onSelect: !isOtherBankCode ? handleBankSelect : undefined,
              bankCodeType: selectedBankCode,
            }}
          />
        </InputGroupWrapper>
      </FormItem>

      <FormField
        name={`${fieldBaseName}.bankName`}
        component={FormInput}
        disabled={!isOtherBankCode || isViewOnly || isEditMode}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.bank_name',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.country`}
        component={CountrySelect}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.country',
        )}
        disabled={!isOtherBankCode || isViewOnly || isEditMode}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.address`}
        component={FormInput}
        disabled={!isOtherBankCode || isViewOnly || isEditMode}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.address',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.city`}
        component={FormInput}
        disabled={!isOtherBankCode || isViewOnly || isEditMode}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_city',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.district`}
        component={FormInput}
        disabled={!isOtherBankCode || isViewOnly || isEditMode}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_district',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.postCode`}
        component={FormInput}
        disabled={!isOtherBankCode || isViewOnly || isEditMode}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_postcode',
        )}
        labelCol={gridSizes}
      />
    </>
  );
};

const InputGroupWrapper = styled(InputGroup)`
  &.ant-input-group {
    display: flex;
  }
  .ant-form-item:last-child {
    width: 100%;
  }
`;

const StyledBankCodeTypeSelect = styled(BankCodeTypeSelect)`
  width: 250px;
  min-width: 250px;
`;

const StyledFormInput = styled(FormInput)`
  width: 100%;
`;

const StyledBankAutocomplete = styled(BankAutocomplete)`
  width: 100% !important;
`;

export default BankDetailsForm;
