import React, { useMemo } from 'react';

// helpers
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

export interface RangeOptionModel {
  min: number | null;
  max: number | null;
}

export type NumberOfOfficesKeys =
  | 'numberOfOffices1'
  | 'numberOfOffices2'
  | 'numberOfOffices3'
  | 'numberOfOffices4'
  | 'numberOfOffices5'
  | 'numberOfOffices6'
  | 'numberOfOffices7'
  | 'numberOfOffices8'
  | 'numberOfOffices9';

export const NumberOfOfficesOptions: Record<
  NumberOfOfficesKeys,
  RangeOptionModel
> = {
  numberOfOffices1: {
    min: 1,
    max: 1,
  },
  numberOfOffices2: {
    min: 2,
    max: 2,
  },
  numberOfOffices3: {
    min: 3,
    max: 3,
  },
  numberOfOffices4: {
    min: 4,
    max: 6,
  },
  numberOfOffices5: {
    min: 7,
    max: 10,
  },
  numberOfOffices6: {
    min: 11,
    max: 15,
  },
  numberOfOffices7: {
    min: 16,
    max: 25,
  },
  numberOfOffices8: {
    min: 26,
    max: 49,
  },
  numberOfOffices9: {
    min: 50,
    max: null,
  },
};

export type IProps = SelectProps;

const NumberOfOfficesSelect = (props: IProps) => {
  const options = useMemo<SelectOption[]>(() => {
    function getOptionLabel(key: NumberOfOfficesKeys) {
      const { min, max } = NumberOfOfficesOptions[key];

      if (typeof min !== 'number' || max === min) {
        return max?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
      }

      if (typeof max !== 'number') {
        return `${min?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}+`;
      }

      return `${min?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}-${max?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}`;
    }

    return Object.keys(NumberOfOfficesOptions).map((key) => ({
      id: key,
      label: getOptionLabel(key as NumberOfOfficesKeys),
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
};

export default NumberOfOfficesSelect;
