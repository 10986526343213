import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// constants
import { VerificationDocumentTypes } from 'enums/profile/accountRecovery';

// components
import { Text, IconSVG, Col, Row, Card } from '@ui';
import { ReactComponent as PassportIcon } from 'resources/icons/remix-icons/passport-line.svg';

const ChooseDocumentTypeForm = () => {
  const { t } = useTranslation('auth');
  const { setFieldValue } = useFormikContext<FormValuesModel>();

  const onTypeSelect = (type: VerificationDocumentTypes) => {
    setFieldValue('idVerificationType', type);
  };

  const gridSizes = { xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };

  return (
    <StyledRow gutter={[20, 20]} align="middle" justify="center">
      <StyledCol {...gridSizes}>
        <StyledCard
          isSelected={false}
          onClick={() => onTypeSelect(VerificationDocumentTypes.Passport)}
        >
          <IconSVG
            component={PassportIcon}
            size="large"
            color={colorsTheme.colorWhite}
          />
          <CardInnerTitle variant="h6" weight="semi-bold">
            {t(
              'account_recovery.id_verification_step.documents.passport.title',
            )}
          </CardInnerTitle>
        </StyledCard>
      </StyledCol>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledCol = styled(Col)`
  height: 100%;
`;

const StyledCard = styled(Card)<{ isSelected: boolean }>`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadiusRegular};
  transition: ${({ theme }) => theme.defaultTransition};
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colorPrimary : theme.cardBorderColor};

  &:hover {
    border-color: ${({ theme }) => theme.colorPrimary};
  }

  height: 100%;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const CardInnerTitle = styled(Text)`
  text-align: center;
`;

export default ChooseDocumentTypeForm;
