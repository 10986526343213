import React from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioButtonProps } from './types';

/**
 * The `RadioButton` component represents a radio option styled as a button.
 * It uses the same props as `Radio`, but presents differently visually.
 */
const RadioButton: React.FC<RadioButtonProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdRadio.Button className={className} {...rest}>
      {children}
    </AntdRadio.Button>
  );
};

export default RadioButton;
