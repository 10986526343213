import React from 'react';

// helpers
import styled from 'styled-components';
import { changeView } from 'redux/actions/view';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { RoutePaths } from 'routes/routes';
import { Text, IconSVG, Button, Col, Row } from '@ui';
import { ReactComponent as ArrowRightIcon } from 'resources/icons/remix-icons/arrow-right-s-line.svg';

interface IProps {
  path: RoutePaths;
  children?: any;
}

const NavButton = ({ children, path }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (path: RoutePaths) => {
    dispatch(changeView(() => navigate(path)));
  };

  return (
    <ButtonWrapper onClick={() => handleNavigate(path)}>
      <StyledRow justify="space-between" align="middle">
        <Col>
          <Text variant="h4" weight="semi-bold">
            {children}
          </Text>
        </Col>

        <Col>
          <IconWrapper>
            <IconSVG
              component={ArrowRightIcon}
              size="middle"
              color={colorsTheme.colorWhite}
            />
          </IconWrapper>
        </Col>
      </StyledRow>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled(Button)`
  width: 100%;
  text-align: left;
  height: ${({ theme }) => theme.heightLg};
  font-size: ${({ theme }) => theme.fontSizeLg};
  padding: 4px ${({ theme }) => theme.paddingXXl} 0
    ${({ theme }) => theme.paddingXXl};
  background-color: ${({ theme }) => theme.colorDarkL3};
  color: ${({ theme }) => theme.colorLight};
  text-transform: none;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colorDarkL3};
    color: ${({ theme }) => theme.colorPrimaryD1};
    border-color: ${({ theme }) => theme.colorPrimaryD1};
  }

  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    font-size: ${({ theme }) => theme.fontSizeMd};
    height: ${({ theme }) => theme.heightMd};
    padding: 4px ${({ theme }) => theme.paddingMd} 0
      ${({ theme }) => theme.paddingMd};
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default NavButton;
