import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { StateModel } from 'redux/reducers';
import { DateHelpers } from 'helpers/date';
import { useSelector } from 'react-redux';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { EntityHelpers } from 'helpers/crm/entity';
import { OnboardingHelpers } from 'helpers/crm/onboarding';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';

// constants
import { EntityTypes, SocialMediaTypes } from 'enums/onboarding/crm';
import {
  NumberOfEmployeesKeys,
  NumberOfEmployeesOptions,
  RangeOptionModel,
} from 'components/Forms/FormComponents/SelectInputs/CRM/NumberOfEmployeesSelect';
import {
  NumberOfOfficesKeys,
  NumberOfOfficesOptions,
} from 'components/Forms/FormComponents/SelectInputs/CRM/NumberOfOfficesSelect';

// components
import GeneralInformationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/GeneralInformationForm';
import { Message } from '@ui';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  isViewOnly: boolean;
  onSave: () => void;
}

const GeneralInformation = ({
  onboardingStatus,
  isViewOnly,
  onSave,
}: IProps) => {
  const { t } = useTranslation(['common', 'onboarding']);

  const applicantId = useSelector<StateModel, string | undefined>(
    ({ applications }) => applications.activeApplication?.crmItemId,
  );

  const initialFormValues = useMemo<FormValuesModel>(() => {
    if (onboardingStatus.item) {
      const organizationData = onboardingStatus.item as IEntity;
      const legalName = EntityHelpers.getEntityNameByNameType(
        organizationData.names,
        EntityTypes.Legal,
      );
      const tradeName = EntityHelpers.getEntityNameByNameType(
        organizationData.names,
        EntityTypes.Trader,
      );

      return {
        submitActionType: null,
        isSave: false,
        legalName,
        tradeName,
        dateOfIncorporation: organizationData.dateOfIncorporation
          ? DateHelpers.formatDateToUTC(organizationData.dateOfIncorporation)
          : null,
        jurisdiction: organizationData.jurisdiction,
        industryCodes: organizationData.industryCodes,
        natureOfBusiness: organizationData.natureOfBusiness,
        numberOfEmployees: organizationData.numberOfEmployees
          ? (OnboardingHelpers.getKeyOfRangeByRangeOption(
              organizationData.numberOfEmployees as RangeOptionModel,
              NumberOfEmployeesOptions,
            ) as NumberOfEmployeesKeys)
          : null,
        numberOfOffices: organizationData.numberOfOffices
          ? (OnboardingHelpers.getKeyOfRangeByRangeOption(
              organizationData.numberOfOffices as RangeOptionModel,
              NumberOfOfficesOptions,
            ) as NumberOfOfficesKeys)
          : null,
        registrationNumber: organizationData.registrationNumber,
        socialMedia: {
          websiteLink: organizationData.socialMedia
            ? OnboardingHelpers.findSocialMediaByType(
                SocialMediaTypes.WebSite,
                organizationData.socialMedia,
              )
            : '',
          twitterLink: organizationData.socialMedia
            ? OnboardingHelpers.findSocialMediaByType(
                SocialMediaTypes.Twitter,
                organizationData.socialMedia,
              )
            : '',
          facebookLink: organizationData.socialMedia
            ? OnboardingHelpers.findSocialMediaByType(
                SocialMediaTypes.Facebook,
                organizationData.socialMedia,
              )
            : '',
          linkedInLink: organizationData.socialMedia
            ? OnboardingHelpers.findSocialMediaByType(
                SocialMediaTypes.LinkedIn,
                organizationData.socialMedia,
              )
            : '',
        },
      };
    } else {
      return {
        submitActionType: null,
        isSave: false,
        legalName: '',
        tradeName: '',
        dateOfIncorporation: null,
        jurisdiction: '',
        industryCodes: [],
        natureOfBusiness: '',
        numberOfEmployees: null,
        numberOfOffices: null,
        registrationNumber: '',
        socialMedia: {
          websiteLink: '',
          twitterLink: '',
          facebookLink: '',
          linkedInLink: '',
        },
      };
    }
  }, []);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitApplicantInformationOrganizationStep(values);
    await onboardingAPI.submitApplicantInformationOrganizationStep(
      formattedBody,
      onboardingStatus.applicationId,
      onboardingStatus.crmItemId,
    );
    Message.success(t('success_save'));
    onSave();
  };

  return (
    <GeneralInformationForm
      title={
        onboardingStatus.crmItemId === applicantId
          ? t('applicant_information.applicant.title', { ns: 'onboarding' })
          : ''
      }
      disabled={isViewOnly}
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
      showOnlySaveButton
    />
  );
};

export default GeneralInformation;
