import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { signOut } from 'redux/actions/auth';
import { useDispatch } from 'react-redux';

// components
import { Link } from '@ui';
import AuthContainer from 'components/Additional/AuthContainer';
import SelectApplicationCard from 'modules/Applications/SelectApplicationCard';

const SelectApplicationPage = () => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <AuthContainer
        marginTop="50px"
        cardWidth="60%"
        footerText={
          <StyledLinkWrapper>
            <Link onClick={() => dispatch(signOut())}>
              {t('email_verification.sign_out_button')}
            </Link>
          </StyledLinkWrapper>
        }
      >
        <SelectApplicationCard />
      </AuthContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginXs};
`;

export default SelectApplicationPage;
