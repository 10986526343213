import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormField } from '@ui';
import { FormInputTextArea } from '@ui';

interface IProps {
  reasonFieldLabel?: string;
}

const InnerForm = ({ reasonFieldLabel }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <FormField
      label={reasonFieldLabel || t('reason')}
      name="reason"
      component={FormInputTextArea}
      additionalProps={{
        autoSize: { minRows: 6, maxRows: 6 },
      }}
    />
  );
};

export default InnerForm;
