import React, { useMemo } from 'react';

// helpers
import { SelectOption, SelectProps } from '@ui';
// components
import { FormSelect } from '@ui';

export interface RangeOptionModel {
  min: number | null;
  max: number | null;
}

export type NumberOfEmployeesKeys =
  | 'numberOfEmployees1'
  | 'numberOfEmployees2'
  | 'numberOfEmployees3'
  | 'numberOfEmployees4'
  | 'numberOfEmployees5'
  | 'numberOfEmployees6'
  | 'numberOfEmployees7'
  | 'numberOfEmployees8'
  | 'numberOfEmployees9';

export const NumberOfEmployeesOptions: Record<
  NumberOfEmployeesKeys,
  RangeOptionModel
> = {
  numberOfEmployees1: {
    min: 1,
    max: 1,
  },
  numberOfEmployees2: {
    min: 2,
    max: 9,
  },
  numberOfEmployees3: {
    min: 10,
    max: 49,
  },
  numberOfEmployees4: {
    min: 50,
    max: 199,
  },
  numberOfEmployees5: {
    min: 200,
    max: 499,
  },
  numberOfEmployees6: {
    min: 500,
    max: 999,
  },
  numberOfEmployees7: {
    min: 1000,
    max: 4999,
  },
  numberOfEmployees8: {
    min: 5000,
    max: 9999,
  },
  numberOfEmployees9: {
    min: 10000,
    max: null,
  },
};

export type IProps = SelectProps;

const NumberOfEmployeesSelect = (props: IProps) => {
  const options = useMemo<SelectOption[]>(() => {
    function getOptionLabel(key: NumberOfEmployeesKeys) {
      const { min, max } = NumberOfEmployeesOptions[key];

      if (typeof min !== 'number' || max === min) {
        return max?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
      }

      if (typeof max !== 'number') {
        return `${min?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}+`;
      }

      return `${min?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}-${max?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}`;
    }

    return Object.keys(NumberOfEmployeesOptions).map((key) => ({
      id: key,
      label: getOptionLabel(key as NumberOfEmployeesKeys),
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
};

export default NumberOfEmployeesSelect;
