import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import StatusLabel from 'components/Typography/StatusLabel';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

export type NodeChangeTypes = 'create' | 'update' | 'delete';

export type Record = {
  name: string;
  type: string;
  change: NodeChangeTypes | null;
};

type IProps = RequiredPropsForTableModel<Record>;

const STATUS_MAP = {
  info: [],
  warning: ['update'],
  success: ['create'],
  error: ['delete'],
};

const NodesChangesTable = (props: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo<TableColumnModel[]>(
    () => [
      {
        width: 270,
        key: 'name',
        title: t('nodes_changes_table.name'),
        render: (record: Record) => (
          <EllipsisTooltip maxTextContainerWidth="200px" title={record.name}>
            {record.name}
          </EllipsisTooltip>
        ),
      },

      {
        width: 250,
        key: 'type',
        title: t('nodes_changes_table.type'),
        render: (record: Record) => (
          <EllipsisTooltip maxTextContainerWidth="200px" title={record.name}>
            {record.type}
          </EllipsisTooltip>
        ),
      },

      {
        key: 'action',
        title: t('nodes_changes_table.action'),
        render: (record: Record) =>
          record.change ? (
            <StatusLabel
              statusMap={STATUS_MAP}
              status={record.change}
              content={t(`nodes_changes_table.action_types.${record.change}`)}
            />
          ) : (
            ''
          ),
      },
    ],
    [],
  );

  return <Table {...props} columns={tableColumns} />;
};

export default NodesChangesTable;
