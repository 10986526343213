import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const SendMessageValidationSchema = yup.object().shape({
  categoryId: yup.number().required('form:validation_messages.required'),
  subject: STRING_VALIDATION_RULE.required('form:validation_messages.required'),
  message: STRING_VALIDATION_RULE.required('form:validation_messages.required'),
  attachments: yup.array().notRequired(),
});

export const ReplyFormValidationSchema = yup.object().shape({
  message: STRING_VALIDATION_RULE.required('form:validation_messages.required'),
  attachments: yup.array().notRequired(),
});
