import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { contactsAPI } from 'api/crm/contactsAPI';
import {
  profileAPI,
  UpdatePhoneNumberResponseModel,
} from 'api/profile/profileAPI';

// components
import {
  default as TemplateEditPhoneModal,
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Auth/EditPhoneModal';
import { Message } from '@ui';

interface IProps {
  initialFormValues: FormValuesModel;
  isVisible: boolean;
  closeCallback: (
    wasSubmitted?: boolean,
    response?: UpdatePhoneNumberResponseModel,
  ) => void;
}

const EditPhoneModal = ({
  initialFormValues,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('auth');

  const onSubmit = async (values: FormValuesModel) => {
    await contactsAPI.updateUserPhoneNumber(values.phone);
    const response = await profileAPI.resendPhoneNumberOTP(values.phone);
    Message.success(
      t('phone_verification.edit.success', { phone: response.number }),
    );
    closeCallback(true, response);
  };

  return (
    <TemplateEditPhoneModal
      isVisible={isVisible}
      closeCallback={closeCallback}
      onSubmit={onSubmit}
      initialValues={initialFormValues}
    />
  );
};

export default EditPhoneModal;
