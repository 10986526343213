import React from 'react';
import { Row as AntdRow } from 'antd';
import { RowProps } from './types';

/**
 * The `Row` component provides a flexible grid layout container,
 * allowing you to position and align elements horizontally and vertically.
 *
 * This component is a wrapper around Ant Design’s `Row` component, ensuring
 * a consistent interface and styling with the rest of your design system.
 *
 * @param {RowProps} props - The properties defined in `RowProps`.
 */
const Row: React.FC<RowProps> = ({
  children,
  className,
  gutter,
  justify,
  align,
  wrap = true,
  ...rest
}) => {
  return (
    <AntdRow
      className={className}
      gutter={gutter}
      justify={justify}
      align={align}
      wrap={wrap}
      {...rest}
    >
      {children}
    </AntdRow>
  );
};

export default Row;
