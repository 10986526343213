import React, { useState } from 'react';

// helpers
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';

// components
import TransactionRulesTable from './TransactionRulesTable';
import AddTransactionRulesModal from './AddTransactionRulesModal';
import { PageButton, Col, Row, Text } from '@ui';

const ApprovalRulesTab = () => {
  const { t } = useTranslation('account_management');

  const [updateTableTrigger, updateTable] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);

  const [hasCreateAccess, hasEditAccess] = useUserAccess([
    'AccountManagement_TransactionRules_ApprovalRules_Create',
    'AccountManagement_TransactionRules_ApprovalRules_Edit',
  ]);

  const handleAddRuleClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = (shouldUpdateTable?: boolean) => {
    shouldUpdateTable && updateTable({});
    setIsModalVisible(false);
    setSelectedRuleId(null);
  };

  const handleOnActionsClick = (key: string, ruleId: string) => {
    switch (key) {
      case 'edit':
        setSelectedRuleId(ruleId);
        break;
    }
  };

  return (
    <>
      <Text variant="body1" gutterBottom>
        {t('transaction_rules.approval_rules_description')}
      </Text>

      {hasCreateAccess && (
        <Row justify="end">
          <Col flex="150px">
            <PageButton fullWidth onClick={handleAddRuleClick}>
              {t('approval_rules.add_rule_button')}
            </PageButton>
          </Col>
        </Row>
      )}

      <TransactionRulesTable
        updateTableTrigger={updateTableTrigger}
        onActionsClick={handleOnActionsClick}
        hasEditAccess={hasEditAccess}
      />

      {(hasCreateAccess || hasEditAccess) && (
        <AddTransactionRulesModal
          isVisible={isModalVisible || !!selectedRuleId}
          closeCallback={handleModalClose}
          transactionRuleId={selectedRuleId}
        />
      )}
    </>
  );
};

export default ApprovalRulesTab;
