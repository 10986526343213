import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IApprovalGroupShort } from 'typings/approvalManagement/accountManagement';
import { FormikProps, useFormikContext } from 'formik';

// components
import { Button, TableAntd } from '@ui';
import HeaderCell from './HeaderCell';
import ContentCell from './ContentCell';

export type ConditionModel = {
  conditions: {
    approvalGroup: string;
    approvalGroupFull?: IApprovalGroupShort | null;
    requiredApproversCount: number;
  }[];

  // Include or no status (checkboxes in table content)
  limitStatuses: { isChecked: boolean }[];
};

export type LimitModel = {
  from: number;
  to?: number;
  toInfinite: boolean;

  // Using for validation, at least of one condition should be selected for each limit item
  // TODO: think how to implement it in a better way (using yup)
  isUsed: boolean;
};

export interface FormValuesModel {
  conditions: ConditionModel[];
  limits: LimitModel[];
  approvalGroups: IApprovalGroupShort[];
}

export interface TableColumnBaseModel {
  onlyView: boolean;
  dataIndex: string;
  title: string;
  colIndex: number;
  form: FormikProps<FormValuesModel>;
}

const TransactionRulesMatrixForm = () => {
  const { t } = useTranslation(['account_management', 'common']);
  const { values, setFieldValue, setValues } =
    useFormikContext<FormValuesModel>();

  const conditionColumn = {
    title: t(
      'approval_rules.bank_accounts.approval_rule_modal.transaction_approval_rules',
    ),
    key: 'conditions',
    dataIndex: 'conditions',
    width: '300px',
  };

  const limitColumn = {
    title: t(
      'approval_rules.bank_accounts.approval_rule_modal.transaction_range',
    ),
    key: 'limit',
    dataIndex: 'limitStatuses',
  };

  const [tableColumns, setTableColumns] = useState<any[]>(getInitialColumns());

  function getInitialColumns() {
    const numberOfLimitColumns = values.limits.length;

    const limitColumnsArray = Array.from(
      Array(numberOfLimitColumns),
      (_, i) => ({
        title: t(
          'approval_rules.bank_accounts.approval_rule_modal.transaction_range',
        ),
        key: `limit-${i}`,
        dataIndex: 'limitStatuses',
      }),
    );

    return [conditionColumn, ...limitColumnsArray];
  }

  // ---------- Table helpers --------
  const handleAddCondition = (typeToAdd: 'and' | 'or', toPosition = 0) => {
    const conditionArrayCopy = values.conditions.slice();
    const limitsCount = values.limits.length;
    let result = [];

    if (typeToAdd === 'and') {
      conditionArrayCopy[toPosition].conditions.push({
        approvalGroup: '',
        requiredApproversCount: 0,
      });
      result = conditionArrayCopy;
    } else {
      const newCondition: any = {
        conditions: [{ approvalGroup: '', requiredApproversCount: 0 }],
        limitStatuses: Array.from(Array(limitsCount), () => ({
          isChecked: false,
        })),
      };
      result = [...conditionArrayCopy, newCondition];
    }

    setFieldValue('conditions', result);
  };

  const getColumns = () => {
    const columns = tableColumns.slice();
    const limitColumnsCount = values.limits.length;

    const mergedColumns = columns.map((col, index: number) => {
      const baseProps = {
        colIndex: index > 0 ? index - 1 : 0,
        dataIndex: col.dataIndex,
        title: col.title,
      };

      return {
        ...col,
        onHeaderCell: () => ({
          ...baseProps,
          isDisabled: baseProps.colIndex < limitColumnsCount - 1,
          disabledFrom: limitColumnsCount > 1,
          canAddLimit: canAddLimit(),
          deleteCallback: () => deleteLastLimit(),
          onAddLimitClick: () => handleAddLimit(),
        }),
        onCell: (record: any, rowIndex: number) => ({
          ...baseProps,
          record,
          rowIndex,
          approvalGroups: values.approvalGroups,
          handleAddAnd: (toPosition: number) =>
            handleAddCondition('and', toPosition),
        }),
      };
    });

    return mergedColumns;
  };

  const handleAddLimit = () => {
    const conditionsCopy = values.conditions.slice();

    // Add limit status value to each condition item
    const updatedConditions = conditionsCopy.map((el) => ({
      ...el,
      limitStatuses: [...el.limitStatuses, { isChecked: false }],
    }));

    const lastLimit = {
      ...values.limits[values.limits.length - 1],
    };

    // Update form values
    setTableColumns([...tableColumns, limitColumn]);

    const updatedLimits = [
      ...values.limits,
      {
        from: lastLimit.to ? lastLimit.to + 0.01 : 0.01,
        to: undefined,
        toInfinite: true,
        isUsed: false,
      },
    ];

    setValues({
      ...values,
      conditions: updatedConditions,
      limits: updatedLimits,
    });
  };

  const deleteLastLimit = () => {
    const limitsCopy = values.limits.slice();

    let conditionsCopy = values.conditions.slice();

    limitsCopy.pop();

    conditionsCopy = conditionsCopy.map((el) => ({
      ...el,
      limitStatuses: el.limitStatuses.slice(0, -1),
    }));

    setTableColumns(tableColumns.slice(0, -1));

    setValues({
      ...values,
      conditions: conditionsCopy,
      limits: limitsCopy,
    });
  };

  const canAddLimit = () => {
    const { limits } = values;

    return (
      typeof limits[limits.length - 1].to !== 'undefined' &&
      typeof limits[limits.length - 1].from !== 'undefined' &&
      limits[limits.length - 1].from! < limits[limits.length - 1].to!
    );
  };

  return (
    <>
      <TableAntd
        bordered
        className="disable-table-row-hover"
        size="large"
        scroll={{ x: 'max-content' }}
        dataSource={values.conditions.slice()}
        columns={getColumns()}
        pagination={false}
        components={{
          header: { cell: HeaderCell },
          body: { cell: ContentCell },
        }}
      />

      <BottomButtonsWrapper>
        <Button onClick={() => handleAddCondition('or')}>
          {t('approval_rules.add_or_button')}
        </Button>
      </BottomButtonsWrapper>
    </>
  );
};

const BottomButtonsWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

export default TransactionRulesMatrixForm;
