import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useField } from 'formik';

// constants
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import { DeleteButton, FormField, FormInput, Col, Row, Card } from '@ui';
import CountrySelect from '../../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import PrimaryStatusText from '../../../../../../Typography/PrimaryStatusText';
import HideIfDisabledForm from '../../../../../HideIfDisabledForm';
import OrganizationAddressTypeSelect, {
  IProps as OrganizationAddressTypeSelectProps,
} from '../../../../../FormComponents/SelectInputs/CRM/OrganizationAddressTypeSelect';

interface IProps {
  index: number;
  onRemoveClick: () => void;
}

const AddressesForm = ({ index, onRemoveClick }: IProps) => {
  const [field] = useField(`addresses.${index}.isPrimary`);
  const { t } = useTranslation(['onboarding', 'common', 'form']);
  const isPrimary = field.value;

  return (
    <StyledCard
      title={t('identification.address_with_number', { number: index + 1 })}
      extra={
        isPrimary ? (
          <PrimaryStatusText />
        ) : (
          <HideIfDisabledForm hideIfSubmitting>
            <DeleteButton onClick={onRemoveClick} />
          </HideIfDisabledForm>
        )
      }
    >
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField<OrganizationAddressTypeSelectProps>
            label={t('identification.form_fields.addresses.address_type')}
            name={`addresses.${index}.type`}
            component={OrganizationAddressTypeSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('identification.form_fields.addresses.country')}
            name={`addresses.${index}.country`}
            component={CountrySelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('identification.form_fields.addresses.city')}
            name={`addresses.${index}.city`}
            component={FormInput}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('identification.form_fields.addresses.street')}
            name={`addresses.${index}.street`}
            component={FormInput}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <Row gutter={[16, 0]}>
            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
              <FormField
                label={t('identification.form_fields.addresses.state_region')}
                name={`addresses.${index}.state`}
                component={FormInput}
              />
            </Col>

            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <FormField
                label={t('identification.form_fields.addresses.postal_code')}
                name={`addresses.${index}.postalCode`}
                component={FormInput}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: ${({ theme }) => `${theme.marginMd} 0`};

  .ant-card-head {
    border-bottom: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
  }

  .ant-card-extra {
    text-transform: none;
  }
`;

export default AddressesForm;
