import React from 'react';
import { DividerProps } from './types';
import { Divider as AntdDivider, DividerProps as AntdDividerProps } from 'antd';

/**
 * The `Divider` component renders a line to visually separate sections of content.
 * It optionally displays text oriented along the line and can be customized to be
 * dashed or vertical, depending on the provided props.
 *
 * @param {DividerProps} props - The properties defined in `DividerProps`.
 */

const Divider: React.FC<DividerProps> = ({
  children,
  dashed = false,
  orientation = 'center',
  className,
  style,
  type = 'horizontal',
}) => {
  const dividerProps: AntdDividerProps = {
    dashed,
    orientation,
    className,
    style,
    type,
  };

  return <AntdDivider {...dividerProps}>{children}</AntdDivider>;
};

export default Divider;
