import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { AccountProcessingTypes } from 'enums/accounting/transfers';
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

export type IProps = SelectProps;

const AccountProcessingTypeSelect = (props: IProps) => {
  const { t } = useTranslation('common');

  const options = useMemo<SelectOption[]>(() => {
    return [
      { key: AccountProcessingTypes.Circle, name: t('yes') },
      { key: AccountProcessingTypes.Native, name: t('no') },
    ].map((e) => ({
      id: e.key,
      label: e.name,
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
};

export default AccountProcessingTypeSelect;
