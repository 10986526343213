import React from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { PanelProps } from './types';

/**
 * The `Panel` component represents a single collapsible panel inside a Collapse.
 * It has a header, which is always visible, and content that shows or hides when toggled.
 *
 * @param {PanelProps} props - The properties defined in `PanelProps`.
 */
const Panel: React.FC<PanelProps> = ({
  className,
  header,
  children,
  ...rest
}) => {
  return (
    <AntdCollapse.Panel className={className} header={header} {...rest}>
      {children}
    </AntdCollapse.Panel>
  );
};

export default Panel;
