import React, { useEffect, useMemo } from 'react';
import useTranslation from 'hooks/useTranslation';
import { routes } from 'routes/routes';
import { Message } from '@ui';
import { signOut } from '../redux/actions/auth';
import { StateModel } from '../redux/reducers';
import { RoutesHelpers } from 'helpers/routes';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AppStateModel } from '../redux/reducers/app';
import { StateModel as AuthStateModel } from '../redux/reducers/auth';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';
import { StateModel as UserAccessStateModel } from 'redux/reducers/userAccess';

const withAccessCheckForRoute = () => (Component: any) => {
  const WithAccessCheckForRoute = () => {
    const { t } = useTranslation('auth');
    const dispatch = useDispatch();
    const location = useLocation();
    const { permissions } = useSelector<StateModel, UserAccessStateModel>(
      (state) => state.userAccess,
    );
    const { profileData } = useSelector<StateModel, AuthStateModel>(
      (store) => store.auth,
    );
    const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
      (state) => state.settings,
    );
    const { lastActivityTimestamp } = useSelector<StateModel, AppStateModel>(
      (store) => store.app,
    );

    // TODO: move this to a separate hook
    // Instantly sign out user if session expired
    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      if (profileData?.sessionDuration) {
        timeoutId = setTimeout(() => {
          dispatch(signOut());
          Message.info(t('signout.signout_message'));
        }, profileData?.sessionDuration * 1000);
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }, [lastActivityTimestamp, profileData?.sessionDuration]);

    const { status, redirectPath } = RoutesHelpers.isAuthorized(
      location.pathname,
      layoutVariant,
      permissions,
      routes,
    );

    const node = useMemo(() => {
      return status ? <Component /> : <Navigate to={redirectPath} />;
    }, [status, redirectPath]);

    return node;
  };

  return WithAccessCheckForRoute;
};

export default withAccessCheckForRoute;
