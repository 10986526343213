import React from 'react';

// helpers
import { IBank } from 'typings/accounting/bank';
import { banksAPI } from 'api/accounting/banksAPI';
import { AutocompleteProps } from '@ui';

// components
import { FormAutocomplete } from '@ui';

export interface BankAutocompleteProps extends AutocompleteProps<IBank> {
  bankCodeType: number;
}

const BankAutocomplete = ({ bankCodeType, ...rest }: BankAutocompleteProps) => {
  const fetchBanks = async (
    searchText: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } = await banksAPI.fetchBanks({
      page,
      limit,
      bankCode: searchText,
      bankCodeTypeId: bankCodeType,
    });

    return {
      total,
      options: data.map((bank) => ({
        id: bank.bankCode,
        label: bank.bankCode,
        model: bank,
      })),
    };
  };

  return <FormAutocomplete {...rest} fetchData={fetchBanks} />;
};

export default BankAutocomplete;
