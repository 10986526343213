import React, { useLayoutEffect } from 'react';

// helpers
import styled from 'styled-components';
import usePrevious from '../../../../hooks/usePrevious';

// components
import { Input } from '@ui';

interface IProps {
  className?: string;
  focus: boolean;
  autoFocus: boolean;
  value: string;
  onFocus: any;
  onChange: any;
  onKeyDown: any;
  onBlur: any;
  onPaste: any;
  disabled: boolean;
}

const OTPSingleInput = React.memo((props: IProps) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef: any = React.useRef(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef && inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <StyledInput {...rest} ref={inputRef} placeholder="" />;
});

const StyledInput = styled(Input)`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizeLg};
  border-radius: 5px;
  &:focus {
    margin-top: -5px;
  }
`;

export default OTPSingleInput;
