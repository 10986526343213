import * as yup from 'yup';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from '../common';
import {
  BIG_DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
} from '../../constants/global';

const RequiredEmploymentHistorySchema = yup.object().shape({
  isSelfEmployed: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  isBusinessUnderOwnName: NULLABLE_BOOLEAN_VALIDATION_RULE,

  employerName: STRING_VALIDATION_RULE.when(
    ['isSelfEmployed'],
    (_, schema): any => {
      return schema
        .min(3, {
          key: 'form:validation_messages.min_characters',
          values: {
            number: 3,
          },
        })
        .max(NAME_MAX_LENGTH, {
          key: 'form:validation_messages.max_characters',
          values: {
            number: NAME_MAX_LENGTH,
          },
        });
    },
  ).when(
    ['isSelfEmployed', 'isBusinessUnderOwnName'],
    (values, schema): any => {
      if (typeof values[0] == 'boolean' && (!values[0] || !values[1])) {
        return schema.required('form:validation_messages.required');
      }
    },
  ),

  occupation: STRING_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: NAME_MAX_LENGTH,
    },
  }).when(['isSelfEmployed', 'isBusinessUnderOwnName'], {
    is: (isSelfEmployed: boolean | null, isBusinessUnderOwnName: boolean) =>
      typeof isSelfEmployed == 'boolean' &&
      (!isSelfEmployed || !isBusinessUnderOwnName),
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  natureOfBusiness: STRING_VALIDATION_RULE.max(DESCRIPTION_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: DESCRIPTION_MAX_LENGTH,
    },
  }).when('isSelfEmployed', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
});

const OptionalEmploymentHistorySchema = yup.object().shape({
  isSelfEmployed: NULLABLE_BOOLEAN_VALIDATION_RULE,
  isBusinessUnderOwnName: NULLABLE_BOOLEAN_VALIDATION_RULE,

  employerName: STRING_VALIDATION_RULE.when(
    ['isSelfEmployed'],
    (_, schema): any => {
      return schema.max(NAME_MAX_LENGTH, {
        key: 'form:validation_messages.max_characters',
        values: {
          number: NAME_MAX_LENGTH,
        },
      });
    },
  ),

  occupation: STRING_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: NAME_MAX_LENGTH,
    },
  }),

  natureOfBusiness: STRING_VALIDATION_RULE.max(DESCRIPTION_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: DESCRIPTION_MAX_LENGTH,
    },
  }),
});

export const SourceOfWealthValidationSchema = yup.object().shape({
  isSave: yup.boolean().required(),

  employmentHistory: yup
    .array()
    .when(['isSave'], (values, schema, value): any => {
      if (values[0]) {
        return schema.of(OptionalEmploymentHistorySchema);
      }

      if (value.value.length > 1) {
        return schema.of(OptionalEmploymentHistorySchema);
      } else {
        return schema.of(RequiredEmploymentHistorySchema);
      }
    }),

  sourceOfWealthCategories: yup.array().when('isSave', {
    is: false,
    then: (schema) =>
      schema
        .of(STRING_VALIDATION_RULE)
        .required('form:validation_messages.required')
        .min(1, 'form:validation_messages.required'),
  }),

  sourceOfWealthDescription: STRING_VALIDATION_RULE.max(
    BIG_DESCRIPTION_MAX_LENGTH,
    {
      key: 'form:validation_messages.max_characters',
      values: {
        number: BIG_DESCRIPTION_MAX_LENGTH,
      },
    },
  ).when('isSave', {
    is: false,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
});
