import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { accountManagementRulesAPI } from 'api/accountManagement/accountManagementRulesAPI';
import {
  onboardingAPI,
  OnboardingApprovalRuleFromQuery,
} from 'api/onboarding/onboardingAPI';

// components
import { Text, Form } from '@ui';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import AccountManagementRulesMatrixForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Approval/AccountManagementRulesMatrixForm';

const Summary = () => {
  const { t } = useTranslation('onboarding');
  const { response: approvalRules } = useFetch(
    () =>
      onboardingAPI.fetchApprovalRules(
        {
          page: 1,
          limit: 1,
        },
        'onboarding-account-management',
      ),
    [],
  );

  const { response: approvalRuleTriggerTypes } = useFetch(
    () => accountManagementRulesAPI.fetchApprovalRuleTriggerTypes(),
    [],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!approvalRuleTriggerTypes || !approvalRules) {
      return null;
    }

    if (!approvalRules.data.length) {
      return null;
    } else {
      const approvalRule = approvalRules
        .data[0] as OnboardingApprovalRuleFromQuery;

      const approvalConditions = approvalRule.ruleSets.map((ruleSet) => {
        const approvalGroupConditions = ruleSet.items.map((item) => ({
          customUniqId: item.group.id,
          approvalGroupId: item.group.id,
          requiredApprovers: item.minimumThreshold,
          maximumRequiredApprovers: item.group.participants.length,
          initialAutocompleteValue: {
            id: item.group.id,
            label: item.group.name,
          },
        }));

        const actionStatuses: { [key: number]: boolean } = {};
        approvalRuleTriggerTypes.forEach((e) => (actionStatuses[e.id] = true));

        return {
          actionStatuses,
          approvalGroupConditions,
        };
      });

      return {
        id: approvalRule._id,
        approvalRuleType: 'onboarding-account-management',
        isInitialTemplate: false,
        approvalConditions,
      };
    }
  }, [approvalRules, approvalRuleTriggerTypes]);

  return (
    <LoadingWrapper loading={!initialFormValues}>
      <Text
        weight="semi-bold"
        color={colorsTheme.colorWhite}
        variant="h5"
        gutterBottom
      >
        {t('administration.administration_rules.summary.sub_title')}
      </Text>
      <Form
        disabled
        enableReinitialize
        onSubmit={console.log}
        initialValues={initialFormValues}
        renderForm={
          <AccountManagementRulesMatrixForm
            triggerTypes={approvalRuleTriggerTypes || []}
          />
        }
      />
    </LoadingWrapper>
  );
};

export default Summary;
