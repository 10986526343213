import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { Trans } from 'react-i18next';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';

// components
import { Link } from '@ui';
import SectionDescriptionText from '../../SectionDescriptionText';

const SetupClientUser = () => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const navigateToUsersPage = () => {
    navigate(RoutePaths.AccountManagement_UserManagement);
  };

  return (
    <SectionDescriptionText>
      <Trans
        t={t}
        i18nKey="welcome_section.add_users.description"
        components={[
          <Link key="0" onClick={navigateToUsersPage}>
            {t(
              'welcome_section.opening_account.pending_approval_description.here',
            )}
          </Link>,
        ]}
      />
    </SectionDescriptionText>
  );
};

export default SetupClientUser;
