import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { IconSVG, Popover } from '@ui';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

const IdentificationTabIsNotEditableInNonKYCFormTooltip = () => {
  const { t } = useTranslation('onboarding');

  return (
    <Popover
      content={t(
        'identification.identification_form_is_not_editable_in_non_kyc_mode_tooltip',
      )}
      placement="rightTop"
      overlayStyle={{ maxWidth: '600px' }}
    >
      <StyledIconSVG
        size="small"
        component={InfoIcon}
        color={colorsTheme.colorWarning}
      />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.paddingXs};
`;

export default IdentificationTabIsNotEditableInNonKYCFormTooltip;
