import React from 'react';

// helpers
import { useTranslation } from 'react-i18next';

// components
import { FormField } from '@ui';
import { FormInput } from '@ui';

interface IProps {
  isViewOnly?: boolean;
  isEditMode?: boolean;
}

const InternalTransferTemplateForm = ({ isViewOnly, isEditMode }: IProps) => {
  const { t } = useTranslation('transfers');
  const gridSizes = { xl: 4, lg: 4, md: 5, sm: 24, xs: 24 };

  return (
    <>
      <FormField
        name="transferFields.accountName"
        component={FormInput}
        disabled={isViewOnly || isEditMode}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.internal_transfer_template.account_name',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.accountNumber"
        component={FormInput}
        disabled={isViewOnly || isEditMode}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.internal_transfer_template.account_number',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.recipientReference"
        component={FormInput}
        disabled={isViewOnly}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.internal_transfer_template.recipient_reference',
        )}
        labelCol={gridSizes}
      />
    </>
  );
};

export default InternalTransferTemplateForm;
