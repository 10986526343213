import React from 'react';

// helpers
import { DocumentHelpers } from '../../../helpers/documents';
import { StyledComponentProps } from '../../../typings/common';

// components
import { Link } from '@ui';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';

interface IProps extends StyledComponentProps {
  text?: string;
  documentId: string;
  fileId?: string;
  fileName?: string;
  onClick?: () => void;
}

const DocumentLink = ({
  text,
  documentId,
  fileId = '',
  fileName,
  onClick,
  ...rest
}: IProps) => {
  const handleDownloadDocument = async () => {
    await DocumentHelpers.downloadDocument(documentId, fileId, fileName || '');
    onClick && onClick();
  };

  return (
    <Link {...rest} onClick={handleDownloadDocument}>
      <EllipsisTooltip maxTextContainerWidth="300px" title={text}>
        {text}
      </EllipsisTooltip>
    </Link>
  );
};

export default DocumentLink;
