import React from 'react';

// helpers
import { TransfersHelpers } from 'helpers/transfers/transfers';
import { AutocompleteProps } from '@ui';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';
import { TransferTemplateStatuses } from 'enums/accounting/templates';
import { ITransferTemplateShortModel } from '../../../../../../typings/accounting/transfer';

// components
import { FormAutocomplete } from '@ui';

export type WireTransferTemplateAutocompleteProps =
  AutocompleteProps<ITransferTemplateShortModel>;

const WireTransferTemplateAutocomplete = (
  props: WireTransferTemplateAutocompleteProps,
) => {
  const fetchData = async (
    searchQuery: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } =
      await transferTemplatesAPI.getInternationalTransferTemplates({
        limit,
        page,
        status: TransferTemplateStatuses.Active,
        name: searchQuery,
      });

    return {
      total,
      options: data.map((e) => ({
        id: e.id,
        label: TransfersHelpers.getTransferTemplateName(String(e.id), e.name),
        model: e,
      })),
    };
  };

  return <FormAutocomplete {...props} fetchData={fetchData} />;
};

export default WireTransferTemplateAutocomplete;
