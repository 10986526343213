import React from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';
import { AddButtonProps } from './types';

// components
import { IconSVG, Button } from '@ui';
import { ReactComponent as PlusIcon } from 'resources/icons/remix-icons/add-line.svg';

const AddButton: React.FC<AddButtonProps> = ({ children, ...rest }) => {
  return (
    <Button type="bordered" {...rest}>
      <StyledIconSVG component={PlusIcon} color={colorsTheme.colorWhite} />
      {children}
    </Button>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default React.memo(AddButton);
