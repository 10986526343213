import React from 'react';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { Col, Row, Text } from '@ui';

interface IProps {
  title: string;
  content: any;
  loading?: boolean;
}

const DescriptionItem = ({ title, content, loading }: IProps) => (
  <LoadingWrapper loading={!!loading}>
    <Row gutter={[6, 0]}>
      <Col>
        <Text variant="body1" weight="semi-bold">
          {title}:
        </Text>
      </Col>
      <Col>
        <Text variant="body1">
          {typeof content == 'function' ? content() : content}
        </Text>
      </Col>
    </Row>
  </LoadingWrapper>
);

export default DescriptionItem;
