import React from 'react';
import { Comment as AntdComment } from 'antd';
import { CommentProps } from './types';

/**
 * The `Comment` component displays a user comment, typically including an avatar, author name,
 * datetime, content, and actions.
 *
 * @param {CommentProps} props - The properties defined in `CommentProps`.
 */
const Comment: React.FC<CommentProps> = ({
  className,
  actions,
  author,
  avatar,
  content,
  datetime,
  children,
  ...rest
}) => {
  return (
    <AntdComment
      className={className}
      actions={actions}
      author={author}
      avatar={avatar}
      content={content}
      datetime={datetime}
      {...rest}
    >
      {children}
    </AntdComment>
  );
};

export default Comment;
