import React from 'react';

// helpers
import { transactionsAPI } from 'api/accounting/transactionsAPI';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Autocomplete, AutocompleteProps } from '@ui';

type TransactionAccountNamesAutocompleteProps = AutocompleteProps<string>;

const TransactionAccountNamesAutocomplete = (
  props: TransactionAccountNamesAutocompleteProps,
) => {
  const fetchOptionsCallback = async (
    searchQuery: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } = await transactionsAPI.fetchTransactionAccountNames({
      search: searchQuery,
      limit,
      page,
    });

    return {
      total,
      options: data.map((option) => ({
        id: option,
        model: option,
        label: (
          <DivAlignCenter>
            <EllipsisTooltip
              placement="right"
              overlayStyle={{ maxWidth: '300px' }}
              title={option}
            >
              {option}
            </EllipsisTooltip>
          </DivAlignCenter>
        ),
      })),
    };
  };

  return <Autocomplete {...props} fetchData={fetchOptionsCallback} />;
};

export default TransactionAccountNamesAutocomplete;
