import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { ReactComponent as ArrowDownIcon } from 'resources/icons/remix-icons/arrow-down-s-line.svg';
import { Text, IconSVG, Dropdown, DropdownProps, Menu, MenuItem } from '@ui';

interface IProps {
  options: OptionModel[];

  defaultSelectedKey?: string | number;
  dropdownProps?: DropdownProps;
  onSelect?: (newValue: string | number, option: OptionModel) => void;
  renderSelectedOption?: (selectedOption: string) => React.ReactNode;
}

export interface OptionModel {
  key: string | number;
  label: string;
}

const DropdownField = ({
  options,
  onSelect,
  dropdownProps,
  renderSelectedOption,
  defaultSelectedKey,
  ...rest
}: IProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionModel>(
    getDefaultValue(options, defaultSelectedKey),
  );

  function getDefaultValue(
    options: OptionModel[],
    defaultSelected?: string | number,
  ) {
    let result = options[0];

    if (defaultSelected) {
      const elIndex = options.findIndex(({ key }) => key === defaultSelected);
      if (elIndex) {
        result = options[elIndex];
      }
    }

    return result;
  }

  const renderMenu = (options: OptionModel[]) => {
    const menuOptions = options.map((option) => (
      <MenuItem key={option.key} onClick={() => handleOptionSelect(option)}>
        {option.label}
      </MenuItem>
    ));

    return <StyledMenu>{menuOptions}</StyledMenu>;
  };

  const handleOptionSelect = (newOption: OptionModel) => {
    setSelectedOption(newOption);
    onSelect && onSelect(newOption.key, newOption);
  };

  return (
    <StyledDropdownWrapper {...rest}>
      <Dropdown
        {...dropdownProps}
        placement="bottomRight"
        trigger={['click']}
        overlay={() => renderMenu(options)}
      >
        <div>
          {renderSelectedOption ? (
            renderSelectedOption(selectedOption.label || '')
          ) : (
            <Text variant="body1" weight="semi-bold">
              {selectedOption && selectedOption.label}
            </Text>
          )}
          <StyledIconSVG
            component={ArrowDownIcon}
            color={colorsTheme.colorWhite}
          />
        </div>
      </Dropdown>
    </StyledDropdownWrapper>
  );
};

const StyledDropdownWrapper = styled.div`
  min-width: 150px;

  display: flex;
  align-items: center;

  padding: 7px 13px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colorDarkL2};

  .ant-dropdown-trigger {
    cursor: pointer;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXs};
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  margin-top: 7px;
  max-height: 400px;
  overflow: auto;
`;

export default DropdownField;
