import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import { Text, FormField, IconSVG } from '@ui';
import OTPField from 'components/Forms/FormComponents/OTPField';
import SubmitButton from 'components/Buttons/SubmitButton';
import { ReactComponent as EditIcon } from 'resources/icons/remix-icons/edit-line.svg';
import { ReactComponent as NotificationIcon } from 'resources/icons/remix-icons/message-2-line.svg';

interface IProps {
  handleEditModalOpen?: () => void;
}

const InnerForm = ({ handleEditModalOpen }: IProps) => {
  const { t } = useTranslation('auth');

  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <StyledIconWrapper>
        <IconSVG
          component={NotificationIcon}
          size="large"
          color={colorsTheme.colorWhite}
        />
      </StyledIconWrapper>

      <StyledText variant="body1">
        {t('phone_verification.description')}
        <StyledPhone variant="body1" gutterBottom>
          {values?.phoneNumber}
          {handleEditModalOpen ? (
            <StyledIconSVG
              component={EditIcon}
              color={colorsTheme.colorWhite}
              onClick={handleEditModalOpen}
            />
          ) : null}
        </StyledPhone>
      </StyledText>

      <StyledOTPFieldWrapper>
        <FormField
          name="code"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </StyledOTPFieldWrapper>

      <StyledSubmitButton fullWidth>
        {t('phone_verification.verify')}
      </StyledSubmitButton>
    </>
  );
};

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.marginSm};
  padding-left: 15px;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledPhone = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin: ${({ theme }) => theme.marginXs} 0px;
`;

const StyledOTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default InnerForm;
