import React, { Ref } from 'react';

// helpers
import { IDocumentForm } from 'typings/onboarding/onboarding';
import {
  IDocumentAssociationInfo,
  ShortDocumentModel,
} from 'typings/documents/documents';
import { FormikHelpers, FormikProps } from 'formik';
import { ApplicationDocumentationValidationSchema } from 'validations/onboarding-new/application-documentation';
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from 'enums/onboarding/crm';

// components
import InnerForm from './InnerForm';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import LastStepSubmitButtons, {
  LastStepSubmitButtonsFormValuesModel,
} from '../Components/LastStepSubmitButtons';
import { Form } from '@ui';

export interface AdditionalFieldFormItemModel {
  id?: string;
  name: string;
  value: any | undefined;
  description: string;
  type: AdditionalFieldTypes;
  isNotProvidedReason: boolean;
  notProvidedReason: string;
  relatedTo: 'any' | 'contact' | 'organization';
  review: { isValid: boolean; comment: string } | null;
  options: {
    onboardingRequired: boolean;
    isForRegulatedOnly: boolean;
    documentRequiresCertification: boolean;
    selectOptions: string[];
    formDocument: IDocumentForm;
  };
}

export interface AdditionalDocumentFormItemModel {
  id?: string;
  name: string;
  description: string;
  documents: ShortDocumentModel[];
}

export interface FormValuesModel extends LastStepSubmitButtonsFormValuesModel {
  onboardingEntryType: OnboardingEntryTypes;
  documentsAssociation: IDocumentAssociationInfo;
  additionalFields: AdditionalFieldFormItemModel[];
  additionalDocuments: AdditionalDocumentFormItemModel[];
}

type SubmitType = (
  values: FormValuesModel,
  helpers: FormikHelpers<FormValuesModel>,
) => Promise<void>;

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel;
  onSubmit: SubmitType | null;

  canSendMessage?: boolean;
  disabled?: boolean;
  canSubmitForReview?: boolean;
  showOnlySaveButton?: boolean;

  showBackButton?: boolean;
  submitButtonText?: string;
}

const ApplicationDocumentationForm = ({
  formRef,
  canSubmitForReview,
  initialFormValues,
  onSubmit,
  disabled,
  showOnlySaveButton,
  submitButtonText,
  showBackButton,
  canSendMessage,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      confirmExitWithoutSaving
      onSubmit={onSubmit ? onSubmit : console.log}
      disabled={disabled}
      initialValues={initialFormValues}
      validationSchema={ApplicationDocumentationValidationSchema}
      renderForm={
        <>
          <InnerForm canSendMessage={canSendMessage} />

          <HideIfDisabledForm>
            <LastStepSubmitButtons
              showBackButton={showBackButton}
              canSubmitForReview={canSubmitForReview}
              showOnlySaveButton={showOnlySaveButton}
              submitButtonText={submitButtonText}
            />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default ApplicationDocumentationForm;
