import React, { useMemo } from 'react';

// helpers
import { AccountsHelpers } from 'helpers/accounts';
import { AutocompleteProps } from '@ui';
import { transactionRulesAPI } from 'api/accounting/transactionRulesAPI';
import { IApprovalRuleAccount } from 'typings/approvalManagement/accountManagement';

// components
import { FormAutocomplete } from '@ui';

export interface AccountsForTransactionRulesAutocompleteProps
  extends AutocompleteProps<IApprovalRuleAccount> {
  value?: any;
}

const AccountsForTransactionRulesAutocomplete = ({
  value,
  initialValue,
  ...rest
}: AccountsForTransactionRulesAutocompleteProps) => {
  const formattedValue = useMemo(() => {
    return value && value.length
      ? value.map((e: any) => e.bankAccountNumber)
      : undefined;
  }, [value]);

  const fetchOptions = async (search: string, page: number, limit: number) => {
    const { data, total } =
      await transactionRulesAPI.fetchTransactionRuleAccounts({
        search,
        page,
        limit,
      });

    return {
      total,
      options: data.map((e) => ({
        id: e.accountNumber,
        label: AccountsHelpers.generateAccountFullName(
          e.accountName,
          e.accountNumber,
          e.currencyCode,
        ),
        model: {
          bankAccountNumber: e.accountNumber,
          bankAccountName: e.accountName,
          bankAccountCurrency: e.currencyCode,
        },
      })),
    };
  };

  return (
    <FormAutocomplete
      {...rest}
      value={formattedValue}
      fetchData={fetchOptions}
      initialValue={initialValue}
      mode="multiple"
    />
  );
};

export default AccountsForTransactionRulesAutocomplete;
