import React, { Ref } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormikHelpers, FormikProps } from 'formik';

// constants
import { ApplicantInformationOrganizationShortValidationSchema } from 'validations/onboarding-new/applicant-information';

// components
import InnerForm from './InnerForm';
import { SectionIntro } from '@ui';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';
import { Form } from '@ui';

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  legalName: string;
}

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel;
  onSubmit: (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;
  disabled?: boolean;
  showOnlySaveButton?: boolean;
}

const GeneralInformationShortForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
}: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <Form<FormValuesModel>
      enableReinitialize
      confirmExitWithoutSaving
      innerRef={formRef}
      onSubmit={onSubmit}
      disabled={disabled}
      initialValues={initialFormValues}
      validationSchema={ApplicantInformationOrganizationShortValidationSchema}
      renderForm={
        <>
          <SectionIntro
            title={t('applicant_information.general.title')}
            description={t('applicant_information.description_entity')}
          />

          <InnerForm />

          <HideIfDisabledForm>
            <SubmitButtons
              showBackButton={false}
              showOnlySaveButton={showOnlySaveButton}
            />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default GeneralInformationShortForm;
