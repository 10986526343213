import React, { ChangeEvent, useCallback } from 'react';

// helpers
import { debounce } from 'lodash';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from 'constants/global';

// components
import { Input } from '@ui';
import { SearchInputProps } from './types';

const SearchInput: React.FC<SearchInputProps> = ({ onSearch, ...rest }) => {
  const debouncedFetchData = useCallback(
    debounce(onSearch, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedFetchData(e.target.value);
  };

  return <Input onChange={handleChange} {...rest} />;
};

export default SearchInput;
