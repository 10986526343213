import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipProps } from './types';

/**
 * The `Tooltip` component displays additional information in a small popup box
 * when the user hovers over or focuses on a component.
 *
 * @param {TooltipProps} props - The properties defined in `TooltipProps`.
 */
const Tooltip: React.FC<TooltipProps> = ({
  className,
  title,
  children,
  ...rest
}) => {
  return (
    <AntdTooltip className={className} title={title} {...rest}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
