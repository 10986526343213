import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { TransferTemplateTypes } from 'enums/accounting/transfers';
import { RequiredPropsForModalDialogModel } from '@ui';
import {
  transferTemplatesAPI,
  transferTemplatesApiHelpers,
} from 'api/accounting/transferTemplatesAPI';
import {
  INewBlockchainTransferRecipientFormValues,
  INewTransferTemplateFormValues,
} from 'typings/accounting/transfer';

// components
import TransferTemplateModal from 'components/ModalDialogs/TemplateModalDialogs/Transfers/TransferTemplateModal';
import { Message } from '@ui';

interface IProps extends RequiredPropsForModalDialogModel {
  initialValues: INewTransferTemplateFormValues | null;
}

const DuplicateTransferTemplateModal = ({
  isVisible,
  closeCallback,
  initialValues,
}: IProps) => {
  const { t } = useTranslation('transfers');
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const modalTitle = useMemo(() => {
    if (initialValues && isVisible) {
      return t('transfer_templates.transfer_template_modal.duplicate.title', {
        templateType: t(
          `transfer_templates.transfer_template_types.${TransferTemplateTypes[initialValues.templateType as TransferTemplateTypes]}`,
        ),
      });
    }

    return '';
  }, [isVisible, initialValues]);

  const handleSubmit = async (values: INewTransferTemplateFormValues) => {
    switch (values.templateType) {
      case TransferTemplateTypes.Internal:
        {
          const requestBody =
            transferTemplatesApiHelpers.generateInternalTemplateRequestBody(
              values,
            );
          await transferTemplatesAPI.createInternalTransferTemplate(
            requestBody,
          );
        }
        break;

      case TransferTemplateTypes.Wire:
        {
          const requestBody =
            transferTemplatesApiHelpers.generateInternationalTemplateRequestBody(
              values,
            );
          await transferTemplatesAPI.createInternationalTransferTemplate(
            requestBody,
          );
        }
        break;

      case TransferTemplateTypes.Blockchain:
        {
          const requestBody =
            transferTemplatesApiHelpers.generateBlockchainTemplateRequestBody(
              values as INewTransferTemplateFormValues<INewBlockchainTransferRecipientFormValues>,
            );
          await transferTemplatesAPI.createBlockchainTransferTemplate(
            requestBody,
          );
        }
        break;
    }

    Message.success(
      t(
        'transfer_templates.transfer_template_modal.duplicate.success_submit_message',
      ),
    );
    closeCallback(true);
  };

  return (
    <TransferTemplateModal
      title={modalTitle}
      isVisible={isVisible}
      onSubmit={handleSubmit}
      closeCallback={closeCallback}
      initialValues={initialValues}
      disabled={!isTwoFactorVerificationEnabled}
    />
  );
};

export default DuplicateTransferTemplateModal;
