import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { BlockchainTransferTemplate } from 'typings/accounting/transfer';

// components
import EllipsisTooltip from '../../../../../../../Tooltips/EllipsisTooltip';
import { Col, Row, Card } from '@ui';

interface IProps {
  template: BlockchainTransferTemplate;
}

const BlockchainTransferTemplateInfo = memo(({ template }: IProps) => {
  const { t } = useTranslation('transfers');

  return (
    <StyledCard>
      <Row>
        <StyledCol
          span={10}
        >{`${t('blockchain_transfer.template_info_section.network')}:`}</StyledCol>
        <StyledCol span={14}>
          <EllipsisTooltip
            title={template.networkChain}
            maxTextContainerWidth="250px"
            placement="left"
          >
            {template.networkChain}
          </EllipsisTooltip>
        </StyledCol>

        <StyledCol
          span={10}
        >{`${t('blockchain_transfer.template_info_section.address')}:`}</StyledCol>
        <StyledCol span={14}>
          <EllipsisTooltip
            title={template.beneficiaryAddress}
            maxTextContainerWidth="250px"
            placement="left"
          >
            {template.beneficiaryAddress}
          </EllipsisTooltip>
        </StyledCol>

        <StyledCol
          span={10}
        >{`${t('blockchain_transfer.template_info_section.address_tag')}:`}</StyledCol>
        <StyledCol span={14}>
          <EllipsisTooltip
            title={template.beneficiaryTag}
            maxTextContainerWidth="250px"
            placement="left"
          >
            {template.beneficiaryTag}
          </EllipsisTooltip>
        </StyledCol>
      </Row>
    </StyledCard>
  );
});

const StyledCard = styled(Card)`
  margin-bottom: 10px;
`;

const StyledCol = styled(Col)`
  padding: 4px;
`;

export default BlockchainTransferTemplateInfo;
