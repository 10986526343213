import React from 'react';
import { SwitchProps } from './types';
import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from 'antd';

/**
 * The `Switch` component provides a simple on/off toggle for a binary state.
 * It can display loading, be disabled, and offer custom content for checked and unchecked states.
 *
 * @param {SwitchProps} props - The properties defined in `SwitchProps`.
 */
const Switch: React.FC<SwitchProps> = ({
  checked,
  defaultChecked,
  disabled,
  size = 'default',
  checkedChildren,
  unCheckedChildren,
  onChange,
  className,
  style,
  loading,
}) => {
  const handleChange = (checkedValue: boolean) => {
    if (onChange) {
      onChange(checkedValue);
    }
  };

  const switchProps: AntdSwitchProps = {
    checked,
    defaultChecked,
    disabled,
    size,
    checkedChildren,
    unCheckedChildren,
    onChange: handleChange,
    className,
    style,
    loading,
  };

  return <AntdSwitch {...switchProps} />;
};

export default Switch;
