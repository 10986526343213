import * as Yup from 'yup';

import { NULLABLE_STRING_VALIDATION_RULE } from '../common';

export const createAddressValidationSchema = Yup.object().shape({
  currency: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  network: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});
