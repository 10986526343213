import React from 'react';
import { Popover as AntdPopover } from 'antd';
import { PopoverProps } from './types';

/**
 * The `Popover` component displays additional information in a popup box when the user
 * hovers over or clicks an element. It wraps AntD's `Popover` to ensure a consistent interface
 * and styling with the rest of your design system.
 *
 * @param {PopoverProps} props - The properties defined in `PopoverProps`.
 */
const Popover: React.FC<PopoverProps> = ({
  className,
  title,
  content,
  children,
  ...rest
}) => {
  return (
    <AntdPopover
      className={className}
      title={title}
      content={content}
      {...rest}
    >
      {children}
    </AntdPopover>
  );
};

export default Popover;
