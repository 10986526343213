import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StyledComponentProps } from 'typings/common';

// components
import { Text } from '@ui';
import InfoTooltip from '../../../InfoTooltip';

type IProps = StyledComponentProps;

const PendingApprovalTooltip = (props: IProps) => {
  const { t } = useTranslation('account_management');

  return (
    <InfoTooltip
      {...props}
      tooltipContent={
        <StyledText variant="body1">
          {t('external_services.circle.pending_approval_tooltip')}
        </StyledText>
      }
    />
  );
};

const StyledText = styled(Text)`
  white-space: pre-line;
`;

export default PendingApprovalTooltip;
