import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { clientMessagingAPI } from 'api/messaging/clientMessagingAPI';
import { ThreadCategoryModel } from 'typings/messaging/messages';
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

export type IProps = SelectProps<ThreadCategoryModel>;

const MessageCategorySelect = (props: IProps) => {
  const { t } = useTranslation('profile');
  const { response, loading } = useFetch(
    () => clientMessagingAPI.fetchThreadCategories(),
    [],
  );

  const options = useMemo<SelectOption<ThreadCategoryModel>[]>(() => {
    if (!response) {
      return [];
    }

    return response.map((e) => ({
      id: e.id,
      label: t([`messages.message_categories.${e.name}`, e.name]),
      model: e,
    }));
  }, [response, t]);

  return <FormSelect {...props} options={options} loading={loading} />;
};

export default MessageCategorySelect;
