import React from 'react';
import { Table as AntdTable } from 'antd';
import { TableAntdProps } from './types';

const TableAntd = <RecordType extends object = any>({
  className,
  columns,
  dataSource,
  children,
  ...rest
}: TableAntdProps<RecordType>) => {
  return (
    <AntdTable
      className={className}
      columns={columns}
      dataSource={dataSource}
      {...rest}
    >
      {children}
    </AntdTable>
  );
};

export default TableAntd;
