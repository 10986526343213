import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import { RangePickerProps } from './types';

const { RangePicker: AntdRangePicker }: any = AntdDatePicker;

/**
 * The `RangePicker` component allows selecting a range of dates or times,
 * providing a start and end date/time selection.
 *
 * @param {RangePickerProps} props - The properties defined in `RangePickerProps`.
 */
const RangePicker: React.FC<RangePickerProps> = ({
  className,
  value,
  defaultValue,
  onChange,
  onCalendarChange,
  onOk,
  disabledDate,
  ...rest
}) => {
  return (
    <AntdRangePicker
      className={className}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onCalendarChange={onCalendarChange}
      onOk={onOk}
      disabledDate={disabledDate}
      {...rest}
    />
  );
};

export default RangePicker;
