import React from 'react';

// helpers
import styled from 'styled-components';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { ShortDocumentModel } from 'typings/documents/documents';
import { ReplyFormValidationSchema } from 'validations/messaging';

// components
import { Form } from '@ui';
import InnerForm from './InnerForm';
import SubmitButton from '../../../../../Buttons/SubmitButton';

export interface FormValuesModel {
  message: string;
  attachments: ShortDocumentModel[];
}

interface IProps {
  initialFormValues: FormValuesModel;
  onSubmit: (values: FormValuesModel) => Promise<void>;
}

const ReplyForm = ({ onSubmit, initialFormValues }: IProps) => {
  const { t } = useTranslation('profile');

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    await onSubmit(values);
    helpers.resetForm({ values: initialFormValues });
  };

  return (
    <Form<FormValuesModel>
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      validationSchema={ReplyFormValidationSchema}
      renderForm={
        <>
          <InnerForm />
          <StyledSubmitButton type="primary">
            {t('messages.send_button')}
          </StyledSubmitButton>
        </>
      }
    />
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: ${({ theme }) => theme.marginXXs};
`;

export default ReplyForm;
