import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { LayoutProps } from './types';

/**
 * The `Layout` component provides a structural container for other layout elements
 * like `Header`, `Sider`, `Content`, and `Footer`. It helps build standard page
 * structures easily and consistently.
 *
 * @param {LayoutProps} props - The properties defined in `LayoutProps`.
 */
const Layout: React.FC<LayoutProps> = ({ className, children, ...rest }) => {
  return (
    <AntdLayout className={className} {...rest}>
      {children}
    </AntdLayout>
  );
};

export default Layout;
