import { SizeType } from 'antd/lib/config-provider/SizeContext';

/**
 * Supported field sizes, corresponding to Ant Design’s size options.
 */
export type FieldSize = SizeType;

/**
 * Validation rules for the field, derived from the form’s validation schema.
 */
export type FieldValidationRule = {
  type: 'required' | 'min' | 'max';
  params?: {
    min?: number;
    max?: number;
  };
};

/**
 * Possible alignments for the field label.
 */
export enum LABEL_ALIGN_ENUM {
  LEFT = 'left',
  RIGHT = 'right',
}

/**
 * Validation statuses to display on the field.
 */
export enum VALIDATE_STATUS_ENUM {
  ERROR = 'error',
  SUCCESS = 'success',
}

/**
 * The props accepted by the `FormField` component. This generic type allows passing
 * additional props (ComponentProps) to the underlying field component.
 */
export interface FormFieldPropsModel<ComponentProps = unknown> {
  /**
   * The name of the field (must match a field in your Formik form).
   */
  name: string;

  /**
   * The field component to render (e.g., Input, Select).
   * This should be a React component that accepts `value`, `onChange`, `onBlur`, etc.
   */
  component: React.ReactNode;

  /**
   * The label text or component to display next to the field.
   */
  label?: string | React.ReactNode;

  /**
   * An optional tooltip message displayed next to the label.
   */
  labelTooltip?: string;

  /**
   * Layout configuration for the label column (Ant Design grid props).
   */
  labelCol?: any;

  /**
   * The alignment of the label text.
   *
   * @default LABEL_ALIGN_ENUM.RIGHT
   */
  labelAlign?: LABEL_ALIGN_ENUM;

  /**
   * The size of the field component (e.g., 'large', 'middle', 'small').
   *
   * @default 'large'
   */
  size?: FieldSize;

  /**
   * If true, the field is disabled and cannot be edited.
   */
  disabled?: boolean;

  /**
   * Placeholder text displayed when the field is empty.
   */
  placeholder?: string;

  /**
   * If false, the error message is not displayed below the field. Only the red border indicates an error.
   *
   * @default true
   */
  shouldShowErrorMessage?: boolean;

  /**
   * An optional change handler that will be called whenever the field value changes.
   *
   * @param e The change event or new value.
   */
  onChange?: (e: any) => void;

  /**
   * Additional props specific to the underlying field component.
   */
  additionalProps?: ComponentProps;

  /**
   * Additional CSS class for styling.
   */
  className?: string;
}

/**
 * The props passed internally to the underlying field component.
 */
export interface InnerComponentModel {
  name: string;
  size?: FieldSize;
  onBlur: (e?: any) => void;
  onChange: (e: any) => void;
  placeholder?: string;
  disabled?: boolean;
  value: any;
  checked?: boolean;
  validationRules: FieldValidationRule[];
}

/**
 * The props for the inner FormItem component that wraps the field.
 */
export interface InnerFormItemProps {
  component: any;
  labelAlign?: LABEL_ALIGN_ENUM;
  validateStatus: VALIDATE_STATUS_ENUM;
  innerComponentProps: InnerComponentModel;
  labelCol: any;
  label?: string | React.ReactNode;
  style?: React.CSSProperties;
  help?: string;
}
