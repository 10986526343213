import React from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from '../../../resources/theme/styled/colors';
import { StyledComponentProps } from '../../../typings/common';

// components
import DivAlignCenter from '../DivAlignCenter';
import { Text, IconSVG } from '@ui';
import { ReactComponent as InfoIcon } from '../../../resources/icons/remix-icons/information-line.svg';

interface IProps extends StyledComponentProps {
  text: string;
}

const WarningIconWithText = ({ text, ...rest }: IProps) => {
  return (
    <DivAlignCenter {...rest}>
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
      <Text variant="body1" color={colorsTheme.colorWarning}>
        {text}
      </Text>
    </DivAlignCenter>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default WarningIconWithText;
