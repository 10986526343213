import React from 'react';

// helpers
import { industryCodesAPI } from 'api/crm/industryCodesAPI';
import { AutocompleteProps } from '@ui';

// components
import { FormAutocomplete } from '@ui';

export type IProps = AutocompleteProps;

const IndustryCodesAutocomplete = (props: IProps) => {
  const fetchIndustryCodes = async (
    searchText: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } = await industryCodesAPI.fetchIndustryCodes({
      search: searchText.trim(),
      page,
      limit,
    });

    return {
      total,
      options: data.map((industryCode) => ({
        id: industryCode.code,
        label: industryCode.code,
        model: industryCode,
      })),
    };
  };

  return <FormAutocomplete {...props} fetchData={fetchIndustryCodes} />;
};

export default IndustryCodesAutocomplete;
