import React from 'react';
import { PageHeader as AntdPageHeader } from 'antd';
import { PageHeaderProps } from './types';

/**
 * The `PageHeader` component displays the title, subtitle, breadcrumbs, and actions
 * at the top of a page or section. It helps provide context and easy navigation.
 *
 * @param {PageHeaderProps} props - The properties defined in `PageHeaderProps`.
 */
const PageHeader: React.FC<PageHeaderProps> = ({
  className,
  title,
  subTitle,
  children,
  ...rest
}) => {
  return (
    <AntdPageHeader
      className={className}
      title={title}
      subTitle={subTitle}
      {...rest}
    >
      {children}
    </AntdPageHeader>
  );
};

export default PageHeader;
