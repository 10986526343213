import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { AccountProcessingTypes } from '../../enums/accounting/transfers';
import { TransferTemplateStatuses } from 'enums/accounting/templates';
import { FetchResponseModel, FetchParamsModel } from '../../typings/common';
import {
  INewTransferTemplateFormValues,
  ITransferTemplateShortModel,
  IInternalTransferTemplate,
  IInternationalTransferTemplate,
  IEditInternalTemplateFormValues,
  IEditInternationalTemplateFormValues,
  INewInternalTransferRecipientFormValues,
  INewInternationalTransferRecipientFormValues,
  INewBlockchainTransferRecipientFormValues,
  IEditBlockchainTemplateFormValues,
  BlockchainTransferTemplate,
  BlockchainTransferTemplateModel,
} from '../../typings/accounting/transfer';

export interface INewInternalTemplateBody {
  accountName: string;
  accountNumber: string;
  isOnlyMe: boolean;
  recipientReference: string;
  templateName: string;
}

export interface INewBlockchainTemplateBody {
  templateName: string;
  networkChain: string;
  beneficiaryAddress: string;
  beneficiaryTag: string;
  processingType: AccountProcessingTypes;
  isOnlyMe: boolean;
}

export interface INewInternationalTemplateBody {
  templateName: string;
  templateType: number;
  isOnlyMe: boolean;
  reference: string;
  processingType: AccountProcessingTypes;

  intermediaryBankCodeType: number;
  intermediaryBankCode: string;

  beneficiaryCountry: string;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;
  beneficiaryAddress1: string;

  beneficiaryCity: string;
  beneficiaryDistrict?: string;
  beneficiaryPostCode: string;

  beneficiaryBankCodeType: number;
  beneficiaryBankCode: string;
}

export interface ArchiveTemplateBody {
  invalidityReason: string;
}

export interface TransferTemplateFetchParamsModel extends FetchParamsModel {
  name?: string;
  status?: TransferTemplateStatuses;
}

export interface FetchInternalTransferTemplatesParamsModel
  extends FetchParamsModel {
  name?: string;
}

export interface FetchBlockchainTransferTemplatesParamsModel
  extends FetchParamsModel {
  name?: string;
}

const transferTemplatesAPI = {
  createInternalTransferTemplate(body: INewInternalTemplateBody) {
    return APIService.post<number>(
      `${APIConfig.accountingApi}/templates/internal`,
      body,
    ).then(({ data }) => data);
  },

  createInternationalTransferTemplate: (
    template: INewInternationalTemplateBody,
  ) => {
    return APIService.post<number>(
      `${APIConfig.accountingApi}/templates/international`,
      template,
    ).then(({ data }) => data);
  },

  createBlockchainTransferTemplate: (template: INewBlockchainTemplateBody) => {
    return APIService.post<number>(
      `${APIConfig.accountingApi}/templates/blockchain`,
      template,
    ).then(({ data }) => data);
  },

  getInternalTransferTemplates: (
    params: FetchInternalTransferTemplatesParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ITransferTemplateShortModel>>(
      `${APIConfig.accountingApi}/templates/internal`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  getBlockchainTransferTemplates: (
    params: FetchBlockchainTransferTemplatesParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<BlockchainTransferTemplateModel>>(
      `${APIConfig.accountingApi}/templates/blockchain`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchBlockchainNetworks: () => {
    return APIService.get<FetchResponseModel<string>>(
      `${APIConfig.accountingApi}/templates/circle/chains`,
    ).then(({ data }) => data);
  },

  fetchCurrenciesByNetwork: (chain?: string) => {
    return APIService.get<string[]>(
      `${APIConfig.accountingApi}/circle/chains/currencies`,
      { params: { chain } },
    ).then(({ data }) => data);
  },

  getInternationalTransferTemplates: (
    params: TransferTemplateFetchParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ITransferTemplateShortModel>>(
      `${APIConfig.accountingApi}/templates/international`,
      {
        params,
      },
    ).then((response) => response.data);
  },

  getTransferTemplates: (params: TransferTemplateFetchParamsModel) => {
    return APIService.get<FetchResponseModel<ITransferTemplateShortModel>>(
      `${APIConfig.accountingApi}/templates`,
      { params },
    ).then(({ data }) => data);
  },

  getInternalTransferTemplate: (templateId: number) => {
    return APIService.get<IInternalTransferTemplate>(
      `${APIConfig.accountingApi}/templates/internal/${templateId}`,
    ).then(({ data }) => data);
  },

  getInternationalTransferTemplate: (templateId: number) => {
    return APIService.get<IInternationalTransferTemplate>(
      `${APIConfig.accountingApi}/templates/international/${templateId}`,
    ).then(({ data }) => data);
  },

  getBlockchainTransferTemplate: (templateId: number) => {
    return APIService.get<BlockchainTransferTemplate>(
      `${APIConfig.accountingApi}/templates/blockchain/${templateId}`,
    ).then(({ data }) => data);
  },

  editInternalTransferTemplate: (
    templateId: number,
    updatedTemplate: INewInternalTemplateBody,
  ) => {
    return APIService.patch<IInternalTransferTemplate>(
      `${APIConfig.accountingApi}/templates/internal/${templateId}`,
      {
        ...updatedTemplate,
        id: templateId,
      },
    ).then(({ data }) => data);
  },

  editInternationalTransferTemplate: (
    templateId: number,
    updatedTemplate: INewInternationalTemplateBody,
  ) => {
    return APIService.patch<IInternationalTransferTemplate>(
      `${APIConfig.accountingApi}/templates/international/${templateId}`,
      {
        ...updatedTemplate,
        id: templateId,
      },
    ).then(({ data }) => data);
  },

  archiveTransferTemplate: (templateId: number, body: ArchiveTemplateBody) => {
    return APIService.post(
      `${APIConfig.accountingApi}/templates/international/${templateId}/archive`,
      body,
    ).then(({ data }) => data);
  },

  activateTransferTemplate: (templateId: number) => {
    return APIService.post(
      `${APIConfig.accountingApi}/templates/international/${templateId}/activate`,
    ).then(({ data }) => data);
  },

  editBlockchainTransferTemplate: (
    templateId: number,
    updatedTemplate: INewBlockchainTemplateBody,
  ) => {
    return APIService.patch<BlockchainTransferTemplate>(
      `${APIConfig.accountingApi}/templates/blockchain/${templateId}`,
      {
        ...updatedTemplate,
        id: templateId,
      },
    ).then(({ data }) => data);
  },
};

function generateInternalTemplateRequestBody(
  values: INewTransferTemplateFormValues | IEditInternalTemplateFormValues,
): INewInternalTemplateBody {
  const { permissions, templateName } = values;
  const transferFields = {
    ...values.transferFields,
  } as INewInternalTransferRecipientFormValues;

  return {
    accountName: transferFields.accountName.trim(),
    accountNumber: transferFields.accountNumber.trim(),
    isOnlyMe: permissions === 'onlyMe',
    recipientReference: transferFields.recipientReference.trim(),
    templateName: templateName.trim(),
  };
}

function generateInternationalTemplateRequestBody(
  values: INewTransferTemplateFormValues | IEditInternationalTemplateFormValues,
): INewInternationalTemplateBody {
  const { permissions, templateName, templateType } = values;
  const transferFields = {
    ...values.transferFields,
  } as INewInternationalTransferRecipientFormValues;

  return {
    templateName: templateName.trim(),
    templateType: Number(templateType),
    isOnlyMe: permissions === 'onlyMe',
    reference: transferFields.reference.trim(),
    processingType: transferFields.isCircle
      ? AccountProcessingTypes.Circle
      : AccountProcessingTypes.Native,

    intermediaryBankCode: transferFields.intermediaryBank?.bankCode || '',
    intermediaryBankCodeType:
      transferFields.intermediaryBank?.bankCodeType || 0,

    beneficiaryAccountName: transferFields.accountName?.trim() || '',
    beneficiaryAccountNumber: transferFields.accountNumber?.trim() || '',
    beneficiaryCountry: transferFields.country || '',
    beneficiaryAddress1: transferFields.address?.trim() || '',

    beneficiaryCity: transferFields.beneficiaryCity.trim() || '',
    beneficiaryDistrict: transferFields.beneficiaryDistrict || '',
    beneficiaryPostCode: transferFields.beneficiaryPostCode.trim() || '',

    beneficiaryBankCode: transferFields.beneficiaryBank?.bankCode || '',
    beneficiaryBankCodeType: transferFields.beneficiaryBank?.bankCodeType || 0,
  };
}

function generateBlockchainTemplateRequestBody(
  values:
    | INewTransferTemplateFormValues<INewBlockchainTransferRecipientFormValues>
    | IEditBlockchainTemplateFormValues,
): INewBlockchainTemplateBody {
  const { permissions, templateName, transferFields } = values;

  return {
    templateName: templateName.trim(),
    isOnlyMe: permissions === 'onlyMe',
    processingType: transferFields.processingType,
    networkChain: transferFields.networkChain,
    beneficiaryAddress: transferFields.beneficiaryAddress,
    beneficiaryTag: transferFields.beneficiaryTag,
  };
}

const transferTemplatesApiHelpers = {
  generateInternalTemplateRequestBody,
  generateInternationalTemplateRequestBody,
  generateBlockchainTemplateRequestBody,
};

export { transferTemplatesAPI, transferTemplatesApiHelpers };
