import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { setChangeRequest } from 'redux/actions/personalDetails';
import { personalDetailsAPI } from 'api/profile/personalDetailsAPI';
import { useDispatch, useSelector } from 'react-redux';

// components
import KYCContactForm from '../KYCContactForm';
import { Text, Button, Col, Row, Modal } from '@ui';

const ContentForKYCUser = () => {
  const { t } = useTranslation(['profile', 'common']);
  const dispatch = useDispatch();
  const isChangeRequestCreated = useSelector<StateModel, boolean>(
    (state) => !!state.personalDetails.changeRequest,
  );

  const handleClickOnEditBtn = async () => {
    Modal.confirm({
      title: t('personal_details.kyc_form.update_kyc_data_modal.title'),
      width: 600,
      icon: null,
      closable: true,
      maskClosable: true,
      content: (
        <Text variant="body1" gutterBottom>
          {t('personal_details.kyc_form.update_kyc_data_modal.description')}
        </Text>
      ),
      okText: t('continue', { ns: 'common' }),
      cancelText: t('cancel', { ns: 'common' }),
      async onOk() {
        const response =
          await personalDetailsAPI.initiateEditKYCDataProcessForContact();
        dispatch(setChangeRequest(response));
      },
    });
  };

  return (
    <>
      {!isChangeRequestCreated && (
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <Button size="large" type="primary" onClick={handleClickOnEditBtn}>
              {t('personal_details.edit.edit_details_post_verified')}
            </Button>
          </Col>
        </Row>
      )}

      <KYCContactForm />
    </>
  );
};

export default ContentForKYCUser;
