import React from 'react';
import styled from 'styled-components';
import { AvatarProps } from './types';
import { Avatar as AntDAvatar } from 'antd';

/**
 * The `Avatar` component provides a styled circular icon or image
 * representing a user or entity.
 *
 * @param {AvatarProps} props - The properties defined in `AvatarProps`.
 */

const Avatar: React.FC<AvatarProps> = ({ icon }) => {
  return <StyledAntDAvatar icon={icon} />;
};

const StyledAntDAvatar = styled(AntDAvatar)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default React.memo(Avatar);
