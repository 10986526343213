import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '../../..';
import { useFormikContext } from 'formik';

// components
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import HideIfDisabledForm from '../../../../../../HideIfDisabledForm';
import { FormField, FormInput, DeleteButton, Col, Row } from '@ui';

interface IProps {
  fieldIndex: number;
  removeCallback: () => void;
}

const AdditionalDocumentFormItem = ({ fieldIndex, removeCallback }: IProps) => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <Row gutter={[16, 16]}>
      <Col xl={7} lg={12} md={12} sm={24} xs={24}>
        <FormField
          label={t(
            'application_documentation.additional_documents.document_name',
          )}
          name={`additionalDocuments.${fieldIndex}.name`}
          component={FormInput}
        />
      </Col>
      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
        <FormField
          label={t(
            'application_documentation.additional_documents.document_description',
          )}
          name={`additionalDocuments.${fieldIndex}.description`}
          component={FormInput}
        />
      </Col>
      <Col xl={8} lg={23} md={23} sm={24} xs={24}>
        <FormField
          label={t('application_documentation.additional_documents.document')}
          name={`additionalDocuments.${fieldIndex}.documents`}
          component={DocumentsField}
          additionalProps={{
            mode: 'multiple',
            association: values.documentsAssociation,
          }}
        />
      </Col>

      <Col xl={1} lg={1} md={1} sm={24} xs={24}>
        <HideIfDisabledForm hideIfSubmitting>
          <RemoveButtonWrapper>
            <DeleteButton onClick={removeCallback} />
          </RemoveButtonWrapper>
        </HideIfDisabledForm>
      </Col>
    </Row>
  );
};

const RemoveButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default AdditionalDocumentFormItem;
