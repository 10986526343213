import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { scrollTop } from 'helpers/utils';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { transfersAPI } from 'api/accounting/transfersAPI';
import { FormikHelpers } from 'formik';
import { transfersAdapter } from 'apiAdapters/accounting/transfersAdapter';
import { NewBlockchainTransferModel } from 'typings/accounting/transfer';

// components
import WarningIconWithText from 'components/Additional/WarningIconWithText';
import BlockchainTransferForm from 'components/Forms/TemplateForms/Transfers/BlockhainTransferForm';
import { Message } from '@ui';

const BlockchainTransfer = () => {
  const { t } = useTranslation('transfers');

  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const initialFormData = useMemo<NewBlockchainTransferModel>(
    () => ({
      sender: {
        accountNumber: '',
        reference: '',
        processingType: null,
        account: null,
      },
      recipient: {
        template: null,
        templateId: null,
        reference: '',
      },
      amount: null,
      transactionCurrencyId: null,
      valueDate: moment(),
      documents: [],
      transactionPurpose: '',
    }),
    [],
  );

  const handleSubmit = async (
    values: NewBlockchainTransferModel,
    helpers: FormikHelpers<NewBlockchainTransferModel>,
  ) => {
    const newTransfer =
      transfersAdapter.generateBodyForBlockchainTransfer(values);
    await transfersAPI.createBlockchainTransfer(newTransfer);
    helpers.resetForm({ values: initialFormData, errors: {} });
    Message.success(t('blockchain_transfer.success_submit_message'));
    scrollTop();
  };

  return (
    <>
      {!isTwoFactorVerificationEnabled ? (
        <StyledWarningInfoNot2FA
          text={t('blockchain_transfer.warning_not_2fa')}
        />
      ) : null}
      <BlockchainTransferForm
        initialValues={initialFormData}
        onSubmit={handleSubmit}
        disabled={!isTwoFactorVerificationEnabled}
      />
      ;
    </>
  );
};

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default BlockchainTransfer;
