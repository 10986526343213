import React from 'react';

// components
import { FormField } from '@ui';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import { FormInputTextArea } from '@ui';

const InnerForm = () => {
  return (
    <>
      <FormField
        name="message"
        component={FormInputTextArea}
        additionalProps={{
          autoSize: { minRows: 4, maxRows: 4 },
        }}
      />
      <FormField
        name="attachments"
        component={DocumentsField}
        additionalProps={{ isMultiple: true, isColumnReverse: true }}
      />
    </>
  );
};

export default InnerForm;
