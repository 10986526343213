import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { useFormikContext } from 'formik';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { DISABLED_FORM_STATUS } from 'constants/form';
import { TransactionApprovalTemplateModel } from 'typings/approvalManagement/accountManagement';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import CreateApprovalRule from './CreateApprovalRule';
import UpdateApprovalRule from './UpdateApprovalRule';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import OnboardingTransactionRulesTable, {
  ActionKeys,
  TableRecord,
} from 'components/Tables/TableTemplates/AccountManagement/OnboardingTransactionRulesTable';
import { Text, Col, Row } from '@ui';

interface IProps {
  applicationId: string;
  onAction: () => void;
}

const ApprovalRules = ({ applicationId, onAction }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentPage, setCurrentPage] = useState(1);
  const [updateTableTrigger, setUpdateTableTrigger] = useState({});
  const [selectedApprovalRuleId, setSelectedApprovalRuleId] = useState<
    string | null
  >(null);
  const activeClientGroupName = useSelector<StateModel, string>(
    (state) =>
      state.applications.activeApplication?.clientGroup?.clientGroupName || '',
  );
  const { status } = useFormikContext();
  const isFormDisabled = status === DISABLED_FORM_STATUS;

  const { response, loading } = useFetch<{
    data: TableRecord[];
    total: number;
  }>(async () => {
    const data = await onboardingAPI.fetchApprovalRules(
      {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      },
      'onboarding-transaction',
    );

    const formattedData = data.data.map((e) => {
      const approvalMatrix: TransactionApprovalTemplateModel = {
        id: e._id,
        isActive: true,
        bankAccounts: [],
        approvalRuleSets: e.ruleSets.map((rs, ruleSetIndex) => {
          return {
            approvalRuleItems: rs.items.map((c) => ({
              minimunApprovalThreashold:
                c.group.participants.length < c.minimumThreshold
                  ? 0
                  : c.minimumThreshold,
              approvalGroup: {
                id: c.group.id,
                name: c.group.name,
                description: '',
                participants: c.group.participants
                  ? c.group.participants.map((p) => ({
                      userId: p.contactId,
                      userFullName: p.name,
                    }))
                  : [],
              },
            })),
            approvalThreasholds: e.criteriaList.map((cl) => {
              return {
                threasholdMin: cl.range?.min || 0,
                threasholdMax: cl.range?.max || -1,
                ticked: cl.activeSetIndices.includes(ruleSetIndex),
              };
            }),
          };
        }),
      };

      return {
        id: e._id,
        bankAccounts: e.associatedAccounts.map((b) => ({
          name: `${activeClientGroupName} ${b.currencyIsoCode}`,
          purpose: b.purpose,
        })),
        approvalMatrix,
        errors:
          e.validationErrors && e.validationErrors.length
            ? e.validationErrors.map((error) => error.message)
            : [],
      };
    });

    return {
      total: data.total,
      data: formattedData,
    };
  }, [currentPage, updateTableTrigger, activeClientGroupName]);

  const handleDataUpdate = () => {
    setUpdateTableTrigger({});
    onAction();
  };

  const handleActionsClick = (key: ActionKeys, record: TableRecord) => {
    if (key == 'edit') {
      setSelectedApprovalRuleId(record.id);
    }
  };

  const handleUpdateModalClose = (wasModified?: boolean) => {
    setSelectedApprovalRuleId(null);
    wasModified && handleDataUpdate();
  };

  return (
    <LoadingWrapper loading={!response}>
      <Text
        weight="semi-bold"
        color={colorsTheme.colorWhite}
        variant="h5"
        gutterBottom
      >
        {t('administration.transaction_rules.approval_rules.title')}
      </Text>
      <Text gutterBottom>
        {t('administration.transaction_rules.approval_rules.description')}
      </Text>
      {response && !response.data.length ? (
        <HideIfDisabledForm>
          <CreateApprovalRule
            onAction={handleDataUpdate}
            applicationId={applicationId}
          />
        </HideIfDisabledForm>
      ) : (
        <>
          <HideIfDisabledForm>
            <Row justify="end">
              <Col>
                <CreateApprovalRule
                  onAction={handleDataUpdate}
                  applicationId={applicationId}
                />
              </Col>
            </Row>
          </HideIfDisabledForm>
          <OnboardingTransactionRulesTable
            data={response?.data || []}
            total={response?.total || 0}
            current={currentPage}
            loading={loading}
            onPaginationChange={setCurrentPage}
            onActionsClick={!isFormDisabled ? handleActionsClick : undefined}
          />
        </>
      )}
      <UpdateApprovalRule
        isVisible={!!selectedApprovalRuleId}
        applicationId={applicationId}
        approvalRuleId={selectedApprovalRuleId}
        closeCallback={handleUpdateModalClose}
      />
    </LoadingWrapper>
  );
};

export default ApprovalRules;
