import React from 'react';

// helpers
import { RegisterSecurityKeyValidationSchema } from '../../../../../validations/profile/security';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

export enum RegisterSecurityKeySteps {
  Initial,
  RegisterKey,
  SetupNameForKey,
}

export interface SecurityKeyCredModel {
  id: string;
  rawId: string;
  type: string;
  response: {
    clientDataJSON: string;
    attestationObject: string;
  };
}

export interface FormValuesModel {
  name: string;
  currentStep: RegisterSecurityKeySteps;
  securityKeyCredInfo: SecurityKeyCredModel | null;
}

const RegisterSecurityKeyModal = ({
  title,
  isVisible,
  initialValues,
  closeCallback,
  onSubmit,
}: IProps) => {
  return (
    <Form
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={RegisterSecurityKeyValidationSchema}
      renderForm={
        <ModalDialog
          width={600}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default RegisterSecurityKeyModal;
