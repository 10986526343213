import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// component
import { Text } from '@ui';
import { Link } from '@ui';
import UserDataStep from './UserDataStep';
import AuthContainer from 'components/Additional/AuthContainer';
import EmailConfirmationStep from './EmailConfirmationStep';

const InnerForm = memo(() => {
  const { t } = useTranslation('auth');
  const { values } = useFormikContext<FormValuesModel>();

  const title = useMemo(() => {
    let result = null;

    switch (values.step) {
      case 'user_data':
        result = t('reset_username.user_data_step_title');
        break;

      case 'email_confirmation':
        result = t('reset_username.email_confirmation_step_title');
        break;
    }

    return result;
  }, [values.step, t]);

  const contentComponent = useMemo(() => {
    let result = null;

    switch (values.step) {
      case 'user_data':
        result = <UserDataStep />;
        break;

      case 'email_confirmation':
        result = <EmailConfirmationStep />;
        break;
    }

    return result;
  }, [values.step]);

  return (
    <StyledAuthContainer
      footerText={
        <FooterLinkWrapper>
          <Link to={RoutePaths.Auth_Root}>
            {t('reset_username.go_back_to_signin')}
          </Link>
        </FooterLinkWrapper>
      }
    >
      <Title variant="h5" weight="semi-bold" gutterBottom>
        {title}
      </Title>
      <div>{contentComponent}</div>
    </StyledAuthContainer>
  );
});

const Title = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledAuthContainer = styled(AuthContainer)`
  min-height: 385px !important;
`;

const FooterLinkWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginXs};
  text-align: center;
`;

export default InnerForm;
