import React, { useMemo } from 'react';

// helpers
import { DescriptionWithLinksProps } from './types';

// components
import { Text } from '@ui';

const DescriptionWithLinks: React.FC<DescriptionWithLinksProps> = ({
  description,
}) => {
  const parsedText = useMemo(() => {
    function splitDescription(description: string) {
      const parts = description.split(/\[(.*?)\]\((.*?)\)/);
      return parts.filter(Boolean); // Remove empty strings from the result
    }

    if (!description) {
      return [];
    }

    return splitDescription(description);
  }, [description]);

  return (
    <Text variant="body1">
      {parsedText.map((part, index) => {
        if (index % 3 === 1) {
          // Render link text
          return (
            <a key={index} href={parsedText[index + 1]} target="blank">
              {part}
            </a>
          );
        } else if (index % 3 === 0) {
          // Render non-link text
          return <span key={index}>{part}</span>;
        } else {
          // Skip link URL parts
          return null;
        }
      })}
    </Text>
  );
};

export default React.memo(DescriptionWithLinks);
