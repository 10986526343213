import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { HeaderProps } from './types';

/**
 * The `Header` component represents the top area of the page layout, often used
 * for navigation, branding, or controls.
 *
 * @param {HeaderProps} props - The properties defined in `HeaderProps`.
 */
const Header: React.FC<HeaderProps> = ({ className, children, style }) => {
  return (
    <AntdLayout.Header className={className} style={style}>
      {children}
    </AntdLayout.Header>
  );
};

export default Header;
