import React from 'react';
import { Menu as AntdMenu } from 'antd';
import { MenuProps } from './types';

/**
 * The `Menu` component provides navigation or action lists.
 * It supports nesting, grouping, icons, and various triggers.
 *
 * @param {MenuProps} props - The properties defined in `MenuProps`.
 */
const Menu: React.FC<MenuProps> = ({ className, children, ...rest }) => {
  return (
    <AntdMenu className={className} {...rest}>
      {children}
    </AntdMenu>
  );
};

export default Menu;
