import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { RequestStatuses } from 'enums/connections/connections';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { CardWrapper } from 'components/Additional/CardWrapper';
import { Text, IconSVG, Col, Row } from '@ui';

interface IProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: React.ReactNode;
  type: React.ReactNode;
  status: React.ReactNode;
  requestedAt: string;
  requestStatus: RequestStatuses;
  actions?: React.ReactNode;
}

const RequestCard = ({
  icon,
  title,
  type,
  status,
  requestedAt,
  requestStatus,
  actions,
}: IProps) => {
  const { t } = useTranslation('connections');
  return (
    <StyledCardWrapper noMargin>
      <Row justify="space-between" align="middle">
        <Col>
          <Content>
            <StyledIconSVG
              size="middle"
              component={icon}
              color={colorsTheme.colorWhite}
            />
            <StyledDivAlignCenter>
              <Text variant="h6">{title}</Text>
              <Text>
                {`${t('requests.request_card.type')} `}
                {type}
              </Text>
              <Text>
                {`${t('requests.request_card.status')} `}
                {status}
              </Text>
              {requestStatus === RequestStatuses.Cancelled && (
                <Text>
                  {`${t('requests.request_card.reason')} `}
                  {t('requests.request_card.cancelled_reason_description', {
                    requestType: type,
                  })}
                </Text>
              )}
              <Text>
                {`${t('requests.request_card.requested_at')} `}
                {requestedAt}
              </Text>
            </StyledDivAlignCenter>
          </Content>
        </Col>
        {actions ? <Col>{actions}</Col> : null}
      </Row>
    </StyledCardWrapper>
  );
};

const StyledCardWrapper = styled(CardWrapper)`
  padding: ${({ theme }) => theme.paddingXl};
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
  margin-top: 3px;
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  flex-direction: column;
  align-items: start;
`;

export default RequestCard;
