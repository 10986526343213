import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from 'components/ModalDialogs/TemplateModalDialogs/Messaging/SendMessageModal/InnerForm';
import { clientMessagingAPI } from 'api/messaging/clientMessagingAPI';
import { clientMessagingAdapter } from 'apiAdapters/messaging/clientMessagingAdapter';

// components
import SendMessageComponent from '../../../Messaging/SendMessage';
import { Message } from '@ui';

interface IProps {
  categoryId?: number;
  subject?: string;
  messageId?: string;
  hideRecipientsField?: boolean;
  onSent?: () => void;
}

const SendMessage = ({ categoryId, hideRecipientsField, onSent }: IProps) => {
  const { t } = useTranslation('profile');

  const handleSendMessage = async (values: FormValuesModel) => {
    try {
      await clientMessagingAPI.createThread(
        clientMessagingAdapter.generateNewMessageBody(values),
      );
      Message.success(t('messages.send_message.success_submit_message'));
    } finally {
      onSent && onSent();
    }
  };

  return (
    <SendMessageComponent
      createThread={handleSendMessage}
      hideRecipientsField={hideRecipientsField}
      categoryId={categoryId}
    />
  );
};

export default SendMessage;
