import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { Moment } from 'moment';
import { getBadges } from 'redux/actions/app';
import { useDispatch } from 'react-redux';
import { DateHelpers } from 'helpers/date';
import { transfersAPI } from 'api/accounting/transfersAPI';
import { FormikHelpers } from 'formik';
import { AccountsHelpers } from 'helpers/accounts';
import { FormValuesModel as WireTransfersGridFormValuesModel } from 'components/Forms/TemplateForms/Transfers/WireTransferGridForm';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { Form, RequiredPropsForFormModel } from '@ui';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';

export interface FormValuesModel extends WireTransfersGridFormValuesModel {
  step: 'confirm-action' | 'processing' | 'completed';
  completed: number;
  result: {
    completed: number;
    failedTransactions: { transactionIndex: number; message: string }[];
  } | null;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  closeCallback: (wasSubmitted?: boolean, values?: FormValuesModel) => void;
}

const SubmitTransactionsBulkModal = ({
  closeCallback,
  isVisible,
  initialValues,
}: IProps) => {
  const { t } = useTranslation(['accounts', 'common', 'server_errors']);
  const dispatch = useDispatch();

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (values.step === 'completed') {
      closeCallback(true, values);
    } else {
      helpers.setFieldValue('step', 'processing');

      const result: {
        completed: string[];
        failed: { transactionIndex: number; message: string }[];
      } = {
        completed: [],
        failed: [],
      };

      for (let index = 0; index < values.transactions.length; index++) {
        const transaction = values.transactions[index];
        try {
          await transfersAPI.createWireTransfer({
            amount: transaction.amount
              ? AccountsHelpers.convertAmountFromIntToBigInt(
                  transaction.amount,
                ) || 0
              : 0,
            beneficiaryReference: transaction.toReference.trim(),
            currencyId: transaction.currencyId as number,
            fromAccountNumber: transaction.from as string,
            purpose: transaction.purpose.trim(),
            reference: transaction.fromReference.trim(),
            valueDate: DateHelpers.formatDateToTimestampWithTruncatedTime(
              transaction.valueDate as Moment,
            ),
            templateId: +(transaction.to || 0),
            relatedDocuments: [],
          });

          result.completed.push(transaction.customId);
        } catch (error) {
          result.failed.push({
            transactionIndex: index,
            message: `${index + 1} - ${t(
              ErrorHandlerService.getErrorCodeFromError(
                error as ErrorFromServer,
              ),
              {
                ns: 'server_errors',
              },
            )}`,
          });
        } finally {
          helpers.setFieldValue(
            'completed',
            result.completed.length + result.failed.length,
          );
        }
      }

      dispatch(getBadges());
      helpers.resetForm({
        values: {
          ...values,
          step: 'completed',
          result: {
            completed: result.completed.length,
            failedTransactions: result.failed,
          },
        },
      });
    }
  };

  return (
    <Form
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={isVisible ? initialValues : null}
      renderForm={(form) => (
        <ModalDialog
          closable={form.values.step !== 'processing'}
          isVisible={isVisible}
          closeCallback={closeCallback}
          closeModalAfterSubmit={false}
          hideFooterButtons={form.values.step === 'processing'}
          title={
            initialValues && form.values.step !== 'completed'
              ? t('international_transfer.submit_multiple_modal.title')
              : t(
                  'international_transfer.submit_multiple_modal.completed_title',
                )
          }
          cancelButtonProps={{ hidden: form.values.step === 'completed' }}
          submitButtonProps={{
            text:
              form.values.step === 'completed'
                ? t('ok', { ns: 'common' })
                : t('submit', { ns: 'common' }),
          }}
        >
          <LoadingWrapper
            loading={form.values.step === 'processing'}
            text={t('processing', {
              ns: 'common',
              number: form.values.completed,
              total: form.values.transactions?.length,
            })}
          >
            <InnerForm />
          </LoadingWrapper>
        </ModalDialog>
      )}
    />
  );
};

export default SubmitTransactionsBulkModal;
