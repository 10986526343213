import React from 'react';
import { Drawer as AntdDrawer } from 'antd';
import { DrawerProps } from './types';

/**
 * The `Drawer` component provides a panel that slides out from the edge of the screen
 * to display additional content or actions without navigating away from the current page.
 *
 * @param {DrawerProps} props - The properties defined in `DrawerProps`.
 */
const Drawer: React.FC<DrawerProps> = ({
  className,
  title,
  footer,
  children,
  ...rest
}) => {
  return (
    <AntdDrawer className={className} title={title} footer={footer} {...rest}>
      {children}
    </AntdDrawer>
  );
};

export default Drawer;
