import React from 'react';

// helpers
import { AutocompleteProps } from '@ui';
import { transferTemplatesAPI } from 'api/accounting/transferTemplatesAPI';
import { BlockchainTransferTemplateModel } from 'typings/accounting/transfer';

// components
import { FormAutocomplete } from '@ui';

export interface BlockchainTransferTemplatesAutocompleteProps
  extends AutocompleteProps<BlockchainTransferTemplateModel> {
  updateTemplatesTrigger?: any;
}

const BlockchainTransferTemplatesAutocomplete = (
  props: BlockchainTransferTemplatesAutocompleteProps,
) => {
  const fetchOptions = async (
    searchQuery: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } =
      await transferTemplatesAPI.getBlockchainTransferTemplates({
        page,
        limit,
        name: searchQuery,
      });

    return {
      total,
      options: data.map((e) => ({
        id: e.id,
        label: e.name,
        model: e,
      })),
    };
  };

  return <FormAutocomplete {...props} fetchData={fetchOptions} />;
};

export default BlockchainTransferTemplatesAutocomplete;
