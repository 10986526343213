import React, { Ref } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { Moment } from 'moment';
import { FormikHelpers, FormikProps } from 'formik';

// constants
import { NumberOfOfficesKeys } from '../../../FormComponents/SelectInputs/CRM/NumberOfOfficesSelect';
import { NumberOfEmployeesKeys } from '../../../FormComponents/SelectInputs/CRM/NumberOfEmployeesSelect';
import { ApplicantInformationOrganizationValidationSchema } from 'validations/onboarding-new/applicant-information';

// components
import { Form } from '@ui';
import InnerForm from './InnerForm';
import { SectionIntro } from '@ui';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  legalName: string;
  tradeName?: string;
  dateOfIncorporation: Moment | null;
  jurisdiction: string;
  industryCodes: string[];
  natureOfBusiness: string;
  numberOfEmployees: NumberOfEmployeesKeys | null;
  numberOfOffices: NumberOfOfficesKeys | null;
  registrationNumber: string;
  socialMedia: {
    websiteLink: string | undefined;
    twitterLink: string | undefined;
    facebookLink: string | undefined;
    linkedInLink: string | undefined;
  };
}

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel;
  onSubmit: (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;
  disabled?: boolean;
  title?: string;
  showOnlySaveButton?: boolean;
}

const GeneralInformationForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
  title,
}: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      disabled={disabled}
      validationSchema={ApplicantInformationOrganizationValidationSchema}
      renderForm={
        <>
          <SectionIntro
            title={title || t('applicant_information.general.title')}
            description={t('applicant_information.description_entity')}
          />

          <InnerForm />

          <HideIfDisabledForm>
            <SubmitButtons
              showBackButton={false}
              showOnlySaveButton={showOnlySaveButton}
            />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default GeneralInformationForm;
