import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { IAccountModel } from 'typings/onboarding/account';
import { useFormikContext } from 'formik';
import {
  AccountProcessingTypes,
  TransferTemplateTypes,
} from 'enums/accounting/transfers';
import {
  IInternalTransferTemplate,
  InternalTransferTemplateDataModel,
  ITransferTemplateShortModel,
  NewInternalTransferModel,
} from 'typings/accounting/transfer';

// components
import { Link } from '@ui';
import { FormField, IconSVG } from '@ui';
import { FormInput } from '@ui';
import DivAlignCenter from '../../../../../../Additional/DivAlignCenter';
import FormErrorTooltip from '../../../../../FormErrorTooltip';
import AddTransferTemplateModal from 'modules/Transfers/Templates/AddTransferTemplateModal';
import InternalTransferTemplateShortInfo from './InternalTransferTemplateShortInfo';
import InternalTransferTemplateAutocomplete, {
  InternalTransferTemplateAutocompleteProps,
} from '../../../../../FormComponents/Autocompletes/Accounting/InternalTransferTemplateAutocomplete';
import RecipientBankAccountAutocomplete, {
  OTHER_BANK_ACCOUNT_OPTION,
  RecipientBankAccountAutocompleteProps,
} from '../../../../../FormComponents/Autocompletes/Accounting/RecipientBankAccountAutocomplete';
import { ReactComponent as PlusIcon } from 'resources/icons/remix-icons/add-line.svg';

const TransferToSideForm = () => {
  const { t } = useTranslation('transfers');
  const { values, setValues, initialValues } =
    useFormikContext<NewInternalTransferModel>();
  const [updateTemplatesTrigger, updateTemplates] = useState({});
  const [isAddTemplateModalVisible, setIsAddTemplateModalVisible] =
    useState(false);

  const onAccountSelect = (newValue: string, account?: IAccountModel) => {
    setValues({
      ...values,
      recipient: {
        accountNumber: newValue,
        account: account || null,
        reference: '',
        templateId: null,
        template: null,
        processingType:
          values.sender.processingType === AccountProcessingTypes.Native
            ? AccountProcessingTypes.Native
            : AccountProcessingTypes.Circle,
      },
    });
  };

  const onAddTemplateClick = () => {
    setIsAddTemplateModalVisible(true);
  };

  const handleAddTemplateModalClose = (
    wasCreated?: boolean,
    newTemplate?: IInternalTransferTemplate,
  ) => {
    wasCreated && updateTemplates({});

    if (newTemplate) {
      setTransferTemplateData(newTemplate.id, {
        accountName: newTemplate.accountName,
        accountNumber: newTemplate.accountNumber,
        reference: newTemplate.recipientReference,
        currencyId: newTemplate.currencyId,
        currencyCode: newTemplate.currencyIsoCode,
      });
    }

    setIsAddTemplateModalVisible(false);
  };

  const setTransferTemplateData = (
    templateId: number | null,
    template: InternalTransferTemplateDataModel | null,
  ) => {
    setValues({
      ...values,
      recipient: {
        ...values.recipient,
        processingType: AccountProcessingTypes.Native,
        templateId,
        template,
        reference: template?.reference || '',
      },
    });
  };

  const handleTransferTemplateSelect = (
    template: ITransferTemplateShortModel,
  ) => {
    setTransferTemplateData(template.id, {
      accountName: template.additionalData.accountName,
      accountNumber: template.additionalData.accountNumber,
      reference: template.additionalData.recipientReference,
      currencyId: template.currencyId,
      currencyCode: template.currencyIsoCode,
    });
  };

  return (
    <>
      <FormField<RecipientBankAccountAutocompleteProps>
        disabled={!values.sender.accountNumber || !values.sender.processingType}
        name="recipient.accountNumber"
        label={t('internal_transfer.form_fields.to')}
        component={RecipientBankAccountAutocomplete}
        additionalProps={{
          onClear: () => onAccountSelect(''),
          onSelect: (newValue, option) =>
            onAccountSelect(newValue as string, option.model),
          senderAccountNumber: values.sender.accountNumber,
          addOtherBankAccountOption:
            values.sender.processingType === AccountProcessingTypes.Native,
          initialValue: values.recipient.accountNumberInitialValue,
          refreshAutocompleteTrigger: initialValues,
        }}
      />

      <FormErrorTooltip errorKey="recipient" />

      {values.recipient.accountNumber === OTHER_BANK_ACCOUNT_OPTION && (
        <FormField<InternalTransferTemplateAutocompleteProps>
          name="recipient.templateId"
          label={t('internal_transfer.form_fields.transfer_template')}
          component={InternalTransferTemplateAutocomplete}
          additionalProps={{
            refreshAutocompleteTrigger: updateTemplatesTrigger,
            initialValue: values.recipient.templateIdInitialValue,
            onSelect: (_, option) =>
              option.model && handleTransferTemplateSelect(option.model),
            onClear: () => setTransferTemplateData(null, null),
            appendToMenu: (
              <OptionWrapper
                className="ant-select-item ant-select-item-option"
                onClick={onAddTemplateClick}
              >
                <Link weight="semi-bold">
                  <DivAlignCenter>
                    <StyledIconSVG
                      component={PlusIcon}
                      color={colorsTheme.colorWhite}
                    />
                    {t('internal_transfer.add_transfer_template_button')}
                  </DivAlignCenter>
                </Link>
              </OptionWrapper>
            ),
          }}
        />
      )}

      {values.recipient.template && (
        <InternalTransferTemplateShortInfo
          template={values.recipient.template}
        />
      )}

      {values.recipient.accountNumber && (
        <FormField
          name="recipient.reference"
          component={FormInput}
          label={t('internal_transfer.form_fields.to_account_reference')}
        />
      )}

      <AddTransferTemplateModal
        returnFullModelAfterSubmit
        templateType={TransferTemplateTypes.Internal}
        isVisible={isAddTemplateModalVisible}
        closeCallback={(wasAdded, newTemplate) =>
          handleAddTemplateModalClose(
            wasAdded,
            newTemplate as IInternalTransferTemplate,
          )
        }
      />
    </>
  );
};

const OptionWrapper = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colorLightD1}`};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default TransferToSideForm;
