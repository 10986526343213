import React, { memo, useState } from 'react';

// helpers
import APIConfig from 'config/api';
import useTranslation from 'hooks/useTranslation';
import { SessionHelpers } from 'helpers/session';
import { DocumentHelpers } from 'helpers/documents';
import { LocalStorageHelpers } from 'helpers/storages/localStorage';

// components
import { Button } from '@ui';

interface IProps {
  type: 'transaction' | 'journal-entry';
  entryId: number;
}

const ExportToPDF = memo(({ type, entryId }: IProps) => {
  const { t } = useTranslation('common');
  const [loader, setLoader] = useState(false);

  const onExportClick = async () => {
    setLoader(true);
    const token = LocalStorageHelpers.getAccessToken();
    const fingerprint = await SessionHelpers.generateFingerprint();
    let link =
      type == 'journal-entry'
        ? `${APIConfig.accountingApi}/report/outgoing-wire-confirmation/journal-entry/${entryId}/pdf`
        : `${APIConfig.accountingApi}/report/outgoing-wire-confirmation/${entryId}/pdf`;
    link += `?tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
    await DocumentHelpers.downloadDocumentByURL(link, 'wire-confirmation');
    setLoader(false);
  };

  return (
    <Button loading={loader} onClick={onExportClick}>
      {t('export_to_pdf')}
    </Button>
  );
});

export default ExportToPDF;
