import React, { useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';

// components
import { Text, Button, Col, Row } from '@ui';
import UpdatePrimaryEmailModal from './UpdatePrimaryEmailModal';
import { CardWrapper } from 'components/Additional/CardWrapper';

const UpdatePrimaryEmail = () => {
  const { t } = useTranslation('profile');
  const [isVisible, setModalVisible] = useState(false);

  const currentEmail = useSelector<StateModel, string | undefined>(
    (state) => state.auth.contactData?.email.address,
  );

  return (
    <CardWrapper>
      <Row gutter={[16, 16]}>
        <Col xl={18} lg={18} md={12} sm={24} xs={24}>
          <Text variant="h4" weight="semi-bold">
            {t('security.update_primary_email.title')}
          </Text>
          <StyledText gutterBottom>{currentEmail}</StyledText>
          <Text variant="body1">
            {t('security.update_primary_email.description')}
          </Text>
        </Col>

        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <StyledButton
            size="large"
            type="bordered"
            onClick={() => setModalVisible(true)}
          >
            {t('security.update_primary_email.update_button')}
          </StyledButton>
        </Col>
      </Row>
      <UpdatePrimaryEmailModal
        isVisible={isVisible}
        closeCallback={() => setModalVisible(false)}
      />
    </CardWrapper>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colorPrimary};
`;

export default UpdatePrimaryEmail;
