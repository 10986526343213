import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { setTemporaryToken } from 'redux/actions/auth';
import { LocalStorageHelpers } from 'helpers/storages/localStorage';
import { WRONG_OTP_ERROR_CODE } from 'constants/errorCodes';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { ResetPasswordValidationSchema } from 'validations/profile/auth';

// components
import { Form, Message } from '@ui';
import ResetPasswordSteps from './ResetPasswordSteps';

export enum ResetTabs {
  RequestResetPasswordUsername = 'request_reset_password_username',
  RequestResetPasswordSMS = 'request_reset_password_sms',
  CodeVerification = 'otp_verification',
  SelfieVerification = 'selfie_verification',
  ResetPassword = 'new_password',
}

export interface FormValuesModel {
  currentStep: ResetTabs;
  username: string;
  verificationSmsCode: string;
  verificationCode: string;
  selfieSrc: string;
  newPassword: string;
  newConfirmPassword: string;
}

const ResetPassword = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { temporaryToken } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const initialFormValues = useMemo<FormValuesModel>(
    () => ({
      currentStep: ResetTabs.RequestResetPasswordUsername,
      //step 1:
      username: '',
      //step 2:
      verificationSmsCode: '',
      //step 3:
      verificationCode: '',
      //step 4:
      selfieSrc: '',
      //step 5:
      newPassword: '',
      newConfirmPassword: '',
    }),
    [],
  );

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    switch (values.currentStep) {
      case ResetTabs.RequestResetPasswordUsername: {
        await userAPI.requestResetUsername({ username: values.username });
        changeTab(ResetTabs.CodeVerification, helpers);
        break;
      }

      case ResetTabs.CodeVerification: {
        try {
          const { jwt } = await userAPI.verifyOtp({
            username: values.username,
            otp: values.verificationCode,
          });
          dispatch(setTemporaryToken(jwt));
          changeTab(ResetTabs.RequestResetPasswordSMS, helpers);
        } catch (error: any) {
          const errorCode = error.response?.data?.code || '';

          if (errorCode === WRONG_OTP_ERROR_CODE) {
            helpers.setFieldError('verificationCode', 'Wrong code');
          } else {
            throw error;
          }
        }
        break;
      }

      case ResetTabs.RequestResetPasswordSMS: {
        const { jwt } = await userAPI.verifySmsOtp(temporaryToken as string, {
          otp: values.verificationSmsCode,
        });
        dispatch(setTemporaryToken(jwt));
        changeTab(ResetTabs.SelfieVerification, helpers);
        break;
      }

      case ResetTabs.SelfieVerification:
        changeTab(ResetTabs.ResetPassword, helpers);
        break;

      case ResetTabs.ResetPassword: {
        const {
          username,
          verificationCode: token,
          selfieSrc,
          newPassword: password,
        } = values;
        await userAPI.resetPassword(temporaryToken as string, {
          username,
          token,
          selfieSrc,
          password,
        });
        Message.success(t('reset_password.success_password_reset'));
        LocalStorageHelpers.clear();
        dispatch(setTemporaryToken(null));
        navigate(RoutePaths.Auth_Root);
        break;
      }

      default:
        return;
    }
  };

  const changeTab = (
    newTab: ResetTabs,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    helpers.setFieldValue('currentStep', newTab);
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201020':
      case '1201018': {
        formikHelpers.setFieldError(
          'verificationCode',
          t(errorCode, { ns: 'server_errors' }),
        );
        break;
      }
      case '1201023':
      case '1201022': {
        formikHelpers.setFieldError(
          'verificationSmsCode',
          t(errorCode, { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      onSubmitError={handleOnSubmitError}
      renderForm={<ResetPasswordSteps />}
      validationSchema={ResetPasswordValidationSchema}
    />
  );
};

export default ResetPassword;
