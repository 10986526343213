import React, { useMemo } from 'react';

// helpers
import { NATIONALITY_CODES } from 'constants/countryCodes';
import { SelectOption, SelectProps } from '@ui';

// components
import { FormSelect } from '@ui';

export type IProps = SelectProps;

const NationalitySelect = (props: SelectProps) => {
  const options = useMemo<SelectOption[]>(
    () =>
      Object.keys(NATIONALITY_CODES).map((key) => ({
        id: key,
        label: NATIONALITY_CODES[key],
      })),
    [],
  );

  return <FormSelect {...props} options={options} />;
};

export default NationalitySelect;
