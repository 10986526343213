import React, { ReactNode } from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from 'typings/common';

// components
import { Card } from '@ui';
import { ReactComponent as PrimaryLogo } from 'resources/app-logos/primary-logo.svg';

interface IProps extends StyledComponentProps {
  children: ReactNode;
  marginTop?: string;
  footerText?: ReactNode;
  cardWidth?: string;
}

const AuthContainer = ({
  children,
  footerText,
  marginTop = '-100px',
  cardWidth = '450px',
  ...rest
}: IProps) => {
  return (
    <StyledCard marginTop={marginTop} cardWidth={cardWidth} {...rest}>
      <StyledLogoWrapper>
        <StyledLogoImg />
      </StyledLogoWrapper>
      <WrapperContainer>{children}</WrapperContainer>
      {footerText && <FooterWrapper>{footerText}</FooterWrapper>}
    </StyledCard>
  );
};

const FooterWrapper = styled.div`
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translate(-50%, 12px);
`;

const StyledCard = styled<any>(Card)`
  display: flex;
  margin-top: ${({ marginTop }) => marginTop};
  height: auto;
  width: ${({ cardWidth }) => cardWidth};
  min-height: 355px;

  .ant-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    padding: 30px;
    padding-top: 100px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-card-body {
      width: 100%;
      padding: 10px;
    }
  }
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogoImg = styled(PrimaryLogo)`
  width: 300px;
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export default AuthContainer;
