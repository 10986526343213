/* eslint-disable react/function-component-definition */
import React, { createContext, useCallback, useState } from 'react';
import RenderForm from './RenderForm';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { AnyObject, Maybe, ObjectSchema } from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { FormProps } from '../types';

/**
 * Context providing the validation schema and error-handling control
 * to `FormField` components or other consumers within the form.
 */
export const FormContext = createContext<{
  /**
   * The validation schema (Yup) used to validate form fields.
   */
  validationSchema: ObjectSchema<Maybe<AnyObject>> | null;
  /**
   * If true, errors thrown during `onSubmit` will be re-thrown,
   * potentially halting further execution. If false, errors are handled gracefully.
   */
  throwOnSubmitError: boolean;
  /**
   * A setter function to toggle `throwOnSubmitError`.
   */
  setThrowOnSubmitError: null | ((value: boolean) => void);
}>({
  validationSchema: null,
  throwOnSubmitError: false,
  setThrowOnSubmitError: null,
});

/**
 * The `Form` component is a high-level wrapper around Formik's `<Formik>` component.
 * It:
 * - Accepts initial values, validation schema, and a custom submit handler.
 * - Integrates with a `RenderForm` component to display fields and actions.
 * - Provides a context so that nested form fields can access the validation schema and error handling configurations.
 *
 * By default:
 * - Validation only occurs on form submission (not on change or blur).
 * - It can reinitialize fields when `initialValues` change if `enableReinitialize` is true.
 * - `confirmExitWithoutSaving` controls whether navigation prompts appear if the user attempts to leave with unsaved changes (logic handled by `RenderForm`).
 *
 * @template Values The shape of form values.
 *
 * @param {FormProps<Values>} props The props defined in `FormProps`.
 * @returns A Formik-wrapped form controlled by the `RenderForm` component.
 */
function Form<Values = unknown>({
  enableReinitialize = false,
  confirmExitWithoutSaving = true,
  initialValues,
  validationSchema,
  disabled,
  innerRef,
  onSubmit,
  onSave,
  renderForm,
  onSubmitError,
  ...rest
}: FormProps<Values>) {
  const [throwOnSubmitError, setThrowOnSubmitError] = useState(false);

  /**
   * Wrapped submit handler. Catches errors thrown in `onSubmit`:
   * - If `onSubmitError` is provided, call it with the error, values, and form helpers.
   * - Otherwise, delegate to `ErrorHandlerService`.
   * - If `throwOnSubmitError` is true, re-throw the error.
   */
  const handleSubmit = useCallback(
    async (values: Values, formikHelpers: FormikHelpers<Values>) => {
      try {
        await onSubmit(values, formikHelpers);
        return { success: true };
      } catch (error: any) {
        if (onSubmitError) {
          onSubmitError(error as ErrorFromServer, values, formikHelpers);
        } else {
          ErrorHandlerService.handleError(error);
        }
        if (throwOnSubmitError) {
          throw error;
        }
      }
    },
    [throwOnSubmitError, onSubmit, onSubmitError],
  );

  if (!renderForm) {
    return null;
  }

  return (
    <FormContext.Provider
      value={{ validationSchema, throwOnSubmitError, setThrowOnSubmitError }}
    >
      <Formik
        innerRef={innerRef}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues || ({} as any)}
        validationSchema={validationSchema}
        enableReinitialize={enableReinitialize}
      >
        {(form: FormikProps<Values>) => (
          <RenderForm
            {...rest}
            form={form}
            onSave={onSave}
            renderForm={renderForm}
            disabled={disabled}
            confirmExitWithoutSaving={confirmExitWithoutSaving}
          />
        )}
      </Formik>
    </FormContext.Provider>
  );
}

export default Form;
