import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from '../common';
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '../../constants/global';

export const ApprovalGroupValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: {
      number: NAME_MIN_LENGTH,
    },
  })
    .max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: NAME_MAX_LENGTH,
      },
    })
    .required('form:validation_messages.required'),

  description: STRING_VALIDATION_RULE.min(DESCRIPTION_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: {
      number: DESCRIPTION_MIN_LENGTH,
    },
  })
    .max(DESCRIPTION_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: DESCRIPTION_MAX_LENGTH,
      },
    })
    .notRequired(),

  participants: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          userId: STRING_VALIDATION_RULE,
          userFullName: STRING_VALIDATION_RULE,
        })
        .required(),
    )
    .required('form:validation_messages.required')
    .min(1, 'form:validation_messages.required'),

  initialParticipantOptions: yup
    .array(
      yup
        .object()
        .shape({
          id: STRING_VALIDATION_RULE,
          content: yup.mixed(),
        })
        .notRequired(),
    )
    .notRequired(),
});
