import React from 'react';
import { Carousel as AntdCarousel } from 'antd';
import { CarouselProps } from './types';

/**
 * The `Carousel` component displays a series of content or images
 * as a slideshow, allowing navigation between slides through arrows,
 * dots, or automatically.
 *
 * By encapsulating AntD’s Carousel, you ensure a consistent interface and styling,
 * making it easier to adjust or replace the implementation in the future.
 *
 * @param {CarouselProps} props - The properties defined in `CarouselProps`.
 */
const Carousel: React.FC<CarouselProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdCarousel className={className} {...rest}>
      {children}
    </AntdCarousel>
  );
};

export default Carousel;
