import React from 'react';

// helpers
import { ChangePasswordValidationSchema } from '../../../../../validations/profile/changePassword';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

export interface FormValuesModel {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePasswordModal = ({
  onSubmit,
  closeCallback,
  initialValues,
  isVisible,
  title,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ChangePasswordValidationSchema}
      renderForm={
        <ModalDialog
          width={600}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default ChangePasswordModal;
