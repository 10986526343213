import React from 'react';
import { Tag as AntdTag } from 'antd';
import { TagProps } from './types';

/**
 * The `Tag` component is used to label, categorize, or mark items.
 * It can display an optional close icon and supports different colors and statuses.
 *
 * @param {TagProps} props - The properties defined in `TagProps`.
 */
const Tag: React.FC<TagProps> = ({ className, children, ...rest }) => {
  return (
    <AntdTag className={className} {...rest}>
      {children}
    </AntdTag>
  );
};

export default Tag;
