import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import { approvalTransactionsAPI } from 'api/approvalManagement/approvalTransactionsAPI';
import { reviewMultipleTransactionsValidationSchema } from 'validations/accounting/transfers';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

export type FormValuesModel = {
  step: 'confirm-action' | 'processing' | 'completed';
  reason: string;
  action: 'approve' | 'reject';
  transactions: { id: string; workflowId: string; description: string }[];
  completed: number;
  result: {
    completed: number;
    failedTransactions: string[];
  } | null;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

const ReviewMultipleTransactionsModal = ({
  isVisible,
  closeCallback,
  initialValues,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['accounts', 'common']);

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (values.step === 'completed') {
      closeCallback(true);
    } else {
      helpers.setFieldValue('step', 'processing');

      const result: { completed: string[]; failed: string[] } = {
        completed: [],
        failed: [],
      };
      const reviewTransaction =
        values.action == 'approve'
          ? approvalTransactionsAPI.approveTransaction
          : approvalTransactionsAPI.rejectTransaction;

      for (const transaction of values.transactions) {
        try {
          await reviewTransaction(transaction.workflowId, values.reason, true);
          result.completed.push(transaction.id);
        } catch (error) {
          result.failed.push(`${transaction.id} - ${transaction.description}`);
        } finally {
          helpers.setFieldValue(
            'completed',
            result.completed.length + result.failed.length,
          );
        }
      }

      dispatch(getBadges());

      helpers.resetForm({
        values: {
          ...values,
          step: 'completed',
          result: {
            completed: result.completed.length,
            failedTransactions: result.failed,
          },
        },
      });
    }
  };

  return (
    <Form
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={reviewMultipleTransactionsValidationSchema}
      renderForm={(form) => (
        <ModalDialog
          isVisible={isVisible}
          closeCallback={() => closeCallback(!!form.values.result)}
          closeModalAfterSubmit={false}
          hideFooterButtons={form.values.step === 'processing'}
          title={
            initialValues && form.values.step !== 'completed'
              ? t(
                  `pending_transactions.review_multiple_txns_modal.${
                    initialValues.transactions.length > 1
                      ? 'review_title_plural'
                      : 'review_title'
                  }`,
                  {
                    action:
                      initialValues.action === 'approve'
                        ? t('approve', { ns: 'common' })
                        : t('reject', { ns: 'common' }),
                    number: initialValues.transactions.length,
                  },
                )
              : t(
                  'pending_transactions.review_multiple_txns_modal.completed_title',
                )
          }
          cancelButtonProps={{ hidden: form.values.step === 'completed' }}
          submitButtonProps={{
            text:
              form.values.step === 'completed'
                ? t('ok', { ns: 'common' })
                : initialValues?.action === 'approve'
                  ? t('approve', { ns: 'common' })
                  : t('reject', { ns: 'common' }),
          }}
        >
          <LoadingWrapper
            loading={form.values.step === 'processing'}
            text={t('processing', {
              ns: 'common',
              number: form.values.completed,
              total: form.values.transactions?.length,
            })}
          >
            <InnerForm />
          </LoadingWrapper>
        </ModalDialog>
      )}
    />
  );
};

export default ReviewMultipleTransactionsModal;
