import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import queryString from 'query-string';
import { RoutePaths } from 'routes/routes';
import { changeView } from '../../../redux/actions/view';
import { useDispatch } from 'react-redux';
import { accountsAPI } from '../../../api/accounting/accountsAPI';
import { IAccountModel } from '../../../typings/onboarding/account';
import { useLocation, useNavigate } from 'react-router-dom';

// components
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import TransactionsTable from './TransactionsTable';
import AccountDetailSection from './AccountDetailSection';

const AccountActivity = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountNumber } = queryString.parse(location.search);
  const [activeAccount, setActiveAccount] = useState<IAccountModel>();
  const [accounts, setAccounts] = useState<IAccountModel[]>([]);
  const { response, loading } = useFetch(
    () => accountsAPI.fetchAccounts({ page: 1, limit: 100 }),
    [],
  );

  const { response: processingTypeResponse, loading: processingTypeLoading } =
    useFetch(() => {
      if (accountNumber) {
        return accountsAPI.getAccountProcessingType(accountNumber as string);
      }

      return null;
    }, [accountNumber]);

  useEffect(() => {
    if (response) {
      setAccounts(response.data || []);
      const account =
        response.data.find(
          (account) => account.accountNumber === accountNumber,
        ) || response.data[0];
      handleAccountChange(account);
    }
  }, [response]);

  const handleAccountChange = (account: IAccountModel) => {
    const { accountNumber } = queryString.parse(location.search);
    setActiveAccount(account);
    if (account?.accountNumber !== accountNumber) {
      dispatch(
        changeView(() => {
          navigate({
            pathname: RoutePaths.Accounts_AccountActivity,
            search: `?accountNumber=${account.accountNumber}`,
          });
        }),
      );
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      <LoadingWrapper loading={processingTypeLoading}>
        <AccountDetailSection
          activeAccount={activeAccount}
          processingType={processingTypeResponse?.processingType}
          accounts={accounts}
          onChangeAccount={handleAccountChange}
        />
      </LoadingWrapper>
      <TransactionsTable accountNumber={activeAccount?.accountNumber} />
    </LoadingWrapper>
  );
};

export default AccountActivity;
