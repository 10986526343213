import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { securityAPI } from 'api/profile/securityAPI';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import OTPField from 'components/Forms/FormComponents/OTPField';
import { FormField } from '@ui';
import ResendOtp from 'components/Additional/ResendOtp';
import { FormInput } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('profile');
  const { values } = useFormikContext<FormValuesModel>();

  const resendOTP = async () => {
    await securityAPI.sendOTPToEmail(values.email);
  };

  return (
    <>
      {!values.otpSent ? (
        <FormField
          name="email"
          component={FormInput}
          label={t(
            'security.update_primary_email.modal_dialog.form_fields.email',
          )}
        />
      ) : (
        <OTPContainer>
          <OTPFieldWrapper>
            <FormField
              name="otp"
              component={StyledOTPField}
              additionalProps={{ isNumberInput: true }}
            />
          </OTPFieldWrapper>

          <StyledResendOtp
            successMessage={t(
              'security.update_primary_email.modal_dialog.otp_sent_message',
            )}
            resendOtpCallback={resendOTP}
          />
        </OTPContainer>
      )}
    </>
  );
};

const StyledResendOtp = styled(ResendOtp)`
  width: 200px;
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const OTPContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default InnerForm;
