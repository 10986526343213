import React from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { BreadcrumbItemProps } from './types';

/**
 * The `BreadcrumbItem` component represents a single crumb within a `Breadcrumb`.
 * It typically contains a link or text representing a page or section.
 *
 * @param {BreadcrumbItemProps} props - The properties defined in `BreadcrumbItemProps`.
 */
const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdBreadcrumb.Item className={className} {...rest}>
      {children}
    </AntdBreadcrumb.Item>
  );
};

export default BreadcrumbItem;
