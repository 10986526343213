import React from 'react';
import { Dropdown as AntdDropdown } from 'antd';
import { DropdownProps } from './types';

/**
 * The `Dropdown` component displays a menu or other element when the user
 * interacts with a trigger element (such as clicking or hovering on it).
 *
 * @param {DropdownProps} props - The properties defined in `DropdownProps`.
 */
const Dropdown: React.FC<DropdownProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdDropdown className={className} {...rest}>
      {children}
    </AntdDropdown>
  );
};

export default Dropdown;
