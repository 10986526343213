import React from 'react';

// helpers
import { PhoneConfirmationValidationSchema } from 'validations/profile/auth';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';

export type FormValuesModel = {
  code: string;
  phoneNumber: string | undefined;
};

interface IProps extends RequiredPropsForFormModel<FormValuesModel> {
  handleEditModalOpen?: () => void;
}

const VerifyPhoneNumberForm = ({
  initialValues,
  onSubmit,
  onSubmitError,
  handleEditModalOpen,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      initialValues={initialValues}
      validationSchema={PhoneConfirmationValidationSchema}
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
      enableReinitialize
      renderForm={<InnerForm handleEditModalOpen={handleEditModalOpen} />}
    />
  );
};

export default VerifyPhoneNumberForm;
