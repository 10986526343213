import * as Yup from 'yup';
import {
  OTP_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
} from '../common';

export const Enable2FAuthenticatorValidationSchema = Yup.object().shape({
  code: OTP_VALIDATION_RULE.required('form:validation_messages.required'),
});

export const RegisterSecurityKeyValidationSchema = Yup.object().shape({
  name: STRING_VALIDATION_RULE.required('form:validation_messages.required'),
});

export const UpdateSecurityKeyValidationSchema = Yup.object().shape({
  name: STRING_VALIDATION_RULE.required('form:validation_messages.required'),
});

export const UpdatePrimaryEmailValidationSchema = Yup.object().shape({
  email: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'profile:security.update_primary_email.modal_dialog.form_fields.email',
    },
  }),
  otpSent: NULLABLE_BOOLEAN_VALIDATION_RULE,
  otp: OTP_VALIDATION_RULE.when('otpSent', {
    is: true,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'common:one_time_password' },
      }),
  }),
});
