import React, { useEffect } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import {
  setTemporaryToken,
  setTwoFactorVerificationData,
} from 'redux/actions/auth';

// components
import AccountRecovery from 'modules/Auth/AccountRecovery';
import { Message } from '@ui';

const AccountRecoveryPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('auth');
  const { twoFactorVerificationData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  useEffect(() => {
    if (!twoFactorVerificationData) {
      Message.info(t('account_recovery.warning_no_auth_data'));
      navigate(RoutePaths.Auth_Root);
    }

    return () => {
      dispatch(setTwoFactorVerificationData(null));
      dispatch(setTemporaryToken(null));
    };
  }, [twoFactorVerificationData]);

  return <AccountRecovery />;
};

export default AccountRecoveryPage;
