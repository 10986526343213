import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { userManagementAPINew } from 'api/accountManagement/userManagementAPINew';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';

// components
import { IconSVG, Tabs } from '@ui';
import Identification from './Tabs/Identification';
import GeneralInformation from './Tabs/GeneralInformation';
import ApplicationDocumentation from './Tabs/ApplicationDocumentation';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

interface IProps {
  isViewOnly: boolean;
  onboardingStatus: OnboardingStatusModel;
  onSubmit: () => void;
}

type TabKeys =
  | 'generalInformation'
  | 'identification'
  | 'applicationDocumentation';

const Organization = ({ onboardingStatus, isViewOnly, onSubmit }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>('generalInformation');
  const [stepStatuses, setStepStatuses] = useState<Record<
    TabKeys,
    boolean | undefined
  > | null>(null);

  const applicantId = useSelector<StateModel, string | undefined>(
    ({ applications }) => applications.activeApplication?.crmItemId,
  );

  useEffect(() => {
    if (!stepStatuses) {
      const { informationFilled } = onboardingStatus;
      const statuses = {
        generalInformation: informationFilled.generalInformation,
        identification: informationFilled.identification,
        applicationDocumentation: informationFilled.applicationDocumentation,
      };

      const notCompletedStep = Object.keys(statuses).find(
        (key) => !statuses[key as TabKeys],
      );
      setStepStatuses(statuses as Record<TabKeys, boolean | undefined>);
      setCurrentStep(
        (notCompletedStep as TabKeys) || 'applicationDocumentation',
      );
    }
  }, [onboardingStatus]);

  const handleStepSave = async (savedStep: TabKeys) => {
    if (stepStatuses) {
      let stepStatusesCopy = {
        ...stepStatuses,
        [savedStep]: true,
      };
      if (savedStep === 'applicationDocumentation') {
        const response = await userManagementAPINew.fetchClientDetails(
          onboardingStatus.crmItemId as string,
          onboardingStatus.type as OnboardingEntryTypes,
        );

        const informationFilled = response.onboardingProcess.informationFilled;
        stepStatusesCopy = {
          ...stepStatuses,
          [savedStep]: informationFilled.applicationDocumentation,
        };
      }
      const nextNotCompletedStep = Object.keys(stepStatusesCopy).find(
        (key) => !stepStatusesCopy[key as TabKeys],
      );
      if (nextNotCompletedStep) {
        setStepStatuses(stepStatusesCopy);
        setCurrentStep(nextNotCompletedStep as TabKeys);
      } else {
        setStepStatuses(stepStatusesCopy);
      }
    }
  };

  const tabs = useMemo<
    { key: TabKeys; title: React.ReactNode; content: React.ReactNode }[]
  >(() => {
    const renderIcon = (key: TabKeys) => {
      if (!stepStatuses) {
        return;
      }

      return stepStatuses[key] ? (
        <StyledIconSVG component={CheckIcon} color={colorsTheme.colorPrimary} />
      ) : (
        <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
      );
    };

    const areAllStepsCompleted =
      !!stepStatuses &&
      !Object.keys(stepStatuses).find((key) => !stepStatuses[key as TabKeys]);

    return [
      {
        key: 'generalInformation',
        title: (
          <>
            {onboardingStatus.crmItemId === applicantId
              ? t('applicant_information.applicant.title')
              : t('applicant_information.general.title')}
            {renderIcon('generalInformation')}
          </>
        ),
        content: (
          <GeneralInformation
            onboardingStatus={onboardingStatus}
            isViewOnly={isViewOnly}
            onSave={() => handleStepSave('generalInformation')}
          />
        ),
      },

      {
        key: 'identification',
        title: (
          <>
            {t('identification.title')} {renderIcon('identification')}
          </>
        ),
        content: (
          <Identification
            onboardingStatus={onboardingStatus}
            isViewOnly={isViewOnly}
            onSave={() => handleStepSave('identification')}
          />
        ),
      },

      {
        key: 'applicationDocumentation',
        title: (
          <>
            {t('application_documentation.title')}{' '}
            {renderIcon('applicationDocumentation')}
          </>
        ),
        content: (
          <ApplicationDocumentation
            onboardingStatus={onboardingStatus}
            isViewOnly={isViewOnly}
            canComplete={areAllStepsCompleted}
            onSave={() => handleStepSave('applicationDocumentation')}
            onComplete={onSubmit}
          />
        ),
      },
    ];
  }, [onboardingStatus, stepStatuses, isViewOnly, applicantId]);

  return (
    <Tabs
      useChangeView
      tabs={tabs}
      activeKey={currentStep}
      defaultActiveKey={currentStep}
      onChange={setCurrentStep}
    />
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default Organization;
