import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { Text, Col, Row } from '@ui';
import GetVerificationCode from './GetVerificationCode';
import { CardWrapper } from 'components/Additional/CardWrapper';

const IdentityVerification = () => {
  const { t } = useTranslation('profile');

  return (
    <CardWrapper>
      <Row gutter={[16, 16]}>
        <Col span={18}>
          <Text variant="h4" weight="semi-bold">
            {t('security.identity_verification.title')}
          </Text>
          <Text variant="body1">
            {t('security.identity_verification.description')}
          </Text>
        </Col>

        <Col span={6}>
          <GetVerificationCode />
        </Col>
      </Row>
    </CardWrapper>
  );
};

export default IdentityVerification;
