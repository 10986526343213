// helpers
import styled from 'styled-components';

// components
import { Text } from '@ui';

export default styled(Text)`
  line-height: 20px;
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.fontSizeSm};
`;
