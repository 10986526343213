import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { ApprovalStatusMap } from '../..';

// components
import { Text, Col, Row, Select, SelectOption } from '@ui';

interface IProps {
  onFilterChange: (key: string, value: string) => void;
  selectedStatus: string | null;
  approvalStatuses: ApprovalStatusMap;
}

// TODO: add ability to filter by user name
const FilterBar = ({
  selectedStatus,
  onFilterChange,
  approvalStatuses,
}: IProps) => {
  const { t } = useTranslation('account_management');
  const filterGridSizes = { xl: 6, lg: 12, md: 24, sm: 24, xs: 24 };

  const options: SelectOption[] = useMemo(() => {
    return Object.keys(approvalStatuses).map((key) => ({
      id: key,
      label: t(`pending_approvals.approval_statuses.${key}`),
    }));
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col {...filterGridSizes}>
        <Row>
          <Col span={24}>
            <Text variant="body1">
              {t('pending_approvals.table.filter_bar.filter_by_status')}
            </Text>
          </Col>
          <Col span={24}>
            <StyledSelect
              allowClear={false}
              value={selectedStatus || undefined}
              size="large"
              onChange={(value) => onFilterChange('filter-by-status', value)}
              options={options}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default FilterBar;
