import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import { FormField, Col, Row } from '@ui';
import DivAlignCenter from '../../../../../Additional/DivAlignCenter';
import AccountPermissionSelect from '../../../../../Forms/FormComponents/SelectInputs/CRM/AccountPermissionSelect';
import AccountPermissionsTooltip from '../../../../../Tooltips/TemplateTooltips/AccountPermissionsTooltip';
import AccountAdministratorTooltip from '../../../../../Tooltips/TemplateTooltips/AccountAdministratorTooltip';
import AdministrationPermissionsSelect from 'components/Forms/FormComponents/SelectInputs/CRM/AdministrationPermissionsSelect';

const InnerForm = () => {
  const { t } = useTranslation(['onboarding']);
  const { values } = useFormikContext<FormValuesModel>();
  const gridSizes = { xl: 12, lg: 12, md: 12, sm: 24, xs: 24 };

  return (
    <>
      <StyledRow gutter={[16, 0]}>
        <Col {...gridSizes}>
          <FormField
            name="accountPermissions"
            component={AccountPermissionSelect}
            label={
              <DivAlignCenter>
                {t('personal_info_modal.form_fields.account_permissions')}{' '}
                <StyledAccountPermissionsTooltip />
              </DivAlignCenter>
            }
          />
        </Col>

        <Col {...gridSizes}>
          <FormField
            name="adminPermissionType"
            component={AdministrationPermissionsSelect}
            additionalProps={{
              hideLimitedPermission: !values.showLimitedPermission,
            }}
            label={
              <DivAlignCenter>
                {t('personal_info_modal.form_fields.account_administrator')}{' '}
                <StyledAccountAdministratorTooltip />
              </DivAlignCenter>
            }
          />
        </Col>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginXs};
`;

const StyledAccountPermissionsTooltip = styled(AccountPermissionsTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledAccountAdministratorTooltip = styled(AccountAdministratorTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default InnerForm;
