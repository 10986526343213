import React, { memo } from 'react';

// helpers
import { TransfersHelpers } from 'helpers/transfers/transfers';
import { AutocompleteProps } from '@ui';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';
import { ITransferTemplateShortModel } from '../../../../../../typings/accounting/transfer';

// components
import { FormAutocomplete } from '@ui';

export type InternalTransferTemplateAutocompleteProps =
  AutocompleteProps<ITransferTemplateShortModel>;

const InternalTransferTemplateAutocomplete = memo(
  (props: InternalTransferTemplateAutocompleteProps) => {
    const fetchOptions = async (
      search: string,
      page: number,
      limit: number,
    ) => {
      const { data, total } =
        await transferTemplatesAPI.getInternalTransferTemplates({
          page,
          limit,
          name: search,
        });

      return {
        total,
        options: data.map((e) => ({
          id: e.id,
          label: TransfersHelpers.getTransferTemplateName(String(e.id), e.name),
          model: e,
        })),
      };
    };

    return <FormAutocomplete {...props} fetchData={fetchOptions} />;
  },
);

export default InternalTransferTemplateAutocomplete;
