import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Text, IconSVG, Button } from '@ui';
import { ReactComponent as KeyIcon } from 'resources/icons/remix-icons/key-line.svg';

interface IProps {
  onNextClick: () => void;
}

const InitialStep = ({ onNextClick }: IProps) => {
  const { t } = useTranslation(['profile', 'common']);

  return (
    <>
      <StyledIconSVG
        component={KeyIcon}
        size="large"
        color={colorsTheme.colorWhite}
      />
      <StyledText variant="body1" gutterBottom>
        {t(
          'security.two_step_verification.security_key.register_new_key.welcome_step.description',
        )}
      </StyledText>
      <StyledButton size="large" onClick={onNextClick}>
        {t('next', { ns: 'common' })}
      </StyledButton>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default InitialStep;
