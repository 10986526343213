import React, { useEffect, useMemo } from 'react';

// helpers
import { useFormikContext } from 'formik';
import { TWO_COL_XL_GRID_SIZES } from 'constants/grids';
import { NewInternalTransferModel } from 'typings/accounting/transfer';

// components
import { Col, Row } from '@ui';
import TransferToSideForm from './TransferToSideForm';
import TransferFromSideForm from '../../TransferFromSideForm';
import DividerBetweenSections from '../../DividerBetweenSections';

const InnerForm = () => {
  const { values, setValues } = useFormikContext<NewInternalTransferModel>();

  // Clean data in recipient side if sender was updated
  useEffect(() => {
    const { sender, recipient } = values;
    if (sender.processingType !== recipient.processingType) {
      setValues({
        ...values,
        recipient: {
          processingType: null,
          accountNumber: '',
          account: null,
          reference: '',
          templateId: null,
          template: null,
        },
      });
    }
  }, [values.sender.processingType]);

  const recipientCurrency = useMemo(() => {
    let currency: { id: number; code: string } | undefined;

    if (values.recipient.account) {
      currency = {
        id: values.recipient.account.currencyId,
        code: values.recipient.account.currencyCode,
      };
    } else if (values.recipient.template) {
      currency = {
        id: values.recipient.template.currencyId,
        code: values.recipient.template.currencyCode,
      };
    }

    return currency;
  }, [values.recipient]);

  return (
    <Row gutter={[64, 0]}>
      <Col {...TWO_COL_XL_GRID_SIZES}>
        <TransferFromSideForm
          transferType="internal"
          recipientCurrency={recipientCurrency}
        />
      </Col>

      <DividerBetweenSections />

      <Col {...TWO_COL_XL_GRID_SIZES}>
        <TransferToSideForm />
      </Col>
    </Row>
  );
};

export default InnerForm;
