import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Text, IconSVG, Col, Row } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import Default2FALabel from '../Default2FALabel';
import EnableSecurityKey from './EnableSecurityKey';
import ManageSecurityKeys from './ManageSecurityKeys';
import DisableFIDOAuthentication from './DisableFIDOAuthentication';
import { ReactComponent as KeyIcon } from 'resources/icons/remix-icons/key-line.svg';

interface IProps {
  isDefault: boolean;
}

const SecurityKey = ({ isDefault }: IProps) => {
  const { t } = useTranslation('profile');
  const isTwoFactorFidoEnabled = useSelector<StateModel, boolean>(
    (state) => !!state.auth.profileData?.isTwoFactorFidoEnabled,
  );

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col xl={18} lg={18} md={12} sm={24} xs={24}>
          <TitleWrapper>
            <StyledIconSVG
              component={KeyIcon}
              size="middle"
              color={colorsTheme.colorWhite}
            />
            <TextWrapper>
              <StyledTitle variant="subtitle1" weight="semi-bold">
                <DivAlignCenter>
                  {t('security.two_step_verification.security_key.title')}{' '}
                  {isDefault && <Default2FALabel />}
                </DivAlignCenter>
              </StyledTitle>
              <Text variant="body1">
                {t('security.two_step_verification.security_key.description')}
              </Text>
            </TextWrapper>
          </TitleWrapper>
        </Col>

        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          {!isTwoFactorFidoEnabled ? (
            <EnableSecurityKey />
          ) : (
            <>
              <ManageSecurityKeys />
              <DisableFIDOAuthentication />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledTitle = styled(Text)`
  color: ${({ theme }) => theme.colorWhite};
`;

export default SecurityKey;
