import React, { memo } from 'react';

// helpers
import { VerifyEmailValidationSchema } from 'validations/profile/auth';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';

export type FormValuesModel = {
  otp: string;
  email: string | undefined;
};

type IProps = RequiredPropsForFormModel<FormValuesModel>;

const VerifyEmailForm = memo(
  ({ initialValues, onSubmit, onSubmitError }: IProps) => {
    return (
      <Form<FormValuesModel>
        initialValues={initialValues}
        validationSchema={VerifyEmailValidationSchema}
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        renderForm={<InnerForm />}
      />
    );
  },
);

export default VerifyEmailForm;
