import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { PageButton } from '@ui';
import CreateRelationshipModal from './Components/CreateRelationshipModal';

interface IProps {
  relationshipScopeId: string;
  closeCallback: (wasSubmitted?: boolean) => void;
}

const AddNewNodeModal = ({ relationshipScopeId, closeCallback }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const data = useMemo(
    () => ({
      activeApplicationScopeId: relationshipScopeId,
    }),
    [relationshipScopeId],
  );

  const handleModalClose = (wasSubmitted?: boolean) => {
    setIsModalVisible(false);
    closeCallback(wasSubmitted);
  };

  return (
    <>
      <PageButton onClick={() => setIsModalVisible(true)}>
        {t('new_node_modal.add_node_button')}
      </PageButton>
      <CreateRelationshipModal
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        data={data}
      />
    </>
  );
};

export default AddNewNodeModal;
