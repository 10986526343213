import React from 'react';
import { CheckboxProps } from './types';
import { Checkbox as AntdCheckbox } from 'antd';

/**
 * The `Checkbox` component is a controlled input for toggling a boolean state.
 *
 * @param {CheckboxProps} props - The properties defined in `CheckboxProps`.
 */
const Checkbox = ({
  value,
  onChange,
  disabled,
  name,
  ...rest
}: CheckboxProps) => {
  return (
    <AntdCheckbox
      name={name}
      checked={value}
      disabled={disabled}
      onChange={onChange}
      {...rest}
    />
  );
};

export default React.memo(Checkbox);
