import React, { ReactNode } from 'react';

// ** helpers utils **
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// ** components **
import { Badge } from '@ui';
import { StateModel } from 'redux/reducers';

interface IProps {
  children?: ReactNode;
}

const NewMessagesBadge = ({ children }: IProps) => {
  const newMessagesCount = useSelector<StateModel, number>(
    (state) => state.notifications.newMessagesCount,
  );

  return (
    <StyledBadge hasChildren={Boolean(children)} count={newMessagesCount}>
      {children}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)<{ hasChildren: boolean }>`
  .ant-badge-count {
    top: ${({ hasChildren }) => (hasChildren ? 0 : -2)}px;
    right: ${({ hasChildren }) => (hasChildren ? 0 : -8)}px;
  }
`;

export default NewMessagesBadge;
