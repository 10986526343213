import React from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';
import { StyledComponentProps } from 'typings/common';

// components
import { Text, Spin } from '@ui';

interface IProps extends StyledComponentProps {
  loading: boolean;
  children: any;
  text?: string;
}

const LoadingWrapper = ({ children, loading, text, ...rest }: IProps) => {
  return loading ? (
    <SpinWrapper {...rest}>
      <Spin size="large" />
      {text && (
        <Text gutterTop color={colorsTheme.colorLightL1}>
          {text}
        </Text>
      )}
    </SpinWrapper>
  ) : (
    children
  );
};

const SpinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 200px;
`;

export default LoadingWrapper;
