import React, { memo, useCallback, useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { userAPI } from 'api/profile/userAPI';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import OTPField from 'components/Forms/FormComponents/OTPField';
import ResendOtp from 'components/Additional/ResendOtp';
import SubmitButton from 'components/Buttons/SubmitButton';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { Text, FormField } from '@ui';

const EmailConfirmationStep = memo(() => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValuesModel>();
  const [canStartTimer, setCanStartTimer] = useState(false);

  useEffect(() => {
    const sendOtp = async () => {
      await userAPI.sendOTPForUsernameRequest(values.user.email);
      setCanStartTimer(true);
    };

    sendOtp();
  }, []);

  const resendEmailOtp = useCallback(async () => {
    await userAPI.sendOTPForUsernameRequest(values.user.email);
  }, [values]);

  return (
    <LoadingWrapper loading={!canStartTimer}>
      <StyledVerificationWrapper>
        <Text>{t('reset_username.email_verification_desc')}</Text>
        <OTPFieldWrapper>
          <FormField
            name="emailConfirmation.otp"
            component={StyledOTPField}
            additionalProps={{ isNumberInput: true }}
          />
        </OTPFieldWrapper>
        {canStartTimer && (
          <ResendOtp
            resendOtpCallback={resendEmailOtp}
            successMessage={t('reset_username.otp_sent_message')}
          />
        )}
      </StyledVerificationWrapper>
      <StyledSubmitButton>
        {t('reset_username.submit_email_confirmation_btn')}
      </StyledSubmitButton>
    </LoadingWrapper>
  );
});

const StyledVerificationWrapper = styled.div`
  text-align: center;
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginMd};
  margin-bottom: 0;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default EmailConfirmationStep;
