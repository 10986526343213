import React from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { CollapseProps } from './types';

/**
 * The `Collapse` component displays a list of collapsible panels.
 * Each panel can show or hide its content when toggled.
 *
 * @param {CollapseProps} props - The properties defined in `CollapseProps`.
 */
const Collapse: React.FC<CollapseProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <AntdCollapse className={className} {...rest}>
      {children}
    </AntdCollapse>
  );
};

export default Collapse;
