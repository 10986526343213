import React, { useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { PageButton } from '@ui';
import AddAccountModalDialog from './AddAccountModalDialog';

interface IProps {
  onCreate?: () => void;
}

const AddAccount = ({ onCreate }: IProps) => {
  const { t } = useTranslation('accounts');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalClose = (wasCreated?: boolean) => {
    if (wasCreated) {
      onCreate && onCreate();
    }

    setIsModalVisible(false);
  };

  return (
    <>
      <PageButton onClick={() => setIsModalVisible(true)}>
        {t('account_summary.create_account.create_account_button')}
      </PageButton>
      <AddAccountModalDialog
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default AddAccount;
