import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { AccountsHelpers } from 'helpers/accounts';
import { IEstimatedTransferRate } from 'typings/accounting/transfer';

// components
import {
  Text,
  ModalDialog,
  RequiredPropsForModalDialogModel,
  Col,
  Row,
} from '@ui';

interface IProps extends RequiredPropsForModalDialogModel {
  exchangeRateData: IEstimatedTransferRate;
  isTransactionFANNcy: boolean;
  loading?: boolean;
}

const EstimatedRateModal = ({
  isVisible,
  closeCallback,
  exchangeRateData,
  isTransactionFANNcy,
  loading,
}: IProps) => {
  const { t } = useTranslation(['transfers', 'common']);

  const isAtLeastOneReference = useMemo(
    () =>
      !!(
        exchangeRateData?.beneficiaryReference ||
        exchangeRateData?.fromAccountReference
      ),
    [exchangeRateData],
  );

  const renderFromInfo = (exchangeRateInfo: IEstimatedTransferRate) => {
    const {
      fromAccountName,
      fromAccountNumber,
      fromAccountReference,
      fromAccountCurrencyIsoCode,
      fromAccountAmount,
      beneficiaryRateWithSpread,
      fromAccountRateWithSpread,
    } = exchangeRateInfo;

    // TODO: need to use method that formats to locale string, currently it cannot be done because this method is also doing round operation
    const estimatedExchangeRate = AccountsHelpers.convertAmountFromBigIntToInt(
      isTransactionFANNcy
        ? beneficiaryRateWithSpread
        : fromAccountRateWithSpread,
    );

    return (
      <>
        <StyledParagraph
          variant="body1"
          weight="semi-bold"
        >{`${t('internal_transfer.estimated_rate_modal.from')}:`}</StyledParagraph>
        <StyledParagraph variant="body1">{fromAccountName}</StyledParagraph>
        <StyledParagraph variant="body1">{fromAccountNumber}</StyledParagraph>

        {isAtLeastOneReference && (
          <StyledParagraph>{fromAccountReference}</StyledParagraph>
        )}

        <AmountParagraph>{`${fromAccountCurrencyIsoCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(
          fromAccountAmount,
        )}`}</AmountParagraph>

        <StyledParagraph variant="body1" weight="semi-bold">
          {`${t('internal_transfer.estimated_rate_modal.exchange_rate')}: `}
          <ExchangeRateText variant="body1" color={colorsTheme.colorWarning}>
            {estimatedExchangeRate}
          </ExchangeRateText>
        </StyledParagraph>
      </>
    );
  };

  const renderToInfo = (exchangeRateInfo: IEstimatedTransferRate) => {
    const {
      beneficiaryAccountName,
      beneficiaryAccountNumber,
      beneficiaryReference,
      beneficiaryCurrencyIsoCode,
      beneficiaryAmount,
    } = exchangeRateInfo;

    return (
      <>
        <StyledParagraph
          variant="body1"
          weight="semi-bold"
        >{`${t('internal_transfer.estimated_rate_modal.to')}:`}</StyledParagraph>
        <StyledParagraph variant="body1">
          {beneficiaryAccountName}
        </StyledParagraph>
        <StyledParagraph variant="body1">
          {beneficiaryAccountNumber}
        </StyledParagraph>

        {isAtLeastOneReference && (
          <StyledParagraph variant="body1">
            {beneficiaryReference}
          </StyledParagraph>
        )}

        <AmountParagraph>
          {`${beneficiaryCurrencyIsoCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(beneficiaryAmount)}`}
        </AmountParagraph>
      </>
    );
  };

  if (!exchangeRateData) {
    return null;
  }

  return (
    <ModalDialog
      width="700px"
      title={t('internal_transfer.estimated_rate_modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      submitButtonProps={{
        loading,
        disabled: loading,
        text: t('submit', { ns: 'common' }),
        onClick: () => closeCallback(true),
      }}
    >
      <Text variant="body1" weight="semi-bold">
        {t('internal_transfer.estimated_rate_modal.description')}
      </Text>

      <Row>
        <Col span={12}>{renderFromInfo(exchangeRateData)}</Col>
        <Col span={12}>{renderToInfo(exchangeRateData)}</Col>
      </Row>
    </ModalDialog>
  );
};

const StyledParagraph = styled(Text)`
  min-height: 24px;
`;

const AmountParagraph = styled(Text)`
  margin-top: ${({ theme }) => theme.marginXs} !important;
  color: ${({ theme }) => theme.colorWarning};
`;

const ExchangeRateText = styled(Text)`
  margin-top: ${({ theme }) => theme.marginXs} !important;
`;

export default EstimatedRateModal;
