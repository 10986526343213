import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { Text } from '@ui';
import SessionHistory from './SessionHistory';
import CurrentSessions from './CurrentSessions';
import { CardWrapper } from 'components/Additional/CardWrapper';

interface IProps {
  updateViewTrigger: any;
  updateView: () => void;
}

const Sessions = ({ updateViewTrigger, updateView }: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <>
      <CardWrapper>
        <Text variant="h4" weight="semi-bold">
          {t('security.sessions.current_sessions.title')}
        </Text>
        <CurrentSessions
          updateViewTrigger={updateViewTrigger}
          updateView={updateView}
        />
      </CardWrapper>

      <CardWrapper>
        <Text variant="h4" weight="semi-bold">
          {t('security.sessions.session_history.title')}
        </Text>
        <SessionHistory updateViewTrigger={updateViewTrigger} />
      </CardWrapper>
    </>
  );
};

export default Sessions;
