import React, { useState, useCallback } from 'react';

// components
import OTPSingleInput from './OTPSingleInput';
import { Row, Col } from '@ui';

interface IProps {
  name: string;
  value: string;
  onChange: (value: any) => void;

  disabled?: boolean;
  autoFocus?: boolean;
  isNumberInput?: boolean;
}

const OTPInputComponent = (props: IProps) => {
  const OTP_INPUTS_COUNT = 6;

  const {
    disabled,
    onChange,
    isNumberInput,
    autoFocus = true,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState<string[]>(
    Array(OTP_INPUTS_COUNT).fill(''),
  );

  // ** HELPER METHODS **
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(
        Math.min(OTP_INPUTS_COUNT - 1, inputIndex),
        0,
      );
      setActiveInput(selectedIndex);
    },
    [OTP_INPUTS_COUNT],
  );

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const getRightValue = useCallback(
    (str: string) => {
      const changedValue = str;

      if (!isNumberInput) {
        return changedValue;
      }

      return !changedValue || /\d/.test(changedValue) ? changedValue : '';
    },
    [isNumberInput],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const handleOnBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('');
      onChange(otpValue);
    },
    [onChange],
  );

  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = otpValues.slice();

      updatedOTPValues[activeInput] = str[0] || '';

      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues],
  );

  // ** HANDLERS **
  const handleOnChange = useCallback(
    (e) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  );

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, OTP_INPUTS_COUNT - activeInput)
        .split('');

      if (pastedData) {
        let nextFocusIndex = 0;

        const updatedOTPValues = [...otpValues];

        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });

        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, OTP_INPUTS_COUNT - 1));
        handleOtpChange(updatedOTPValues);
      }
    },
    [activeInput, getRightValue, OTP_INPUTS_COUNT, otpValues],
  );

  return (
    <div {...rest}>
      <Row gutter={[8, 8]}>
        {Array(OTP_INPUTS_COUNT)
          .fill('')
          .map((_, i) => (
            <Col span={4} key={i}>
              <OTPSingleInput
                focus={activeInput === i}
                autoFocus={autoFocus}
                value={otpValues[i]}
                onFocus={handleOnFocus(i)}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnBlur}
                onPaste={handleOnPaste}
                disabled={!!disabled}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

// Memoize this component
const OTPInput = React.memo(OTPInputComponent);

export default OTPInput;
