import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { invitationAPI } from 'api/invitation/invitation';

// components
import { Text, Button, Message } from '@ui';
import InvitationRequestForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Invitation/InvitationRequestForm';

const InvitationRequest = () => {
  const { t } = useTranslation('invitation');

  const [isInvitationRequestSended, setIsInvitationRequestSended] =
    useState(false);

  const initialFormValues: FormValuesModel = useMemo(
    () => ({
      email: '',
    }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    await invitationAPI.generateCode(values);
    Message.success(
      t('invitation_request.success_message', { email: values.email }),
    );
    setIsInvitationRequestSended(true);
  };

  const sectionTitle = useMemo(() => {
    if (isInvitationRequestSended) {
      return (
        <>
          <StyledTitleText variant="h5" gutterBottom>
            {t('invitation_request.repeat_title')}
          </StyledTitleText>
          <StyledDescription variant="body1">
            {t('invitation_request.try_again_description')}
          </StyledDescription>
        </>
      );
    }

    return (
      <>
        <StyledTitleText variant="h5" gutterBottom>
          {t('invitation_request.title')}
        </StyledTitleText>
      </>
    );
  }, [isInvitationRequestSended]);

  return (
    <>
      <div>{sectionTitle}</div>

      <div>
        {!isInvitationRequestSended ? (
          <InvitationRequestForm
            onSubmit={handleSubmit}
            initialValues={initialFormValues}
          />
        ) : (
          <StyledButton
            size="large"
            onClick={() => setIsInvitationRequestSended(false)}
          >
            {t('invitation_request.try_again_button')}
          </StyledButton>
        )}
      </div>
    </>
  );
};

const StyledTitleText = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledDescription = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default InvitationRequest;
