import React, { memo, useCallback, useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { IKeyRelationshipTemplate } from 'typings/application/key-relationship-templates';

// components
import { FormField } from '@ui';
import EntityTypeCategoryAutocomplete, {
  EntityTypeCategoryAutocompleteProps,
} from '../../../../../../Forms/FormComponents/Autocompletes/CRM/EntityTypeCategoryAutocomplete';
import ContactShortInfoForm, {
  FormValuesModel as ContactFormValuesModel,
} from '../../../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';
import OrganizationShortInfoForm, {
  FormValuesModel as OrganizationFormValuesModel,
} from '../../../../../../Forms/TemplateForms/CRM/OrganizationShortInfoForm';

const NodeInformationSection = memo(() => {
  const { t } = useTranslation('onboarding');
  const { values, setValues } = useFormikContext<FormValuesModel>();

  const NodeContent = useMemo(() => {
    if (!values.template.id) {
      return null;
    }

    switch (values.template.visibleFor) {
      case OnboardingEntryTypes.Contact:
        return (
          <ContactShortInfoForm
            namePrefix="contact"
            hideAccessFieldsWhenNotOnline
          />
        );

      case OnboardingEntryTypes.Organization:
        return <OrganizationShortInfoForm namePrefix="organization" />;
    }
  }, [values]);

  const handleTypeSelect = useCallback(
    (id: string, template: IKeyRelationshipTemplate) => {
      const emptyContactData: ContactFormValuesModel = {
        isPermissionsSelectAvailable: false,
        firstName: '',
        lastName: '',
        middleName: '',
        isPEP: null,
        pepInformation: '',
        isOnlineUser: null,
        adminPermissionType: null,
        accountPermissions: null,
        phone: '',
        email: '',
      };

      const emptyOrganizationData: OrganizationFormValuesModel = {
        name: '',
        isRegulated: null,
        regulatedCountries: [],
      };

      setValues({
        ...values,
        template: {
          id,
          visibleFor: template.visibleFor as OnboardingEntryTypes,
        },
        contact: values.contact
          ? values.contact
          : template.visibleFor === OnboardingEntryTypes.Contact
            ? emptyContactData
            : null,
        organization: values.organization
          ? values.organization
          : template.visibleFor === OnboardingEntryTypes.Organization
            ? emptyOrganizationData
            : null,
      });
    },
    [values],
  );

  return (
    <>
      <FormField<EntityTypeCategoryAutocompleteProps>
        name="template.id"
        label={t(
          'node_modal.relationships.form_fields.relationship_entry_type',
        )}
        component={EntityTypeCategoryAutocomplete}
        additionalProps={{
          isVisible: true,
          clientGroupEntryType: values.clientGroupEntryType,
          onSelect: (newValue, option) =>
            option.model && handleTypeSelect(newValue as string, option.model),
        }}
      />

      {NodeContent}
    </>
  );
});

export default NodeInformationSection;
