import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { DISABLED_FORM_STATUS } from '../../../../../../../constants/form';
import { OnboardingStatusModel } from '../../../../../../../typings/onboarding/onboarding';

// components
import EditNodeModal from '../ModalDialogs/EditNodeModal';
import HideIfDisabledForm from '../../../../../HideIfDisabledForm';
import CreateRelationshipWithNewNodes from './Components/CreateRelationshipWithNewNodes';
import GraphChart, {
  GraphData,
  GraphEdge,
  GraphNode,
} from '../../../../../../Charts/GraphChart';
import { Col, Row } from '@ui';

interface IProps {
  graph: GraphData;
  onboardingData: OnboardingStatusModel;
  onModify: () => void;
}

const ChartView = ({ graph, onboardingData, onModify }: IProps) => {
  const { status } = useFormikContext<FormValuesModel>();
  const isFormDisabled = status === DISABLED_FORM_STATUS;
  const [formattedGraphData, setFormattedGraphData] =
    useState<GraphData | null>(null);

  const [openedModal, setOpenedModal] = useState<{
    modalKey: 'view-node';
    edge?: GraphEdge;
    childNode?: GraphNode;
    parentNode?: GraphNode;
    edges?: GraphEdge[];
  } | null>(null);

  useEffect(() => {
    const result: GraphData = { nodes: [], edges: [] };
    result.nodes = graph.nodes.map((n) => ({
      ...n,
      reviewStatus: undefined,
      actions: {
        onClick: () => onNodeClick(n, graph.edges),
      },
    }));
    result.edges = graph.edges.map((edge) => ({ ...edge }));
    setFormattedGraphData(result);
  }, [graph]);

  const onNodeClick = (node: GraphNode, edges: GraphEdge[]) => {
    const nodeEdges = edges.filter((e) => e.source === node.id);

    setOpenedModal({
      modalKey: 'view-node',
      parentNode: node,
      edges: nodeEdges,
    });
  };

  const handleModalClose = (wasModified?: boolean) => {
    if (wasModified) {
      onModify();
    }

    setOpenedModal(null);
  };

  const handleHandoffRequest = (node: GraphNode | null) => {
    if (node) {
      setFormattedGraphData((prev) => {
        if (!prev) {
          return null;
        }

        const nodeIndex = prev.nodes.findIndex((e) => e.id == node.id);
        prev.nodes[nodeIndex].metadata.handoffCanBeSent = false;
        return prev;
      });
    }
  };

  return (
    <>
      <HideIfDisabledForm>
        <StyledRow justify="end" gutter={[16, 16]}>
          <Col>
            <CreateRelationshipWithNewNodes
              onboardingData={onboardingData}
              closeCallback={handleModalClose}
            />
          </Col>
        </StyledRow>
      </HideIfDisabledForm>
      <TreeWrapper>
        {formattedGraphData && (
          <GraphChart
            displaySharedDataStatusIcon
            displayApplicantIcon
            data={formattedGraphData}
          />
        )}
      </TreeWrapper>
      <EditNodeModal
        isViewOnly={isFormDisabled}
        isVisible={openedModal?.modalKey === 'view-node'}
        closeCallback={handleModalClose}
        onHandoffRequestSend={() =>
          handleHandoffRequest(openedModal?.parentNode as GraphNode)
        }
        data={
          openedModal?.modalKey === 'view-node'
            ? {
                activeScopeId: onboardingData.relationshipScopeId,
                node: openedModal?.parentNode as GraphNode,
                edges: openedModal.edges || [],
                clientGroupId: onboardingData.clientGroupId,
                allNodes: graph.nodes,
              }
            : null
        }
      />
    </>
  );
};

const TreeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colorDarkL4};
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default ChartView;
