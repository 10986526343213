import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// constants
import {
  UploadDocumentMethods,
  VerificationDocumentTypes,
} from '../../../../../enums/profile/accountRecovery';

// components
import { Link } from '@ui';
import WebcamMethodForm from './WebcamMethodForm';
import UploadDocumentMethodForm from './UploadDocumentMethodForm';

const UploadVerificationDocumentForm = () => {
  const { t } = useTranslation('auth');
  const { values, setValues } = useFormikContext<FormValuesModel>();

  const handleGoBackClick = () => {
    setValues({
      ...values,
      snapshots: null,
      idVerificationType: null,
      isUploadedIdDocumentPhotos: false,
    });
  };

  // Render content form
  // 1) Webcam method - render steps to take a photos using webcam (front, back (depends on type of document), selfie)
  // 2) Upload method - for this method we should use 2 different layouts:
  //    1. The layout where user should upload (browse) all document photos
  //    2. The webcam layout - after the user has uploaded all photos of the document, he should make a selfie using webcam
  const renderContentForm = (
    uploadDocumentMethod: UploadDocumentMethods | null,
    idVerificationType: VerificationDocumentTypes | null,
  ) => {
    if (!uploadDocumentMethod || !idVerificationType) {
      return null;
    }

    const shouldRenderWebcam =
      uploadDocumentMethod === UploadDocumentMethods.Webcam ||
      values.isUploadedIdDocumentPhotos;

    return shouldRenderWebcam ? (
      <WebcamMethodForm />
    ) : (
      <UploadDocumentMethodForm />
    );
  };

  return (
    <>
      {renderContentForm(
        values.uploadDocumentMethod,
        values.idVerificationType,
      )}

      <BottomActionsWrapper>
        <StyledParagraph onClick={() => handleGoBackClick()}>
          {t('account_recovery.go_to_previous_step')}
        </StyledParagraph>
      </BottomActionsWrapper>
    </>
  );
};

const BottomActionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginMd};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledParagraph = styled(Link)`
  color: ${({ theme }) => theme.colorAdditionalL1} !important;
  transition: ${({ theme }) => theme.defaultTransition};

  &:hover {
    color: ${({ theme }) => theme.colorWhite} !important;
  }
`;

export default UploadVerificationDocumentForm;
