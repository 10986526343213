import React from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';
import { DatePickerProps } from './types';
import { DatePicker as AntdDatePicker } from 'antd';

// TODO: investigate problems with types of AntdDatePicker component, right now from some reason we can't render it
const DatePickerBase = AntdDatePicker as any;

/**
 * The `DatePicker` component provides a user interface for selecting a single date.
 * It displays a calendar from which users can pick a date, optionally styled to
 * highlight future dates. A placeholder can guide users when no date is selected.
 *
 * @param {DatePickerProps} props - The properties defined in `DatePickerProps`.
 */

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  placeholder,
  disabled,
  className,
  ...rest
}) => {
  const dateRender = (currentDate: Moment, today: Moment) => {
    return (
      <StyledDate
        className="ant-picker-cell-inner"
        isFuture={today.diff(currentDate) < 0}
      >
        {currentDate.date()}
      </StyledDate>
    );
  };

  return (
    <DatePickerBase
      {...rest}
      allowClear
      dateRender={dateRender}
      value={value}
      disabled={disabled}
      className={className}
      placeholder={placeholder}
    />
  );
};

const StyledDate = styled.div<{ isFuture: boolean }>`
  color: ${({ isFuture, theme }) =>
    isFuture ? theme.colorSuccess : 'none'} !important;
`;

export default DatePicker;
