import React, { useMemo, useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { fetchPersonalDetails } from 'redux/actions/personalDetails';
import { useDispatch, useSelector } from 'react-redux';
import { personalDetailsAPIAdapter } from 'apiAdapters/profile/personalDetailsAPIAdapter';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';
import { AssociationEntries, OnboardingEntryTypes } from 'enums/onboarding/crm';
import {
  ChangeRequestStatuses,
  personalDetailsAPI,
} from 'api/profile/personalDetailsAPI';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ApplicationDocumentationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/ApplicationDocumentationForm';
import { Message } from '@ui';

const Verification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { changeRequest, contact } = useSelector<
    StateModel,
    PersonalDetailsStateModel
  >((state) => state.personalDetails);
  const [updateAnswersTrigger, updateAnswers] = useState<any>();

  const { response: answersResponse, loading: answersLoader } = useFetch(
    () =>
      contact
        ? personalDetailsAPI.fetchVerificationDocuments(
            contact._id,
            changeRequest?.status === ChangeRequestStatuses.InProgress
              ? changeRequest._id
              : undefined,
          )
        : null,
    [changeRequest?.status, contact?._id, updateAnswersTrigger],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!answersResponse || !contact) {
      return null;
    }

    return personalDetailsAPIAdapter.formatVerificationDocumentsToFormModel(
      answersResponse,
      {
        id: contact._id,
        type: AssociationEntries.Contact,
      },
    );
  }, [answersResponse, contact]);

  const onSubmit = async (formValues: FormValuesModel) => {
    const formattedBody = personalDetailsAPIAdapter.submitVerificationStep(
      formValues,
      initialFormValues as FormValuesModel,
      contact?._id as string,
      OnboardingEntryTypes.Contact,
      changeRequest?._id as string,
    );

    await personalDetailsAPI.submitVerificationStep(formattedBody);
    dispatch(fetchPersonalDetails(true));
    Message.success(t('success_save'));
    updateAnswers({});
  };

  return (
    <LoadingWrapper loading={answersLoader}>
      {initialFormValues && (
        <ApplicationDocumentationForm
          showOnlySaveButton
          onSubmit={onSubmit}
          disabled={
            !changeRequest ||
            changeRequest.status !== ChangeRequestStatuses.InProgress
          }
          initialFormValues={initialFormValues}
        />
      )}
    </LoadingWrapper>
  );
};

export default Verification;
