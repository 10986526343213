import React from 'react';
import { ColProps } from './types';
import { Col as AntdCol } from 'antd';

/**
 * The `Col` component represents a single column within a grid layout.
 * It is typically used inside a `Row` to structure page content responsively.
 *
 * This component wraps AntD’s `Col` to maintain a consistent API and styling
 * across your design system.
 *
 * @param {ColProps} props - The properties defined in `ColProps`.
 */
const Col: React.FC<ColProps> = ({
  children,
  className,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  flex,
  offset,
  pull,
  style,
  push,
}) => {
  return (
    <AntdCol
      className={className}
      span={span}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xxl={xxl}
      flex={flex}
      offset={offset}
      pull={pull}
      style={style}
      push={push}
    >
      {children}
    </AntdCol>
  );
};

export default Col;
