import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useNavigate } from 'react-router-dom';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from 'enums/profile/auth';
import { setTwoFactorVerificationData } from 'redux/actions/auth';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';

// components
import OTPField from 'components/Forms/FormComponents/OTPField';
import SubmitButton from 'components/Buttons/SubmitButton';
import { ReactComponent as VerifiedIcon } from 'resources/icons/remix-icons/shield-check-line.svg';
import { Text, FormField, IconSVG, Link, Collapse, Panel } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { twoFactorVerificationData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const isFidoEnabled = useMemo(() => {
    if (twoFactorVerificationData?.options) {
      return !!twoFactorVerificationData?.options.find(
        (e) => e.code === TwoFactorVerificationTypes.Fido && e.enabled,
      );
    }
  }, [twoFactorVerificationData?.options]);

  const changeToFido = () => {
    if (twoFactorVerificationData) {
      dispatch(
        setTwoFactorVerificationData({
          ...twoFactorVerificationData,
          type: TwoFactorVerificationTypes.Fido,
        }),
      );
    }
  };

  const navigateToAccountRecovery = () => {
    navigate(RoutePaths.Auth_AccountRecovery);
  };

  return (
    <Wrapper>
      <StyledText variant="body1" gutterBottom>
        <StyledIconSVG
          component={VerifiedIcon}
          color={colorsTheme.colorWhite}
          size="large"
        />
        {t('two_factor_authentication.authenticator.enter_otp_code')}
      </StyledText>

      <OTPFieldWrapper>
        <FormField
          name="code"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </OTPFieldWrapper>

      <StyledSubmitButton fullWidth>
        {t('two_factor_authentication.authenticator.submit_button')}
      </StyledSubmitButton>

      <Collapse bordered={false}>
        <Panel
          key={1}
          collapsible="header"
          header={t(
            'two_factor_authentication.authenticator.unable_to_submit_otp',
          )}
        >
          {isFidoEnabled && (
            <StyledLink onClick={changeToFido}>
              {t(
                'two_factor_authentication.authenticator.use_fido_to_continue',
              )}
            </StyledLink>
          )}
          <StyledLink onClick={navigateToAccountRecovery}>
            {t('two_factor_authentication.authenticator.lost_my_authenticator')}
          </StyledLink>
        </Panel>
      </Collapse>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const StyledLink = styled(Link)`
  display: block;
`;

export default InnerForm;
