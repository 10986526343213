import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { userAPI } from 'api/profile/userAPI';
import { BrowserHelpers } from 'helpers/browser';

// components
import { Text } from '@ui';
import Content from './Content';
import BrowserIsNotSupported from '../../../../../../Additional/Auth/TwoFactorVerification/BrowserIsNotSupported';

const RegisterKeyStep = () => {
  const canUseWebAuthn = BrowserHelpers.isWebAuthnSupported();
  const { t } = useTranslation('profile');
  const { response: challenge, loading } = useFetch(
    () => (canUseWebAuthn ? userAPI.getChallengeForRegistrationNewKey() : null),
    [canUseWebAuthn],
  );

  return (
    <>
      <StyledTitleText variant="h5" weight="semi-bold" gutterBottom>
        {t(
          'security.two_step_verification.security_key.register_new_key.register_key_step.title',
        )}
      </StyledTitleText>
      {canUseWebAuthn ? (
        <Content loadingChallenge={loading} challenge={challenge} />
      ) : (
        <BrowserIsNotSupported />
      )}
    </>
  );
};

const StyledTitleText = styled(Text)`
  text-align: center;
`;

export default RegisterKeyStep;
