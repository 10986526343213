import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { InvitationCodeValidation } from 'validations/invitations';

// components
import InnerForm from './InnerForm';
import SubmitButton from '../../../../Buttons/SubmitButton';
import { Form, RequiredPropsForFormModel } from '@ui';

export interface FormValuesModel {
  code: string;
  termsOfUse: boolean | null;
}

type IProps = RequiredPropsForFormModel<FormValuesModel>;

const InvitationVerifyForm = ({ onSubmit, initialValues }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={InvitationCodeValidation}
      renderForm={
        <>
          <InnerForm />
          <StyledSubmitButton fullWidth type="primary">
            {t('submit')}
          </StyledSubmitButton>
        </>
      }
    />
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default InvitationVerifyForm;
