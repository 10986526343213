import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormInput } from '@ui';
import { FormField } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('invitation');

  return (
    <FormField
      name="email"
      component={FormInput}
      label={t('invitation_request.form_fields.email')}
    />
  );
};

export default InnerForm;
