import React, { useCallback, useEffect, useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useQueryParam from 'hooks/useQueryParam';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { transferTemplatesAPI } from 'api/accounting/transferTemplatesAPI';
import { TransferTemplateTypes } from 'enums/accounting/transfers';
import { TransferTemplateStatuses } from 'enums/accounting/templates';
import { TransferTemplateStatusesFilterBar } from './FIlterBar/TransferTemplateStatusSelect';
import {
  INewTransferTemplateFormValues,
  ITransferTemplateShortModel,
} from 'typings/accounting/transfer';

// components
import { Divider } from '@ui';
import FilterBar from './FIlterBar';
import EditTransferTemplateModal from '../EditTransferTemplateModal';
import DuplicateTransferTemplateModal from '../DuplicateTransferTemplateModal';
import {
  default as TemplateTransferTemplatesTable,
  ActionKeys,
} from 'components/Tables/TableTemplates/Accounts/TransferTemplatesTable';

interface IProps {
  updateTableTrigger?: any;
}

export type FilterKeys = 'filter_by_status';

const TransferTemplatesTable = ({ updateTableTrigger }: IProps) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [localUpdateTableTrigger, updateTable] = useState({});
  const [filterValues, setFilterValues] = useState<{
    status?: TransferTemplateStatusesFilterBar;
  }>({
    status: TransferTemplateStatuses.Active,
  });

  const templateId = useQueryParam({ param: 'templateId' });
  const templateType = useQueryParam({ param: 'templateType' });

  const [selectedTransferTemplate, setSelectedTransferTemplate] = useState<{
    id: number;
    type: TransferTemplateTypes;
  } | null>(null);
  const [formValuesForDuplicateModal, setFormValuesForDuplicateModal] =
    useState<INewTransferTemplateFormValues | null>(null);

  const { response, loading } = useFetch(
    () =>
      transferTemplatesAPI.getTransferTemplates({
        limit: DEFAULT_TABLE_LIMIT,
        page: currentPage,
        status:
          !filterValues.status || filterValues.status === 'All'
            ? undefined
            : TransferTemplateStatuses[filterValues.status],
      }),
    [currentPage, updateTableTrigger, localUpdateTableTrigger, filterValues],
  );

  const handleActionsClick = (
    key: ActionKeys,
    record: ITransferTemplateShortModel,
  ) => {
    switch (key) {
      case 'view-details': {
        setSelectedTransferTemplate({
          id: record.id,
          type: record.templateTypeId,
        });
      }
    }
  };

  const handleDuplicateClick = useCallback(
    (formValues: INewTransferTemplateFormValues | null) => {
      setFormValuesForDuplicateModal(formValues);
    },
    [],
  );

  const handleModalClose = useCallback(
    (wasUpdated?: boolean) => {
      if (templateId && templateType) {
        const searchParams = new URLSearchParams(location.search);

        searchParams.delete('templateId');
        searchParams.delete('templateType');

        const newSearchParams = searchParams.toString();

        navigate({
          pathname: location.pathname,
          search: newSearchParams,
        });
      }
      setSelectedTransferTemplate(null);
      wasUpdated && updateTable({});
    },
    [templateId, templateType],
  );

  const handleDuplicateModalClose = useCallback((wasUpdated?: boolean) => {
    setFormValuesForDuplicateModal(null);

    if (wasUpdated) {
      setSelectedTransferTemplate(null);
      updateTable({});
    }
  }, []);

  const handleFilterChange = (key: FilterKeys, value?: string) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'filter_by_status':
        setFilterValues((prev) => ({
          ...prev,
          status: value
            ? (value as TransferTemplateStatusesFilterBar)
            : undefined,
        }));
        break;
    }
  };

  useEffect(() => {
    if (templateId && templateType) {
      setSelectedTransferTemplate({
        id: Number(templateId),
        type: Number(templateType),
      });
    }
  }, [templateId, templateType]);

  return (
    <>
      <FilterBar
        selectedStatus={filterValues.status}
        onFilterChange={handleFilterChange}
      />

      <Divider />

      <TemplateTransferTemplatesTable
        current={currentPage}
        loading={loading}
        data={response?.data || []}
        total={response?.total || 0}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />

      <EditTransferTemplateModal
        templateId={selectedTransferTemplate?.id || null}
        templateType={selectedTransferTemplate?.type || null}
        isVisible={!!selectedTransferTemplate}
        closeCallback={handleModalClose}
        onDuplicateClick={handleDuplicateClick}
      />

      <DuplicateTransferTemplateModal
        isVisible={!!formValuesForDuplicateModal}
        initialValues={formValuesForDuplicateModal}
        closeCallback={handleDuplicateModalClose}
      />
    </>
  );
};

export default TransferTemplatesTable;
