import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { StateModel } from 'redux/reducers';
import { FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { accountManagementRulesAPI } from 'api/accountManagement/accountManagementRulesAPI';

// components
import { Message } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import WarningIconWithText from 'components/Additional/WarningIconWithText';
import ApprovalGroupModal, {
  ApprovalGroupFormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Approval/ApprovalGroupModal';

interface IProps {
  groupId: string;
  isVisible: boolean;
  closeCallback: (wasCreated?: boolean) => void;
}

const EditApprovalGroupModal = ({
  isVisible,
  closeCallback,
  groupId,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const dispatch = useDispatch();
  const { response } = useFetch(
    () =>
      groupId
        ? accountManagementRulesAPI.fetchApprovalGroupById(groupId)
        : null,
    [groupId],
  );

  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const initialFormValues: ApprovalGroupFormValuesModel | null = useMemo(() => {
    let result = null;

    if (response) {
      const { name, description, participants } = response;
      const initialParticipantOptions = participants.map((e) => ({
        id: e.userId,
        label: e.userFullName,
        model: e,
      }));
      result = { name, description, participants, initialParticipantOptions };
    }

    return result;
  }, [response]);

  const handleFormSubmit = async (
    values: ApprovalGroupFormValuesModel,
    _: FormikHelpers<ApprovalGroupFormValuesModel>,
    isUpdated?: boolean,
  ) => {
    if (!isUpdated) {
      Message.info(t('nothing_to_update', { ns: 'common' }));
    } else {
      const updatedGroup = {
        name: values.name,
        description: values.description as string,
        participants: values.participants,
        id: groupId,
      };
      await accountManagementRulesAPI.editApprovalGroup(updatedGroup);
      dispatch(getBadges());
      Message.success(
        t('success_submit_for_account_management_review_message'),
      );
    }
  };

  const modalTitle = useMemo(() => {
    if (!isTwoFactorVerificationEnabled) {
      return (
        <DivAlignCenter>
          <StyledTitle>
            {t('approval_groups.approval_group_modal.edit.title')}
          </StyledTitle>
          <StyledWarningInfoNot2FA
            text={t(
              'approval_groups.approval_group_modal.edit.warning_not_2fa',
            )}
          />
        </DivAlignCenter>
      );
    }

    return t('approval_groups.approval_group_modal.edit.title');
  }, [isTwoFactorVerificationEnabled]);

  return (
    <ApprovalGroupModal
      isEditMode
      approvalWorkflowType="transactions"
      title={modalTitle}
      onSubmit={handleFormSubmit}
      isVisible={isVisible}
      disabled={!isTwoFactorVerificationEnabled}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      loadingInitialData={!initialFormValues}
    />
  );
};

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default EditApprovalGroupModal;
