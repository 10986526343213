import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import { Button } from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';

export type ActionTypes = 'approve' | 'reject';

const ActionButtons = memo(() => {
  const [hasApproveAccess, hasRejectAccess] = useUserAccess([
    'Connections_Requests_Approve',
    'Connections_Requests_Reject',
  ]);
  const { t } = useTranslation('connections');
  const { setFieldValue, submitForm, values, isSubmitting } =
    useFormikContext<FormValuesModel>();

  const onAction = async (action: ActionTypes) => {
    setFieldValue('actionType', action);
    submitForm();
  };

  return (
    <DivAlignCenter>
      {hasRejectAccess && (
        <StyledButton
          fullWidth
          type="bordered"
          size="large"
          loading={isSubmitting && values.actionType === 'reject'}
          onClick={() => onAction('reject')}
        >
          {t('requests.view_request_modal.share_contact_request.reject_btn')}
        </StyledButton>
      )}
      {hasApproveAccess && (
        <Button
          loading={isSubmitting && values.actionType === 'approve'}
          fullWidth
          size="large"
          onClick={() => onAction('approve')}
        >
          {t('requests.view_request_modal.share_contact_request.approve_btn')}
        </Button>
      )}
    </DivAlignCenter>
  );
});

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default ActionButtons;
