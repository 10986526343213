import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';

// components
import { ReactComponent as DownloadIcon } from 'resources/icons/remix-icons/download-2-line.svg';
import { IconSVG, Popover } from '@ui';
import { colorsTheme } from 'resources/theme/styled/colors';

interface IProps {
  derivedEntryType: EntryTypes;
}

type EntryTypes = 'transaction' | 'template';

const DerivedFromCircleTooltip = ({ derivedEntryType }: IProps) => {
  const { t } = useTranslation('transfers');

  const getPopoverContent = (entry: EntryTypes) => {
    switch (entry) {
      case 'template': {
        return t('additional.derived_from_circle.template');
      }

      case 'transaction': {
        return t('additional.derived_from_circle.transaction');
      }
    }
  };

  return (
    <Popover
      placement="bottom"
      content={getPopoverContent(derivedEntryType)}
      overlayStyle={{ zIndex: 99999 }}
    >
      <StyledIconSVG
        color={colorsTheme.colorWarning}
        component={DownloadIcon}
      />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default DerivedFromCircleTooltip;
