import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { DateHelpers } from 'helpers/date';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ApprovalStatuses } from 'enums/approvalManagement/approvalManagement';
import { IApprovalMatrixRecord } from 'typings/accounting/transaction';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';

// components
import StatusLabel from 'components/Typography/StatusLabel';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Trans } from 'react-i18next';
import { Divider, Text, Row, Col } from '@ui';

interface IProps {
  transactionApprovals: IApprovalMatrixRecord[][] | null;
}

const STATUS_MAP = {
  success: [ApprovalStatuses.Approved],
  error: [ApprovalStatuses.Rejected],
  warning: [],
  info: [],
};

const ApprovalReviewResults = ({ transactionApprovals }: IProps) => {
  const { t } = useTranslation('account_management');

  if (!transactionApprovals) {
    return null;
  }

  const renderStatusValue = (status: ApprovalStatuses, date: string) => {
    if (status === ApprovalStatuses.Pending) {
      return '';
    }

    let content = null;
    switch (status) {
      case ApprovalStatuses.Approved:
        content = `${t('approval_matrix.statuses.approved')} - ${DateHelpers.formatDateToString(date, DEFAULT_DATE_TIME_FORMAT)}`;
        break;
      case ApprovalStatuses.Rejected:
        content = `${t('approval_matrix.statuses.rejected')} - ${DateHelpers.formatDateToString(date, DEFAULT_DATE_TIME_FORMAT)}`;
        break;
    }

    return (
      <StatusLabel status={status} statusMap={STATUS_MAP} content={content} />
    );
  };

  return (
    <>
      {transactionApprovals.map((conditions, i) => (
        <>
          <StyledCard>
            <Text
              variant="h6"
              weight="semi-bold"
              color={colorsTheme.colorWhite}
            >
              {t(
                'approval_rules.approval_matrix_with_review_results.condition_with_idx',
                { number: i + 1 },
              )}
            </Text>
            <StyledDivider />
            {conditions.map((condition, conditionIdx) => (
              <>
                <Text>
                  <Trans
                    t={t}
                    i18nKey="approval_rules.approval_matrix_with_review_results.rule_label"
                    values={{
                      approvalGroupCondition: condition.groupNameTittle,
                    }}
                    components={[
                      <Text
                        variant="span"
                        weight="semi-bold"
                        color={colorsTheme.colorWhite}
                      />,
                    ]}
                  />
                </Text>
                <Text>
                  <Text
                    variant="span"
                    weight="semi-bold"
                    color={colorsTheme.colorWhite}
                  >
                    {t(
                      'approval_rules.approval_matrix_with_review_results.members',
                    )}
                  </Text>
                </Text>
                {condition.members.map((member) => (
                  <Row>
                    <Col span={8}>
                      <Text>{member.userFullName}</Text>
                    </Col>
                    <Col span={8}>
                      {renderStatusValue(
                        member.approvalAction,
                        member.approvalDate,
                      )}
                    </Col>
                    <Col span={8}>
                      <EllipsisTooltip
                        maxTextContainerWidth="200px"
                        title={member.approvalReason}
                      >
                        {member.approvalReason}
                      </EllipsisTooltip>
                    </Col>
                  </Row>
                ))}

                {conditions.length !== conditionIdx + 1 ? (
                  <StyledANDWrapper>
                    {t(
                      'approval_rules.approval_matrix_with_review_results.and',
                    )}
                  </StyledANDWrapper>
                ) : null}
              </>
            ))}
          </StyledCard>
          {transactionApprovals.length !== i + 1 ? (
            <StyledORSectionsDivider>
              <StyledORWrapper>
                {t('approval_rules.approval_matrix_with_review_results.or')}
              </StyledORWrapper>
            </StyledORSectionsDivider>
          ) : null}
        </>
      ))}
    </>
  );
};

const StyledORWrapper = styled.div`
  width: 50px;
  margin: 8px 0px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colorWhite};
  background: ${({ theme }) => theme.colorWarning};
`;

const StyledANDWrapper = styled.div`
  width: 50px;
  margin: 8px 0px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colorWhite};
  background: ${({ theme }) => theme.colorPrimary};
`;

const StyledCard = styled.div`
  padding: ${({ theme }) => theme.paddingSm};
  border: 1px solid #42515b;
  background: rgba(0, 0, 0, 0.2);
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXXs} 0px`};
`;

const StyledORSectionsDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXXs} 0px`};
`;

export default ApprovalReviewResults;
