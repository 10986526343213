import React from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { Input as AntDInput } from 'antd';
import { InputPasswordProps } from './types';

/**
 * The `InputPassword` component renders a secure input field for entering passwords.
 * It supports configurable size, disabled state, custom placeholder text, and focus/blur events.
 *
 * @param {InputPasswordProps} props - The properties defined in `InputPasswordProps`.
 */
const InputPassword: React.FC<InputPasswordProps> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  disabled,
  placeholder,
  name,
  size,
  ...rest
}) => {
  const { t } = useTranslation('form');

  return (
    <AntDInput.Password
      size={size}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      disabled={disabled}
      placeholder={placeholder || t('enter_value')}
      {...rest}
    />
  );
};

export default React.memo(InputPassword);
