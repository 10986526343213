import React from 'react';
import { Card as AntdCard } from 'antd';
import { CardProps } from './types';

/**
 * The `Card` component displays content and actions on a single subject.
 * It encapsulates information and makes actions readily accessible.
 *
 * Encapsulating AntD’s `Card` allows for consistent styling and easy replacement
 * of the underlying implementation if necessary.
 *
 * @param {CardProps} props - The properties defined in `CardProps`.
 */
const Card: React.FC<CardProps> = ({
  className,
  title,
  cover,
  children,
  ...rest
}) => {
  return (
    <AntdCard className={className} title={title} cover={cover} {...rest}>
      {children}
    </AntdCard>
  );
};

export default Card;
