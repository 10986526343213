import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { ResetTabs } from '../..';

// components
import { Text, FormField } from '@ui';
import { FormInput } from '@ui';

const RequestResetPasswordUsername = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <StyledText variant="body1" gutterBottom>
        {t(
          `reset_password.steps.${ResetTabs.RequestResetPasswordUsername}.description`,
        )}
      </StyledText>

      <FormField
        name="username"
        label={t(
          `reset_password.steps.${ResetTabs.RequestResetPasswordUsername}.form_fields.username`,
        )}
        component={FormInput}
      />
    </>
  );
};

const StyledText = styled(Text)`
  text-align: center;
`;

export default RequestResetPasswordUsername;
